// @flow
import React, { useContext, useEffect } from 'react';
import { PacingContext } from 'context';
import { InputLabel, Datepicker, Grid } from '@ssgglobal/techintnue'
import { Input } from 'reactstrap';

type Props = {
    portfolioAsOf: string,
    updatePortfolioAsOf: Function,
};

const inputStyle = {
    color: '#268fb3',
    border: '1px solid #BDBFBF',
    borderRadius: '5px',
    padding: '8px 8px 8px 16px',
    width: '100%',
    height: '40px',
}

export default function({
    portfolioAsOf,
    updatePortfolioAsOf,
}: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);
    const onChangeHandler = (e) => {
        const dateTarget = {
            target: {
                value: e
            }
        }
        updatePortfolioAsOf(dateTarget)
    }
    return (
        <>
            <InputLabel md={12}>Portfolio as of</InputLabel>
            <>
                {pacingClientReadOnlyAndSpiClientId() ? (
                    <div>{portfolioAsOf}</div>
                ) : (
                    <Grid sx={{
                        '& > .MuiFormControl-root > .MuiInputBase-root': {
                            height: '40px',
                            '& > input': {
                                fontSize: '12px',
                            }
                        }
                    }}>
                        <Datepicker
                            value={portfolioAsOf}
                            onChange={onChangeHandler}
                            format='YYYY-MM-DD'
                        />
                    </Grid>
                )}
            </>
        </>
    );
}
