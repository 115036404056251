import React from 'react';
import { DropdownTextField } from '@ssgglobal/techintnue'

type Props = {
    month: string,
    handleMonthChange: Function,
};

const MonthDropdown = ({ month, handleMonthChange }: Props) => {
    const monthOptions = ['March', 'June', 'September'];

    const months = monthOptions.map((currMonth) => {
        return (
            <option key={currMonth} value={currMonth}>
                {currMonth}
            </option>
        );
    });

    const monthsOpt = monthOptions.map((currMonth) => {
        return {
            label: currMonth,
            value: currMonth
        }
    });

    const handleChange = (e) => {
        const monthChange = {
            target: {
                name: 'fiscalMonth',
                value: e.target.value
            }
        }
        handleMonthChange(monthChange)
    }

    return (
        <DropdownTextField 
            options={monthsOpt}
            value={month}
            onChange={handleChange}
        />
    );
};

export default MonthDropdown;
