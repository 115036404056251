import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import './App.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { ContextProviders } from 'context';

import Routes from './Routes';
import { SsgThemeProvider } from '@ssgglobal/techintnue';
import ThemeProviders from './theme';

require('dotenv').config();

const bugsnagClient = bugsnag({
    apiKey: process.env.BUGSNAG_API_KEY || process.env.REACT_APP_BUGSNAG,
    releaseStage: process.env.REACT_APP_ENV,
    enabledReleaseStages: ['production', 'staging'],
});
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

// callbackMap set up
window.callbackMap = {}

const App = () => (
    <ErrorBoundary>
        <SsgThemeProvider>
            <ThemeProviders>
                <Router>
                    <ContextProviders>
                        <Routes />
                    </ContextProviders>
                </Router>
            </ThemeProviders>
        </SsgThemeProvider>
    </ErrorBoundary>
);

export default App;
