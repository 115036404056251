import React from 'react';
import { Row, Col } from 'reactstrap';
import {
    NavGraph,
} from './components';

type Props = {
    viewYear: boolean,
    growthOnLiquidAssets: number,
};

const QuarterlyNavPlot = ({ growthOnLiquidAssets, viewYear }: Props) => (
    <div className='panel'>
        <Row>
            <Col md="12">
                <NavGraph
                    growthOnLiquidAssets={growthOnLiquidAssets}
                    viewYear={viewYear}
                />
            </Col>
        </Row>
    </div>
);

export default QuarterlyNavPlot;
