import { useState } from 'react';

// TODO: Move this to techint
const useSnackbar = () => {
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const clearSnackbar = () => {
        setSnackbarMessage(null);
    };

    const fireSnackbar = (message) => {
        setSnackbarMessage(message);
        // setTimeout(clearSnackbar, 2000);
    };

    return [snackbarMessage, fireSnackbar, clearSnackbar];
};

export default useSnackbar;
