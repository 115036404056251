const uuidv4 = require('uuid/v4');

/**
 * Format fund with existing default assumptions
 *
 * @param {*} values
 */
const setExistingValues = (values) => {
    let coinvestFlag;
    if (Object.prototype.hasOwnProperty.call(values, 'coinvestFlag')) {
        coinvestFlag = values.coinvestFlag;
    } else if (Object.prototype.hasOwnProperty.call(values, 'coinvest_flag')) {
        coinvestFlag = values.coinvest_flag;
    } else {
        coinvestFlag = values.p7;
    }

    return {
        strategy: values.strategy || values.Strategy,
        model: values.model || values.Model,
        paidIn: values.paidIn || values.paid_in || values.p1 || 0,
        irr: values.irr || values.p2 || 0,
        yld: values.yld || values.p3 || 0,
        fundLife: values.fundLife || values.fund_life || values.p4 || 1,
        bow: values.bow || values.p5 || 0,
        rc1: values.rc1 || values.p6 || 0,
        rc2: values.rc2 || values.y1 || 0,
        rc3: values.rc3 || values.y2 || 0,
        rc4: values.rc4 || values.y4 || 0,
        rc5: values.rc5 || values.y5 || 0,
        coinvestFlag,
        contribTiming: values.contribTiming || values.contrib_timing,
        commitQtr: values.commitQtr || 'Q2',
        case: values.case,
        userEdited: values.userEdited || [],
    };
};

/**
 * Set default values for fund that don't already have
 * default assumptions
 *
 * @param {*} defaultStrats
 * @param {*} valueScenario
 */
const setDefaultValues = (defaultStrats, valueScenario) => {
    const defaultValues = defaultStrats.find(
        (row) => row.case === valueScenario
    );

    return {
        strategy: defaultValues.strategy,
        model: defaultValues.model,
        paidIn: defaultValues.paidIn,
        irr: defaultValues.irr,
        yld: defaultValues.yld,
        fundLife: defaultValues.fundLife,
        bow: defaultValues.bow,
        rc1: defaultValues.rc1,
        rc2: defaultValues.rc2,
        rc3: defaultValues.rc3,
        rc4: defaultValues.rc4,
        rc5: defaultValues.rc5,
        coinvestFlag: defaultValues.coinvestFlag,
        contribTiming: defaultValues.contribTiming,
        commitQtr: 'Q2',
        case: 'Base',
        userEdited: defaultValues.userEdited || [],
    };
};

const setFundId = (fundSliderRow, tableData) => {
    const foundTableDataRecord = tableData.find((row) => {
        if (row.investmentId) {
            return row.investmentId === fundSliderRow.investmentId;
        } else {
            return row.Investment === fundSliderRow.Investment;
        }
    });

    return foundTableDataRecord?.Id || uuidv4();
};

/**
 * Foromat fundSliderValues properties on load
 * Prevents need to check 2+ different variable names
 * within the app
 *
 * @param {*} fundSliderValues
 * @param {*} tableData
 * @param {*} strategyParameters
 */
const normalizeFundSliderValues = (
    fundSliderValues,
    tableData,
    strategyParameters
) => {
    // return formatted fundSliderValues
    const newFundSliderValues = [];
    fundSliderValues.forEach((fund) => {
        const { values, valuesHigh, valuesLow } = fund;
        const foundTableDataRecord = tableData.find((row) => {
            return row.Id === fund.id;
        });

        if (foundTableDataRecord) {
            const newHolder = {
                investment: foundTableDataRecord.Investment,
                id: foundTableDataRecord.Id || setFundId(fund, tableData),
                investmentId: foundTableDataRecord.investmentId || null,
                closingYear:
                    fund.closingYear ||
                    (foundTableDataRecord.ClosingDate
                        ? parseInt(
                              foundTableDataRecord.ClosingDate.substring(0, 4),
                              10
                          )
                        : foundTableDataRecord.Vintage),
                userEdited: foundTableDataRecord?.userEdited?.length ?  foundTableDataRecord.userEdited : [],
                values: {},
                valuesHigh: {},
                valuesLow: {},
            };

            if (fund.values) {
                newHolder.values = setExistingValues(values);
            }

            if (fund.valuesHigh) {
                newHolder.valuesHigh = setExistingValues(valuesHigh);
            }

            if (fund.valuesLow) {
                newHolder.valuesLow = setExistingValues(valuesLow);
            }

            // Handle if the fund does not have values or valuesHigh or valuesLow
            if (!values || !valuesHigh || !valuesLow) {
                const defaultStrats = strategyParameters.filter(
                    (row) =>
                        row.numFunds === 1 &&
                        row.strategy === foundTableDataRecord.PacingStrategy &&
                        row.contribTiming === 'Base'
                );

                if (defaultStrats.length > 0) {
                    newHolder.values = values
                        ? newHolder.values
                        : setDefaultValues(defaultStrats, 'Base');
                    newHolder.valuesHigh = valuesHigh
                        ? newHolder.valuesHigh
                        : setDefaultValues(defaultStrats, 'High');
                    newHolder.valuesLow = valuesLow
                        ? newHolder.valuesLow
                        : setDefaultValues(defaultStrats, 'Low');
                }
            }

            newFundSliderValues.push(newHolder);
        }
    });

    tableData.forEach((fund) => {
        const slidersExists = newFundSliderValues.find(
            ({ id }) => id === fund.Id
        );

        if (!slidersExists) {
            const holder = {
                investment: fund.Investment,
                id: fund.Id,
                investmentId: fund.investmentId || null,
                closingYear: fund.ClosingDate
                    ? parseInt(fund.ClosingDate.substring(0, 4), 10)
                    : fund.Vintage,
                userEdited: fund.userEdited?.length ?  fund.userEdited : [],
                values: {},
                valuesHigh: {},
                valuesLow: {},
            };

            const defaultStrats = strategyParameters.filter(
                (row) =>
                    row.numFunds === 1 &&
                    row.strategy === fund.PacingStrategy &&
                    row.contribTiming === 'Base'
            );

            if (defaultStrats.length > 0) {
                holder.values = setDefaultValues(defaultStrats, 'Base');
                holder.valuesHigh = setDefaultValues(defaultStrats, 'High');
                holder.valuesLow = setDefaultValues(defaultStrats, 'Low');
            }

            newFundSliderValues.push(holder);
        }
    });

    return newFundSliderValues;
};

export default normalizeFundSliderValues;
