import { apiCall, quantUrl } from './BaseApi';

export const getPacingUsers = async () => {
    const url = `${quantUrl}/pacing/pacing_users/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const createPacingUser = async (data) => {
    const url = `${quantUrl}/pacing/pacing_users/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const updatePacingUser = async (id, data) => {
    const url = `${quantUrl}/pacing/pacing_users/${id}/`;
    const method = 'PATCH';
    return apiCall(url, method, data);
};

export const destroyPacingUser = async (id) => {
    const url = `${quantUrl}/pacing/pacing_users/${id}/`;
    const method = 'DELETE';
    return apiCall(url, method);
};

export const sendActivationEmail = async (data) => {
    const url = `${quantUrl}/pacing/welcome/`;
    const method = 'POST';
    return apiCall(url, method, data);
};