import React from 'react';
import { Table } from 'reactstrap';

const tooltipRender = (context) => <Tooltip {...context} />;
export default tooltipRender;

const Tooltip = (props) => {
    const { categoryText, points } = { ...props };

    const data = points.map((point) => {
        const {
            series: { name, type, color },
            value,
            value: { from, to },
        } = point;

        const legendStyle = {
            backgroundColor: `${color}`,
            display: 'inline-block',
            margin: '2px',
        };

        if (type === 'rangeArea') {
            return (
                <tr key={name}>
                    <td sm={2}>
                        <div className='legend'>
                            <div style={legendStyle} />
                        </div>
                    </td>
                    <td sm={5}>{name}</td>
                    <td sm={5}>
                        {Math.round(from)}-{Math.round(to)}%
                    </td>
                </tr>
            );
        }

        return (
            <tr key={name}>
                <td sm={2}>
                    <div className='legend'>
                        <div style={legendStyle} />
                    </div>
                </td>
                <td sm={6}>{name}</td>
                <td sm={6}>{Math.round(value)}%</td>
            </tr>
        );
    });

    return (
        <Table borderless style={{ fontSize: 11, opacity: 0.8 }}>
            <thead>
                <tr>
                    <th colSpan={3}>Year: {categoryText}</th>
                </tr>
            </thead>
            <tbody>{data}</tbody>
        </Table>
    );
};
