import React, { Component } from 'react';
import Button from 'reactstrap/es/Button';
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import { FiRefreshCcw } from 'react-icons/fi';

type Props = {
    handleReset: Function,
    data: Object,
};

class TableDataActionButtons extends Component<Props> {
    handleResetClick = () => {
        const { handleReset, data } = this.props;
        handleReset(data);
    };

    render() {
        return (
            <ButtonGroup>
                <Button
                    style={{ backgroundColor: '#007899' }}
                    size='sm'
                    onClick={this.handleResetClick}
                    title='Reset values to default assumptions'
                >
                    <FiRefreshCcw />
                </Button>
            </ButtonGroup>
        );
    }
}

export default TableDataActionButtons;
