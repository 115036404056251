import React, {useState} from 'react';
import { RadioGroup } from '@ssgglobal/techintnue';

type Props = {
    growthOnLiquidAssets: boolean,
    setGrowthOn: Function,
};

const GrowthOnRadioButtons = ({ growthOnLiquidAssets, setGrowthOn }: Props) => {

    const [growthValue, setGrowthValue] = useState(growthOnLiquidAssets ? 'liquid-assets' : 'total-aum')

    const growthRadioOptions = [
        {
            label: 'Total AuM',
            value: 'total-aum'
        },
        {
            label: 'Liquid Assets',
            value: 'liquid-assets'
        }
    ]

    const handleChange = (e) => {
        setGrowthValue(e)
        const typeChange = {
            target: {
                name: 'targetYearType',
                value: e,
            }
        }
        setGrowthOn(typeChange)
    }

    return (
        <RadioGroup
            isThemed
            label="Growth on:"
            onChange={handleChange}
            options={growthRadioOptions}
            value={growthValue}
        />
    )
};

export default GrowthOnRadioButtons;
