/**
 * Data related to existing commitments.
 */

// @flow
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { Node } from 'react';
import { withRouter } from 'react-router-dom';
import { getAvailableAllocation } from 'utils';
import { getOmniData } from 'api';
import { AuthContext, StaticsContext } from 'context';
import Swal from 'sweetalert2';

type TargetAllocationContextProps = {
    children: Node,
    history: any,
};

export const TargetAllocationContext = createContext({});
export const TargetAllocationContextConsumer = TargetAllocationContext.Consumer;

const TargetAllocationContextProvider = ({
    children,
    history,
}: TargetAllocationContextProps) => {
    const [exposure, setExposure] = useState({
        pe: 0,
        pd: 0,
        re: 0,
        ra: 0,
    });
    const [strategyAllocation, setStrategyAllocation] = useState([]);
    const [targetAllocation, setTargetAllocation] = useState({
        currency: '$',
        targetExposureType: 'percent',
    });
    const [uuidToResetRemove, setUuidToResetRemove] = useState('');
    const [userDefinedRenamedTAS, setUserDefinedRenamedTAS] = useState({
        pe_udef1: {},
        pe_udef2: {},
        pe_udef3: {},
        pd_udef1: {},
        pd_udef2: {},
        pd_udef3: {},
        re_udef1: {},
        re_udef2: {},
        re_udef3: {},
        ra_udef1: {},
        ra_udef2: {},
        ra_udef3: {},
    });

    const { user, userSpiClientId } = useContext(AuthContext);
    const { targetAllocationStrategies, setCurrencyCode } = useContext(StaticsContext);

    /**
     * Object for handleSave function in UnderwritingContext
     */
    const targetAllocationContextDataToSave = {
        exposure,
        strategyAllocation,
        targetAllocation,
        userDefinedRenamedTAS,
    };

    const initializeStrategies = () => {
        const strategyAllocationArray = [];
        const userDefinedRenamedTASArray = userDefinedRenamedTAS;

        targetAllocationStrategies.forEach(
            ({ assetClass, strategy, uiName, wName }) => {
                strategyAllocationArray.push({
                    assetClassShort: assetClass,
                    serverNames: strategy,
                    alloc: 0,
                    northAmerica: 60,
                    europe: 20,
                    asia: 20,
                });

                if (uiName.includes('User Defined')) {
                    userDefinedRenamedTASArray[wName] = {
                        prefix: assetClass,
                        originalValue: uiName,
                        currentValue: uiName,
                    };
                }
            }
        );

        setStrategyAllocation(strategyAllocationArray);
        setUserDefinedRenamedTAS(userDefinedRenamedTASArray);
    };

    /**
     * Initialize strategies on mount
     */
    useEffect(() => {
        if (targetAllocationStrategies.length && !strategyAllocation.length) {
            initializeStrategies();
        }
    }, [targetAllocationStrategies]);

    /**
     * Updating a single value in strategyAllocation
     */
    const handleTotalAllocationUpdate = (assetClass, strategy, value) => {
        const availableAllocation = getAvailableAllocation(
            strategyAllocation,
            assetClass,
            strategy
        );

        const newStrategyAllocation = [...strategyAllocation].map((strat) => {
            const holder = strat;
            if (holder.serverNames === strategy) {
                holder.alloc =
                    value > availableAllocation ? availableAllocation : value;
            }
            return holder;
        });
        setStrategyAllocation(newStrategyAllocation);
    };

    /**
     * Updates the selection flag of a single strategy
     */
    const updateStrategySelection = (strategy, selected) => {
        const newStrategyAllocation = strategyAllocation.map((strat) => {
            const holder = strat;
            if (holder.serverNames === strategy) {
                holder.selected = selected;
            }
            return holder;
        });
        setStrategyAllocation(newStrategyAllocation);
    }

    /**
     * Update strategyAllocation
     */
    const handleAssetClassStrategyAllocationUpdate = async (
        allocations,
        strategy = null
    ) => {
        if (strategy) {
            const newStrategyAllocation = strategyAllocation.map((row) => {
                let holder = row;
                if (holder.serverNames === strategy) {
                    holder = allocations;
                }

                return holder;
            });

            setStrategyAllocation(newStrategyAllocation);
        }
    };

    /**
     * Update strategyAllocation alloc value
     */
    const handleStrategyAllocationUpdate = async (
        strategy,
        alloc,
    ) => {
        if (strategy) {
            const newStrategyAllocation = strategyAllocation;
            const foundStrategyIndex = strategyAllocation.findIndex(({ serverNames }) => serverNames == strategy);
            if (foundStrategyIndex >= 0) {
                newStrategyAllocation[foundStrategyIndex] = {
                    ...newStrategyAllocation[foundStrategyIndex],
                    alloc,
                }
                setStrategyAllocation(newStrategyAllocation);
            }
        }
    };

    /**
     * Store exposure type in portfolio context
     * 'percent' or 'currency'
     */
    const setTargetExposureType = (newTargetExposureType) => {
        targetAllocation.targetExposureType = newTargetExposureType;
        setTargetAllocation({ ...targetAllocation });
    };

    /**
     * Set currency
     */
    const setCurrency = async (currency) => {
        if (user && !userSpiClientId && userSpiClientId === null) {
            const data = await getOmniData('currencies/codesAndSymbols');
            const defaultCurrency = {code: 'USD', symbol: '$'};
            const foundCurrency = data.find(({ code }) => code === currency) || data.find(({ symbol }) => symbol === currency) || defaultCurrency;

            const symbol = foundCurrency.symbol;
            const code = foundCurrency.code;
            setCurrencyCode(code);

            targetAllocation.currency = symbol;
            setTargetAllocation(targetAllocation);
        }
    };

    const displayValidationError = (message) => {
        Swal.fire({
            type: 'error',
            html: message,
        });
    };

    const targetAllocationValidation = (redirectTo) => {
        let returnValue = true;
        let stratAllocated = false;
        const exposureTotal = Object.values(exposure).reduce((a, b) => a + b);

        Object.keys(exposure).forEach((assetClassLower) => {
            let stratTotal = 0;
            const filteredStrats = strategyAllocation.filter(
                ({ assetClassShort }) =>
                    assetClassLower.toUpperCase() === assetClassShort
            );

            filteredStrats.forEach(({ alloc }) => {
                stratTotal += parseInt(alloc, 10);
            });
            if (stratTotal > 0) {
                stratAllocated = true;
            }

            if (
                exposure[assetClassLower] > 0 &&
                targetAllocation.targetExposureType !== 'noNewCommitments'
            ) {
                if (stratTotal === 0) {
                    displayValidationError(
                        'Please allocate values to the strategies below that correspond to your selected asset class(es)'
                    );
                    returnValue = false;
                } else if (stratTotal < 100 && stratTotal > 0) {
                    displayValidationError(
                        'Please allocate all 100% of available value for each asset class'
                    );
                    returnValue = false;
                }
            } else if (
                exposure[assetClassLower] === 0 &&
                targetAllocation.targetExposureType !== 'noNewCommitments' &&
                stratTotal > 0
            ) {
                displayValidationError(
                    'Allocations must be made at the Asset Class level'
                );
                returnValue = false;
            }
        });

        if (
            exposureTotal === 0 &&
            !stratAllocated &&
            targetAllocation.targetExposureType !== 'noNewCommitments'
        ) {
            displayValidationError(
                'Please set allocations at the Asset Class and Strategy level. If projecting no future commitments, please set the target to “No New Commitments'
            );
            return false;
        }

        if (!returnValue) {
            return false;
        }

        if (redirectTo === 'pacing') {
            return true;
        }
        history.push(redirectTo);
        return undefined;
    };

    const allocationCommitmentValidation = () => {
        const strategySelected = strategyAllocation.find(({ selected }) => selected);
        return !strategySelected && targetAllocation.targetExposureType !== 'noNewCommitments'
    }

    const updateStrategyAllocation = (strategyAllocationFromResponse) => {
        // iterate through the current strategyAllocation array held in TargetAllocationContext
        // & build new array with updated alloc & geographic data from saved pacings
        const updatedStrategyAllocation = [];
        // if recently saved with new strategyAllocation data structure, then below will be true
        if (!strategyAllocationFromResponse.PD) {
            strategyAllocation.forEach((datum) => {
                const foundStrategy = strategyAllocationFromResponse.find(
                    ({ serverNames }) => {
                        return serverNames === datum.serverNames;
                    }
                );
                // get more information to the strategy allocation object
                const foundTargetStrategy = targetAllocationStrategies.find(
                    ({ strategy }) => {
                        return strategy == datum.serverNames;
                    }
                )

                if (foundStrategy) {
                    updatedStrategyAllocation.push({
                        ...foundTargetStrategy,
                        ...foundStrategy,
                        selected: foundStrategy.selected != undefined ? foundStrategy.selected : foundStrategy.alloc > 0,
                    });
                } else {
                    updatedStrategyAllocation.push({
                        ...foundTargetStrategy,
                        ...datum,
                        selected: datum.selected != undefined ? datum.selected : datum.alloc > 0,
                    });
                }
            });
        } else {
            strategyAllocation.forEach((row) => {
                const { assetClassShort, serverNames } = row;
                // get more information to the strategy allocation object
                const foundTargetStrategy = targetAllocationStrategies.find(
                    ({ strategy }) => {
                        return strategy == row.serverNames;
                    }
                )

                // wName is used to find strategyAllocation in the response object from a saved pacing
                const { wName = '' } = targetAllocationStrategies.find(
                    ({ strategy }) => {
                        return strategy === serverNames;
                    }
                );
                if (!strategyAllocationFromResponse[assetClassShort][wName]) {
                    updatedStrategyAllocation.push({
                        ...foundTargetStrategy,
                        ...row,
                        selected: row.selected != undefined ? row.selected : row.alloc > 0,
                    });
                } else {
                    const {
                        alloc,
                        northAmerica,
                        europe,
                        asia,
                    } = strategyAllocationFromResponse[assetClassShort][wName];

                    updatedStrategyAllocation.push({
                        ...foundTargetStrategy,
                        assetClassShort,
                        serverNames,
                        alloc,
                        northAmerica,
                        europe,
                        asia,
                        selected: foundTargetStrategy.selected != undefined ? foundTargetStrategy.selected : alloc > 0,
                    });
                }
            });
        }
        setStrategyAllocation(updatedStrategyAllocation);
    };

    /**
     * Store user defined display name values
     */
    const renameUserDefinedTAS = ({ target: { name = '', value = '' } }) => {
        const userDefinedTAS = { ...userDefinedRenamedTAS };
        const prefix = name.substring(0, 2);
        const origName = name.substring(3);

        Object.keys(userDefinedRenamedTAS).forEach((key) => {
            if (
                userDefinedTAS[key].originalValue === origName &&
                prefix === userDefinedTAS[key].prefix
            ) {
                // disallow empty values
                userDefinedTAS[key].currentValue = value ? value : userDefinedTAS[key].originalValue;
            }
        });
        setUserDefinedRenamedTAS(userDefinedTAS);
    };

    /**
     * Return all UserDefinedTAS for asset class
     */
    const getUserDefinedTASByAssetClass = (assetClass = "") => {
        return Object.values(userDefinedRenamedTAS).filter((tas) => tas.prefix == assetClass.toUpperCase())
    }

    /**
     * Return all UserDefinedTAS by Asset Class
     */
    const getUserDefinedTASForAssetClass = (assetClass = "") => {
        const userDefinedTAS = getUserDefinedTASByAssetClass(assetClass);
        const availableTAS = userDefinedTAS.filter((tas) => {
            const alloc = strategyAllocation.find((stratAlloc) => stratAlloc.uiName == tas.originalValue)
            // defined have allocation or change name
            return alloc && (alloc.alloc != 0 || tas.originalValue != tas.currentValue);
        });
        return availableTAS;
    }

    /**
     * Return all UserDefinedTAS available or unset for asset class
     */
    const getAvailableUserDefinedTASForAssetClass = (assetClass = "") => {
        const userDefinedTAS = getUserDefinedTASByAssetClass(assetClass);
        const availableTAS = userDefinedTAS.filter((tas) => {
            // not defined = no name set
            return tas.originalValue == tas.currentValue;
        });
        return availableTAS;
    }

    /**
     * Return true if asset class have at least one user defined strategies
     */
    const hasUserDefinedTASForAssetClass = (assetClass = "") => {
        const userDefinedTASs = getAvailableUserDefinedTASForAssetClass(assetClass)
        // 3 because there is only 3 user define strats
        // better way to do it is to have a better way
        // to tell if an strategy is defined or not
        return userDefinedTASs.length < 3;
    }

    const updateTargetAllocation = (data) => {
        setTargetExposureType(data.targetExposureType);
        setCurrency(data.currency);
    };

    const updateTargetAllocationContext = (data) => {
        setExposure(data.exposure || exposure);
        updateStrategyAllocation(data.strategyAllocation || strategyAllocation);
        updateTargetAllocation(data.targetAllocation || targetAllocation);
        setUserDefinedRenamedTAS(
            data.userDefinedRenamedTAS || userDefinedRenamedTAS
        );
    };

    return (
        <TargetAllocationContext.Provider
            value={{
                exposure,
                handleAssetClassStrategyAllocationUpdate,
                handleTotalAllocationUpdate,
                handleStrategyAllocationUpdate,
                updateStrategySelection,
                initializeStrategies,
                renameUserDefinedTAS,
                setCurrency,
                setExposure,
                setStrategyAllocation,
                setTargetAllocation,
                setTargetExposureType,
                setUserDefinedRenamedTAS,
                setUuidToResetRemove,
                strategyAllocation,
                targetAllocation,
                targetAllocationContextDataToSave,
                targetAllocationValidation,
                updateStrategyAllocation,
                updateTargetAllocationContext,
                userDefinedRenamedTAS,
                uuidToResetRemove,
                allocationCommitmentValidation,
                hasUserDefinedTASForAssetClass,
                getAvailableUserDefinedTASForAssetClass,
                getUserDefinedTASForAssetClass,
            }}
        >
            {children}
        </TargetAllocationContext.Provider>
    );
};

export default withRouter(TargetAllocationContextProvider);
