/**
 * This context is for Underwriting related data:
 * sliderValues, fundSliderValues, underwritingDataset
 */

// @flow
import React, { createContext, useContext, useState } from 'react';
import type { Node } from 'react';
import Swal from 'sweetalert2';
import { postPacingDataById } from 'api';
import {
    normalizeFundSliderValues,
    normalizeSliderValues,
    normalizeTableData,
    trimSliderValues,
} from 'utils';
import { AuthContext } from './AuthContext';
import { LandingContext } from './LandingContext';
import { PacingAnalysisContext } from './PacingAnalysisContext';
import { PacingContext } from './PacingContext';
import { TargetAllocationContext } from './TargetAllocationContext';
import { StaticsContext } from './StaticsContext';

type Props = {
    children: Node,
};

export const UnderwritingContext = createContext({});
export const UnderwritingContextConsumer = UnderwritingContext.Consumer;

const UnderwritingContextProvider = ({ children }: Props) => {
    const [commitmentType, setCommitmentType] = useState('existing');
    const [fundId, setFundId] = useState('');
    const [fundSliderValues, setFundSliderValues] = useState([]);
    const [sliderValues, setSliderValues] = useState([]);
    const [underwritingDataset, setUnderwritingDataset] = useState([]);
    // const [existingUnderwritingColumnsDefs, setExistingUnderwritingColumnsDefs] = useState(null)
    const {
        checkForRenamedStrategy,
        pacingAnalysisContextDataToSave,
        setPacingParametersKeyValue,
    } = useContext(PacingAnalysisContext);
    const { pacingContextDataToSave } = useContext(PacingContext);
    const { landingContextDataToSave, tableData, setRawData, setTableData, reportDate } = useContext(
        LandingContext
    );
    const {
        strategyAllocation,
        targetAllocationContextDataToSave,
    } = useContext(TargetAllocationContext);
    const { user } = useContext(AuthContext);
    const { strategyParameters } = useContext(StaticsContext);

    /**
     * Object for handleSave function below
     */
    const underwritingContextDataToSave = {
        // do not include sliderValues because we trim them in handleSave function
        commitmentType,
        fundId,
        fundSliderValues,
        underwritingDataset,
    };

    const loadSliderValues = () => {
        const normalizedSliderValues = normalizeSliderValues(
            sliderValues,
            strategyParameters
        );

        setSliderValues(normalizedSliderValues);
    };

    const updateBulkFundSliders = (newSliders, scenario) => {
        const mapValues = {
            'Base': 'values',
            'High': 'valuesHigh',
            'Low': 'valuesLow',
        }
        const valuesKey = mapValues[scenario];
        if (newSliders && newSliders.length > 0) {
            const newFundSliderValues = [...fundSliderValues].map((fundSlider) => {
                const values = fundSlider[valuesKey];
                if (values) {
                    const newSlider = newSliders.find((slider) => slider.strategy === values.strategy);
                    if (newSlider) {
                        // only updated userEdited fields
                        newSlider.userEdited.map((key) => {
                            values[key] = newSlider[key];
                        })
                        return {
                            ...fundSlider,
                            // update values
                            [valuesKey]: {
                                ...values,
                                userEdited: [...new Set([...newSlider.userEdited, ...values.userEdited])]
                            }
                        }
                    }
                }
                return fundSlider;
            });
            setFundSliderValues(newFundSliderValues);
        }
    }

    const updateSliderValue = (updatedSlider) => {
        if (updatedSlider) {
            const newSliderValues = sliderValues.map((slider) => {
                if (
                    updatedSlider.strategy === slider.strategy
                    && updatedSlider.scenario.toLowerCase() === slider.case.toLowerCase()
                ) {
                    return {
                        ...slider,
                        rc1: updatedSlider.rc1,
                        rc2: updatedSlider.rc2,
                        rc3: updatedSlider.rc3,
                        rc4: updatedSlider.rc4,
                        rc5: updatedSlider.rc5,
                        yld: updatedSlider.yld,
                        paidIn: updatedSlider.paidIn,
                        irr: updatedSlider.irr,
                        fundLife: updatedSlider.fundLife,
                        bow: updatedSlider.bow,
                        commitQtr: updatedSlider.commitQtr,
                    };
                }
                return slider
            });
            setSliderValues(newSliderValues);
        }
    }

    /**
     * Store all variables from Parameter Lab
     */
    const updateParameterLab = (newCommitmentType, newFundId) => {
        setCommitmentType(newCommitmentType);
        setFundId(newFundId);
    };

    const updateCoinvestToggle = (strategyName) => {
        const newSliderValues = [...sliderValues];

        const strategiesToToggle =
            sliderValues.filter((row) => checkForRenamedStrategy(row.strategy) === checkForRenamedStrategy(strategyName)) || {};

        strategiesToToggle.map((strat) => {
            const yearNames = ['rc1', 'rc2', 'rc3', 'rc4', 'rc5'];

            strat.coinvestFlag = strat.coinvestFlag ? 0 : 1;
            yearNames.forEach((yearName) => (strat[yearName] = 1));

            if (underwritingDataset.length) {
                const foundDatum = underwritingDataset.find(
                    ({ strategy }) => checkForRenamedStrategy(strategy) === checkForRenamedStrategy(strat.strategy)
                );

                foundDatum.coinvestFlag = strat.coinvestFlag || 0;

                if (strat.coinvestFlag) {
                    foundDatum.rc1 = 100;
                    foundDatum.rc2 = 100;
                    foundDatum.rc3 = 100;
                    foundDatum.rc4 = 100;
                    foundDatum.rc5 = 100;
                }
            }
        });

        setSliderValues(newSliderValues);
    };

    /**
     * Updates or creates a new user defined strategy 
     */
    const updateUserDefinedStrategy = (name, newStrategy) => {
        const newSliderValues = sliderValues.map((slider) => {
            if (slider.strategy == name) {
                return {
                    ...slider, // filled with previous values
                    // update new values,
                    ...newStrategy,
                }
            } else {
                return slider;
            }
        });
        
        setSliderValues(newSliderValues);
    }

    const handleSave = async () => {
        const { newSliderValues } = trimSliderValues(
            underwritingDataset,
            sliderValues,
            checkForRenamedStrategy,
            strategyAllocation
        );

        const dataToSave = {
            pacingId: pacingContextDataToSave.pacingId,
            user,
            sliderValues: newSliderValues,
            ...landingContextDataToSave,
            ...pacingAnalysisContextDataToSave,
            ...pacingContextDataToSave,
            ...targetAllocationContextDataToSave,
            ...underwritingContextDataToSave,
        };

        const userEmail = user && user.email ? user.email : '';
        const userName = user && user.name ? user.name : '';
        const params = {
            email: userEmail,
            edited_by: userName,
            saved_data: JSON.stringify(dataToSave),
        };

        try {
            await postPacingDataById(params, pacingContextDataToSave.pacingId);
            Swal.fire({
                type: 'success',
                html: 'Pacing saved!',
            });
        } catch (error) {
            let text = '';
            if (
                error.responseJSON &&
                error.responseJSON.name[0] === 'This field may not be blank.'
            ) {
                text = 'Name is required.';
            } else if (
                error.responseJSON &&
                error.responseJSON.name[0] ===
                    'pacing data with this name already exists.'
            ) {
                text = 'Saved pacings must have a unique name.';
            } else {
                text =
                    'Something went wrong trying to save the pacing model. ' +
                    'Please contact an administrator if the problem persists';
            }
            Swal.fire({
                type: 'error',
                html: text,
            });
        }
    };

    /**
     * Clean underwriting context
     */
    const cleanContext = async () => {
        setTableData([]);
        setRawData([]);
        setFundSliderValues([])
        setSliderValues([]);
        setUnderwritingDataset([])
        setCommitmentType('existing');
        setFundId('');
        resetGlobalData([], [], false)
    }

    /**
     * Reset portfolio context
     */
    const resetGlobalData = async (
        newRawData,
        newTableData,
        keepCustomParams
    ) => {
        const newRawDataCopy = [...newRawData];
        let newTableDataCopy = [...newTableData];

        // we're doing this to 0-min this data because sometimes it
        // incorrectly can come back as negative
        let itemToModify;
        newRawDataCopy.forEach((item) => {
            itemToModify = item;
            itemToModify.AdjNAV = Math.max(0, itemToModify.AdjNAV);
        });

        newTableDataCopy.forEach((item) => {
            itemToModify = item;
            itemToModify.AdjNAV = Math.max(0, itemToModify.AdjNAV);
        });

        if (keepCustomParams) {
            setPacingParametersKeyValue('startYear', Number(reportDate.substring(0,4)));

            newTableDataCopy = newTableDataCopy.map((row) => {
                const foundFund = fundSliderValues.find((fund) => {
                    return row.Investment === fund.investment;
                });

                if (foundFund) {
                    row.Id = foundFund.id;
                    row.PacingStrategy = foundFund?.values?.strategy;
                }

                return row;
            });
        } else {
            setFundSliderValues([]);
        }

        setRawData(newRawDataCopy);
        setTableData(newTableDataCopy);
        setCommitmentType('existing');
    };

    /**
     * Must be run with all context updater functions.
     *
     * @param {*} data
     */
    const updateUnderwritingContext = async (data) => {
        const normalizedFundSliderValues = await normalizeFundSliderValues(
            data.fundSliderValues,
            data.tableData,
            strategyParameters
        );
        const normalizedSliderValues = await normalizeSliderValues(
            data.sliderValues,
            strategyParameters
        );

        // Set tableData
        // To Do: move tableData stuff back to LandingContext without breaking logic
        const { updatedAt } = data;
        const updatedDate = new Date(updatedAt);
        const dateToCompare = new Date('2020-10-22');
        if (updatedDate < dateToCompare) {
            const normalizedData = await normalizeTableData(
                data.tableData,
                normalizedFundSliderValues
            );

            setTableData(normalizedData.tableData);
            setRawData(normalizedData.tableData);

            setFundSliderValues(
                normalizedData.fundSliderValues || fundSliderValues
            );
        } else {
            setTableData(data.tableData);
            setRawData(data.tableData);

            setFundSliderValues(normalizedFundSliderValues || fundSliderValues);
        }

        setSliderValues(normalizedSliderValues || sliderValues);
        setCommitmentType(data.commitmentType || commitmentType);
        setFundId(data.fundId || fundId);
    };

    const getExistingPacingStrategiesSliders = (scenario) => {
        // TODO: might need to update to function with cases, now it only working with the first case it finds
        const uniqueStrategies = [...new Set(tableData.map((fund) => fund.PacingStrategy))];
        if (scenario) {
            // get only specific scenario
            const strategies = uniqueStrategies
                .map((unique) => sliderValues.find((slider) => slider.strategy === unique && slider.case === scenario))
                .filter((strategy) => !!strategy);
            return strategies;
        }
        // get all strategies
        const strategies = uniqueStrategies
            .map((unique) => sliderValues.filter((slider) => slider.strategy === unique))
            .filter((strategy) => !!strategy);
        return strategies;
    }

    return (
        <UnderwritingContext.Provider
            value={{
                commitmentType,
                fundId,
                fundSliderValues,
                handleSave,
                loadSliderValues,
                resetGlobalData,
                setCommitmentType,
                setFundId,
                setFundSliderValues,
                setSliderValues,
                updateSliderValue,
                updateBulkFundSliders,
                setUnderwritingDataset,
                sliderValues,
                underwritingContextDataToSave,
                underwritingDataset,
                updateCoinvestToggle,
                updateParameterLab,
                updateUnderwritingContext,
                updateUserDefinedStrategy,
                cleanContext,
                getExistingPacingStrategiesSliders,
                // existingUnderwritingColumnsDefs,
                // setExistingUnderwritingColumnsDefs
            }}
        >
            {children}
        </UnderwritingContext.Provider>
    );
};

export default UnderwritingContextProvider;
