import React from 'react';
import {InputBase, InputLabel, InputTextField} from '@ssgglobal/techintnue'

type Props = {
    feesOnNav: number,
    setNumber: Function,
};

const inputStyle = {
    color: '#333',
    border: '1px solid #BDBFBF',
    borderRadius: '5px',
    display: 'flex',
    height: '40px',
    padding: '8px 16px',
    alignItems: 'center',
    gap: '10px',
}

export default function({ feesOnNav, setNumber}: Props) {

    return (
        <>
            <InputLabel md={12}>Fees on NAV (%)</InputLabel>
            <InputTextField
                type='number'
                name='feesOnNav'
                defaultValue={feesOnNav}
                onChange={setNumber}
            />
        </>
    );
}
