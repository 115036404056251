import React, { useContext, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    ModalFooter,
    Button,
} from 'reactstrap';
import { PacingAnalysisContext, LandingContext } from 'context';
import { PortfolioDropdown } from './components';

type Props = {
    isOpen: boolean,
    toggleModal: Function,
    smoothing: number,
    setNumber: Function,
};

const AdvancedOptimizationModal = ({
    isOpen,
    toggleModal,
    // smoothing, 
    // setNumber,
}: 
Props) => {
    const {
        pacingParameters: { commitmentScheduleStrategies },
        setPacingParametersKeyValue,
    } = useContext(PacingAnalysisContext);
    const { portfolioDropdown } = useContext(LandingContext);
    const options = [...portfolioDropdown];
    options.unshift('');
    const strategies = [...commitmentScheduleStrategies];

    const handlePortfolioSave = () => {
        commitmentScheduleStrategies.forEach(({ strategy }) => {
            var select = document.getElementById(strategy);
            var value = select.options[select.selectedIndex].value;
            const foundStrat = strategies.find(({ strategy: strat }) => {
                return strat === strategy;
            })
            if (foundStrat) {
                foundStrat['portfolio'] = value;
            }

            toggleModal();
        });

        setPacingParametersKeyValue('commitmentScheduleStrategies', strategies);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
                Pacing Strategy Portfolio
                <Row>
                    <Col
                        style={{ fontSize: '12px', marginTop: '10px' }}
                        sm={12}
                    >
                        Use this selection below to tag a Pacing Strategy to a
                        specific portfolio
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            {commitmentScheduleStrategies?.length > 0 ? (
                                commitmentScheduleStrategies.map((strat) => {
                                    return (
                                        <PortfolioDropdown
                                            options={options}
                                            optionKey={strat.strategy}
                                            strategies={strategies}
                                        />
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className='secondary-button' onClick={toggleModal}>
                    Cancel
                </Button>
                <Button
                    className='primary-button'
                    onClick={handlePortfolioSave}
                >
                    Save
                </Button>
            </ModalFooter>
            
            {/* Bring back if we want to add smoothing feature
            
            <ModalHeader toggle={toggleModal}>
                Advanced Optimization Features:
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label>
                                <strong>Smoothing:</strong>
                            </Label>
                            <Input
                                placeholder='Enter a number 0-5000 (no decimals)'
                                type='number'
                                name='smoothing'
                                value={smoothing}
                                onChange={setNumber(smoothing)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody> */}
            {/* <ModalFooter>
                <Button color='success' onClick={toggleModal}>
                    Save & Close
                </Button>
            </ModalFooter> */}
        </Modal>
    );
};

export default AdvancedOptimizationModal;
