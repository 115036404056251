export default function checkInputLimits(field, value) {
    if (value === '' || value === null || !value) {
        value = 0;
    }

    switch (field) {
        case 'vintage':
            const stringValue = value.toString();
            if (stringValue.length > 4) {
                return stringValue.slice(0, 4);
            }
            break;
        case 'irr':
            if (value > 50) {
                return 50;
            }
            if (value < -99) {
                return -99;
            }
            break;
        case 'yld':
            if (value > 20) {
                return 20;
            }
            if (value < 0) {
                return 0;
            }
            break;
        case 'bow':
            if (value > 10) {
                return 10;
            }
            if (value < 0.1) {
                return 0.1;
            }
            break;
        case 'fundLife':
            if (value > 100) {
                return 100;
            }
            if (value < 1) {
                return 1;
            }
            break;
        case 'paidIn':
            if (value > 200) {
                return 200;
            }
            if (value < 0) {
                return 0;
            }
            break;
        case 'rc1':
        case 'rc2':
        case 'rc3':
        case 'rc4':
        case 'rc5':
            if (value > 100) {
                return 100;
            }
            if (value < 0) {
                return 0;
            }
            break;
        case 'min':
            if (value < 0) {
                return 0;
            }
            break;
        case 'max':
            if (value > 100000) {
                return 100000;
            }
            break;
        default:
            return value;
    }

    return value;
}
