const resetScenario = (rowData, values, strategyParameters) => {
    const foundDefault = strategyParameters.find((row) => {
        return (
            values.strategy === row.strategy &&
            values.case === row.case &&
            rowData.contribTiming === row.contribTiming &&
            (values.case === 'Base' ? 1 : parseInt(rowData.numFunds, 10)) ===
                parseInt(row.numFunds, 10)
        );
    });

    return {
        strategy: foundDefault.strategy,
        model: foundDefault.model,
        paidIn: foundDefault.paidIn,
        irr: foundDefault.irr,
        yld: foundDefault.yld,
        fundLife: foundDefault.fundLife,
        bow: foundDefault.bow,
        rc1: foundDefault.rc1,
        rc2: foundDefault.rc2,
        rc3: foundDefault.rc3,
        rc4: foundDefault.rc4,
        rc5: foundDefault.rc5,
        coinvestFlag: foundDefault.coinvestFlag,
        contribTiming: foundDefault.contribTiming,
        numFunds: foundDefault.numFunds,
        commitQtr: 'Q2',
        case: foundDefault.case,
        userEdited: foundDefault.userEdited || [],
    };
};

export default resetScenario;
