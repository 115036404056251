const calculateNumQuarter = (vintageDate, reportDate) => {
    // calculate istart
    // (aka the difference in quarter between the vintage date and the reportDate)
    const parts = reportDate.split('-');
    const date1 = new Date(parts[0], parts[1] - 1, parts[2]); // reportDate
    const date2 = new Date(vintageDate);
    const diffTime = Math.abs(date1.getTime() - date2.getTime()) || 0;
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    
    return Math.round((diffDays / 365.25) * 4);
};

export default calculateNumQuarter;
