import {
    filterStrategyAllocationByAssetClass,
    getDisplayDates,
    getQuarterFromDate,
    numberFormat,
} from 'utils';

const cleanData = (data) => (data == null ? '' : data);

function formatRawData(
    data,
    formattedDates,
    offset,
    checkForRenamedStrategy,
    commitmentScheduleStrategies
) {
    for (let i = 0; i < offset; i++) {
        formattedDates.unshift('');
    }

    const formattedData = [formattedDates];

    Object.keys(data).forEach((key) => {
        const holder = [...data[key]].splice(0, offset).map((value) => {
            let newValue = value.toString();

            if (newValue.includes('User Defined')) {
                newValue = checkForRenamedStrategy(value);
            }

            return newValue;
        });

        // Portfolio tagging feature
        holder.forEach((value, key) => {
            if (value === 'New Commitments') {
                const foundCommitStrat = commitmentScheduleStrategies.find(
                    ({ strategy }) => {
                        return strategy === holder[key + 1];
                    }
                );
                if (foundCommitStrat) {
                    holder[key - 1] = foundCommitStrat.portfolio;
                }
            }
        });

        const numbersOnly = [...data[key]].slice(offset);

        numbersOnly.forEach((number) => {
            const formattedValue = cleanData(numberFormat(number, 8));
            holder.push(numberFormat(formattedValue, 8));
        });

        formattedData.push(holder);
    });

    return formattedData;
}

const formatRawHighLowData = (
    data,
    formattedDates,
    offset,
    checkForRenamedStrategy,
    commitmentScheduleStrategies
) => {
    // account for adding portfolio name by unshifting less to align dates
    for (let i = 0; i < offset - 1; i++) {
        formattedDates.unshift('');
    }

    const formattedDataLow = [formattedDates];
    const formattedDataHigh = [formattedDates];
    Object.keys(data).forEach((key) => {
        let holder = [...data[key]].splice(0, offset).map((value) => {
            let newValue = value.toString();
            if (newValue.includes('User Defined')) {
                newValue = checkForRenamedStrategy(value);
            }

            return newValue;
        });

        // Portfolio tagging feature
        holder.forEach((value, key) => {
            if (value === 'New Commitments') {
                const foundCommitStrat = commitmentScheduleStrategies.find(
                    ({ strategy }) => {
                        return strategy === holder[key + 1];
                    }
                );
                if (foundCommitStrat) {
                    holder[key - 1] = foundCommitStrat.portfolio;
                }
            }
        });

        holder = [...holder.slice(0, 4), ...holder.slice(5)];
        const numbersOnly = [...data[key]].slice(offset);

        numbersOnly.forEach((number) => {
            const formattedValue = cleanData(numberFormat(number, 8));
            holder.push(numberFormat(formattedValue, 8));
        });
        if (holder[4].includes('High')) {
            formattedDataHigh.push(holder);
        } else {
            formattedDataLow.push(holder);
        }
    });

    return {
        rawFundDataHigh: formattedDataHigh,
        rawFundDataLow: formattedDataLow,
    };
};

const formatExistingCommitments = (tableData) => {
    const formattedCommitments = [];
    const keys = [
        'Investment',
        'PacingStrategy',
        'Vintage',
        'AssetClass',
        'Investment',
        'Sector',
        'SubSector',
        'Geography',
        'Commitment',
        'Contributions',
        'Distributions',
        'AdjNAV',
        'Unfunded',
        'IRR',
        'TVM',
    ];

    const keyIndeces = {
        Investment: 0,
        PacingStrategy: 1,
        Vintage: 2,
        AssetClass: 3,
        InvestmentType: 4,
        Sector: 5,
        SubSector: 6,
        Geography: 7,
        Commitment: 8,
        Contributions: 9,
        Distributions: 10,
        AdjNAV: 11,
        Unfunded: 12,
        IRR: 13,
        TVM: 14,
    };

    formattedCommitments.push(keys);

    tableData.forEach((commitment) => {
        const commitmentArray = Array(15).fill(0);
        Object.keys(commitment).forEach((item) => {
            if (item !== 'Portfolio' && item !== 'Id') {
                commitmentArray[keyIndeces[item]] = cleanData(commitment[item]);
            }
        });
        formattedCommitments.push(commitmentArray);
    });

    return formattedCommitments;
};

const findLongestArray = (data) => {
    let longestArray = [];
    for (let i = 0; i < Object.values(data).length; i++) {
        longestArray =
            Object.values(data)[i].date.length > longestArray
                ? Object.values(data)[i].date
                : longestArray;
    }
    return longestArray;
};

function getFormattedDates(dates, yearDates, type) {
    if (type === 'annual' && yearDates) {
        return yearDates;
    }
    const formattedDates = [];
    if (type === 'annual') {
        let year = 0;
        for (let j = 0; j < dates.length; j++) {
            const newYear = parseInt(dates[j], 10);
            if (year !== newYear) {
                formattedDates.push(parseInt(dates[j], 10));
                year = newYear;
            }
        }
    } else {
        for (let i = 0; i < dates.length; i++) {
            const month = dates[i].slice(5, 7);
            let quarter = '';

            switch (month) {
                case '01':
                case '02':
                case '03':
                    quarter = 'Q1';
                    break;
                case '04':
                case '05':
                case '06':
                    quarter = 'Q2';
                    break;
                case '07':
                case '08':
                case '09':
                    quarter = 'Q3';
                    break;
                case '10':
                case '11':
                case '12':
                    quarter = 'Q4';
                    break;
                default:
                    break;
            }

            const dateString = `${quarter} ${parseInt(dates[i], 10)}`;

            formattedDates.push(dateString);
        }
    }

    return formattedDates;
}

function formatAssetClassNav(
    data,
    formattedDates,
    historicalData,
    viewHistorical,
    type
) {
    let formattedDates2 = [...formattedDates];
    let dateOverlap = false;

    const nav = [];
    const commitment = [];
    const navPercent = [];
    const contributions = [];
    const distributions = [];
    const netCashFlow = [];
    let contribPop = 0;
    let distribPop = 0;
    let commitPop = 0;

    if (viewHistorical) {
        const dataToParse =
            type === 'annual'
                ? { ...historicalData.historical_annual }
                : { ...historicalData.historical_qtr };
        const { commit, contrib, distrib, ncf, nav: histNav } = {
            ...dataToParse,
        };
        const histDates =
            type === 'annual' ? dataToParse.Year : dataToParse.date;
        const formattedHistDates =
            type === 'annual'
                ? histDates.map((val) => {
                      return val.toString();
                  })
                : getDisplayDates(histDates).years;
        let lastHistoricDate = formattedHistDates.pop();

        lastHistoricDate == formattedDates2[0] &&
        getQuarterFromDate(historicalData.historical_qtr.date[0]) == 'Q4'
            ? (dateOverlap = true)
            : (dateOverlap = false);

        formattedDates2 = formattedHistDates.concat(formattedDates2);

        // Remove the last value of each array if not quarter end
        if (!dateOverlap) {
            commitPop = commit.pop();
            contribPop = contrib.pop();
            distribPop = distrib.pop();
            ncf.pop();
            histNav.pop();
        }

        commit.forEach((val) => {
            commitment.push(numberFormat(val));
            navPercent.push(0);
        });

        contrib.forEach((val) => {
            contributions.push(val * -1);
        });

        distrib.forEach((val) => {
            distributions.push(val);
        });

        ncf.forEach((val) => {
            netCashFlow.push(val);
        });

        histNav.forEach((val) => {
            nav.push(val);
        });
    }

    formattedDates.unshift('Asset Class');
    formattedDates2.unshift('Asset Class');

    const formattedData = {
        quarterlyNavByAssetClass: [formattedDates],
        commitmentNav: [formattedDates2],
        netCashFlow: [formattedDates2],
    };
    const portfolioAum = [...data[0]].slice(2);

    const navHolder = {};
    const commitmentHolder = {};
    const contribHolder = {};
    const distribHolder = {};
    Object.keys(data).forEach((key) => {
        const holder = [];
        const category = [...data[key]].splice(0, 2);
        const slicedData = dateOverlap
            ? [...data[key]].slice(3)
            : [...data[key]].slice(2);

        if (category[1] === 'NAV') {
            holder.push(category[0]);
            slicedData.forEach((value, index) => {
                if (value !== 0) {
                    holder.push(value / portfolioAum[index]);
                } else {
                    holder.push(0);
                }

                if (navHolder[index]) {
                    navHolder[index] += value;
                } else {
                    navHolder[index] = value;
                }
            });

            formattedData.quarterlyNavByAssetClass.push(holder);
        } else if (
            category[1] === 'Commitment' ||
            category[1] === 'Commitments'
        ) {
            slicedData.forEach((value, index) => {
                if (commitmentHolder[index]) {
                    commitmentHolder[index] += value;
                } else {
                    commitmentHolder[index] = value;
                }
            });
        } else if (category[1] === 'Contributions') {
            slicedData.forEach((value, index) => {
                if (contribHolder[index]) {
                    contribHolder[index] += value * -1;
                } else {
                    contribHolder[index] = value * -1;
                }
            });
        } else if (category[1] === 'Distributions') {
            slicedData.forEach((value, index) => {
                if (distribHolder[index]) {
                    distribHolder[index] += value;
                } else {
                    distribHolder[index] = value;
                }
            });
        }
    });

    // If data overlaps, merge values (commitment)
    distribHolder[0] += distribPop;
    contribHolder[0] += contribPop * -1;
    commitmentHolder[0] += commitPop;

    Object.keys(navHolder).forEach((key) => {
        commitment.push(commitmentHolder[key]);
        nav.push(navHolder[key]);
        navPercent.push((navHolder[key] / portfolioAum[key]) * 100);
        contributions.push(contribHolder[key]);
        distributions.push(distribHolder[key]);
        netCashFlow.push(distribHolder[key] + contribHolder[key]);
    });

    commitment.unshift('Commitment');
    nav.unshift('NAV');
    navPercent.unshift('NAV Percent');
    contributions.unshift('Contributions');
    distributions.unshift('Distributions');
    netCashFlow.unshift('Net Cash Flow');

    formattedData.commitmentNav.push(commitment);
    formattedData.commitmentNav.push(nav);
    formattedData.commitmentNav.push(navPercent);
    formattedData.netCashFlow.push(contributions);
    formattedData.netCashFlow.push(distributions);
    formattedData.netCashFlow.push(netCashFlow);

    return formattedData;
}

function formatNavByStrategy(data, formattedDates, checkForRenamedStrategy) {
    formattedDates.unshift('Strategy');

    const formattedData = {
        nav: [formattedDates],
    };

    const portfolioAum = data[0].slice(3);
    Object.keys(data).forEach((key) => {
        const category = [...data[key]].splice(0, 3);
        const slicedData = [...data[key]].slice(3);
        const holder = [];
        if (category[2] === 'NAV') {
            const displayStrategy = checkForRenamedStrategy(category[0]);
            holder.push(displayStrategy);
            slicedData.forEach((value, index) => {
                if (value !== 0) {
                    holder.push(value / portfolioAum[index]);
                } else {
                    holder.push(0);
                }
            });

            formattedData.nav.push(holder);
        }
    });

    return formattedData;
}

function formatFundCrisisCashFlows(data, formattedDates) {
    if (!data) {
        return [];
    }

    formattedDates.unshift('Category');

    const formattedData = {
        netCashFlow: [formattedDates],
        nav: [formattedDates],
    };
    const navHolder = {};
    const contribHolder = {};
    const distribHolder = {};
    const navBaseHolder = {};

    Object.keys(data).forEach((key) => {
        const category = [...data[key]].splice(0, 4);
        const slicedData = [...data[key]].slice(4);

        if (category[3] === 'NAV.Crisis') {
            slicedData.forEach((value, index) => {
                if (navHolder[index]) {
                    navHolder[index] += value;
                } else {
                    navHolder[index] = value;
                }
            });
        } else if (category[3] === 'NAV') {
            slicedData.forEach((value, index) => {
                if (navBaseHolder[index]) {
                    navBaseHolder[index] += value;
                } else {
                    navBaseHolder[index] = value;
                }
            });
        } else if (category[3] === 'Contributions.Crisis') {
            slicedData.forEach((value, index) => {
                if (contribHolder[index]) {
                    contribHolder[index] += value * -1;
                } else {
                    contribHolder[index] = value * -1;
                }
            });
        } else if (category[3] === 'Distributions.Crisis') {
            slicedData.forEach((value, index) => {
                if (distribHolder[index]) {
                    distribHolder[index] += value;
                } else {
                    distribHolder[index] = value;
                }
            });
        }
    });

    const contributions = [];
    const distributions = [];
    const netCashFlow = [];
    const nav = [];
    const navBase = [];

    Object.keys(navHolder).forEach((key) => {
        contributions.push(contribHolder[key]);
        distributions.push(distribHolder[key]);
        netCashFlow.push(distribHolder[key] + contribHolder[key]);
        nav.push(navHolder[key]);
        navBase.push(navBaseHolder[key]);
    });

    contributions.unshift('Contributions');
    distributions.unshift('Distributions');
    netCashFlow.unshift('Net Cash Flow');
    nav.unshift('Downturn NAV');
    navBase.unshift('Base NAV');

    formattedData.netCashFlow.push(contributions);
    formattedData.netCashFlow.push(distributions);
    formattedData.netCashFlow.push(netCashFlow);
    formattedData.netCashFlow.push(nav);
    formattedData.nav.push(nav);
    formattedData.nav.push(navBase);

    return formattedData;
}

function formatRealEstateExposure(data, formattedDates) {
    formattedDates.unshift('Strategy');

    const formattedData = {
        nav: [formattedDates],
    };

    Object.keys(data).forEach((key) => {
        formattedData.nav.push(cleanData(data[key]));
    });

    return formattedData;
}

function formatCommitmentTypeNav(data, formattedDates) {
    const cashFlowDates = [...formattedDates];
    const navDates = [...formattedDates];
    cashFlowDates.unshift('Existing/Future Cash Flows');
    navDates.unshift('Existing/Future NAV');

    const formattedData = {
        netCashFlow: [cashFlowDates],
        nav: [navDates],
    };

    if (data) {
        Object.keys(data).forEach((key) => {
            const holder = [];
            const category = [...data[key]].splice(0, 2);
            const slicedData = [...data[key]].slice(2);

            slicedData.forEach((value) => {
                let newValue = value;
                if (category[1] === 'Contributions') {
                    newValue *= -1;
                }
                holder.push(newValue);
            });

            if (category[1] === 'Contributions') {
                const title =
                    category[0] === 'New'
                        ? 'New Contributions'
                        : 'Existing Contributions';
                holder.unshift(title);

                formattedData.netCashFlow.push(holder);
            } else if (category[1] === 'Distributions') {
                const title =
                    category[0] === 'New'
                        ? 'New Distributions'
                        : 'Existing Distributions';
                holder.unshift(title);

                formattedData.netCashFlow.push(holder);
            } else if (category[1] === 'NAV') {
                const title =
                    category[0] === 'New' ? 'New NAV' : 'Existing NAV';
                holder.unshift(title);

                formattedData.nav.push(holder);
            }
        });
    }

    return formattedData;
}

function formatFundRatio(data, formattedDates) {
    formattedDates.unshift('Commitment Sensitivity');

    const formattedData = {
        FundRatio: [formattedDates],
    };

    if (data) {
        Object.keys(data).forEach((key) => {
            // Only push Commitment Sensitivity related data
            if (key < 5) {
                formattedData.FundRatio.push(cleanData(data[key]));
            }
        });
    }

    return formattedData;
}

function formatAumRatio(data, formattedDates) {
    formattedDates.unshift('Commitment Sensitivity');

    const formattedData = {
        AumRatio: [formattedDates],
    };

    if (data) {
        Object.keys(data).forEach((key) => {
            // Only push AUM Sensitivity related data
            if (key < 1 || key > 4)
                formattedData.AumRatio.push(cleanData(data[key]));
        });
    }

    return formattedData;
}

function formatPsets(
    newSliderValues,
    fundSliderValues,
    strategyAllocation,
    strategyParameters,
    tableData,
    checkForRenamedStrategy
) {
    const newSliderValuesCopy = [...newSliderValues];
    const fundSliderValuesCopy = [...fundSliderValues];
    let addEmptyRow = true;

    const formattedPsets = [];
    const keys = [
        'Strategy',
        'Model',
        'Scenario',
        'Diversification',
        'paidIn',
        'irr',
        'yld',
        'fundLife',
        'bow',
        'rc1',
        'rc2',
        'rc3',
        'rc4',
        'rc5',
    ];
    const keyIndeces = {
        strategy: 0,
        model: 1,
        case: 2,
        numFunds: 3,
        paidIn: 4,
        irr: 5,
        yld: 6,
        fundLife: 7,
        bow: 8,
        rc1: 9,
        rc2: 10,
        rc3: 11,
        rc4: 12,
        rc5: 13,
    };

    formattedPsets.push(keys);

    newSliderValuesCopy.forEach((sliderValue) => {
        const holder = { ...sliderValue };
        const foundStrategyAllocation = strategyAllocation.find(
            ({ serverNames }) => serverNames === sliderValue.strategy
        );

        if (foundStrategyAllocation.alloc !== 0) {
            const stratArray = Array(14).fill(0);

            Object.keys(keyIndeces).forEach((item) => {
                // account for new sliderValue column names
                const shouldBeNumbers = [
                    'paidIn',
                    'irr',
                    'yld',
                    'fundLife',
                    'bow',
                    'rc1',
                    'rc2',
                    'rc3',
                    'rc4',
                    'rc5',
                ];

                if (
                    shouldBeNumbers.includes(item) &&
                    typeof holder[item] !== 'number'
                ) {
                    // Some sliderValues were strings and not able to be formatted as numbers
                    holder[item] = parseFloat(sliderValue[item]);
                }

                if (sliderValue[item] === 0) {
                    // Wasn't printing all 0's in excel sheet without being strings
                    holder[item] = '0';
                }

                if (item === 'strategy') {
                    holder[item] = checkForRenamedStrategy(sliderValue[item]);
                }

                stratArray[keyIndeces[item]] = cleanData(holder[item]);
            });

            formattedPsets.push(stratArray);
        }
    });

    fundSliderValuesCopy.forEach((row) => {
        // check if exist in table data
        const inExistingCommitments = tableData.find(({ Id }) => Id === row.id);

        if (!inExistingCommitments) {
            const { values, valuesHigh, valuesLow } = { ...row };

            if (values) {
                const defaultHigh = [...strategyParameters].find(
                    ({ strategy, case: scenario, contribTiming, numFunds }) =>
                        strategy === values.strategy &&
                        scenario === 'High' &&
                        contribTiming === 'Base' &&
                        numFunds === 1
                );
                const defaultLow = [...strategyParameters].find(
                    ({ strategy, case: scenario, contribTiming, numFunds }) =>
                        strategy === values.strategy &&
                        scenario === 'Low' &&
                        contribTiming === 'Base' &&
                        numFunds === 1
                );

                const holder = { ...values };
                const holderHigh = valuesHigh
                    ? { ...valuesHigh }
                    : { ...defaultHigh };
                const holderLow = valuesLow
                    ? { ...valuesLow }
                    : { ...defaultLow };

                const holderArray = Array(14).fill(0);
                const holderArrayHigh = Array(14).fill(0);
                const holderArrayLow = Array(14).fill(0);

                holder.strategy = { ...row }.investment;
                holder.case = 'Base';
                holder.numFunds = 1;

                if (!values.strategy.includes('User Defined')) {
                    holderHigh.strategy = { ...row }.investment;
                    holderHigh.numFunds = 1;
                    holderLow.strategy = { ...row }.investment;
                    holderLow.numFunds = 1;
                }

                Object.keys(keyIndeces).forEach((item) => {
                    holderArray[keyIndeces[item]] = cleanData(holder[item]);

                    if (!holder.strategy.includes('User Defined')) {
                        holderArrayHigh[keyIndeces[item]] = cleanData(
                            holderHigh[item]
                        );
                        holderArrayLow[keyIndeces[item]] = cleanData(
                            holderLow[item]
                        );
                    }
                });

                formattedPsets.push(holderArray);
                formattedPsets.push(holderArrayHigh);
                formattedPsets.push(holderArrayLow);
            }
        } else if (inExistingCommitments) {
            const { values, valuesHigh, valuesLow } = { ...row };

            if (values) {
                const defaultHigh = [...strategyParameters].find(
                    ({ strategy, case: scenario, contribTiming, numFunds }) =>
                        strategy === values.strategy &&
                        scenario === 'High' &&
                        contribTiming === 'Base' &&
                        numFunds === 1
                );
                const defaultLow = [...strategyParameters].find(
                    ({ strategy, case: scenario, contribTiming, numFunds }) =>
                        strategy === values.strategy &&
                        scenario === 'Low' &&
                        contribTiming === 'Base' &&
                        numFunds === 1
                );

                const holder = { ...values };
                const holderHigh = valuesHigh
                    ? { ...valuesHigh }
                    : { ...defaultHigh };
                const holderLow = valuesLow
                    ? { ...valuesLow }
                    : { ...defaultLow };

                const holderArray = Array(14).fill(0);
                const holderArrayHigh = Array(14).fill(0);
                const holderArrayLow = Array(14).fill(0);
                const emptyArray = Array(14).fill('');

                holder.strategy = { ...row }.investment;
                holder.case = 'Base';
                holder.numFunds = 1;

                if (!values.strategy.includes('User Defined')) {
                    holderHigh.strategy = { ...row }.investment;
                    holderHigh.numFunds = 1;
                    holderLow.strategy = { ...row }.investment;
                    holderLow.numFunds = 1;
                }

                Object.keys(keyIndeces).forEach((item) => {
                    holderArray[keyIndeces[item]] = cleanData(holder[item]);

                    if (!holder.strategy.includes('User Defined')) {
                        holderArrayHigh[keyIndeces[item]] = cleanData(
                            holderHigh[item]
                        );
                        holderArrayLow[keyIndeces[item]] = cleanData(
                            holderLow[item]
                        );
                    }
                });

                if (addEmptyRow) {
                    formattedPsets.push(emptyArray);
                    addEmptyRow = false;
                }

                formattedPsets.push(holderArray);
                formattedPsets.push(holderArrayHigh);
                formattedPsets.push(holderArrayLow);
            }
        }
    });

    return formattedPsets;
}

function formatStrategyAllocation(
    strategyAllocation,
    checkForRenamedStrategy,
    assetClassShortNames,
    targetAllocationStrategies
) {
    const formattedStrategies = [];
    const keys = [
        'Strategy',
        '% of Asset Class Allocated',
    ];

    formattedStrategies.push(keys);

    assetClassShortNames.forEach((assetClass) => {
        const filteredStrategyAllocations = filterStrategyAllocationByAssetClass(
            strategyAllocation,
            assetClass
        );
        filteredStrategyAllocations.forEach(
            ({ serverNames, alloc }) => {
                targetAllocationStrategies.forEach(({ strategy }) => {
                    if (alloc !== 0 && serverNames === strategy) {
                        const holder = [0, 0];

                        const displayStrategy = checkForRenamedStrategy(
                            strategy
                        );

                        holder[0] = cleanData(displayStrategy);
                        holder[1] = cleanData(alloc);

                        formattedStrategies.push(holder);
                    }
                });
            }
        );
    });

    return formattedStrategies;
}

function formatMiscInputs(data) {
    const miscInputs = {};
    Object.keys(data).forEach((key) => {

        if (
            !key.includes('pct_') &&
            !key.includes('reg_') &&
            !key.includes('sname_')
        ) {
            miscInputs[key] = cleanData(data[key]);
        }
    });

    return [
        ['Currency', miscInputs.currency],
        ['Last Project Year', miscInputs.lastYear],
        ['Portfolio Size', miscInputs.portsize],
        ['Growth p.a. (%)', miscInputs.portgrowth.toString()],
        ['Portfolio as of', miscInputs.aumDate],
        ['% Private Market Exposure', miscInputs.percentPrivateMarketsExposure],
        ['Start Quarter', miscInputs.startqtr],
        ['Start Year', miscInputs.startyear],
        [
            'Target Year',
            miscInputs.usetarget === false ? 'Optimized' : miscInputs.usetarget,
        ],
        ['Date Style', miscInputs.useFiscal],
    ];
}

export function formatExcelExportInput(
    tableData,
    strategyAllocation,
    miscInputs,
    checkForRenamedStrategy,
    assetClassShortNames,
    sliderValues,
    fundSliderValues,
    strategyParameters,
    targetAllocationStrategies
) {
    const formattedExistingCommitments = formatExistingCommitments(tableData);

    const formattedPsets = formatPsets(
        [...sliderValues],
        [...fundSliderValues],
        strategyAllocation,
        strategyParameters,
        tableData,
        checkForRenamedStrategy
    );

    const formattedStrategyAllocation = formatStrategyAllocation(
        strategyAllocation,
        checkForRenamedStrategy,
        assetClassShortNames,
        targetAllocationStrategies
    );

    const formattedMiscInputs = formatMiscInputs(miscInputs);

    return {
        formattedExistingCommitments,
        formattedPsets,
        formattedStrategyAllocation,
        formattedMiscInputs,
    };
}

export function formatExcelExportOutput(
    data,
    historicalData,
    checkForRenamedStrategy,
    type = 'annual',
    viewHistorical
) {
    const {
        assetclass_yr: assetClassYr,
        assetclass_qtr: assetClassQtr,
        exist_new_yr: existNewYr,
        exist_new_qtr: existNewQtr,
        model_cfs_yr: modelCfsYr,
        model_cfs_qtr: modelsCfsQtr,
        model_qtrdates: modelQtrDates,
        strategy_yr: strategyYr,
        strategy_qtr: strategyQtr,
        fund_qtr_ratio: fundQtrRatio,
        fund_yr_ratio: fundYrRatio,
        re_nav_yr: reNavYr,
        qtrdates,
        year_labels: yearLabels,
        fund_qtr_crisis: fundQtrCrisis,
        fund_yr_crisis: fundYrCrisis,
    } = data;

    const formattedDates = getFormattedDates(qtrdates, yearLabels, type);
    const formattedAssetClasses = formatAssetClassNav(
        type === 'annual' ? assetClassYr : assetClassQtr,
        [...formattedDates],
        historicalData,
        viewHistorical,
        type
    );
    const formattedModelDates = getFormattedDates(
        modelQtrDates,
        yearLabels,
        type
    ); // these dates correspond to model_cfs data
    const formattedNavByStrategy = formatNavByStrategy(
        type === 'annual' ? strategyYr : strategyQtr,
        [...formattedDates],
        checkForRenamedStrategy
    );

    let assetClassYrCopy = JSON.parse(JSON.stringify(assetClassYr));
    let assetClassQtrCopy = JSON.parse(JSON.stringify(assetClassQtr));

    // Match length of crisis data
    Object.values(assetClassYrCopy).forEach((value) => {
        return value.unshift('', '');
    });
    // Match length of crisis data
    Object.values(assetClassQtrCopy).forEach((value) => {
        return value.unshift('', '');
    });

    // Assign unique keys to combine objects to avoid overrides
    let newQtrKey = Object.keys(fundQtrCrisis).length;
    Object.keys(assetClassQtrCopy).forEach((key) => {
        delete Object.assign(assetClassQtrCopy, {
            [newQtrKey]: assetClassQtrCopy[key],
        })[key];
        newQtrKey += 1;
    });

    // Assign unique keys to combine objects to avoid overrides
    let newYrKey = Object.keys(fundYrCrisis).length;
    Object.keys(assetClassYrCopy).forEach((key) => {
        delete Object.assign(assetClassYrCopy, {
            [newYrKey]: assetClassYrCopy[key],
        })[key];
        newYrKey += 1;
    });

    let combinedFundYrCrisis = { ...fundYrCrisis, ...assetClassYrCopy };
    let combinedFundQtrCrisis = { ...fundQtrCrisis, ...assetClassQtrCopy };

    const formattedFundCrisises = formatFundCrisisCashFlows(
        type === 'annual' ? combinedFundYrCrisis : combinedFundQtrCrisis,
        [...formattedDates]
    );
    const formattedRealEstateExposure = formatRealEstateExposure(reNavYr, [
        ...formattedDates,
    ]);
    const formattedCommitmentTypeNav = formatCommitmentTypeNav(
        type === 'annual' ? existNewYr : existNewQtr,
        [...formattedDates]
    );
    const formattedFundRatio = formatFundRatio(
        type === 'annual' ? fundYrRatio : fundQtrRatio,
        [...formattedDates]
    );
    const formattedAumRatio = formatAumRatio(
        type === 'annual' ? fundYrRatio : fundQtrRatio,
        [...formattedDates]
    );

    const object = {
        formattedAssetClass: formattedAssetClasses,
        formattedNavByStrategy,
        formattedRealEstateExposure,
        formattedCommitmentTypeNav,
        formattedFundCrisis: formattedFundCrisises,
        formattedFundRatio,
        formattedAumRatio,
    };

    return object;
}

export function historicalYearsBack(originalDate, yearsBack) {
    const origin = new Date(originalDate);
    const backDate = new Date(origin);

    return new Date(backDate.setFullYear(origin.getFullYear() - yearsBack));
}

export function formatExcelExportRawData(
    graphData,
    type,
    checkForRenamedStrategy,
    commitmentScheduleStrategies,
    historicalExportData,
    reportDate
) {
    const {
        assetclass_qtr: assetClassQtr,
        assetclass_yr: assetClassYr,
        strategy_yr: strategyYr,
        strategy_qtr: strategyQtr,
        fund_yr: fundYr,
        fund_qtr: fundQtr,
        fund_yr_hl: fundYrHighLow,
        fund_qtr_hl: fundQtrHighLow,
        qtrdates,
        year_labels: yearLabels,
        fund_yr_crisis: fundYrCrisis,
        fund_qtr_crisis: fundQtrCrisis,
    } = graphData;

    const historicalDataSet = Object.values(historicalExportData).length
        ? true
        : false;

    const formattedDates = getFormattedDates(qtrdates, yearLabels, type);
    let formattedHistoricalDates = [];

    let fundQtrHist = {};
    let fundYrHist = {};
    let fundQtrHistHighLow = {};
    let fundYrHistHighLow = {};

    if (historicalDataSet) {
        const firstDate = findEarliestDate(historicalExportData, reportDate);
        const dateObj = createDatesObj(firstDate, reportDate);
        const historicalDates = getFormattedDates(
            dateObj.quarters,
            dateObj.years,
            type
        );

        if (type === 'annual') {
            fundYrHist = JSON.parse(JSON.stringify(fundYr));
            fundYrHistHighLow = JSON.parse(JSON.stringify(fundYrHighLow));

            addHistoricalValsToFundYr(
                historicalExportData,
                dateObj,
                fundYrHist
            );

            addHistoricalValsToFundYr(
                historicalExportData,
                dateObj,
                fundYrHistHighLow
            );
        } else {
            fundQtrHist = JSON.parse(JSON.stringify(fundQtr));
            fundQtrHistHighLow = JSON.parse(JSON.stringify(fundQtrHighLow));

            addHistoricalValsToFundQtr(
                historicalExportData,
                dateObj,
                fundQtrHist
            );

            addHistoricalValsToFundQtr(
                historicalExportData,
                dateObj,
                fundQtrHistHighLow
            );
        }

        formattedHistoricalDates = [...historicalDates];
    }

    const rawFundDataBase = formatRawData(
        type === 'annual'
            ? historicalDataSet
                ? fundYrHist
                : fundYr
            : historicalDataSet
            ? fundQtrHist
            : fundQtr,
        [...formattedHistoricalDates, ...formattedDates],
        5,
        checkForRenamedStrategy,
        commitmentScheduleStrategies
    );

    const rawFundDTDataBase = formatRawData(
        type === 'annual' ? fundYrCrisis : fundQtrCrisis,
        [...formattedHistoricalDates, ...formattedDates],
        4,
        checkForRenamedStrategy,
        commitmentScheduleStrategies
    );

    const { rawFundDataHigh, rawFundDataLow } = formatRawHighLowData(
        type === 'annual'
            ? historicalDataSet
                ? fundYrHistHighLow
                : fundYrHighLow
            : historicalDataSet
            ? fundQtrHistHighLow
            : fundQtrHighLow,
        [...formattedHistoricalDates, ...formattedDates],
        6,
        checkForRenamedStrategy,
        commitmentScheduleStrategies
    );
    const rawStrategyData = formatRawData(
        type === 'annual' ? strategyYr : strategyQtr,
        [...formattedDates],
        3,
        checkForRenamedStrategy,
        commitmentScheduleStrategies
    );
    const rawAssetClassData = formatRawData(
        type === 'annual' ? assetClassYr : assetClassQtr,
        [...formattedDates],
        2,
        checkForRenamedStrategy,
        commitmentScheduleStrategies
    );

    return {
        rawFundDataBase,
        rawFundDataHigh,
        rawFundDataLow,
        rawStrategyData,
        rawAssetClassData,
        rawFundDTDataBase,
    };
}

export function addPortfolioNameToFundData(tableData, graphData) {
    const keys = ['fund_yr', 'fund_yr_hl', 'fund_qtr', 'fund_qtr_hl'];

    keys.forEach((val) => {
        const obj = graphData[val];
        Object.keys(obj).forEach((key) => {
            let port = tableData.find((o) => o.Investment === obj[key][0]);

            if (port) {
                const portfolio = port.Portfolio || '';
                obj[key].unshift(portfolio);
            } else {
                obj[key].unshift('');
            }
        });
    });
}

const findEarliestDate = (histArr, reportDate) => {
    let earliestDate = new Date(reportDate);

    for (let i = 0; i < Object.values(histArr).length; i++) {
        // Pull first date of each instance
        if (Object.values(histArr)[i].date[0] !== 0) {
            let tempDate = new Date(Object.values(histArr)[i].date[0]);
            earliestDate = tempDate < earliestDate ? tempDate : earliestDate;
        }
    }
    return earliestDate;
};

const createDatesObj = (startDate, reportDate) => {
    let end = new Date(reportDate);
    let start = new Date(startDate);
    let month = start.getMonth() + 1;
    let year = start.getFullYear();
    let day = start.getDate();
    let dateObj = { years: [], quarters: [] };

    while (start < end) {
        let yearToAdd = start.getFullYear().toString();
        if (!dateObj.years.includes(yearToAdd)) {
            dateObj.years.push(yearToAdd);
        }
        // Add zero to beginning of single digit months to match YYYY-MM-DDDD format
        let quarterToAdd =
            month.toString().length == 2
                ? `${year}-${month}-${day}`
                : `${year}-0${month}-${day}`;
        if (!dateObj.quarters.includes(quarterToAdd)) {
            dateObj.quarters.push(quarterToAdd);
        }
        // bump date to next quarter based on last date
        switch (month) {
            case 1:
                month = 4;
                day = 30;
                break;
            case 2:
                month = 5;
                day = 31;
            case 3:
                month = 6;
                day = 30;
                break;
            case 4:
                month = 7;
                day = 31;
                break;
            case 5:
                month = 8;
                day = 31;
                break;
            case 6:
                month = 9;
                day = 30;
                break;
            case 7:
                month = 10;
                day = 31;
                break;
            case 8:
                month = 11;
                day = 30;
                break;
            case 9:
                month = 12;
                day = 31;
                break;
            case 10:
                month = 1;
                day = 31;
                year = year + 1;
                break;
            case 11:
                month = 2;
                day = 29;
                year = year + 1;
                break;
            case 12:
                month = 3;
                day = 31;
                year = year + 1;
                break;
            default:
                break;
        }

        start = new Date(`${year}-${month}-${day}`);
    }
    return dateObj;
};

const addHistoricalValsToFundQtr = (historicalData, dateObj, fundValues) => {
    let qtrLen = dateObj['quarters'].length;
    let qtrFilled = new Array(qtrLen).fill(0);
    let offsetStr = fundValues[0][5].toString();
    let offset =
        offsetStr.includes('.Low') || offsetStr.includes('.High') ? 6 : 5;

    Object.keys(fundValues).forEach((key) => {
        let columnKey = key;
        fundValues[columnKey].splice(offset, 0, ...qtrFilled);
        let fundName = fundValues[columnKey][1];

        if (
            Object.keys(historicalData).includes(fundName) &&
            Object.values(historicalData[fundName])[0].length
        ) {
            let historicalDateIndex = historicalData[fundName]['date'][0].slice(
                0,
                10
            );
            // Add offset to start after string values
            let startIndex =
                dateObj['quarters'].indexOf(historicalDateIndex) + offset;
            let valuesLen = historicalData[fundName]['contrib'].length;
            let contributions = historicalData[fundName]['contrib'];
            contributions = contributions.map((val) => {
                return val / 1000000;
            });
            let distributions = historicalData[fundName]['distrib'];
            distributions = distributions.map((val) => {
                return val / 1000000;
            });
            let nav = historicalData[fundName]['nav'];
            nav = nav.map((val) => {
                return val / 1000000;
            });

            if (
                fundValues[columnKey].includes('Contributions') ||
                fundValues[columnKey].includes('Contributions.Low') ||
                fundValues[columnKey].includes('Contributions.High')
            ) {
                fundValues[columnKey].splice(
                    startIndex,
                    valuesLen,
                    ...contributions
                );
            } else if (
                fundValues[columnKey].includes('Distributions') ||
                fundValues[columnKey].includes('Distributions.Low') ||
                fundValues[columnKey].includes('Distributions.High')
            ) {
                fundValues[columnKey].splice(
                    startIndex,
                    valuesLen,
                    ...distributions
                );
            } else if (
                fundValues[columnKey].includes('NAV') ||
                fundValues[columnKey].includes('NAV.Low') ||
                fundValues[columnKey].includes('NAV.High')
            ) {
                fundValues[columnKey].splice(startIndex, valuesLen, ...nav);
            }
        }
    });
};

const addHistoricalValsToFundYr = (historicalData, dateObj, fundValues) => {
    let yearLen = dateObj['years'].length;
    let yearFilled = new Array(yearLen).fill(0);
    let offsetStr = fundValues[0][5].toString();
    let offset =
        offsetStr.includes('.Low') || offsetStr.includes('.High') ? 6 : 5;
    Object.keys(fundValues).forEach((key) => {
        let columnKey = key;
        fundValues[columnKey].splice(offset, 0, ...yearFilled);
        let fundName = fundValues[columnKey][1];
        if (
            Object.keys(historicalData).includes(fundName) &&
            Object.values(historicalData[fundName])[0].length
        ) {
            const historicalDateIndex = historicalData[fundName][
                'date'
            ][0].slice(0, 4);
            // Add 5 to start offset after string values
            const startIndex =
                dateObj['years'].indexOf(historicalDateIndex) + offset;
            const valuesLen = historicalData[fundName]['contrib_yr'].length;
            let contributions = historicalData[fundName]['contrib_yr'];
            contributions = contributions.map((val) => {
                return val / 1000000;
            });
            let distributions = historicalData[fundName]['distrib_yr'];
            distributions = distributions.map((val) => {
                return val / 1000000;
            });
            let nav = historicalData[fundName]['nav_yr'];
            nav = nav.map((val) => {
                return val / 1000000;
            });

            if (
                fundValues[columnKey].includes('Contributions') ||
                fundValues[columnKey].includes('Contributions.Low') ||
                fundValues[columnKey].includes('Contributions.High')
            ) {
                fundValues[columnKey].splice(
                    startIndex,
                    valuesLen,
                    ...contributions
                );
            } else if (
                fundValues[columnKey].includes('Distributions') ||
                fundValues[columnKey].includes('Distributions.Low') ||
                fundValues[columnKey].includes('Distributions.High')
            ) {
                fundValues[columnKey].splice(
                    startIndex,
                    valuesLen,
                    ...distributions
                );
            } else if (
                fundValues[columnKey].includes('NAV') ||
                fundValues[columnKey].includes('NAV.Low') ||
                fundValues[columnKey].includes('NAV.High')
            ) {
                fundValues[columnKey].splice(startIndex, valuesLen, ...nav);
            }
        }
    });
};

export const getQuarterAndYear = (startDate) => {
    let periods = {};
    const quarterNum = Math.floor(startDate.getMonth() / 3) + 1;
    const yearNum = startDate.getFullYear();

    periods['quarter'] = `Q${quarterNum} ${yearNum}`;
    periods['year'] = `${yearNum}`;

    return periods;
};

export const segmentRawData = (funds, periods) => {
    let pos;

    if (funds[0][5].indexOf('Q') >= 0) {
        pos = funds[0].indexOf(periods.quarter);
    } else {
        pos = funds[0].indexOf(periods.year);
    }

    for (let i = 0; i < funds.length; i++) {
        funds[i].splice(5, pos - 5);
    }

    return funds;
};

export const getHistoricRawData = (reportDate, historicBackValue, rawData) => {
    const report = new Date(reportDate);

    const lastYear = historicalYearsBack(report, historicBackValue);

    const periods = getQuarterAndYear(lastYear);

    const rawDataKeys = [
        'rawFundDataBase',
        'rawFundDataHigh',
        'rawFundDataLow',
    ];

    for (const key in rawData) {
        if (rawData.hasOwnProperty(key) && rawDataKeys.includes(key)) {
            const tempData = JSON.parse(JSON.stringify(rawData[key]));
            const fnd = segmentRawData(tempData, periods);
            rawData[key] = fnd;
        }
    }
};
