import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, TableVariant } from '@ssgglobal/techintnue';
import { StaticsContext, TargetAllocationContext, UnderwritingContext } from 'context';
import { filterStrategyAllocationByAssetClassAndStrategy } from 'utils';

const RowStrategy = ({ value, label, data, handleAction }) => {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <span>{ value }</span>
            <Button
                sx={{
                    padding: 0,
                    height: '100%',
                    textTransform: 'capitalize',
                }}
                onClick={() => handleAction(data.serverNames)}
            >
                { label }
            </Button>
        </Grid>
    )
}

const StrategiesTables = ({ asset, strategy }) => {
    const {
        strategyAllocation,
        updateStrategySelection,
        handleStrategyAllocationUpdate,
        userDefinedRenamedTAS,
        getUserDefinedTASForAssetClass,
        targetAllocationStrategies,
    } = useContext(TargetAllocationContext);
    const [available, setAvailable] = useState([])
    const [selected, setSelected] = useState([])

    const populateAvailable = (strategies) => {
        if (strategy != 'User Defined Strategies') {
            return setAvailable(strategies);
        }
        const userDefinedStrategies = getUserDefinedTASForAssetClass(asset);
        const newStrategies = strategies.filter((strat) =>
            !!userDefinedStrategies.find((tas) => tas.originalValue == strat.uiName)
        )
        setAvailable(newStrategies.map((strategy) => {
            const tas = userDefinedRenamedTAS[strategy.wName];
            if (tas) {
                return {
                    ...strategy,
                    uiName: tas.currentValue,
                }
            }
            return strategy;
        }))
    }

    const populateSelected = (strategies) => {
        const userDefinedStrategies = Object.values(userDefinedRenamedTAS);
        setSelected(strategies.map((strategy) => {
            let newStrategy = strategy;
            const userDefined = userDefinedStrategies.find((tas) => tas.originalValue == strategy.uiName)
            if (userDefined) {
                const tas = userDefinedRenamedTAS[newStrategy.wName];
                newStrategy = {
                    ...strategy,
                    uiName: tas.currentValue,
                }
            }
            return newStrategy;
        }))
    }

    useEffect(() => {
        const strategies = filterStrategyAllocationByAssetClassAndStrategy(strategyAllocation, asset, strategy)
        populateAvailable(strategies.filter(({ selected }) => !selected))
        populateSelected(strategyAllocation.filter(({ selected }) => selected))
    }, [targetAllocationStrategies, userDefinedRenamedTAS, strategyAllocation, asset, strategy])

    const handleRemoveStrategy = (strategy) => {
        // resetting allocation value
        handleStrategyAllocationUpdate(strategy, 0);
        updateStrategySelection(strategy, false);
    }
    const handleAddStrategy = (strategy) => updateStrategySelection(strategy, true)

    return (
        <Grid
            container
            direction="column"
            gap="25px"
            sx={{
                height: '250px',
                '& .ag-body-viewport': {
                    border: 'var(--ag-borders) var(--ag-border-color)',
                }
            }}
        >
            <Grid item xs={6}>
                <TableVariant
                    gridStyle={{ height: '250px', width: '100%' }}
                    tableType="aggrid"
                    minHeight="250px"
                    agTheme="ag-theme-balham"
                    className="ag-grid-custom"
                    agGridOptions={{
                        height: '250px',
                        rowHeight: 28,
                        columnDefs: [{
                            field: 'uiName',
                            headerName: 'Available Strategies',
                            flex: '1',
                            cellRenderer: RowStrategy,
                            cellRendererParams: {
                                label: 'add',
                                handleAction: handleAddStrategy
                            }
                        }],
                        rowData: available,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TableVariant
                    gridStyle={{ height: '250px', width: '100%' }}
                    tableType="aggrid"
                    minHeight="250px"
                    agTheme="ag-theme-balham"
                    className="ag-grid-custom"
                    agGridOptions={{
                        height: '250px',
                        rowHeight: 28,
                        columnDefs: [{
                            field: 'uiName',
                            headerName: 'Selected Strategies',
                            flex: '1',
                            cellRenderer: RowStrategy,
                            cellRendererParams: {
                                label: 'remove',
                                handleAction: handleRemoveStrategy
                            }
                        }],
                        rowData: selected,
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default StrategiesTables;
