// @flow
import React, { useContext } from 'react';
import { InputLabel, Grid, InputTextField } from '@ssgglobal/techintnue'
import { PacingContext } from 'context';

type Props = {
    // handlePrivMrkExpChg: Function,
    handlePrivMrkExpOnBlur: Function,
    percentDisableCheck: boolean,
    privateMarketExposure: number,
};

const inputStyle = {
    color: '#333',
    border: '1px solid #BDBFBF',
    borderRadius: '5px',
    padding: '8px 8px 8px 16px',
    width: '100%',
    height: '40px',
}

export default function({
    // handlePrivMrkExpChg,
    handlePrivMrkExpOnBlur,
    percentDisableCheck,
    privateMarketExposure,
}: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    return (
        <>
            <InputLabel md={12}>Private Markets as % of total AuM</InputLabel>
            <Grid
                item
                md={12}
            >
                {pacingClientReadOnlyAndSpiClientId() ? (
                    <div>{privateMarketExposure}</div>
                ) : (
                    <InputTextField
                        type='number'
                        name='privateMarketExposure'
                        defaultValue={privateMarketExposure}
                        // onChange={handlePrivMrkExpChg}
                        onChange={handlePrivMrkExpOnBlur}
                        disabled={percentDisableCheck}
                    />
                )}
            </Grid>
        </>
    );
}
