import XLSX from '@sheet/edit';
import { numberFormat } from 'utils';
import moment from 'moment';

export function parsePacingJson() {
    const jsonData = {};

    return jsonData;
}

const convertExcelSerialDate = (serialDate) => {
    let momentDate = null;

    if (serialDate) {
        const newDate = new Date(Date.UTC(0, 0, serialDate, 0));

        momentDate = moment(newDate).format('YYYY-MM-DD');
    }

    return momentDate;
};

/**
 * Parse Excel worksheet and format for table
 *
 * @param {object} returnData
 */
export function parseExcel(data, isTrialUser) {
    const workbook = XLSX.read(data, { type: 'binary' });

    if (!workbook.Sheets['Existing Commitments']) {
        return {
            correctTemplate: false,
            jsonData: {},
        };
    }

    const { utils } = XLSX;
    let jsonData = utils.sheet_to_json(
        workbook.Sheets['Existing Commitments'],
        { raw: true }
    );

    let correctTemplate = true;
    // Map through data to format keys
    jsonData.forEach((datum, index) => {
        const keys = Object.keys(datum);
        keys.forEach((oldKey) => {
            const keyArray = [
                'Investment',
                'PacingStrategy',
                'Vintage',
                'ClosingDate',
                'AssetClass',
                'InvestmentType',
                'Sector',
                'SubSector',
                'Geography',
                'Commitment',
                'Contributions',
                'Distributions',
                'AdjNAV',
                'Unfunded',
                'IRR',
                'TVM',
            ];
            if (!keyArray.includes(oldKey)) {
                correctTemplate = false;
            }
        });
        /* eslint-disable no-param-reassign */
        datum.Investment = datum.Investment.toString();
        datum.ClosingDate = convertExcelSerialDate(datum.ClosingDate);
        datum.Commitment = Math.round(datum.Commitment) || 0;
        datum.Contributions = Math.round(datum.Contributions) || 0;
        datum.Distributions = Math.round(datum.Distributions) || 0;
        // we're doing this to 0-min this data because sometimes it
        // incorrectly can come back as negative
        datum.AdjNAV = Math.round(Math.max(0, datum.AdjNAV)) || 0;
        datum.Unfunded = Math.round(datum.Unfunded) || 0;
        datum.IRR = numberFormat(datum.IRR * 100, 0);
        datum.Id = index.toString();
        /* eslint-enable no-param-reassign */
    });

    if (isTrialUser && jsonData.length > 10) {
        jsonData = jsonData.slice(0, 10);
    }

    const returnData = {
        correctTemplate,
        jsonData,
    };

    return returnData;
}
