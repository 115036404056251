const checkPacingStrategies = (tableData, targetAllocationStrategies) => {
    let listOfFunds = '';

    tableData.forEach((row) => {
        const foundStrategy = targetAllocationStrategies.find(
            ({ strategy }) => {
                return strategy === row.PacingStrategy;
            }
        );

        if (!foundStrategy) {
            listOfFunds += `<div>Investment:${row.Investment} & Strategy:${row.PacingStrategy}</div>`;
        }
    });

    return listOfFunds;
};

export default checkPacingStrategies;
