//@flow
import React, { useContext } from 'react';
import {
    MaterialDataGridContext,
    MaterialDataGridPagination,
    MaterialDataGridTableHeader,
    MaterialDataGridLoader,
} from '@ssgglobal/material-data-grid';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

type Props = {
    setUserModalOpen: Function,
    editUser: Function,
    deleteUser: Function,
    loading: Boolean,
    sendEmail: Function,
};

const AdminUserPageContainer = ({
    setUserModalOpen,
    editUser,
    deleteUser,
    loading,
    sendEmail,
}: Props) => {
    const { sortedData: users } = useContext(MaterialDataGridContext);

    return (
        <Container fluid>
            <Row>
                <Col md={12}>
                    <Breadcrumb className='mb-0 pb-0'>
                        <BreadcrumbItem>
                            <b style={{ fontSize: '15px' }}>Manage Users</b>
                        </BreadcrumbItem>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className='panel section-border'>
                        <Grid container>
                            <Grid item xs={6}>
                                <h3>Manage Users</h3>
                            </Grid>
                            <Grid item xs={6} container justify='flex-end'>
                                <Button
                                    color='primary'
                                    onClick={() => setUserModalOpen(true)}
                                    variant='outlined'
                                    style={{ marginBottom: '16px' }}
                                >
                                    Add User
                                </Button>
                            </Grid>
                        </Grid>
                        <Table aria-label='users-table' size='small'>
                            <TableHead>
                                <TableRow>
                                    <MaterialDataGridTableHeader
                                        name='name'
                                        filterable
                                        sortable
                                    >
                                        Name
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='email'
                                        filterable
                                        sortable
                                    >
                                        Email
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='spiClientId'
                                        filterable
                                        sortable
                                        type='number'
                                    >
                                        Client ID
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='isAdmin'
                                        filterable
                                        sortable
                                        type='boolean'
                                    >
                                        Pacing Admin
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='parameterLabAccess'
                                        filterable
                                        sortable
                                        type='boolean'
                                    >
                                        Underwriting Access
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='omniImportAccess'
                                        filterable
                                        sortable
                                        type='boolean'
                                    >
                                        SPI Reporting Import Access
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='isActive'
                                        filterable
                                        sortable
                                        type='boolean'
                                    >
                                        Active
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='trialUser'
                                        filterable
                                        sortable
                                        type='boolean'
                                    >
                                        Trial User
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        name='AccessUntil'
                                        filterable
                                        sortable
                                        type='boolean'
                                    >
                                        Access Until
                                    </MaterialDataGridTableHeader>
                                    <MaterialDataGridTableHeader
                                        style={{ width: '80px', padding: 0 }}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <MaterialDataGridLoader
                                    colSpan={7}
                                    size={50}
                                    loading={loading}
                                    noData={!loading && users?.length === 0}
                                    noDataMessage='No Users Found'
                                >
                                    {users.map((user) => (
                                        <TableRow key={user?.userId}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>
                                                {user?.spiClientId}
                                            </TableCell>
                                            <TableCell>
                                                {user?.isAdmin ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell>
                                                {user?.parameterLabAccess
                                                    ? 'Yes'
                                                    : 'No'}
                                            </TableCell>
                                            <TableCell>
                                                {user?.omniImportAccess
                                                    ? 'Yes'
                                                    : 'No'}
                                            </TableCell>
                                            <TableCell>
                                                {user?.isActive ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell>
                                                {user?.trialUser ? 'Yes' : 'No'}
                                            </TableCell>
                                            <TableCell>
                                                {user?.accessUntil}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: '80px',
                                                    padding: 0,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <IconButton
                                                    size='small'
                                                    color='primary'
                                                    onClick={() =>
                                                        sendEmail(user?.userId)
                                                    }
                                                >
                                                    <MailOutlineIcon />
                                                </IconButton>
                                                <IconButton
                                                    size='small'
                                                    color='primary'
                                                    onClick={() =>
                                                        editUser(user?.userId)
                                                    }
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <IconButton
                                                    size='small'
                                                    color='secondary'
                                                    onClick={() =>
                                                        deleteUser(user?.userId)
                                                    }
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </MaterialDataGridLoader>
                            </TableBody>
                            <MaterialDataGridPagination colSpan={9} />
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminUserPageContainer;
