// @flow
import React, { Component, useContext } from 'react';
import Button from 'reactstrap/es/Button';
import swal2 from 'sweetalert2';
import { AuthContext, StaticsContext } from 'context';
import { UserType } from 'types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import { getPacingData, postPacingDataById } from 'api';
import { ManagePacingColumnDefinitions } from './components';

type Props = {
    user: UserType,
    userSpiClientId: number | null,
    clients: Array,
};

class ManagePacingTableComponent extends Component<Props> {
    state = {
        rowData: [],
        overlayLoadingTemplate: '<span>Loading Pacings...</span>',
        defaultColDef: {
            resizable: true,
            sortable: true,
            filter: true,
            editable: true,
            menuTabs: ['filterMenuTab'],
        },
        tooltipShowDelay: 0,
        tooltipHideDelay: 2000,
        autoGroupColumnDef: {
            headerName: 'Pacing Name',
            field: 'name',
            editable: (params) => {
                if (this.props.userSpiClientId && params.data.client_read_only === 'Yes') {
                    return false;
                }
                return true;
            },
        },
        groupDefaultExpanded: -1,
        modifiedPacings: [],
    };

    fetchPacings = async () => {
        this.gridApi.showLoadingOverlay();

        const { userSpiClientId } = this.props;

        try {
            const allPacings = await getPacingData();
            // If the user is an admin, show all Pacings sorted by Client ID
            if (userSpiClientId === null) {
                this.renderPacings(allPacings);
                // If the user is a client, display only Pacings with matching Client ID
            } else {
                const clientPacings = allPacings.filter(
                    ({ spi_client_id: pacingSpiClientId }) =>
                        pacingSpiClientId === userSpiClientId
                );
                this.renderPacings(clientPacings);
            }
        } catch (e) {
            swal2.fire({
                type: 'error',
                html:
                    'Something went wrong trying to load the pacing model. ' +
                    'Please contact an administrator if the problem persists',
            });
        }
    };

    renderPacings = async (fetchedPacings) => {
        if (fetchedPacings.length === 0) {
            swal2.fire({
                type: 'warning',
                html:
                    'No pacings found. If you think this is an error, please contact an admin.',
            });
        }

        const formattedData = [];
        fetchedPacings.forEach((pacing) => {
            const rowValues = {
                spi_client_id: `Client ID: ${pacing.spi_client_id}`,
                pacing_data_id: pacing.pacing_data_id,
                name: pacing.name,
                client_name: pacing.spi_client_id || null, 
                client_access: pacing.client_access ? 'Yes' : 'No',
                client_read_only: pacing.client_read_only ? 'Yes' : 'No',
                last_updated_by: pacing.edited_by,
                last_updated_on: pacing.updated_at,
            };

            formattedData.push(rowValues);
        });

        await this.setState({ rowData: formattedData });

        this.gridApi.hideOverlay();
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        await this.fetchPacings();

        params.api.sizeColumnsToFit();
    };

    onCellChange = (row) => {
        const { modifiedPacings } = this.state;
        const { clients } = this.props;
        const {
            user: { name, email },
        } = this.props;
        const clientId = clients.find(client => client.name == row.data.client_name)?.id

        const rowToPush = {
            pacing_data_id: row.data.pacing_data_id,
            name: row.data.name,
            spi_client_id: clientId || 0, 
            client_access: row.data.client_access === 'Yes' ? 1 : 0,
            client_read_only: row.data.client_read_only === 'Yes' ? 1 : 0,
            edited_by: name,
            email,
        };
        modifiedPacings.push(rowToPush);

        this.setState({
            modifiedPacings,
        });
    };

    saveChanges = async () => {
        swal2.showLoading();

        const { modifiedPacings } = this.state;

        await modifiedPacings.forEach(async (row) => {
            await postPacingDataById(row, row.pacing_data_id);
        });

        swal2.close();
        swal2.fire(
            'Saved',
            'All modified pacings have been updated',
            'success'
        );
    };

    cancelChanges = () => {
        this.fetchPacings();
    };

    render() {
        const {
            rowData,
            overlayLoadingTemplate,
            autoGroupColumnDef,
            groupDefaultExpanded,
            defaultColDef,
        } = this.state;
        const { userSpiClientId, clients } = this.props;

        const columnDefinitions = ManagePacingColumnDefinitions(
            userSpiClientId,
            this.fetchPacings,
            clients
        );

        return (
            <>
                <Button className='primary-button' onClick={this.saveChanges}>
                    Save
                </Button>
                <Button
                    className='secondary-button'
                    onClick={this.cancelChanges}
                >
                    Cancel
                </Button>
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: '500px',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        columnDefs={columnDefinitions}
                        defaultColDef={defaultColDef}
                        animateRows
                        enableRangeSelection
                        //groupMultiAutoColumn
                        groupDisplayType = 'multipleColumns'
                        autoGroupColumnDef={autoGroupColumnDef}
                        groupDefaultExpanded={groupDefaultExpanded}
                        rowData={rowData}
                        onCellValueChanged={this.onCellChange}
                        onGridReady={this.onGridReady}
                        overlayLoadingTemplate={overlayLoadingTemplate}
                        rowHeight={35}
                        singleClickEdit
                        stopEditingWhenCellLosesFocus
                        suppressExcelExport
                        suppressCsvExport
                        suppressMenuHide
                    />
                </div>
            </>
        );
    }
}

const ManagePacingTable = (props) => {
    const { user, userSpiClientId } = useContext(AuthContext);
    const { clients } = useContext(StaticsContext);

    return (
        <ManagePacingTableComponent
            user={user}
            userSpiClientId={userSpiClientId}
            clients={clients}
            {...props}
        />
    );
};

export default ManagePacingTable;
