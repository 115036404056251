import moment from 'moment';

function splitDates(date) {
    const dateParts = date.split('/');
    let cellDate;
    if (dateParts[3] !== undefined) {
        const time = dateParts[3].split(':');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[0]);
        const day = Number(dateParts[1]);
        const hour = Number(time[0]);
        const minutes = Number(time[1]);

        cellDate = new Date(year, month, day, hour, minutes);
    } else {
        const year = Number(dateParts[2]);
        const month = Number(dateParts[0]) - 1;
        const day = Number(dateParts[1]);

        cellDate = new Date(year, month, day);
    }

    return cellDate;
}

export function dateSort(date1, date2) {
    const date1AsString = moment(date1).format('MM/DD/YYYY/HH:mm');
    const date2AsString = moment(date2).format('MM/DD/YYYY/HH:mm');

    if (date1AsString == null || date2AsString == null) {
        return 0;
    }
    const cellDate1 = splitDates(date1AsString);
    const cellDate2 = splitDates(date2AsString);

    // Now that both parameters are Date objects, we can compare
    if (cellDate1 < cellDate2) {
        return -1;
        // eslint-disable-next-line no-else-return
    } else if (cellDate1 > cellDate2) {
        return 1;
    }
    // eslint-enable-next-line no-else-return
    return 0;
}

export function dateFilter(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = moment(cellValue).format('MM/DD/YYYY');
    if (dateAsString == null) {
        return 0;
    }

    const cellDate = splitDates(dateAsString);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
        // eslint-disable-next-line no-else-return
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    // eslint-enable-next-line no-else-return
    return 0;
}
