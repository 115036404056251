import React, { Component } from 'react';
import Button from 'reactstrap/es/Button';
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import { GoTrashcan } from 'react-icons/go';
import { FiRefreshCcw } from 'react-icons/fi';

type Props = {
    handleDelete: Function,
    data: any,
    handleReset: Function,
    value: any,
    node: { rowPinned: boolean },
};

class TableDataActionButtons extends Component<Props> {
    handleDeleteClick = () => {
        const { handleDelete, data } = this.props;
        handleDelete(data);
    };

    handleResetClick = () => {
        const { handleReset, data } = this.props;
        handleReset(data);
    };

    render() {
        const { value, node } = this.props;

        return value && node.rowPinned !== 'bottom' ? (
            <ButtonGroup>
                <Button
                    color='danger'
                    size='sm'
                    onClick={this.handleDeleteClick}
                    title='Remove row from dataset'
                >
                    <GoTrashcan />
                </Button>
                &nbsp;
                <Button
                    color='info'
                    size='sm'
                    onClick={this.handleResetClick}
                    title='Reset to Raw Data'
                >
                    <FiRefreshCcw />
                </Button>
            </ButtonGroup>
        ) : (
            <></>
        );
    }
}

export default TableDataActionButtons;
