/**
 * Fill out sliderValues with the unused strats that weren't saved
 *
 * @param {*} updatedSliderValues
 * @param {*} strategyParameters
 */
const getRestOfStrats = (updatedSliderValues, strategyParameters) => {
    // Add 'Base' to sliders first loading editable High/Low
    let modSliderValues = updatedSliderValues.map((row) => {
        const rowHolder = row;

        if (!Object.prototype.hasOwnProperty.call(row, 'case')) {
            rowHolder.case = 'Base';
            // eslint-disable-next-line no-unused-expressions
            rowHolder.numFunds
                ? (rowHolder.numFunds = 5)
                : (rowHolder.num_funds = 5);
        }

        return rowHolder;
    });

    // Add High/Low rows to sliderValues that don't have them yet;
    modSliderValues.forEach((row) => {
        const rowStrategy = row.strategy;
        const count = [...modSliderValues].filter((strat) => {
            return strat.strategy === rowStrategy;
        }).length;

        const missingHighLow = strategyParameters.filter(
            (item) =>
                item.strategy === rowStrategy &&
                item.numFunds === 5 &&
                item.contribTiming === 'Base' &&
                item.case !== 'Base'
        );

        const formattedMissingHighLow = missingHighLow.map((item) => {
            return {
                strategy: item.strategy,
                model: item.model,
                paidIn: item.paidIn,
                irr: item.irr,
                yld: item.yld,
                fundLife: item.fundLife,
                bow: item.bow,
                rc1: item.rc1,
                rc2: item.rc2,
                rc3: item.rc3,
                rc4: item.rc4,
                rc5: item.rc5,
                coinvestFlag: item.coinvestFlag,
                contribTiming: item.contribTiming,
                commitQtr: 'All',
                numFunds: item.numFunds,
                case: item.case,
                userEdited: item.userEdited || [],
            };
        });

        // Add high/low default if sliderValues doens' already have them
        if (count === 1) {
            modSliderValues = [...modSliderValues, ...formattedMissingHighLow];
        }
    });

    const existingSliders = modSliderValues.reduce(
        (acc, item) => ({
            ...acc,
            [item.strategy]: true,
        }),
        {}
    );

    const restOfStrats = [...strategyParameters].filter(
        (item) =>
            !existingSliders[item.strategy] &&
            item.numFunds === 5 &&
            item.contribTiming === 'Base'
    );

    const formattedRestOfStrats = restOfStrats.map((item) => {
        return {
            strategy: item.strategy,
            model: item.model,
            paidIn: item.paidIn,
            irr: item.irr,
            yld: item.yld,
            fundLife: item.fundLife,
            bow: item.bow,
            rc1: item.rc1,
            rc2: item.rc2,
            rc3: item.rc3,
            rc4: item.rc4,
            rc5: item.rc5,
            coinvestFlag: item.coinvestFlag,
            contribTiming: item.contribTiming,
            commitQtr: 'All',
            numFunds: item.numFunds,
            case: item.case,
            userEdited: item.userEdited || [],
        };
    });

    return [...modSliderValues, ...formattedRestOfStrats];
};

/**
 * Format sliderValues on load
 * Prevents need to check 2+ different variable names
 *
 * @param {*} sliderValues
 * @param {*} strategyParameters
 */
const normalizeSliderValues = (sliderValues, strategyParameters) => {
    const normalizedSliderValues = sliderValues.map((strat) => {
        return {
            strategy: strat.strategy || strat.Strategy,
            model: strat.model || strat.Model,
            paidIn: strat.paidIn || strat.paid_in || strat.p1 || 0,
            irr: strat.irr || strat.p2 || 0,
            yld: strat.yld || strat.p3 || 0,
            fundLife: strat.fundLife || strat.fund_life || strat.p4 || 1,
            bow: strat.bow || strat.p5 || 0,
            rc1: strat.rc1 || strat.p6 || 0,
            rc2: strat.rc2 || strat.y1 || 0,
            rc3: strat.rc3 || strat.y2 || 0,
            rc4: strat.rc4 || strat.y4 || 0,
            rc5: strat.rc5 || strat.y5 || 0,
            coinvestFlag:
                strat.coinvestFlag || strat.coinvest_flag || strat.p7 || 0,
            contribTiming:
                strat.contribTiming || strat.contrib_timing || 'Base',
            commitQtr: strat.commitQtr || 'All',
            numFunds: strat.numFunds || strat.num_funds || 1,
            case: strat.case || 'Base',
            userEdited: strat.userEdited || [],
        };
    });

    const newSliderValues = getRestOfStrats(
        normalizedSliderValues,
        strategyParameters
    );

    return newSliderValues;
};

export default normalizeSliderValues;
