import React, { useContext, useEffect, useState } from 'react';
import { Button, FormInput, FormProvider, Grid, Modal, useForm } from '@ssgglobal/techintnue';
import { UnderwritingContext } from 'context';
import { EditStrategyTable } from './EditStrategyTable/index';

const EditMultipleModal = ({ isOpen, onClose }) => {
    const {
        getExistingPacingStrategiesSliders,
        updateBulkFundSliders,
    } = useContext(UnderwritingContext);
    
    const useFormMethods = useForm({
        defaultValues: {
            strategies: [],
            scenario: 'Base',
            rows: [],
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });

    const onSubmit = (values) => {
        const { rows, scenario } = values;
        if (rows && rows.length > 0) {
            // update sliders
            const newSliders = rows
                .map((row) => ({
                    ...row,
                    paidIn: row.paidIn / 100,
                    irr: row.irr / 100,
                    rc1: row.rc1 / 100,
                    rc2: row.rc2 / 100,
                    rc3: row.rc3 / 100,
                    rc4: row.rc4 / 100,
                    rc5: row.rc5 / 100,
                    yld: row.yld / 100,
                }));
            updateBulkFundSliders(newSliders, scenario);
        }
        onClose();
    }

    const { watch, register, setValue, handleSubmit } = useFormMethods;
    const selectedStrategies = watch('strategies');
    const scenario = watch('scenario');
    const rows = watch('rows');

    const existingStrategies = getExistingPacingStrategiesSliders(scenario);
    const existingStrategiesOptions = existingStrategies.map(({ strategy }) => ({ label: strategy, id: strategy }));
    const scenarioOptions = [{ label: 'Base' }, { label: 'High' }, { label: 'Low' }];

    useEffect(() => {
        // remove not selected strategies
        const currents = [...rows]
            .filter((row) => selectedStrategies.map(({ label }) => label).includes(row.strategy));

        const newRows = selectedStrategies
            // get strategies
            .map(({ label }) =>
                existingStrategies.find((strat) => (strat.strategy === label && strat.case == scenario)
            ))
            // filter strategies already on the table
            .filter((row) => !currents.find((current) => current.strategy === row.strategy))
            // scale percentages values
            .map((row) => ({
                ...row,
                paidIn: row.paidIn * 100,
                irr: row.irr * 100,
                rc1: row.rc1 * 100,
                rc2: row.rc2 * 100,
                rc3: row.rc3 * 100,
                rc4: row.rc4 * 100,
                rc5: row.rc5 * 100,
                yld: row.yld * 100,
            }));
        setValue('rows', [...currents, ...newRows]);
    }, [selectedStrategies, scenario]);

    return (
        <Modal
            title="Edit Investment Strategies"
            isThemed
            size="xl"
            open={isOpen}
            onClose={onClose}
        >
            <FormProvider useFormMethods={useFormMethods}>
                <Grid container direction="column" spacing="24px">
                    <Grid container item direction="row" spacing="24px">
                        <Grid item xs={8}>
                            <FormInput
                                label="Select Strategies"
                                name="strategies"
                                type="autocomplete"
                                multiple
                                options={existingStrategiesOptions}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput
                                label="Select Scenario"
                                name="scenario"
                                type="dropdown"
                                options={scenarioOptions}
                                optionValue="label"
                                optionLabel="label"
                            />
                        </Grid>
                    </Grid>
                    <Grid item sx={{
                        '& .ag-body-viewport': {
                            border: 'var(--ag-borders) var(--ag-border-color)',
                        }
                    }}>
                        <EditStrategyTable register={register} rows={rows} />
                    </Grid>
                    <Grid container item justifyContent="flex-end" spacing="16px">
                        <Grid item>
                            <Button
                                onClick={() => onClose()}
                                theme="secondary"
                                sx={{ width: '88px' }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleSubmit(onSubmit)}
                                theme="primary"
                                sx={{ width: '88px' }}
                            >
                                Apply Changes
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </FormProvider>
        </Modal>
    );
};

export default EditMultipleModal;
