import { createMuiTheme } from '@material-ui/core/styles';
import primary from './colors/primary';

const theme = createMuiTheme({
    palette: {
        primary,
    },
    overrides: {
        MuiInputBase: {
            input: {
                minHeight: 'auto',
            },
        },
        MuiTableCell: {
            root: {
                padding: '8px',
            },
            stickyHeader: {
                backgroundColor: '#f5f7fa',
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#f5f7fa',
            },
        },
        MuiTableFooter: {
            root: {
                backgroundColor: '#f5f7fa',
            },
        },
        MuiTab: {
            root: {
                minWidth: '0 !important',
                marginLeft: '8px',
                marginRight: '8px',
            },
        },
        MuiTabs: {
            root: {
                borderBottom: '1px solid lightgray',
            },
            vertical: {
                borderBottom: 'none',
            },
        },
    },
});

export default theme;
