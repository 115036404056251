import { numberFormat } from 'utils';

function formatDisplayPercentage(params) {
    const { value, decimalPlace } = params;
    if (typeof value === 'undefined') {
        return '';
    }

    const formattedValue = numberFormat(value, decimalPlace);

    return params.colDef.field === 'delayInDistributions'
        ? `${formattedValue}`
        : `${formattedValue}%`;
}

export default function DefaultAssumptionsColumnDefinitions() {
    return [
        {
            headerName: 'Asset Class',
            field: 'assetClass',
            width: 200,
            rowGroup: true,
            hide: true,
        },
        {
            headerName: 'Paid-In',
            field: 'paidIn',
            cellRenderer: (data) => {
                if (typeof data.value === 'undefined') {
                    return '';
                }

                return `${data.value}%`;
            },
            width: 100,
        },
        {
            headerName: 'Year 1',
            field: 'rc1',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 2;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Year 2',
            field: 'rc2',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 2;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Year 3',
            field: 'rc3',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 2;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Year 4',
            field: 'rc4',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 2;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Year 5+',
            field: 'rc5',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 2;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'IRR ',
            field: 'irr',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 1;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Yield',
            field: 'yld',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 1;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Fund Life (Years)',
            headerTooltip: 'Fund Life (Years)',
            field: 'fundLife',
            width: 75,
        },
        {
            headerName: 'Delay in Distributions',
            headerTooltip: 'Delay in Distributions',
            field: 'delayInDistributions',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: () => {
                const decimalPlace = 2;
                return {
                    decimalPlace,
                };
            },
            width: 75,
        },
        {
            headerName: 'Scenario',
            field: 'case',
            hide: true,
            width: 75,
        },
        {
            headerName: 'Number of Funds',
            field: 'numFunds',
            width: 75,
        },
        {
            headerName: 'Contribution Timing',
            field: 'contribTiming',
            width: 75,
        },
    ];
}
