// @flow
import React, { useContext, useCallback, useState } from 'react';
import swal2 from 'sweetalert2';
import { getPacingDataById } from 'api';
import {
    AuthContext,
    PacingContext,
    PacingAnalysisContext,
    TargetAllocationContext,
    UnderwritingContext,
    LandingContext,
} from 'context';
import {
    Button,
    Breadcrumbs,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import PacingSelector from './PacingSelector';
import DialogTitleClose from '../../../AdminUserPage/DialogTitleClose';
const uuidv4 = require('uuid/v4');

type Props = {
    open: boolean,
    toggleModal: Function,
    setLoadedSavedPacing: Function,
};

const LoadFromDatabase = ({
    open,
    toggleModal,
    setLoadedSavedPacing,
}: Props) => {
    const [selectedPacing, setSelectedPacing] = useState(null);
    const { userSpiClientId } = useContext(AuthContext);
    const { updateLandingContext, setEntityId, setIsSavedPacing } = useContext(LandingContext);
    const { updatePacingContext } = useContext(PacingContext);
    const { updatePacingAnalysisContext } = useContext(PacingAnalysisContext);
    const { updateTargetAllocationContext } = useContext(
        TargetAllocationContext
    );
    const {
        updateUnderwritingContext,
        cleanContext,
    } = useContext(UnderwritingContext);
    const [loading, setLoading] = useState(false);

    const loadSelectedPacing = useCallback(async () => {
        const { pacing_data_id: clickedPacingId } = selectedPacing || {};

        if (!clickedPacingId) {
            return;
        }
        setLoading(true);
        try {
            const response = await getPacingDataById(clickedPacingId);
            const data = JSON.parse(response.saved_data);
            const portIds = [];
            const tableDataIds = [];
 
            await cleanContext();

            // we're doing this to 0-min this data because sometimes it
            // incorrectly can come back as negative
            let itemToModify;
            data.rawData.forEach((item) => {
                itemToModify = item;
                itemToModify.AdjNAV = Math.max(0, itemToModify.AdjNAV);
            });

            data.tableData.forEach((item) => {
                if (
                    item.hasOwnProperty('PortfolioId') &&
                    !portIds.includes(item.PortfolioId)
                ) {
                    portIds.push(item.PortfolioId);
                }
                itemToModify = item;
                itemToModify.AdjNAV = Math.max(0, itemToModify.AdjNAV);
                
                // make sure id's are unique here for old saved pacings
                if (item.hasOwnProperty('Id') && !tableDataIds.includes(item.Id)) {
                    tableDataIds.push(item.Id)
                } else if (item.hasOwnProperty('Id') && tableDataIds.includes(item.Id)) {
                    itemToModify.Id = uuidv4();
                }
            });

            if (portIds.length) {
                setEntityId(portIds);
            }

            data.updatedAt = response.updated_at;

            // Set reportDate to either report_date (old pacings)
            // or reportDate (newer pacings) from the database record.
            const reportDateToSet = Object.prototype.hasOwnProperty.call(
                data,
                'report_date'
            )
                ? data.report_date
                : data.reportDate;

            // set the edit text indicating the report date
            // will be edited, not set
            setLoadedSavedPacing(true);
            setIsSavedPacing(true);

            // these updaters must always be run together
            updateLandingContext(data, reportDateToSet);
            updatePacingContext(response);
            updatePacingAnalysisContext(data);
            updateTargetAllocationContext(data);
            updateUnderwritingContext(data);
            swal2.close();
        } catch (e) {
            console.log('error', e);
            swal2.close();
            swal2.fire({
                type: 'error',
                html:
                    'Something went wrong trying to load the pacing model. ' +
                    'Please contact an administrator if the problem persists',
            });
            setLoading(false);
            return;
        }
        setLoading(false);
        toggleModal();
    }, [selectedPacing, toggleModal]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
            onClose={() => !loading && toggleModal()}
        >
            <DialogTitleClose onClose={toggleModal} loading={loading}>
                <Breadcrumbs>
                    <div>Pacings</div>
                    <div>Load From Database</div>
                </Breadcrumbs>
            </DialogTitleClose>
            <DialogContent>
                <PacingSelector
                    selectedPacing={selectedPacing}
                    setSelectedPacing={setSelectedPacing}
                    userSpiClientId={userSpiClientId}
                />
            </DialogContent>
            <DialogActions>
                {selectedPacing && (
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={loadSelectedPacing}
                        style={{ background: '#007899', color: '#FFF'}}
                    >
                        Load
                    </Button>
                )}
                <Button
                    onClick={toggleModal}
                    disabled={false}
                    variant='contained'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoadFromDatabase;

