import React, { useContext } from 'react';
import { Col } from 'reactstrap';
import { UnderwritingContext } from 'context';

const UnderwritingTableLegend = () => {
    const { commitmentType } = useContext(UnderwritingContext);

    const legendStyling = {
        marginLeft: '15px',
        display: 'inline-block',
    };

    return (
        <Col xs={6}>
            <div className='legend'>
                {commitmentType === 'existing' && (
                    <div
                        className='positive-unfunded-old'
                        style={legendStyling}
                    >
                        Number of years between vintage year and current year is
                        greater than fund life.
                    </div>
                )}
            </div>
            <div className='legend'>
                {commitmentType === 'existing' && (
                    <div className='underperforming-fund' style={legendStyling}>
                        Underperforming fund, consider adjusting the default
                        parameters.
                    </div>
                )}
            </div>
        </Col>
    );
};

export default UnderwritingTableLegend;
