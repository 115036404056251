import React from 'react';
import {
    Col,
    Row,
    Input,
    FormGroup,
    Label,
    UncontrolledTooltip,
} from 'reactstrap';
import { IoMdInformationCircleOutline as InformationIcon } from 'react-icons/io';

const KeepCustomParams = ({ KeepCustomParams, setKeepCustomParams }) => {
    const style = {
        display: 'inline-flex',
        top: '-10px',
        left: '30px',
    };

    return (
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <Input
                        onChange={() => setKeepCustomParams()}
                        checked={KeepCustomParams}
                        type='checkbox'
                        style={style}
                    />
                    <Label
                        style={{ marginLeft: '20px', display: 'inline-flex' }}
                    >
                        Keep Custom Parameters
                    </Label>
                    <span href='#' id='KeepParams' style={{marginLeft: '10px'}}>
                        <InformationIcon />
                    </span>
                    <UncontrolledTooltip
                        style={{
                            backgroundColor: '#007899',
                            color: '#fff',
                            minWidth: '500px',
                            fontSize: '12px',
                        }}
                        placement='auto'
                        target='KeepParams'
                    >
                        Keep Custom Parameters allows you to maintain custom 
                        changes made on the Underwriting Page while updating
                        the Existing Commitments data. When loading through 
                        Excel, the application matches input assumptions based 
                        on the fund name, so confirm that your fund names match 
                        the prior upload.
                    </UncontrolledTooltip>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default KeepCustomParams;
