import { getQuarterFromDate } from 'utils';

const createFundSliderValues = (tableData, strategyParameters) => {
    const fundSliderValues = [];

    tableData.forEach(
        async ({ PacingStrategy, Investment, Id, Vintage, ClosingDate, investmentId }) => {
            const strategyDefaults = [...strategyParameters].filter(
                ({ strategy, contribTiming, numFunds }) =>
                    strategy === PacingStrategy &&
                    contribTiming === 'Base' &&
                    numFunds === 1
            );

            if (strategyDefaults) {
                const fundSliderHolder = {
                    investment: Investment,
                    id: String(Id),
                    investmentId: investmentId || null,
                    closingYear: ClosingDate
                        ? parseInt(ClosingDate.substring(0, 4))
                        : parseInt(Vintage),
                };

                strategyDefaults.forEach((stratDefault) => {
                    const { case: scenario } = stratDefault;

                    const holder = {
                        strategy: stratDefault.strategy,
                        model: stratDefault.model,
                        case: stratDefault.case,
                        paidIn: parseFloat(stratDefault.paidIn),
                        irr: parseFloat(stratDefault.irr),
                        yld: parseFloat(stratDefault.yld),
                        fundLife: parseFloat(stratDefault.fundLife),
                        bow: parseFloat(stratDefault.bow),
                        rc1: parseFloat(stratDefault.rc1),
                        rc2: parseFloat(stratDefault.rc2),
                        rc3: parseFloat(stratDefault.rc3),
                        rc4: parseFloat(stratDefault.rc4),
                        rc5: parseFloat(stratDefault.rc5),
                        coinvesFlag: stratDefault.coinvestFlag || 0,
                        commitQtr: ClosingDate
                            ? getQuarterFromDate(ClosingDate)
                            : 'Q2',
                        userEdited: stratDefault.userEdited || [],
                    };

                    if (scenario === 'Base') {
                        fundSliderHolder.values = holder;
                    } else if (scenario === 'High') {
                        fundSliderHolder.valuesHigh = holder;
                    } else if (scenario === 'Low') {
                        fundSliderHolder.valuesLow = holder;
                    }
                });

                fundSliderValues.push(fundSliderHolder);
            }
        }
    );

    return fundSliderValues;
};

export default createFundSliderValues;
