import {
    formatExcelExportOutput,
    formatExcelExportInput,
    formatExcelExportRawData,
    addPortfolioNameToFundData,
    getHistoricRawData,
} from './ExcelExportHelpers';
import { formatInputs } from './PacingAnalysisHelpers';

/**
 * Export the pacing.
 * Can export data grouped by quarter or by year
 */
const formatExport = (
    type,
    tableData,
    pacingAnalysisContext,
    targetAllocationContext,
    underwritingContext,
    smoothing,
    assetClassShortNames,
    strategyParameters,
    targetAllocationStrategies,
    reportDate,
    useForwardNav,
    graphData,
    historicalData,
    viewHistorical,
    historicalExportData,
    historicalSetBackValue
) => {
    // Make copy of graphData to avoid modifying the original
    const graphDataCopy = JSON.parse(
        JSON.stringify(graphData)
    );

    addPortfolioNameToFundData(tableData, {...graphDataCopy});

    const { fundSliderValues, sliderValues } = underwritingContext;
    const { strategyAllocation } = targetAllocationContext;
    const { checkForRenamedStrategy, pacingParameters: { commitmentScheduleStrategies }, } = pacingAnalysisContext;
    const formattedInputs = formatInputs(
        pacingAnalysisContext,
        targetAllocationContext,
        targetAllocationStrategies,
        smoothing,
        assetClassShortNames,
        reportDate,
        useForwardNav,
        'excel'
    );

    // format the pacing outputs from run analysis
    const formattedData = formatExcelExportOutput(
        { ...graphDataCopy },
        { ...historicalData },
        checkForRenamedStrategy,
        type,
        viewHistorical
    );

    // format the paramters for the analysis
    const formattedMiscInputs = formatExcelExportInput(
        tableData,
        strategyAllocation,
        formattedInputs,
        checkForRenamedStrategy,
        assetClassShortNames,
        [...sliderValues],
        [...fundSliderValues],
        strategyParameters,
        targetAllocationStrategies
    );

    // Return the raw data from the output rounded to 2 decimals
    let formattedRawData = formatExcelExportRawData(
        { ...graphDataCopy },
        type,
        checkForRenamedStrategy,
        commitmentScheduleStrategies,
        historicalExportData,
        reportDate,
    );

    if (historicalSetBackValue < 100) {
        getHistoricRawData(reportDate, historicalSetBackValue, formattedRawData)
    }

    return {
        formattedData,
        formattedMiscInputs,
        formattedRawData,
    };
};

export default formatExport;
