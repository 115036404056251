// @flow
import React, { Component } from 'react';
import FormatNumber from './FormatNumber';

type Props = {
    optimizer: Array<any>,
    colDef: { field: string },
    data: { strategy: string },
    toggleLock: Function,
    rowIndex: number,
    handleValueClick: Function,
};

export default class FormatPercentage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    handleToggle = () => {
        const { colDef, data, toggleLock } = this.props;

        toggleLock(colDef.field, data.strategy);
    };

    handleValueClick = () => {
        const { colDef, data, rowIndex, handleValueClick } = this.props;

        handleValueClick(colDef, rowIndex, data);
    };

    // a11y compliance
    handleKeyUp = (event: SyntheticKeyboardEvent<HTMLBodyElement>) => {
        if (event.key === 'Enter') {
            this.handleValueClick();
        }
    };

    render() {
        const {
            data: { strategy },
            isPercentage = false,
        } = this.props;

        const formattedNumber = FormatNumber(this.props);
        const formattedText = isPercentage ? `${formattedNumber}%` : formattedNumber;

        return strategy !== 'Liquid Assets' && strategy !== 'Portfolio AuM' ? (
            <>
                {/* Do not add a tab-index. Will break functionality */}
                {/* eslint-disable jsx-a11y/interactive-supports-focus */}
                <div
                    style={{ flexGrow: 1 }}
                    onClick={this.handleValueClick}
                    role='button'
                    onKeyUp={this.handleKeyUp}
                >
                    {formattedText}
                </div>
                {/* eslint-enable jsx-a11y/interactive-supports-focus */}
            </>
        ) : (
            <></>
        );
    }
}
