import React from 'react';
import { Tooltip } from '@mui/material';
import { Button } from '@ssgglobal/techintnue';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';

const DefaultAssumptionsButton = ({
    isTrialUser,
    handleClick,
}) => {
    return (
        <Tooltip
            placement='top-start'
            title={
                isTrialUser
                    ? 'Not available during Pacing Trial'
                    : 'View list of strategies and their default assumptions.'
            }
        >
            <Button
                theme="primary"
                size="small"
                onClick={handleClick}
                disabled={isTrialUser}
            >
                View Default Assumptions
            </Button>
        </Tooltip>
    )
}

const TargetAllocationButton = ({
    isTrialUser,
    handleClick,
}) => {
    return (
        <Tooltip
            title="Add new strategies"
        >
            <Button
                theme="primary"
                size="small"
                onClick={handleClick}
                disabled={isTrialUser}
            >
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <AddIcon />
                    <span>Add Investment Strategies</span>
                </Box>
            </Button>
        </Tooltip>
    )
}

const ModalButton = ({
    isTrialUser,
    commitmentType,
    setTargeAllocationModal,
}) => {
    if (commitmentType == 'existing') {
        return null;
    }
    return (
        <TargetAllocationButton
            isTrialUser={isTrialUser}
            handleClick={() => setTargeAllocationModal(true)}
        />
    );
}

export default ModalButton;
