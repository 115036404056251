import React, { useContext } from 'react';
import { Tabs } from '@ssgglobal/techintnue';

const StrategyTab = ({
    value,
    onChange,
    options,
}) => {
    const parsedOptions = options.map((option) => ({ tabLabel: option }))
    return (
        <Tabs
            variant="ssg"
            initialValue={value}
            handleInitialChange={onChange}
            tabObject={parsedOptions}
        />
    )
};

export default StrategyTab;
