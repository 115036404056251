import React, { useContext } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Label } from 'reactstrap'
import { Button, DropdownTextField, FormInput, FormProvider, Grid, Modal, TableVariant, Typography, useForm } from '@ssgglobal/techintnue'
import { StaticsContext, TargetAllocationContext, UnderwritingContext } from 'context'
import ContributionPercentageTable from './ContributionPercentageTable'
import MetricPercentageTable from './MetricPercentageTable'
import Swal from 'sweetalert2'
import { InputLabel } from '@mui/material'

const FormSchema = yup.object({
    name: yup.string().required(),
    assetClass: yup.string().required(),
})

const errorMapping = {
    name: 'Strategy Name Required',
    assetClass: 'Asset Class Required',
}

const CreateNewStrategyModal = ({ isOpen, onClose }) => {
    /**
     * Error alert with custum error message
     */
    const displayError = (message) => {
        Swal.fire({
            type: 'error',
            html: message,
        });
    };
    const { assetClasses, strategiesByAllocation } = useContext(StaticsContext)
    const { updateUserDefinedStrategy, updateCoinvestToggle } = useContext(UnderwritingContext)
    const {
        renameUserDefinedTAS,
        getAvailableUserDefinedTASForAssetClass,
        updateStrategySelection,
    } = useContext(TargetAllocationContext)
    
    const useFormMethods = useForm({
        defaultValues: {
            name: '',
            assetClass: '',
            contribution: {
                rc1: 20,
                rc2: 20,
                rc3: 20,
                rc4: 20,
                rc5: 20,
            },
            metrics: {
                irr: 10,
                yld: 0,
                fundLife: 10,
                bow: 2,
                paidIn: 100,
            },
            coinvest: false,
        },
        resolver: async (data, context, options) => {
            const result = await yupResolver(FormSchema)(data, context, options);
            if (result.errors && Object.keys(result.errors).length > 0) {
                const errorMessage = Object.keys(result.errors)
                    .map((key) => `<p>${errorMapping[key]}</p>`)
                    .join('');
                displayError(errorMessage);
            }
            return result;
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    })
    const {
        register,
        handleSubmit,
        watch,
        reset,
    } = useFormMethods
    const assetClass = watch('assetClass')
    const contribution = watch('contribution')
    const metrics = watch('metrics')

    const handleClose = () => {
        onClose();
        reset();
    }

    const onSubmit = (values) => {
        const availableTASs = getAvailableUserDefinedTASForAssetClass(values.assetClass);
        if (availableTASs.length <= 0) { // no more user defined TAS available
            displayError(`No more User Defined Strategies available for Asset Class ${values.assetClass}`);   
        } else {
            const availableTAS = availableTASs[0];
            const stratName = `${availableTAS.prefix} ${availableTAS.originalValue}`
            // rename strat
            renameUserDefinedTAS({ target: { name: stratName, value: values.name } })
            if (values.coinvest) {
                // update coinvest flag
                updateCoinvestToggle(stratName);
            }
            // update values of sliders
            updateUserDefinedStrategy(stratName, {
                rc1: values.contribution.rc1 / 100,
                rc2: values.contribution.rc2 / 100,
                rc3: values.contribution.rc3 / 100,
                rc4: values.contribution.rc4 / 100,
                rc5: values.contribution.rc5 / 100,
                irr: values.metrics.irr / 100,
                yld: values.metrics.yld / 100,
                fundLife: values.metrics.fundLife,
                bow: values.metrics.bow,
                paidIn: values.metrics.paidIn / 100,
            });
            // mark it as selected
            updateStrategySelection(stratName, true);
        }
        handleClose();
    }

    const assetList = assetClasses.map((asset) => ({
        value: asset.short_name,
        label: asset.long_name
    }));
    const strategyList = strategiesByAllocation[assetClass]?.map((strategy) => ({
        value: strategy,
        label: strategy,
    })) || []
    return (
        <Modal
            title="Create New Strategy"
            isThemed
            size="xl"
            open={isOpen}
            onClose={handleClose}
        >
            <FormProvider useFormMethods={useFormMethods}>
                <Grid
                    container
                    flex
                    gap="24px"
                    direction="column"
                    sx={{
                        '>  .MuiBox-root > div[role=tabpanel]': {
                            height: 0 
                        }
                    }}
                >
                    <Grid container item flex direction="row" spacing="24px">
                        <Grid item xs={6}>
                            <FormInput
                                name="name"
                                label="Name Strategy"
                                placeholder="New Assumption"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel sx={{ marginBottom: '3px' }}>Asset Class</InputLabel>
                            <DropdownTextField
                                {...register("assetClass")}
                                options={assetList}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item flex gap="24px">
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Contribution as % of Unfunded</Typography>
                        <ContributionPercentageTable register={register} value={contribution} />
                    </Grid>
                    <Grid container item flex gap="24px">
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Metrics</Typography>
                        <MetricPercentageTable register={register} value={metrics} />
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <FormInput
                                name="coinvest"
                                label="Co-Invest / Secondary"
                                type="checkbox"
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            flex
                            spacing="16px"
                            justifyContent="end"
                        >
                            <Grid item>
                                <Button
                                    onClick={handleClose}
                                    theme="secondary"
                                    sx={{ width: '88px' }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleSubmit(onSubmit)}
                                    theme="primary"
                                    sx={{ width: '88px' }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FormProvider>
        </Modal>
    )
}

export default CreateNewStrategyModal
