// @flow
import React, { useContext } from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { PacingContext, PacingAnalysisContext } from 'context';

type Props = {
    updateIncrement: Function,
};

export default function({ updateIncrement }: Props) {
    const { increment } = useContext(PacingAnalysisContext);
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    const updateValue = (event) => {
        if (event.key === 'Enter') {
            updateIncrement(event);
        }
    };

    const style = {
        marginTop: '8px',
        marginLeft: '18px',
    };



    return (
        <Row>
            <Label
                style={style}
                title='Amount in millions wtih a maximum of 250'
            >
                Increment
            </Label>
            <Col md={1}>
                {pacingClientReadOnlyAndSpiClientId() ? (
                    <div>{increment}</div>
                ) : (
                    <Input
                        name='increment'
                        step='1'
                        type='number'
                        defaultValue={increment}
                        onBlur={updateIncrement}
                        onKeyPress={updateValue}
                        max={250}
                        min={0}
                    />
                )}
            </Col>
        </Row>
    );
}
