import React, { useContext } from 'react';
import Button from 'reactstrap/es/Button';
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import { GoTrashcan } from 'react-icons/go';
import { deletePacingDataById } from 'api';
import swal2 from 'sweetalert2';

const ManagePacingActionButtons = ({ value, data, refreshTable, spiClientId }) => {
    const deletePacing = (rowInfo) => {
        swal2
            .fire({
                type: 'warning',
                text: `Are you sure you would like to delete "${rowInfo.name}"? This action cannot be undone.`,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'confirm-button-class',
                    cancelButton: 'cancel-button-class',
                },
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            })
            .then(async (result) => {
                if (result.value) {
                    try {
                        await deletePacingDataById(rowInfo.pacing_data_id);

                        swal2.fire(
                            'Deleted',
                            'The pacing has been deleted.',
                            'success'
                        );

                        await refreshTable();
                    } catch (error) {
                        swal2.fire(
                            'Error',
                            'Something went wrong while trying to delete the pacing. Please try again or contact an administrator.',
                            'error'
                        );
                    }
                }
            });
    };

    return value && (value === 'No' || !spiClientId) ? (
        <ButtonGroup>
            <Button
                color='danger'
                size='sm'
                title='Delete pacing'
                onClick={() => deletePacing(data)}
            >
                <GoTrashcan />
            </Button>
        </ButtonGroup>
    ) : (
        <></>
    );
};

export default ManagePacingActionButtons;
