// @flow
import React, { useContext } from 'react';
import { Grid, RadioGroup, InputTextField } from '@ssgglobal/techintnue';
import { PacingContext } from 'context';

type Props = {
    disableCheck: boolean,
    handleTargetTypeChange: Function,
    // handleYearChange: Function,
    handleTargetYearBlur: Function,
    targetType: string,
    targetYear: number,
};

export default function({
    targetType,
    targetYear,
    handleTargetTypeChange,
    // handleYearChange,
    handleTargetYearBlur,
    disableCheck,
}: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    const showDropdown = targetType === 'custom';

    const targetOptions = [
        {
            label: 'Optimize',
            value: 'optimize'
        },
        {
            label: 'Set Year',
            value: 'custom'
        }
    ]

    const inputStyle = {
        color: '#333',
        border: '1px solid #BDBFBF',
        borderRadius: '5px',
        padding: '8px 8px 8px 16px',
        width: '100%',
        height: '40px',
    }

    const handleChange = (e) => {
        const typeChange = {
            target: {
                name: 'targetYearType',
                value: e,
            }
        }
        handleTargetTypeChange(typeChange)
    }

    return (
        <>
            {pacingClientReadOnlyAndSpiClientId() ? (
                <Grid item>
                    {targetType === 'optimize' ? 'Optimized' : targetYear}
                </Grid>
            ) : (
                <Grid container>
                    <Grid
                        item
                        md={6}
                        sx={{
                            '& > .MuiBox-root > .MuiFormGroup-root > .MuiFormControlLabel-root': {
                                marginBottom: 0,
                            },
                        }}
                    >
                        <RadioGroup
                            isThemed
                            label="Target Year"
                            value={targetType}
                            onChange={handleChange}
                            options={targetOptions}
                        />
                    </Grid>
                    { showDropdown &&
                        <Grid item md={6} sx={{ '& > *': { marginTop: '22px' } }}>
                            <InputTextField
                                name='targetYear'
                                defaultValue={targetYear}
                                // onChange={handleYearChange}
                                onChange={handleTargetYearBlur}
                            />
                        </Grid>
                    }
                </Grid>
            )}
        </>
    );
}
