import React, { useState, useEffect, useContext, useRef } from 'react'
import { Button, Col, Row, Label, Input } from 'reactstrap';
import {format, addMonths} from "date-fns";
import { getNextQuarterStartDate } from 'utils';
import { getQuarterAndYearFromDate, getQuartersStartDate, generateQuarters } from '../../../utils/Helpers';
import { PacingAnalysisContext } from 'context';
import { Modal } from '@ssgglobal/techintnue'

const DownTurnInput = ({reportDate, runAnalysis}) => {

    const {setPacingParametersKeyValue, pacingParameters, dtHistoricValues} = useContext(PacingAnalysisContext)

    const [downturnValue, setDownturnValue] = useState('40')
    const [runScenarioEnabled, setRunScenarioEnabled] = useState(true)
    const [endDateOptions, setEndDateOptions] = useState([])
    const reportDateOffsetString = getNextQuarterStartDate(reportDate)
    const [startDateValue, setStartDateValue] = useState(getQuarterAndYearFromDate(reportDateOffsetString))
    const [endDateValue, setEndDateValue] = useState('')

    const [openDocsModal, setOpenDocsModal] = useState(false)
    const onCloseDocsModal = () => {
        setOpenDocsModal(false)
    }

    useEffect(() => {
        calculateEndDateOptions()
    }, [startDateValue])

    const calculateEndDateOptions = () => {
        const generatedValue = pacingParameters.dtStartDate || startDateValue
        const quarterList = generateQuarters(generatedValue, 20);
        const endValue = quarterList[3] 
        setEndDateValue(endValue)

        if(dtHistoricValues.current){
            setPacingParametersKeyValue('dtEndDate', endValue)
            dtHistoricValues.current = false
        }

        const options = [];

        quarterList.forEach( (q) => {
            options.push(
                <option key={crypto.randomUUID()} value={q}>
                    {q}
                </option>
            )
        })

        setEndDateOptions(options);
    }

    const setQuartersDateOptions = (isEndDates = false, reportDate) => {
        const startDate = isEndDates ? format(addMonths(new Date(reportDate), 3), 'yyyy-MM-dd') : getNextQuarterStartDate(reportDate);

        const quarters = getQuartersStartDate(startDate);
        const options = [];

        quarters.forEach( (q) => {
            options.push(
                <option key={crypto.randomUUID()} value={q}>
                    {q}
                </option>
            )
        })
        return options
    }

    const handleDownturnChange = (e) => {
        let val = e.target.value;

        const valid = /^(?:0|[1-9][0-9]?|100)$/.test(val)

        if (valid) {
            setDownturnValue(val)
            setPacingParametersKeyValue('dtPercentValue', val)
            setRunScenarioEnabled(true)
        } else {
            setDownturnValue('')
            setRunScenarioEnabled(false)
        }
    }

    const handleStartDate = (e) => {
        setStartDateValue(e.target.value)
        dtHistoricValues.current = true
        setPacingParametersKeyValue('dtStartDate', e.target.value)
    }

    const handleEndDate = (e) => {
        setEndDateValue(e.target.value);
        setPacingParametersKeyValue('dtEndDate', e.target.value)
    }

    const handleRunScenario = () => {
        const downturnStartDate = startDateValue.split(" ");
        const downturnEndDate = endDateValue.split(" ");

        setPacingParametersKeyValue('dtStartQtr', downturnStartDate[0]);
        setPacingParametersKeyValue('dtStartYear', parseInt(downturnStartDate[1]));
        setPacingParametersKeyValue('dtEndQtr', downturnEndDate[0]);
        setPacingParametersKeyValue('dtEndYear', parseInt(downturnEndDate[1]));
        setPacingParametersKeyValue('dtPercent', parseInt(downturnValue) / 100 || 0);

        runAnalysis(true)
    }

    const handleShowDocs = () => {
        setOpenDocsModal(true)
    }

    return (
    <Row style={{ marginTop: '20px'}}>
        <Col 
            md={3}
            style={{
                paddingLeft: '20px',
            }}
            >
            <Label>Start Date (Beginning of Quarter)</Label>
            <Input
                type='select'
                value={pacingParameters.dtStartDate || startDateValue}
                onChange={handleStartDate}
                name='startDate'
                style={{
                    height: '40px'
                }}
            >
                {/* <option disabled value=''>
                    Year, Quarter
                </option> */}
                {setQuartersDateOptions(false, reportDate)}
            </Input>
        </Col>
        <Col 
            md={3}
            style={{
                paddingLeft: '20px',
            }}
            >
            <Label>End Date (End of Quarter)</Label>
            <Input
                type='select'
                value={pacingParameters.dtEndDate || endDateValue}
                onChange={handleEndDate}
                name='endDate'
                style={{
                    height: '40px'
                }}
                >
                {/* <option disabled value=''>
                    Year, Quarter
                </option> */}
                {endDateOptions}
            </Input>
        </Col>
        <Col 
            md={2}
            style={{
                paddingLeft: '20px',
            }}
            >
            <Label>Downturn %</Label>
            <Input
                type='text'
                maxLength={3}
                max={100}
                value={pacingParameters.dtPercentValue || downturnValue}
                onChange={handleDownturnChange}
                name='downTurn'
                style={{
                    height: '40px'
                }}
            />
        </Col>
        <Col md={3} style={{marginTop: '20px'}}>
            <Button 
                className='primary-button'
                color='info'
                onClick={handleRunScenario}
                disabled={!runScenarioEnabled}
            >
                Run Scenario
            </Button>
            <Button 
                className='secondary-button'
                color='info'
                onClick={handleShowDocs}
            >
                Documentation
            </Button>
            <Modal 
                title={'Downturn Scenario Documentation'}
                isThemed={'true'}
                size='lg'
                open={openDocsModal} 
                onClose={onCloseDocsModal}
            >
                <p>
                    The Downturn Scenario is a function that temporarily overrides the pacing assumptions for a set period
                    defined by the user. The function is designed to mimic an economic slowdown where less capital is
                    drawn for new investments, and fewer assets are liquidated, meaning distributions to LPs are reduced.
                </p>
                <p>
                    The downturn functions as a time change, with the duration set in quarters, and the time slow-down
                    defined as a percentage. As an illustration, if the downturn is 50% and set for one year, the model will
                    produce 50% of the base case projections (contributions and distributions) during that one-year period,
                    followed by a return to the base case assumptions. In this example, the fund life will also extend by 0.5
                    years. A 100% downturn for one year would result in no cash flows for the year and extend the fund life
                    by one year. As a reference, annual contributions and distributions during the GFC slowed to around
                    60% of the rate post-2010 (40% downturn).<sup>1</sup>
                </p>
                <p>
                    This scenario impacts all vintages, including new commitments. Importantly, the downturn directly
                    affects contributions and distributions but does not model a decrease in the NAV growth rate. The
                    projected NAV is nevertheless impacted by the change in projected cash flows.
                </p>
                    
                <p>
                    <i style={{fontSize: '10px'}}><sup>1</sup>StepStone Analysis, as of 6/9/2020. Analysis based on a Buyout portfolio 
                        constructed with equal commitments across vintages beginning in 1995. Compares the contributions and distributions during the GFC
                        (12/31/2007-3/31/2009) to contributions distributions after the GFC (9/30/2010-9/30/2019).
                    </i>
                </p>
            </Modal>
        </Col>
    </Row>
    )
}

export default DownTurnInput
