import React, { Component } from 'react';
import { numberFormat } from 'utils';
import { ExistingCommitmentsGraph, NewCommitmentsGraph } from './components';

type Props = {
    commitmentType: string,
    contribToDate: number,
    distribToDate: number,
    numYrsFromVinToCurrDate: number,
    projectedContrib: number,
    plotGraph: any,
    graphValues: any,
    graphValuesHigh: any,
    graphValuesLow: any,
};

class LeftChart extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            contribDataPoints: [],
            distribDataPoints: [],
            baseContribDataPoints: [],
            baseDistribDataPoints: [],
            projectedContribDataPoints: [],
            yield: [],
            contribUncertainty: [],
            distribUncertainty: [],
            years: [],
            contribToDateLine: [],
            distribToDateLine: [],
        };
    }

    /**
     * To prevent multiple rerenderings of the graph
     *
     * @param {*} nextProps
     * @param {*} nextState
     */
    shouldComponentUpdate(nextProps, nextState) {
        const {
            graphValues,
            graphValuesHigh,
            graphValuesLow,
            historical,
        } = this.props;
        if (
            graphValues !== nextProps.graphValues ||
            graphValuesLow !== nextProps.graphValuesLow ||
            graphValuesHigh !== nextProps.graphValuesHigh ||
            historical !== nextProps.historical ||
            this.state !== nextState
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        this.updateGraph(prevProps);
    }

    /**
     * Format the incoming data into arrays
     * to plug into kendo charts
     */
    calculateValues = () => {
        const {
            graphValues,
            graphValuesLow,
            graphValuesHigh,
            commitmentType,
            projectedContrib,
            contribToDate,
            distribToDate,
            numYrsFromVinToCurrDate,
            historical,
        } = this.props;

        const shiftQtr = historical ? historical.shift_qtr / 4 : 0;
        const contribDataPoints = [];
        const distribDataPoints = [];

        let year = 0;
        const newBaseContribDataPoints = [];
        const newBaseDistribDataPoints = [];
        const newProjectedContribDataPoints = [];
        const newInvYield = [];
        const newContribUncertainty = [];
        const newDistribUncertainty = [];
        const time = [];
        const newContribToDateLine = [];
        const newDistribToDateLine = [];

        let cumContrib = 0;
        let cumDistrib = 0;
        let cumBaseContrib = 0;
        let cumBaseDistrib = 0;
        let cumYield = 0;
        let cumContribUncertaintyHigh = 0;
        let cumDistribUncertaintyHigh = 0;
        let cumContribUncertaintyLow = 0;
        let cumDistribUncertaintyLow = 0;
        // format values in graph and calculate uncertainty area range
        for (let i = 0; i < graphValues.contrib.length; i++) {
            // increment time by a quarter
            time.push(year);
            cumContrib += graphValues.contrib[i];
            cumDistrib += graphValues.distrib[i];
            cumYield += graphValues.yld[i];

            // Assign the contribution and distribution graph line values
            let contribValue;
            let distribValue;
            if (commitmentType === 'existing') {
                let contribToDateObject;
                if (year === parseFloat(numYrsFromVinToCurrDate) + shiftQtr) {
                    newContribToDateLine.push(contribToDate);
                    newDistribToDateLine.push(distribToDate);
                } else {
                    newContribToDateLine.push(contribToDateObject);
                    newDistribToDateLine.push(contribToDateObject);
                }

                newProjectedContribDataPoints.push(projectedContrib);
                if (
                    year > numYrsFromVinToCurrDate + shiftQtr &&
                    typeof cumContrib !== 'undefined'
                ) {
                    contribValue = numberFormat(cumContrib * 100);
                } else if (historical) {
                    contribValue = numberFormat(
                        historical.contrib_perc[i] * 100
                    );
                }

                if (
                    year > numYrsFromVinToCurrDate + shiftQtr &&
                    typeof cumDistrib !== 'undefined'
                ) {
                    distribValue = numberFormat(cumDistrib * 100);
                } else if (historical) {
                    distribValue = numberFormat(
                        historical.distrib_perc[i] * 100
                    );
                }
            } else {
                contribValue = numberFormat(cumContrib * 100);
                distribValue = numberFormat(cumDistrib * 100);
            }

            // Assign contribution and distribution values
            contribDataPoints.push(contribValue);
            distribDataPoints.push(distribValue);
            newInvYield.push(numberFormat(cumYield * 100));

            // Assign the base case contribution/distribution graph line values
            if (graphValues.base_contrib && graphValues.base_distrib) {
                cumBaseContrib += graphValues.base_contrib[i];
                cumBaseDistrib += graphValues.base_distrib[i];

                newBaseContribDataPoints.push(
                    numberFormat(cumBaseContrib * 100)
                );
                newBaseDistribDataPoints.push(
                    numberFormat(cumBaseDistrib * 100)
                );
            }

            // Create the uncertainty shading
            if (
                typeof graphValuesHigh !== 'undefined' &&
                typeof graphValuesLow !== 'undefined'
            ) {
                // Create the uncertainty shading.
                cumContribUncertaintyHigh += graphValuesHigh.contrib[i];
                cumDistribUncertaintyHigh += graphValuesHigh.distrib[i];
                cumContribUncertaintyLow += graphValuesLow.contrib[i];
                cumDistribUncertaintyLow += graphValuesLow.distrib[i];

                if (cumContribUncertaintyLow !== 0) {
                    newContribUncertainty.push({
                        min: numberFormat(cumContribUncertaintyLow * 100),
                        max: numberFormat(cumContribUncertaintyHigh * 100),
                    });
                } else {
                    newContribUncertainty.push({});
                }
                if (cumContribUncertaintyLow !== 0) {
                    newDistribUncertainty.push({
                        min: numberFormat(cumDistribUncertaintyLow * 100),
                        max: numberFormat(cumDistribUncertaintyHigh * 100),
                    });
                } else {
                    newDistribUncertainty.push({});
                }
            }

            year += 0.25;
        }

        return {
            contribDataPoints,
            distribDataPoints,
            baseContribDataPoints: newBaseContribDataPoints,
            baseDistribDataPoints: newBaseDistribDataPoints,
            projectedContribDataPoints: newProjectedContribDataPoints,
            invYield: newInvYield,
            contribUncertainty: newContribUncertainty,
            distribUncertainty: newDistribUncertainty,
            time,
            contribToDateLine: newContribToDateLine,
            distribToDateLine: newDistribToDateLine,
        };
    };

    /**
     * Sets state with returned value from
     * calculateValues()
     *
     * @param {*} prevProps
     */
    updateGraph = (prevProps) => {
        const { graphValues } = this.props;

        if (typeof graphValues !== 'undefined' && this.props !== prevProps) {
            const {
                contribDataPoints,
                distribDataPoints,
                baseContribDataPoints,
                baseDistribDataPoints,
                projectedContribDataPoints,
                invYield,
                contribUncertainty,
                distribUncertainty,
                time,
                contribToDateLine,
                distribToDateLine,
            } = this.calculateValues();

            this.setState({
                contribDataPoints,
                distribDataPoints,
                baseContribDataPoints,
                baseDistribDataPoints,
                projectedContribDataPoints,
                yield: invYield,
                contribUncertainty,
                distribUncertainty,
                years: time,
                contribToDateLine,
                distribToDateLine,
            });
        }
    };

    render() {
        const {
            baseContribDataPoints,
            baseDistribDataPoints,
            contribDataPoints,
            distribDataPoints,
            contribUncertainty,
            distribUncertainty,
            projectedContribDataPoints,
            years,
            contribToDateLine,
            distribToDateLine,
            yield: invYield,
        } = this.state;
        const { commitmentType, plotGraph } = this.props;
        let commitmentsGraph = '';

        if (commitmentType === 'new') {
            commitmentsGraph = (
                <NewCommitmentsGraph
                    plotGraph={plotGraph}
                    contribDataPoints={contribDataPoints || []}
                    distribDataPoints={distribDataPoints || []}
                    invYield={invYield || []}
                    contribUncertainty={contribUncertainty || []}
                    distribUncertainty={distribUncertainty || []}
                    years={years || []}
                />
            );
        } else {
            commitmentsGraph = (
                <ExistingCommitmentsGraph
                    plotGraph={plotGraph}
                    contribDataPoints={contribDataPoints || []}
                    distribDataPoints={distribDataPoints || []}
                    invYield={invYield || []}
                    baseContribDataPoints={baseContribDataPoints || []}
                    baseDistribDataPoints={baseDistribDataPoints || []}
                    projectedContribDataPoints={
                        projectedContribDataPoints || []
                    }
                    years={years || []}
                    contribUncertainty={contribUncertainty || []}
                    distribUncertainty={distribUncertainty || []}
                    contribToDateLine={contribToDateLine || []}
                    distribToDateLine={distribToDateLine || []}
                />
            );
        }

        return (
            <div className='panel underwriting-topper m-0 section-border'>
                {commitmentsGraph}
            </div>
        );
    }
}

export default LeftChart;
