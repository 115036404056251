// @flow
import React, { useContext, useEffect } from 'react';
import Container from 'reactstrap/es/Container';
import { withRouter } from 'react-router-dom';
import { colorCombos } from 'utils';
import { AuthContext } from 'context';

type Props = {
    history: Object,
};

const AuthComponent = ({ history }: Props) => {
    const { checkAuthentication, message } = useContext(AuthContext);

    useEffect(() => {
        const awaitAuthenticationCheck = async () => {
            await checkAuthentication();

            history.push('/');
        };
        awaitAuthenticationCheck();
    }, []);

    /* eslint-disable no-nested-ternary */
    return (
        <Container
            style={{
                minHeight: '1000px',
            }}
        >
            <h2
                className='m-auto'
                style={{
                    paddingTop: '300px',
                    width: '190px',
                    color: colorCombos[0].primary,
                }}
            >
                {message}
            </h2>
        </Container>
    );
};

export default withRouter(AuthComponent);
