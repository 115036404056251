// if value doesnt match the regex (IE we pasted a word string here by accident)
// reformat the cell to say ERROR
export const numericValidator = ({ node, column, newValue }) => {
    const regex = /[\d-.]+$/;
    if (!newValue.toString().match(regex) && newValue !== 'ERROR') {
        node.setDataValue(column.colId, 'ERROR');
    }
};

// if value does not exist in the dropdown choices given (IE via pasting wrong)
// reformat the cell to say ERROR
export const dropdownValidator = ({ node, column, colDef, newValue }) => {
    let dropdownChoices = [];

    if (typeof colDef.cellEditorParams === 'object') {
        dropdownChoices = colDef.cellEditorParams.values;
    } else {
        dropdownChoices = colDef.cellEditorParams().values;
    }

    if (!dropdownChoices.includes(newValue) && newValue !== 'ERROR') {
        node.setDataValue(column.colId, 'ERROR');
    }
};
