/**
 * Add rows to the commitent schedule for any new strategies
 * allocated after the intial commitment schedule was made
 */
const addNewRows = (years, stratsToAdd, newCommitmentSchedule, findStrategyByName, sliderValues, scenario) => {
    stratsToAdd.forEach((strategy) => {
        const foundStrategy = findStrategyByName(strategy);
        const slider = sliderValues.find((value) =>
            value.strategy == foundStrategy.serverNames && value.case.toLowerCase() == scenario.toLowerCase()
        );
        const holder = {
            alloc: 0,
            commitQtr: slider.commitQtr || 'All',
            strategy,
            serverNames: foundStrategy.serverNames,
            min: 0,
            max: 100000,
            increment: 1,
        };
        years.forEach((year) => {
            holder[year] = 0;
        });

        newCommitmentSchedule.push(holder);
    });

    return newCommitmentSchedule;
};

const addOptimizerRows = (years, stratsToAdd, newOptimizer) => {
    stratsToAdd.forEach((strategy) => {
        const holder = { strategy };
        years.forEach((year) => {
            holder[year] = 0;
        });

        newOptimizer.push(holder);
    });

    return newOptimizer;
};

/**
 * Create the commitment schedule based on startYear, endYear,
 * fiscalYearPlusOne, and existing commitment schedule (if exists)
 */
const createCommitmentSchedule = (
    commitmentSchedule,
    commitmentScheduleOptimizer,
    stratYearsArray,
    stratsToAdd = [],
    checkForRenamedStrategy,
    findStrategyByName,
    strategyAllocation,
    sliderValues,
    scenario = 'Base',
) => {
    const { strategies, years } = stratYearsArray;

    // Create a new commitment schedule array
    // from the old array that only contains strategies
    // in the new set of strategies
    const newCommitmentSchedule = [];
    const newCommitmentOptimizer = [];
    commitmentSchedule.forEach((row, index) => {
        const renamed = checkForRenamedStrategy(row.strategy);
        const foundStrategy = findStrategyByName(row.strategy);
        const slider = sliderValues.find((value) => 
            value.strategy == foundStrategy.serverNames && value.case.toLowerCase() == scenario.toLowerCase()
        );
        if (strategies.includes(renamed)) {
            const commitQtr = slider ? slider.commitQtr : 'All'
            const holder = {
                alloc: row.alloc || foundStrategy.alloc || 0,
                commitQtr,
                strategy: renamed,
                serverNames: foundStrategy.serverNames,
                min: row.min || 0,
                max: row.max || 100000,
                increment: row.increment || 1,
                userEdited: row?.userEdited?.length ?  row.userEdited : [],
            };
            const optimizerHolder = { strategy: renamed };

            years.forEach((year) => {
                if (!Object.prototype.hasOwnProperty.call(row, year)) {
                    holder[year] = 0;
                    optimizerHolder[year] = 0;
                } else {
                    holder[year] = row[year];
                    if (commitmentScheduleOptimizer[index]) {
                        optimizerHolder[
                            year
                        ] = Object.prototype.hasOwnProperty.call(
                            commitmentScheduleOptimizer[index],
                            year
                        )
                            ? commitmentScheduleOptimizer[index][year]
                            : 0;
                    }
                }
            });

            newCommitmentSchedule.push(holder);
            newCommitmentOptimizer.push(optimizerHolder);
        }
    });

    const newSchedule = addNewRows(
        years,
        stratsToAdd,
        newCommitmentSchedule,
        findStrategyByName,
        sliderValues,
        scenario
    );
    const newOptimizer = addOptimizerRows(
        years,
        stratsToAdd,
        newCommitmentOptimizer
    );

    return {
        newSchedule,
        newOptimizer,
    };
};

/**
 * check if there are rows that need to be added or removed
 * from the existing commitment schedule (if exists)
 */
const checkAddRemoveRows = async (
    targetAllocationStrategies,
    commitmentSchedule,
    commitmentScheduleOptimizer,
    assetClassShortNames,
    exposure,
    strategyAllocation,
    checkForRenamedStrategy,
    findStrategyByName,
    startYear,
    endYear,
    fiscalYearPlusOne,
    sliderValues,
    scenario,
) => {
    const currentStrategies = [];
    commitmentSchedule.forEach((row) => {
        const renamed = checkForRenamedStrategy(row.strategy);
        currentStrategies.push(renamed);
    });

    const strategies = [];
    assetClassShortNames.forEach((shortName) => {
        targetAllocationStrategies.forEach((strat) => {
            const foundStrategy = findStrategyByName(strat.strategy);

            if (
                shortName === strat.assetClass &&
                typeof foundStrategy !== 'undefined' &&
                foundStrategy.selected
            ) {
                if (strat.wName.includes('udef')) {
                    const displayedName = checkForRenamedStrategy(
                        strat.strategy
                    );

                    strategies.push(displayedName);
                } else {
                    strategies.push(strat.strategy);
                }
            }
        });
    });

    const stratsToAdd = [];
    strategies.forEach((strat) => {
        if (!currentStrategies.includes(strat)) {
            stratsToAdd.push(strat);
        }
    });

    const years = [];
    for (let i = startYear; i <= endYear; i++) {
        const year = fiscalYearPlusOne ? i + 1 : i;
        if (year <= endYear) {
            years.push(year);
        }
    }

    const valueToReturn = createCommitmentSchedule(
        commitmentSchedule,
        commitmentScheduleOptimizer,
        { strategies, years },
        stratsToAdd, 
        checkForRenamedStrategy,
        findStrategyByName,
        strategyAllocation,
        sliderValues,
        scenario,
    );

    return valueToReturn;
};

export default checkAddRemoveRows;