const numberFields = [
    'Vintage',
    'Commitment',
    'Contributions',
    'Distributions',
    'AdjNAV',
    'ForwardNAV',
    'Unfunded',
    'IRR',
    'TVM',
];

const updateValueHandler = (tableData, value, field, data) => {
    
    let altField = field;
    if (altField?.includes('_1')) {
        altField = field.slice(0, field.length - 2);
    }

    if (numberFields.includes(altField)) {
        if (data[altField] === value) {
            return tableData;
        }
    }


    return tableData.map((row) => {
        const fund = row;

        if (fund.Id === data.Id) {
            if (numberFields.includes(field)) {
                fund[field] = parseFloat(value);
            } else {
                fund[field] = value;
            }
            // Add field to userEdited array
            if (
                Array.isArray(fund?.userEdited) &&
                !fund?.userEdited?.includes(altField)
            ) {
                fund.userEdited.push(altField);
            } else if (!Array.isArray(fund.userEdited)) {
                fund.userEdited = [altField];
            }
        }

        return fund;
    });
};

export default updateValueHandler;
