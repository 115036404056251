import React, { Component } from 'react';
import { numberFormat } from 'utils';
import { ExistingCommitmentsGraph, NewCommitmentsGraph } from './components';

type Props = {
    commitmentType: string,
    numYrsFromVinToCurrDate: number,
    plotGraph: any,
    graphValues: any,
    graphValuesHigh: any,
    graphValuesLow: any,
};

/**
 * Maps cumulative net cash flow
 */
class RightChart extends Component<Props> {
    state = {
        dataPoints: [],
        baseDataPoints: [],
        navUncertainty: [],
        years: [],
    };

    /**
     * To prevent multiple rerenderings of the graph
     * 
     * @param {*} nextProps 
     * @param {*} nextState 
     */
    shouldComponentUpdate(nextProps, nextState) {
        const {
            graphValues,
            graphValuesHigh,
            graphValuesLow,
            historical,
        } = this.props;
        if (
            graphValues !== nextProps.graphValues ||
            graphValuesLow !== nextProps.graphValuesLow ||
            graphValuesHigh !== nextProps.graphValuesHigh ||
            historical !== nextProps.historical ||
            this.state !== nextState
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        this.updateGraph(prevProps);
    }

    /**
     * Format the incoming data into arrays
     * to plug into kendo charts
     */
    calculateValues = () => {
        const {
            graphValues,
            graphValuesHigh,
            graphValuesLow,
            commitmentType,
            numYrsFromVinToCurrDate,
            historical,
        } = this.props;

        if (Object.keys(graphValues).length === 0) {
            return {};
        }

        const shiftQtr = historical ? historical.shift_qtr / 4 : 0;
        const dataPoints = [];

        const { nav, base_nav: baseNav } = graphValues;
        let year = 0;
        const baseDataPoints = [];
        const navUncertainty = [];
        const currentNavLine = [];

        const time = [];
        for (let i = 0; i < nav.length; i++) {
            time.push(year);
            if (commitmentType === 'existing') {
                let holderObject;
                let netAssetValue;
                if (year > numYrsFromVinToCurrDate + shiftQtr && typeof nav[i] !== 'undefined') {
                    netAssetValue = numberFormat(nav[i] * 100);
                } else if (historical) {
                    netAssetValue = numberFormat(historical.nav_perc[i] * 100);
                }
                dataPoints.push(netAssetValue);
                if (baseNav) {
                    baseDataPoints.push(numberFormat(baseNav[i] * 100));
                }

                if (year === numYrsFromVinToCurrDate + shiftQtr) {
                    currentNavLine.push(netAssetValue);
                } else {
                    currentNavLine.push(holderObject);
                }
            } else {
                dataPoints.push(numberFormat(nav[i] * 100));
                if (baseNav) {
                    baseDataPoints.push(numberFormat(baseNav[i] * 100));
                }
            }

            // Create the uncertainty shading
            if (
                typeof graphValuesHigh !== 'undefined' &&
                typeof graphValuesLow !== 'undefined'
            ) {
                // Create the uncertainty shading.
                if (graphValuesHigh.nav[i]) {
                    navUncertainty.push({
                        min: numberFormat(graphValuesHigh.nav[i] * 100),
                        max: numberFormat(graphValuesLow.nav[i] * 100),
                    });
                } else {
                    navUncertainty.push({});
                }
            }

            year += 0.25;
        }

        return {
            dataPoints,
            baseDataPoints,
            navUncertainty,
            currentNavLine,
            time,
        };
    };

    /**
     * Sets state with returned value from 
     * calculateValues()
     * 
     * @param {*} prevProps 
     */
    updateGraph = (prevProps) => {
        const { graphValues } = this.props;
        const { nav } = graphValues;
        if (typeof nav !== 'undefined' && this.props !== prevProps) {
            const {
                dataPoints,
                baseDataPoints,
                navUncertainty,
                currentNavLine,
                time,
            } = this.calculateValues();

            this.setState({
                dataPoints,
                baseDataPoints,
                navUncertainty,
                currentNavLine,
                years: time,
            });
        }
    };

    render() {
        const {
            dataPoints,
            baseDataPoints,
            navUncertainty,
            currentNavLine,
            years,
        } = this.state;
        const { commitmentType, plotGraph } = this.props;
        let commitmentsGraph = '';

        if (commitmentType === 'new') {
            commitmentsGraph = (
                <NewCommitmentsGraph
                    plotGraph={plotGraph}
                    dataPoints={dataPoints || []}
                    baseDataPoints={baseDataPoints || []}
                    navUncertainty={navUncertainty}
                    years={years}
                />
            );
        } else {
            commitmentsGraph = (
                <ExistingCommitmentsGraph
                    plotGraph={plotGraph}
                    dataPoints={dataPoints || []}
                    baseDataPoints={baseDataPoints || []}
                    navUncertainty={navUncertainty}
                    currentNavLine={currentNavLine}
                    years={years}
                />
            );
        }

        return (
            <div className='panel underwriting-topper m-0 section-border'>
                {commitmentsGraph}
            </div>
        );
    }
}

export default RightChart;
