import React, { useState } from 'react';
import { InputLabel, DropdownTextField } from '@ssgglobal/techintnue';

const options = [
    { value: 'base', label: 'Base' },
    { value: 'high', label: 'High' },
    { value: 'low', label: 'Low' },
]

const Scenario = ({
    value = 'base',
    onChange,
    disabled = false,
}) => {
    const handleScenarioChange = (e) => {
        onChange(e.target.value);
    }
    return (
        <>
            <InputLabel>Scenario</InputLabel>
            <DropdownTextField
                options={options}
                value={value}
                onChange={handleScenarioChange}
                disabled={disabled}
            />
        </>
    )
}

export default Scenario;
