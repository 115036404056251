// temporary implementation
// this is only while we fixed react into react build
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { SSGAppHeader } from '@ssgglobal/techintnue'

import MockHeaderPayload from './HeaderMock.json'
import { AuthContext } from 'context'

const validateAbsoluteUrlPath = (urlString) => {
    return (urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0)
}

const NavBarWrapperTechint = ({ history }) => {
    const {
        user: {
            email,
            name,
        },
        isClient,
    } = useContext(AuthContext);
    const [payload, setPayload] = useState(MockHeaderPayload)

    const redirect = (url) => {
        if (validateAbsoluteUrlPath(url)) {
            window.open(url, '_blank').focus();
            return
        }
        history.push(url);
    }

    useEffect(() => {
        // handle redirects
        window.callbackMap.redirect = redirect
        // handle select on tabs
        window.callbackMap.select = (_event, { href }) => {
            redirect(href)
            const tabs = payload.find(({ type }) => type === "tabs")
            if (tabs) {
                const index = tabs.content.findIndex((item) => item.href === window.location.pathname)
                if (index >= 0) {
                    tabs.selected = index;
                    const newPayload = payload.map((component) => {
                        if (component.type === 'tabs') {
                            return tabs;
                        }
                        return component;
                    })
                    setPayload(newPayload);
                }
            }
        }
    }, [])

    const handleLoad = () => {
        const newPayload = payload
        .map((component) => {
            let newValue = component;

            // if it's client limit tabs options
            if (component.type === 'tabs' && isClient() ) {
                const content = component.content || [];
                newValue.content = content
                    .filter((tab) => !['Manage Users', 'Training'].includes(tab.title));
            }

            // inject user data to user_profile
            else if (component.type === 'user_profile') {
                newValue.full_name = name;
                newValue.email = email;
                newValue.initials = name?.split(' ').map((c) => c[0]).reduce((a, b) => `${a}${b}`);
            }
            return newValue
        });

        setPayload(newPayload)
    }

    useEffect(() => {
        handleLoad()
    }, [name, email])

    return (
        <SSGAppHeader data-payload={encodeURIComponent(JSON.stringify(payload))} />
    )
}

export default withRouter(NavBarWrapperTechint)
