import React from 'react';

type Props = {
    point: {
        category: string,
        value: {
            from: number,
            to: number,
        },
        series: { type: string },
    },
};

export default (context) => <Tooltip {...context} />;

const Tooltip = (props: Props) => {
    const {
        point: { category, value, series },
    } = props;

    if (series.type === 'rangeArea') {
        return (
            <div>
                Year {category}: {Math.round(value.from)}-{Math.round(value.to)}
                %
            </div>
        );
    }

    return (
        <div>
            Year {category}: {value}%
        </div>
    );
};
