import React from 'react';
import { Row, Col } from 'reactstrap/es';
import styled from 'styled-components';

type Props = {
    longName: string,
    isUserDefined: boolean,
};

const CleanBreakStrong = styled.strong`
    overflow-wrap: normal;
`;

export default function SliderTitles({ longName, isUserDefined }: Props) {
    const allocationColClass = isUserDefined ? 'col-md-3' : 'col-md-4';
    const geographicColClass = isUserDefined ? 'col-md-5' : 'col-md-6';

    return (
        <div className='row'>
            <div className='col-md-2'>
                <div />
            </div>
            <div className={allocationColClass}>Allocation (% {longName})</div>
            <div className={geographicColClass}>
                <Row>
                    <Col className='text-center' md={12}>
                        <CleanBreakStrong>Percent Allocation</CleanBreakStrong>
                    </Col>
                    {
                     // PAC-378: Remove for now 
                    /* <Col className='text-center' md={3}>
                        <CleanBreakStrong>North America</CleanBreakStrong>
                    </Col>
                    <Col className='text-center' md={3}>
                        <CleanBreakStrong>Europe</CleanBreakStrong>
                    </Col>
                    <Col className='text-center' md={3}>
                        <CleanBreakStrong>Asia</CleanBreakStrong>
                    </Col> */
                    }
                </Row>
            </div>
            {isUserDefined && (
                <div className='col-md-2'>Co-invest / Secondary</div>
            )}
        </div>
    );
}
