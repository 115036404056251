import { formatDisplayPercentage } from "utils";

const defaultEditableColumnDef = (field, updateValue) => ({
    field,
    editable: true,
    cellRenderer: formatDisplayPercentage,
    cellRendererParams: (params) => {
        const decimalPlace = 0;
        return {
            params,
            decimalPlace,
        };
    },
    cellEditor: 'numericEditor',
    cellEditorParams: {
        field,
        updateValue,
    },
    cellStyle: { color: '#007899' },
    flex: 1,
});

export const ContributionColumnDefinition = (updateValue) => [
    {
        headerName: 'Year 1',
        ...defaultEditableColumnDef('rc1', updateValue),
    },
    {
        headerName: 'Year 2',
        ...defaultEditableColumnDef('rc2', updateValue),
    },
    {
        headerName: 'Year 3',
        ...defaultEditableColumnDef('rc3', updateValue),
    },
    {
        headerName: 'Year 4',
        ...defaultEditableColumnDef('rc4', updateValue),
    },
    {
        headerName: 'Year 5+',
        ...defaultEditableColumnDef('rc5', updateValue),
    },
]