/**
 *  Add fund tableData IDs where applicable
 *
 * @param fundSliderValues
 * @param tableData
 * @param strategyParameters
 */
const assignFundSliderIds = (
    fundSliderValues,
    tableData,
    strategyParameters
) => {
    const uniqueIds = [];
    const newFundSliders = [];

    if (tableData) {
        fundSliderValues.forEach((row) => {
            let id;
            const rowCopy = { ...row };
            if (
                Object.prototype.hasOwnProperty.call(rowCopy, 'id') &&
                typeof rowCopy.id !== 'undefined'
            ) {
                const tableDataFund = tableData.find((fund) => {
                    return (
                        fund.Id === rowCopy.id && !uniqueIds.includes(fund.Id)
                    );
                });

                if (tableDataFund) {
                    id = tableDataFund.Id;
                } else {
                    const alternativeFund = tableData.find((fund) => {
                        return (
                            fund.Investment === rowCopy.investment &&
                            !uniqueIds.includes(fund.Id)
                        );
                    });

                    if (alternativeFund) {
                        id = alternativeFund.Id;
                    }
                }

                if (typeof id !== 'undefined') {
                    uniqueIds.push(id);
                    rowCopy.id = id;
                    newFundSliders.push(rowCopy);
                }
            } else {
                const tableDataFund = tableData.find((fund) => {
                    return (
                        fund.Investment === rowCopy.investment &&
                        !uniqueIds.includes(fund.Id)
                    );
                });

                if (tableDataFund) {
                    rowCopy.id = tableDataFund.Id;
                    uniqueIds.push(tableDataFund.Id);
                    newFundSliders.push(rowCopy);
                }
            }
        });
    }

    // Add High/Lows to fundSliderValues
    const HighLowFundSliders = newFundSliders.map((row) => {
        const holder = { ...row };
        const { values, valuesHigh, valuesLow } = { ...row };
        const holderKeys = [
            'paidIn',
            'irr',
            'yld',
            'fundLife',
            'bow',
            'rc1',
            'rc2',
            'rc3',
            'rc4',
            'rc5',
        ];

        if (!valuesHigh && !valuesLow && values) {
            const foundDefaults = [...strategyParameters].filter(
                ({ strategy, numFunds, case: scenario, contribTiming }) =>
                    strategy === values.strategy &&
                    numFunds === 1 &&
                    (scenario === 'High' || scenario === 'Low') &&
                    contribTiming === 'Base'
            );

            foundDefaults.forEach((defaultPset) => {
                if (defaultPset.case === 'High') {
                    holder.valuesHigh = defaultPset;
                    holderKeys.map((item) => {
                        holder.valuesHigh[item] = parseFloat(
                            holder.valuesHigh[item]
                        );
                    });
                } else if (defaultPset.case === 'Low') {
                    holder.valuesLow = defaultPset;
                    holderKeys.map((item) => {
                        holder.valuesLow[item] = parseFloat(
                            holder.valuesLow[item]
                        );
                    });
                }
            });
        }

        return holder;
    });

    return HighLowFundSliders;
};

export default assignFundSliderIds;
