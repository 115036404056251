// @flow
import { apiCall, quantUrl } from './BaseApi';

const quant = quantUrl || '';

/**
 * Quant proxy for all Omni Data
 *
 * Endpoints:
 * currencies/codesAndSymbols, currencies/all
 * client-organizations/all, portfolios/all
 * reporting/investments, reporting/investment-data
 */
const getOmniData = async (path: string, searchParams: any = {}) => {
    const url = new URL(`${quant}/pacing/omni/${path}`);
    const parsedParams = await new URLSearchParams(searchParams);
    url.search = parsedParams.toString();
    const method = 'GET';
    return apiCall(url, method);
};

export default getOmniData;
