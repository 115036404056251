// @flow
import { apiCall, quantUrl } from './BaseApi';

const quant = quantUrl || '';

export const getPacingData = async () => {
    const url = `${quant}/pacing/data/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const getPacingDataById = async (id: number | string) => {
    const url = `${quant}/pacing/data/${id}/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const postPacingDataById = async (data: any, id: number | string) => {
    const url = `${quant}/pacing/data/${id}/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postNewPacingData = async (data: any) => {
    const url = `${quant}/pacing/data/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const deletePacingDataById = async (id: number | string) => {
    const url = `${quant}/pacing/data/${id}/`;
    const method = 'DELETE';
    return apiCall(url, method);
};
