import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../context';

const LogoutInterstitialPage = () => {
    const {
        logOut,
    } = useContext(AuthContext);
    useEffect(() => {
        logOut()
    }, [])
    return null
}

export default LogoutInterstitialPage
