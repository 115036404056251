import React from 'react';
import { TableVariant } from '@ssgglobal/techintnue';
import defaultColDef, { EditMultipleGridFrameworkComponents } from './EditMultipleGridDefinitions';

const EditStrategyTable = ({ register, rows }) => {
    const { name, onChange } = register('rows');
    const updateValue = (value, field, row, index) => {
        const rowIndex = parseInt(index);

        const newRows = [...rows];
        const userEdited = [...new Set([...row.userEdited, field])]
        newRows[rowIndex] = {
            ...row,
            [field]: value,
            userEdited,
        };
        const event = {
            target: {
                name,
                value: newRows,
            }
        }
        onChange(event);
    }
    const columnDefs = defaultColDef(updateValue);

    return (
        <TableVariant
            gridStyle={{ height: '200px', width: '100%' }}
            tableType="aggrid"
            minHeight="200px"
            agTheme="ag-theme-balham"
            className="ag-grid-custom"
            agGridOptions={{
                height: '200px',
                columnDefs: columnDefs,
                rowData: rows,
                components: EditMultipleGridFrameworkComponents,
            }}
        />
    );
};

export default EditStrategyTable;
