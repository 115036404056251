// @flow
import React, { Component } from 'react';
import { numberFormat } from 'utils';
import checkInputLimits from './checkInputLimits';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
// const KEY_MINUS = 45;

type Props = {
    updateValue: Function,
    field: string,
    data: any,
    node: { id: number },
    keyPress: number,
    charPress: number,
    value: number | string,
    denyNegativeValues: boolean,
};

type State = {
    value: number | string,
    highlightAllOnFocus: boolean,
    cancel: boolean,
};

type InputKeyboardEventType = SyntheticKeyboardEvent<HTMLInputElement>;
type MyRefType = React.Ref<'input'>;

export default class NumericEditor extends Component<Props, State> {
    myRef: MyRefType;

    constructor(props: Props) {
        super(props);

        this.state = this.createInitialState();
        this.myRef = React.createRef<MyRefType>();
    }

    componentDidMount = () => {
        const { highlightAllOnFocus } = this.state;
        // get ref from React component
        const eInput = this.myRef.current;
        eInput.focus();
        if (highlightAllOnFocus) {
            eInput.select();

            this.setState({
                highlightAllOnFocus: false,
            });
        } else {
            // when we started editing, we want the carot at the end, not the start.
            // this comes into play in two scenarios: a) when user hits F2 and b)
            // when user hits a printable character, then on IE (and only IE) the carot
            // was placed after the first character, thus 'apply' would end up as 'pplea'
            const length = eInput.value ? eInput.value.length : 0;
            if (length > 0) {
                eInput.setSelectionRange(length, length);
            }
        }
    };

    deleteOrBackspace = (event: InputKeyboardEventType) => {
        return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
    };

    getValue = () => {
        const { value } = this.state;
        return value;
    };

    onKeyDown = (event: InputKeyboardEventType) => {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (!this.isKeyPressedNumeric(event)) {
            if (event.preventDefault) event.preventDefault();
        }
    };

    isLeftOrRight = (event: InputKeyboardEventType) => {
        return [37, 39].indexOf(event.keyCode) > -1;
    };

    isCancelAfterEnd = async () => {
        const { value } = this.state;
        const { updateValue, field, data, node } = this.props;
        const rowNodeId = node.id;
        const validValue = checkInputLimits(field, value);

        await this.setState({ value: validValue });
        updateValue(validValue, field, data, rowNodeId);

        // Check if number is extremely large
        if (parseFloat(value) > 1000000000) {
            return true;
        }

        return false;
    };

    handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
        const { value } = event.target;
        
        const { denyNegativeValues } = this.props;

        // allow for empty strings(which translate to 0) and positive integers only
        if(denyNegativeValues && (!value.match(/^(\s*|\d+)$/))){
            return;
        }
        // will take the string of characters and format to 2 decimal places
        const formattedValue = numberFormat(value);

        this.setState({ value: formattedValue });
    };

    getCharCodeFromEvent = (event: InputKeyboardEventType) => {
        return typeof event.which === 'undefined' ? event.keyCode : event.which;
    };

    isCharNumeric = (charStr: string) => {
        return !!/\d/.test(charStr);
    };

    isKeyPressedNumeric = (event: InputKeyboardEventType) => {
        const charCode = this.getCharCodeFromEvent(event);
        const charAllowed = ['.', 'Backspace', '-'];
        const charStr: string = event.key
            ? event.key
            : String.fromCharCode(charCode);
        return this.isCharNumeric(charStr) || charAllowed.includes(charStr);
    };

    createInitialState() {
        const { props } = this;

        let startValue;
        let highlightAllOnFocus = true;

        if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (props.charPress) {
            // if a letter was pressed, we start with the letter
            startValue = props.charPress;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = props.value;
            if (props.keyPress === KEY_F2) {
                highlightAllOnFocus = false;
            }
        }

        return {
            value: startValue,
            highlightAllOnFocus,
            cancel: true,
        };
    }

    render() {
        const { value } = this.state;
        return (
            <input
                ref={this.myRef}
                value={value}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
                className='numeric-input'
                // onBlur={this.handleBlur}
            />
        );
    }
}
