import React, { Component, useState, useContext } from 'react';
import { LandingContext, PacingAnalysisContext } from 'context';
import {
    Row,
    Col,
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { MenuItem, Select } from '@material-ui/core';
import { Switch } from 'utils';

type Props = {
    open: boolean,
    toggleModal: Function,
    exportAnalysis: Function,
};

//class SelectExportType extends Component<Props> {
const SelectExportType = ({open, toggleModal, exportAnalysis}: Props) => {

    const [yearlyOn, setYearlyOn] = useState(false)
    const [isHistoric, setIsHistoric] = useState(false)
    const [historicValue, setHistoricValue] = useState(1)
    const {isOmniImport, isSavedPacing} = useContext(LandingContext)
    const {historicalSetBackValue, setHistoricalSetBackValue} = useContext(PacingAnalysisContext)

    const handleTime = () => {
        const yrl = yearlyOn;
        setYearlyOn(!yearlyOn);
        setHistoricalSetBackValue({
            ...historicalSetBackValue,
            type: !yrl
        })
    }

    const handleHistoric = () => {
        const hst = isHistoric;
        setIsHistoric(!isHistoric);
        setHistoricalSetBackValue({
            ...historicalSetBackValue,
            isHistoric: !hst
        })
    }

    const handleExport = () => {
        setHistoricalSetBackValue({
            ...historicalSetBackValue,
            drawChart: true
        })
        const exportType = yearlyOn ? 'annual' : 'quarterly'
        exportAnalysis(exportType)
    };

    const handleCancel = () => {
        toggleModal()
        setHistoricalSetBackValue({
            type: false,
            isHistoric: false,
            value: 1
        })
        setHistoricValue(1)
        setIsHistoric(false);
        setYearlyOn(false);
    }

    const handleHistoricValue = (e) => {
        setHistoricValue(e.target.value)
        setHistoricalSetBackValue({
            type: yearlyOn,
            isHistoric: true,
            value: e.target.value
        })
    }

    const switchLabelStyle = {
        fontWeight: '400',
    }

    return (
        <Modal isOpen={open} toggle={handleCancel}>
            <ModalHeader toggle={handleCancel} style={{borderBottom: 'none', fontWeight:'bolder'}} tag={'h4'}>
                Export Settings
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <Switch 
                            isOn={yearlyOn}
                            leftLabel='Quarterly'
                            leftStyle={switchLabelStyle}
                            rightStyle={switchLabelStyle}
                            rightLabel='Annual'
                            handleToggle={handleTime}
                        />
                        {(isOmniImport || isSavedPacing) &&
                            <Switch 
                                isOn={isHistoric}
                                leftLabel='Historic Data'
                                leftStyle={{...switchLabelStyle, 'marginRight': '25px'}}
                                handleToggle={handleHistoric}
                            />
                        }
                        {isHistoric &&
                            <>
                                <Label style={{display:'block'}}># of Historical Years</Label>
                                <Select
                                    id='historicalInputs'
                                    style={{marginLeft: '15px'}}
                                    onChange={handleHistoricValue}
                                    value={historicValue}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={100}>All</MenuItem>
                                </Select>
                            </>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter style={{borderTop: 'none'}}>
                <Button className='secondary-button' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    className='primary-button'
                    onClick={handleExport}
                >
                    Export
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default SelectExportType;
