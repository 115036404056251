import React from 'react';
import './SwitchButton.sass';

const SwitchButton = (props) => {

    const { isOn, handleToggle, leftLabel, rightLabel } = props;

    const handleClick = () => {
        handleToggle()
    }

    return (
        <div className='togglePlacement'>
            <button 
                onClick={handleClick}
                className={[
                    'switchButton',
                    'switch-left',
                    isOn ? '' : 'switch-on'
                    ].join(' ')
                    }
                disabled={!isOn}
            >{leftLabel}</button>
            <button 
                onClick={handleClick}
                className={[
                    'switchButton',
                    'switch-right',
                    isOn ? 'switch-on' : ''
                ].join(' ')
                }
                disabled={isOn}
            >{rightLabel}</button>
        </div>
    );
}

export default SwitchButton
