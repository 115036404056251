import { formatIntWithCommas } from 'utils';

export default function FormatNumber(params) {
    const unformattedNumber = params.value ? params.value : 0;
    const decimal = typeof params.decimal !== 'undefined' ? params.decimal : 0;

    const formattedNumber = formatIntWithCommas(unformattedNumber, decimal);

    const parts = formattedNumber.toString().split('.');
    if (parts[1]) {
        return formattedNumber;
    }

    return parts[0];
}
