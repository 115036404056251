import React, { Component, useContext } from 'react';
import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { numberFormat, colorCombos, getDisplayDates } from 'utils';
import { LandingContext, PacingAnalysisContext } from 'context';

type Props = {
    graphData: any,
    privateMarketExposure: number,
    portfolioAumAndLiquidAssets: number,
    growthOnLiquidAssets: number,
    viewYear: boolean,
    dateType: string,
    useForwardNav: boolean,
};

class NavGraphComponent extends Component<Props> {
    constructor(props) {
        super(props);
        const { privateMarketExposure } = props;
        this.state = {
            data: {
                pe: [],
                pd: [],
                re: [],
                in: [],
                ra: [],
                target: [],
                portfolioAumAndLiquidAssets: [],
            },
            privateMarketExposure,
            prvMrkPercentOfAuM: [],
        };
    }

    componentDidMount() {
        const { graphData } = this.props;
        if (
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_yr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_qtr')
        ) {
            this.setData();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { privateMarketExposure, data } = this.state;
        const nextPropsGraphData = nextProps.graphData;
        const { viewYear, graphData } = this.props;

        if (
            graphData.assetclass_yr !== nextPropsGraphData.assetclass_yr ||
            graphData.assetclass_qtr !== nextPropsGraphData.assetclass_qtr ||
            privateMarketExposure !== nextProps.privateMarketExposure ||
            viewYear !== nextProps.viewYear ||
            data !== nextState.data
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { privateMarketExposure } = this.state;
        const {
            viewYear,
            graphData,
            privateMarketExposure: propsPrivateMarketExposure,
            portfolioAumAndLiquidAssets,
            growthOnLiquidAssets,
        } = this.props;

        if (
            graphData.assetclass_yr !== prevProps.graphData.assetclass_yr ||
            graphData.assetclass_qtr !== prevProps.graphData.assetclass_qtr ||
            privateMarketExposure !== propsPrivateMarketExposure ||
            (prevProps.viewYear !== viewYear &&
                Object.prototype.hasOwnProperty.call(
                    graphData,
                    'assetclass_yr'
                ) &&
                Object.prototype.hasOwnProperty.call(
                    graphData,
                    'assetclass_qtr'
                ))
        ) {
            this.setData();
        }

        if (
            portfolioAumAndLiquidAssets !==
                prevProps.portfolioAumAndLiquidAssets &&
            growthOnLiquidAssets
        ) {
            // Account for empty objects sometimes evaluating to false when compared
            if (
                portfolioAumAndLiquidAssets.length !== 0 &&
                prevProps.portfolioAumAndLiquidAssets.length !== 0
            ) {
                this.setPortfolioAumAndLiquidAssetsGraphData();
            }
        }
    }

    getAssetClassData = () => {
        const { graphData, viewYear } = this.props;

        if (!Object.prototype.hasOwnProperty.call(graphData, 'assetclass_yr')) {
            return false;
        }

        let assetClassData = {};
        if (viewYear) {
            assetClassData = graphData.assetclass_yr;
        } else {
            assetClassData = graphData.assetclass_qtr;
        }

        const portAum = assetClassData['0'].slice(2);

        return { assetClassData, portAum };
    };

    setData = () => {
        const {
            graphData,
            viewYear,
            privateMarketExposure,
            portfolioAumAndLiquidAssets,
            growthOnLiquidAssets,
            useForwardNav,
        } = this.props;

        let assetClassData = {};
        if (viewYear) {
            assetClassData = graphData.assetclass_yr;
        } else {
            assetClassData = graphData.assetclass_qtr;
        }

        if (!assetClassData) {
            return;
        }

        const portAum = assetClassData['0'].slice(2);

        let years = [];
        let prvMrkPercentOfAuM = [];
        Object.keys(assetClassData).forEach((row) => {
            if (assetClassData[row][1] === 'NAV') {
                const holder = [];
                const assetClassNAV = assetClassData[row].slice(2);
                assetClassNAV.forEach((value, index) => {
                    if (value !== 0) {
                        holder.push(
                            numberFormat((value / portAum[index]) * 100)
                        );
                    } else {
                        holder.push(0);
                    }
                });

                this.setState((prevState) => ({
                    data: {
                        ...prevState.data,
                        [assetClassData[row][0].toLowerCase()]: holder,
                    },
                }));
            }
        });

        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                years = graphData.year_labels;
                for (let j = 0; j < graphData.year_labels.length; j++) {
                    prvMrkPercentOfAuM.push(privateMarketExposure);
                }
            } else {
                let year = 0;
                for (let j = 0; j < graphData.qtrdates.length; j++) {
                    const newYear = parseInt(graphData.qtrdates[j], 10);
                    if (year !== newYear) {
                        years.push(parseInt(graphData.qtrdates[j], 10));
                        year = newYear;
                        prvMrkPercentOfAuM.push(privateMarketExposure);
                    }
                }
            }
        } else {
            ({ years, percent: prvMrkPercentOfAuM } = getDisplayDates(
                graphData.qtrdates,
                privateMarketExposure,
                useForwardNav
            ));
        }

        if (
            Object.prototype.hasOwnProperty.call(
                graphData,
                'port_conversus_yr_ratio'
            ) &&
            Object.prototype.hasOwnProperty.call(
                graphData,
                'port_conversus_qtr'
            ) &&
            graphData.port_conversus_yr_ratio.liquid_yr_ratio !== null &&
            graphData.port_conversus_qtr.liquid_ratio_qtr !== null &&
            portfolioAumAndLiquidAssets &&
            growthOnLiquidAssets
        ) {
            this.setPortfolioAumAndLiquidAssetsGraphData();
        }

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                xValues: years,
            },
            privateMarketExposure,
            prvMrkPercentOfAuM,
        }));
    };

    setPortfolioAumAndLiquidAssetsGraphData = () => {
        const {
            graphData: {
                port_conversus_yr_ratio: { liquid_yr_ratio: liquidRatioYearly },
                port_conversus_qtr: { liquid_ratio_qtr: liquidRatioQuarterly },
            },
            viewYear,
        } = this.props;

        const liquidAssetOverTotalAumData = viewYear
            ? liquidRatioYearly
            : liquidRatioQuarterly;

        const dataAsPercentages = liquidAssetOverTotalAumData.map((datum) => {
            return datum * 100;
        });

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                portfolioAumAndLiquidAssets: dataAsPercentages,
            },
        }));
    };

    render() {
        const { viewYear, dateType, growthOnLiquidAssets } = this.props;
        const { data, prvMrkPercentOfAuM } = this.state;

        const label = viewYear
            ? { step: 1, rotation: 'auto' }
            : { step: 8, rotation: 'auto' };

        const title =
            dateType === 'fiscal' && viewYear
                ? { text: 'Fiscal Years' }
                : { text: 'Years' };

        return (
            <div className='panel'>
                <Chart pannable={false} zoomable={false}>
                    <ChartLegend
                        visible
                        position='middle'
                        orientation='vertical'
                    />
                    <ChartAxisDefaults majorGridLines={false} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            title={title}
                            categories={data.xValues}
                            labels={label}
                        />
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            title={{ text: 'NAV (% of total portfolio)' }}
                        />
                    </ChartValueAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            name='PE'
                            type='column'
                            color={colorCombos[0].primary}
                            stack
                            data={data.pe}
                        />
                        <ChartSeriesItem
                            name='PD'
                            type='column'
                            color={colorCombos[1].primary}
                            data={data.pd}
                        />
                        <ChartSeriesItem
                            name='RE'
                            type='column'
                            color={colorCombos[2].primary}
                            data={data.re}
                        />
                        {/* <ChartSeriesItem
                            name='IN'
                            type='column'
                            color={colorCombos[3].primary}
                            data={data.in}
                        /> */}
                        <ChartSeriesItem
                            name='RA'
                            type='column'
                            color={colorCombos[3].primary}
                            data={data.ra}
                        />
                        <ChartSeriesItem
                            name='Liquid Assets'
                            type='column'
                            color={colorCombos[20].primary}
                            data={
                                growthOnLiquidAssets
                                    ? data.portfolioAumAndLiquidAssets
                                    : []
                            }
                            visibleInLegend={
                                data.portfolioAumAndLiquidAssets.length !== 0
                            }
                        />
                        <ChartSeriesItem
                            name='Target'
                            type='line'
                            dashType='longDash'
                            color={colorCombos[1].primary}
                            markers={{ visible: false }}
                            data={prvMrkPercentOfAuM}
                        />
                    </ChartSeries>
                    <ChartTooltip />
                </Chart>
            </div>
        );
    }
}

const NavGraph = (props) => {
    const {
        graphData,
        pacingParameters: { dateType, privateMarketExposure },
        portfolioAumAndLiquidAssets,
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);

    return (
        <NavGraphComponent
            dateType={dateType}
            graphData={graphData}
            portfolioAumAndLiquidAssets={portfolioAumAndLiquidAssets}
            privateMarketExposure={privateMarketExposure}
            useForwardNav={useForwardNav}
            {...props}
        />
    );
};

export default NavGraph;
