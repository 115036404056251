/**
 * Convert object commitment schedule to array commitment schedule
 * for old pacings
 */

const normalizeCommitmentSchedule = (commitmentSchedule) => {
    return commitmentSchedule.map((row) => {
        const holder = { ...row };

        holder.min = row.min || 0;
        holder.max = row.max || 1000;
        holder.increment = row.increment || 1;

        return holder;
    });
};

const formatCommitSchedule = (commitmentSchedule) => {
    if (!Array.isArray(commitmentSchedule)) {
        const newCommitmentSchedule = [];
        Object.keys(commitmentSchedule).forEach((key) => {
            const holder = { strategy: key };
            Object.keys(commitmentSchedule[key]).forEach((year) => {
                holder[year] = commitmentSchedule[key][year];
            });

            newCommitmentSchedule.push(holder);
        });

        return normalizeCommitmentSchedule(newCommitmentSchedule);
    }

    return normalizeCommitmentSchedule(commitmentSchedule);
};

export default formatCommitSchedule;
