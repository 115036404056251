import { getExcelBase } from 'api';
import XLSX from '@sheet/edit';

/*
 *  retrieveTemplate: retrieves xlsx template from quant
 */
async function retrieveTemplate() {
    const response = await getExcelBase();
    let binary = '';
    const responseArray = new Uint8Array(response);
    const length = responseArray.byteLength;
    for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(responseArray[i]);
    }
    return XLSX.read(binary, { type: 'binary', template: true });
}

/**
 * Construct/Update each sheet in the workbook with data.
 * Download the pacing.
 */
const downloadExcelFile = async (
    formattedData,
    formattedMiscInputs,
    formattedRawData,
    privateMarketExposure,
    pacingName
) => {
    const retrievedTemplate = await retrieveTemplate();
    let exportTypeSelected = '';

    Object.keys(formattedData).forEach((key) => {
        Object.keys(formattedData[key]).forEach((category) => {
            let title = '';

            if (key.includes('AssetClass')) {
                exportTypeSelected = formattedData[key][
                    category
                ][0][1].includes('Q')
                    ? 'Qtr'
                    : 'Yr';
                if (category === 'quarterlyNavByAssetClass') {
                    title = 'NAV by Asset Class';
                } else if (category === 'commitmentNav') {
                    title = 'Commitment & NAV';
                } else if (category === 'netCashFlow') {
                    title = 'Net Cash Flow';
                }
            } else if (key.includes('NavByStrategy')) {
                title = 'NAV by Strategy';
            } else if (key.includes('RealEstateExposure')) {
                title = 'Real Estate Exposure';
            } else if (key.includes('CommitmentTypeNav')) {
                if (category === 'netCashFlow') {
                    title = 'Existing.Future Cash Flow';
                } else if (category === 'nav') {
                    title = 'Existing.Future NAV';
                }
            } else if (key.includes('FundRatio')) {
                title = 'Commitment Sensitivity';
            } else if (key.includes('AumRatio')) {
                title = 'AUM Sensitivity';
            } else if (key.includes('FundCrisis')) {
                if (category === 'netCashFlow') {
                    title = 'Downturn Scenario Cash Flow';
                } else if (category === 'nav') {
                    title = 'Downturn Scenario NAV';
                }
            }

            const worksheet = retrievedTemplate.Sheets[title];

            XLSX.utils.template_set_aoa(
                retrievedTemplate,
                worksheet,
                'A20',
                formattedData[key][category]
            );

            if (
                ['quarterlyNavByAssetClass', 'commitmentNav'].includes(category)
            ) {
                const entries = formattedData[key][category][0].length;
                const aumValue = privateMarketExposure
                    ? privateMarketExposure / 100
                    : 0;
                const aumArray = Array(entries).fill(aumValue);
                aumArray[0] = '% of total AUM';

                // Yearly Nav sheet
                if (category === 'quarterlyNavByAssetClass') {
                    XLSX.utils.template_set_aoa(
                        retrievedTemplate,
                        worksheet,
                        'A27',
                        [[...aumArray]]
                    );
                } else {
                    // get last row in array of rows
                    const rowNumber = formattedData[key][category].length - 1;
                    const navPercentRow =
                        formattedData[key][category][rowNumber];

                    // make sure the key cell doesnt get forced into the number meatgrinder
                    const formattedNavRow = navPercentRow.map((entry) =>
                        typeof entry === 'string'
                            ? entry
                            : Math.round(entry) / 100
                    );

                    XLSX.utils.template_set_aoa(
                        retrievedTemplate,
                        worksheet,
                        'A25',
                        [[...formattedNavRow], [...aumArray]]
                    );
                }
            }
        });
    });

    Object.keys(formattedMiscInputs).forEach((key) => {
        let inputWorksheet;

        if (key.includes('MiscInputs')) {
            inputWorksheet = retrievedTemplate.Sheets['Misc. Inputs'];

            XLSX.utils.template_set_aoa(
                retrievedTemplate,
                inputWorksheet,
                'A1',
                formattedMiscInputs[key]
            );
        } else {
            let title = '';
            if (key.includes('ExistingCommitments')) {
                title = 'Existing Commitments';
            } else if (key.includes('StrategyAllocation')) {
                title = 'Target Allocations';
            } else if (key.includes('Psets')) {
                title = 'Underwriting';
            }

            inputWorksheet = retrievedTemplate.Sheets[title];
            
            XLSX.utils.template_set_aoa(
                retrievedTemplate,
                inputWorksheet,
                'A1',
                formattedMiscInputs[key]
            );
        }
    });

    Object.keys(formattedRawData).forEach((key) => {
        let title = '';

        if (key === 'rawFundDataHigh') {
            title = 'Fund-Level High';
        } else if (key === 'rawFundDataLow') {
            title = 'Fund-Level Low';
        } else if (key === 'rawFundDataBase') {
            title = 'Fund-Level Data';
        } else if (key.includes('Strategy')) {
            title = 'Strategy Data';
        } else if (key.includes('AssetClass')) {
            title = 'Asset Class Data';
        } else if (key.includes('rawFundDTDataBase')) { 
            title = 'Fund-Level Downturn';
        }

        const inputWorksheet = retrievedTemplate.Sheets[title];

        XLSX.utils.template_set_aoa(
            retrievedTemplate,
            inputWorksheet,
            'A1',
            formattedRawData[key]
        );
    });

    // TODO: Handle when exporting before saving
    const excelFileName = `${pacingName} - ${exportTypeSelected}.xlsx`;

    XLSX.writeFile(retrievedTemplate, excelFileName, {
        template: true,
    });
};

export default downloadExcelFile;
