import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { Col, Input, Label } from 'reactstrap';

const HistoricalInputs = ({
    viewHistorical,
    handleHistoricalToggle,
    handleHistoricalYearChange,
    numHistoricalYears,
}) => {
    const styles = {
        marginLeft: '15px',
    };

    return (
        <>
            <Col md={12} style={styles}>
                <Input
                    type='checkbox'
                    value
                    name='viewHistorical'
                    checked={viewHistorical === true}
                    onChange={handleHistoricalToggle}
                />
                <Label sm={10}>View Historical Data</Label>
            </Col>
            {viewHistorical ? (
                <Col md={12}>
                    <Label># of Historical Years</Label>
                    <Select
                        id='historicalInputs'
                        style={{marginLeft: '15px'}}
                        onChange={handleHistoricalYearChange}
                        value={numHistoricalYears}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={100}>All</MenuItem>
                    </Select>
                </Col>
            ) : (
                <></>
            )}
        </>
    );
};

export default HistoricalInputs;
