/* eslint-disable no-unused-expressions */
// @flow
import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import {
    AuthContext,
    TargetAllocationContext,
    LandingContext,
    PacingAnalysisContext,
    UnderwritingContext,
    StaticsContext,
} from 'context';
import {
    Button,
    Col,
    Row,
    Container,
    Label,
    Input,
} from 'reactstrap';

import { PageTitle, BottomNav, Switch as TypeToggle } from 'utils';
import {
    DescriptiveStatistics,
    LeftChart,
    MiddleChart,
    RightChart,
    UnderwritingTableLegend,
    UnderwritingGrid,
} from './components';

import {
    plotGraphHelper,
    updateGraphHelper,
    calculateNumQuarter,
    assignFundSliderIds,
    createFundSliderValues,
    formatDataToSetHelper,
    getVintageDate,
    setFundSliderOnNameChange,
} from './helpers';
import { checkPacingStrategies } from '../Landing/helpers/index';
import { ModalManager } from './components/ModalManager';
import { ModalButton } from './components/ModalButton';
import { Button as ButtonTec, Grid } from '@ssgglobal/techintnue';

const ParameterLab = () => {
    const {
        exposure,
        targetAllocation: { targetExposureType },
        strategyAllocation,
        updateStrategyAllocation,
        userDefinedRenamedTAS,
    } = useContext(TargetAllocationContext);
    const { checkForRenamedStrategy } = useContext(PacingAnalysisContext);
    const { tableData, reportDate, yearlyHistorical } = useContext(LandingContext);
    const { strategyParameters, targetAllocationStrategies } = useContext(
        StaticsContext
    );
    const {
        commitmentType,
        fundId,
        fundSliderValues,
        handleSave,
        setFundSliderValues,
        setSliderValues,
        updateSliderValue,
        sliderValues,
        underwritingDataset,
        updateParameterLab,
        loadSliderValues,
    } = useContext(UnderwritingContext);
    const {
        user: { spi_client_id: spiClientId },
        isTrialUser,
    } = useContext(AuthContext);

    const [scenario, setScenario] = useState('Base');
    const [contribToDate, setContribToDate] = useState(0);
    const [distribToDate, setDistribToDate] = useState(0);
    const [numYrsFromVinToCurrDate, setNumYrsFromVinToCurrDate] = useState(0);
    const [projectedContrib, setProjectedContrib] = useState(0);
    const [graphValues, setGraphValues] = useState({});
    const [graphValuesHigh, setGraphValuesHigh] = useState();
    const [graphValuesLow, setGraphValuesLow] = useState();
    const [historicalGraphValues, setHistoricalGraphValues] = useState();
    const [descStatGraphData, setDescStatsGraphData] = useState({});
    const [showLiquidatedFunds, setShowLiquidatedFunds] = useState(false);
    const [currentSliders, setCurrentSliders] = useState({
        model: '',
        strategy: '',
        paidIn: 0,
        irr: 0,
        yld: 0,
        fundLife: 0,
        bow: 0,
        rc1: 0,
        coinvestFlag: 0,
        rc2: 0,
        rc3: 0,
        rc4: 0,
        rc5: 0,
        userEdited: [],
    });

    // load strategies
    useEffect(() => {
        const loadStrategies = async () => {
            await updateStrategyAllocation(strategyAllocation)
            await loadSliderValues();
        };
        loadStrategies();
    }, [])

    /**
     * Create new fundSliderValues data object
     */
    const loadFundSliderValues = async () => {
        const values = await createFundSliderValues(
            tableData,
            strategyParameters
        );

        const listOfFunds = checkPacingStrategies(
            tableData,
            targetAllocationStrategies
        );

        if (listOfFunds !== '') {
            Swal.fire({
                type: 'warning',
                html: `<b>Pacing strategy not found for:</b> ${listOfFunds}<b>Please select a different strategy in the grid below.</b>`,
                width: '600px',
            });
        }

        return values;
    };

    /**
     * Check if funds in tableData have changed strategies
     */
    const checkForFundStratAndNameChange = () => {
        const newFundSliderValues = [];

        tableData.forEach((row) => {
            const foundFund = fundSliderValues.find((fund) => {
                return row.Id === fund.id && fund;
            });

            if (
                foundFund &&
                foundFund.values &&
                foundFund.values.strategy === row.PacingStrategy
            ) {
                foundFund.investment = row.Investment;

                newFundSliderValues.push(foundFund);
            } else {
                const strategyDefaults = [...strategyParameters].filter(
                    (stratDefault) =>
                        stratDefault.strategy === row.PacingStrategy &&
                        stratDefault.numFunds === 1 &&
                        stratDefault.contribTiming === 'Base'
                );

                if (strategyDefaults) {
                    let holder = {
                        investment: row.Investment,
                        id: String(row.Id),
                    };
                    strategyDefaults.forEach((defaults) => {
                        holder = setFundSliderOnNameChange(defaults, holder);
                    });

                    newFundSliderValues.push(holder);
                } else {
                    const text = `Strategy "${row.PacingStrategy}" not found for "${row.Investment}". Please select a different strategy in the grid below.`;
                    Swal.fire({
                        type: 'warning',
                        html: text,
                        width: '600px',
                    });
                }
            }
        });

        setFundSliderValues(newFundSliderValues);
        return newFundSliderValues;
    };

    const getStrategyName = (strategy) => {
        const renamed = Object.values(userDefinedRenamedTAS)
            .find((tas) => `${tas.prefix} ${tas.currentValue}` == strategy);
        if (renamed) {
            return `${renamed.prefix} ${renamed.originalValue}`;
        }
        return strategy;
    };

    /**
     * Update globalContext sliderValues & fundSliderValues
     */
    const updateGlobalSliders = () => {
        const dataToUpdate = formatDataToSetHelper(
            commitmentType,
            underwritingDataset,
            fundSliderValues,
            sliderValues,
            checkForRenamedStrategy,
            strategyAllocation,
            getStrategyName,
        );
        const { name, data } = dataToUpdate;

        name === 'sliderValues' ? setSliderValues(data) : null;
        name === 'fundSliderValues' ? setFundSliderValues(data) : null;
    };

    const calculateCommitmentType = () => {
        let newCommitmentType =
            commitmentType === 'existing' ? 'existing' : 'new';

        const totalExposure =
            exposure.pe + exposure.pd + exposure.re + exposure.in + exposure.ra;

        newCommitmentType =
            tableData.length !== 0 && totalExposure === 0
                ? 'existing'
                : newCommitmentType;

        newCommitmentType =
            targetExposureType === 'noNewCommitments'
                ? 'existing'
                : newCommitmentType;

        updateParameterLab(newCommitmentType, fundId);
    };

    /**
     * Error alert with custum error message
     */
    const displayError = (message) => {
        Swal.fire({
            type: 'error',
            html: message,
        });
    };

    /**
     * Requests data from back-end and sets graph values in state
     */
    const updateGraphs = async () => {
        const stateProps = {
            currentSliders,
            commitmentType,
            fundId,
            numYrsFromVinToCurrDate,
        };
        let error = '';

        const data = await updateGraphHelper(
            stateProps,
            tableData,
            reportDate,
            fundSliderValues,
            sliderValues,
            underwritingDataset,
            strategyParameters,
            yearlyHistorical,
        );

        if (Object.prototype.hasOwnProperty.call(data, 'error')) {
           if (
                data.error.message === "Cannot read property 'p1' of undefined"
            ) {
                error =
                    'Please select a Pacing Strategy for all Existing Commitments.';
            } else {
                error = 'There was an error updating your graphs.';
            }
        } else {
            setGraphValues(data.graphValues);
            setGraphValuesHigh(data.graphValuesHigh);
            setGraphValuesLow(data.graphValuesLow);
            setHistoricalGraphValues(data.historical);
        }

        if (error !== '') {
            displayError(error);
        }
    };

    /**
     * Handle toggling between new and existing commitment type
     */
    const handleToggle = () => {
        const newType = commitmentType === 'new' ? 'existing' : 'new';

        updateParameterLab(newType, fundId);
        updateGlobalSliders();
    };

    /**
     * Creates graph options based on values being plotted
     */
    const plotGraph = (event) => {
        return plotGraphHelper(commitmentType)(event);
    };

    /**
     * Update currentSliders
     */
    const updateCurrentSliders = ({
        paidIn,
        irr,
        yld,
        fundLife,
        bow,
        coinvestFlag,
        rc1,
        rc2,
        rc3,
        rc4,
        rc5,
    }) => {
        const newCurrentSliders = currentSliders;

        newCurrentSliders.paidIn = paidIn;
        newCurrentSliders.irr = irr;
        newCurrentSliders.yld = yld;
        newCurrentSliders.fundLife = fundLife;
        newCurrentSliders.bow = bow;
        newCurrentSliders.coinvestFlag = coinvestFlag;
        newCurrentSliders.rc1 = rc1;
        newCurrentSliders.rc2 = rc2;
        newCurrentSliders.rc3 = rc3;
        newCurrentSliders.rc4 = rc4;
        newCurrentSliders.rc5 = rc5;

        setCurrentSliders(newCurrentSliders);
    };

    /**
     * Updated information based on currently selected row
     */
    const updateSelectedRowData = async (selectedRow) => {
        if (selectedRow) {
            const vintageDate = await getVintageDate(
                selectedRow.commitQtr,
                selectedRow.closingYear
            );

            const newNumYrsFromVinToCurrDate = selectedRow.closingYear
                ? (await calculateNumQuarter(vintageDate, reportDate)) / 4 - 0.25
                : null;

            const newCurrentSliders = { ...currentSliders };
            newCurrentSliders.paidIn = parseFloat(selectedRow.paidIn / 100);
            newCurrentSliders.irr = parseFloat(selectedRow.irr / 100);
            newCurrentSliders.yld = parseFloat(selectedRow.yld / 100);
            newCurrentSliders.fundLife = parseFloat(selectedRow.fundLife);
            newCurrentSliders.bow = parseFloat(selectedRow.bow);
            newCurrentSliders.rc1 = parseFloat(selectedRow.rc1 / 100);
            newCurrentSliders.coinvestFlag = parseFloat(
                selectedRow.coinvestFlag
            );
            newCurrentSliders.rc2 = parseFloat(selectedRow.rc2 / 100);
            newCurrentSliders.rc3 = parseFloat(selectedRow.rc3 / 100);
            newCurrentSliders.rc4 = parseFloat(selectedRow.rc4 / 100);
            newCurrentSliders.rc5 = parseFloat(selectedRow.rc5 / 100);
            newCurrentSliders.model = 'Yale';
            newCurrentSliders.strategy = selectedRow.strategy;
            newCurrentSliders.commitQtr = selectedRow.commitQtr;
            newCurrentSliders.scenario = selectedRow.scenario;
            newCurrentSliders.case = selectedRow.scenario;
            newCurrentSliders.investmentId = selectedRow.investmentId;
            newCurrentSliders.closingYear = selectedRow.closingYear;

            await setContribToDate(selectedRow.contribToDate);
            await setDistribToDate(selectedRow.distribToDate);
            await setNumYrsFromVinToCurrDate(newNumYrsFromVinToCurrDate);
            await setProjectedContrib(selectedRow.projectedContrib);

            setTimeout(() => {
                setCurrentSliders(newCurrentSliders);
            }, 1000);

            await updateParameterLab(
                commitmentType,
                commitmentType === 'new' ? '' : selectedRow.id
            );
        }
    };

    /**
     * Update Parameter Lab data in underwritingContext before save
     */
    const onSaveClick = async () => {
        await updateGlobalSliders();
        await updateParameterLab(commitmentType, fundId);
        await handleSave();
    };

    /**
     * Update scenario
     */
    const handleScenarioChange = ({ target: { value } }) => {
        setScenario(value);
        updateGlobalSliders();
    };

    /**
     * Create Scenario dropdown
     */
    const getOptions = () => {
        return (
            <Input
                type='select'
                defaultValue='Base'
                onChange={handleScenarioChange}
            >
                <option key='Base' value='Base'>
                    Base
                </option>
                <option key='High' value='High'>
                    High
                </option>
                <option key='Low' value='Low'>
                    Low
                </option>
                <option key='All' value='All'>
                    All
                </option>
            </Input>
        );
    };

    const handleCheckboxChange = () => {
        setShowLiquidatedFunds(!showLiquidatedFunds);
    };

    /**
     * Update graphs when relavent change is made
     */
    useEffect(() => {
        if (
            currentSliders.strategy !== '(Select a Strategy)' &&
            typeof currentSliders !== 'undefined' &&
            currentSliders.model !== ''
        ) {
            updateGraphs();
        }
    }, [currentSliders]);

    /**
     * Update descriptive stats graph data on graphValues update
     */
    useEffect(() => {
        let newDescStatGraphData = graphValues;
        if (currentSliders.scenario === 'High') {
            newDescStatGraphData = graphValuesHigh;
        } else if (currentSliders.scenario === 'Low') {
            newDescStatGraphData = graphValuesLow;
        }

        setDescStatsGraphData(newDescStatGraphData);
    }, [graphValues, graphValuesHigh, graphValuesLow, currentSliders]);

    /**
     * Initialize fundSliderValues on mount and update globals on unmount
     */
    useEffect(() => {
        calculateCommitmentType();

        if (fundSliderValues.length === 0 && tableData.length !== 0) {
            const createNewFundSliderValues = async () => {
                const newFundSliderValues = await loadFundSliderValues();

                const fundSlidersWithIds = await assignFundSliderIds(
                    newFundSliderValues,
                    tableData,
                    strategyParameters
                );

                setFundSliderValues(fundSlidersWithIds);
            };

            createNewFundSliderValues();
        } else {
            const updateExistingFundSliderValues = async () => {
                // Check if fund to strategy correlation has changed
                const updatedFundSliderValues = await checkForFundStratAndNameChange();

                const fundSlidersWithIds = assignFundSliderIds(
                    updatedFundSliderValues,
                    tableData,
                    strategyParameters
                );

                setFundSliderValues(fundSlidersWithIds);
            };

            updateExistingFundSliderValues();
        }

        return updateGlobalSliders();
    }, []);

    useEffect(() => {
        updateGlobalSliders();
    }, [underwritingDataset]);

    /**
    * Target Allocation modal
    */
    const [isTargetAllocationModalOpen, setTargeAllocationModal] = useState(false);
    /**
    * Create Strategy modal
    */
    const [isCreateStrategyModalOpen, setCreateStrategyModal] = useState(false);
    /**
    * Default Assumptions modal
    */
    const [isDefaultAssumptionsModalOpen, setDefaultAssumptionsModal] = useState(false);
    /**
    * Edit Multiple modal
    */
    const [isEditMultipleModalOpen, setEditMultipleModalOpen] = useState(false);

    return (
        <Container fluid style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
            <PageTitle title='Underwriting' handleSave={onSaveClick} />
            <Row>
                <div style={{ flex: '0 1 33%', padding: '0 15px'}}>
                    <LeftChart
                        commitmentType={commitmentType}
                        contribToDate={contribToDate}
                        distribToDate={distribToDate}
                        numYrsFromVinToCurrDate={numYrsFromVinToCurrDate}
                        projectedContrib={projectedContrib}
                        plotGraph={plotGraph}
                        graphValues={graphValues}
                        graphValuesHigh={graphValuesHigh}
                        graphValuesLow={graphValuesLow}
                        historical={historicalGraphValues}
                    />
                </div>
                <div style={{ flex: '0 1 34%', padding: '0 15px'}}>
                    <MiddleChart
                        commitmentType={commitmentType}
                        contribToDate={contribToDate}
                        distribToDate={distribToDate}
                        numYrsFromVinToCurrDate={numYrsFromVinToCurrDate}
                        plotGraph={plotGraph}
                        graphValues={graphValues}
                        graphValuesHigh={graphValuesHigh}
                        graphValuesLow={graphValuesLow}
                        historical={historicalGraphValues}
                    />
                </div>
                <div style={{ flex: '0 1 33%', padding: '0 15px'}}>
                    <RightChart
                        commitmentType={commitmentType}
                        numYrsFromVinToCurrDate={numYrsFromVinToCurrDate}
                        plotGraph={plotGraph}
                        graphValues={graphValues}
                        graphValuesHigh={graphValuesHigh}
                        graphValuesLow={graphValuesLow}
                        historical={historicalGraphValues}
                    />
                </div>
            </Row>
            <div className='panel section-border'>
                <Row>
                    <Col align='left' md={12}>
                        <DescriptiveStatistics
                            commitmentType={commitmentType}
                            descriptiveStats={descStatGraphData}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} align='left'>
                        {targetExposureType === 'noNewCommitments' ? (
                            <Label style={{ fontWeight: 'bold' }}>
                                Commitment Type: Existing
                            </Label>
                        ) : (
                            <TypeToggle
                                isOn={commitmentType === 'new'}
                                handleToggle={handleToggle}
                                leftLabel='Existing Commitments'
                                rightLabel='Target Allocations'
                            />
                        )}
                        {commitmentType === 'existing' ? (
                            <TypeToggle
                                isOn={showLiquidatedFunds}
                                handleToggle={handleCheckboxChange}
                                leftLabel='Hide Liquidated Funds'
                                rightLabel='Show Liquidated Funds'
                            />
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={6} style={{ display: 'flex', alignItems: 'center' }} align='left'>
                            <ModalButton
                                isTrialUser={isTrialUser}
                                commitmentType={commitmentType}
                                setDefaultAssumptionsModal={setDefaultAssumptionsModal}
                                setTargeAllocationModal={setTargeAllocationModal}
                            />

                        {/* {spiClientId ? (
                            <></>
                        ) : (
                            <ModalButton
                                isTrialUser={isTrialUser}
                                commitmentType={commitmentType}
                                setDefaultAssumptionsModal={setDefaultAssumptionsModal}
                                setTargeAllocationModal={setTargeAllocationModal}
                            />
                        )} */}
                    </Col>
                    <Col md={6} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', gap: '16px' }}>
                        { commitmentType === 'existing' ? (
                            <ButtonTec
                                size="small"
                                theme="primary"
                                onClick={() => setEditMultipleModalOpen(true)}
                            >
                                Edit Multiple
                            </ButtonTec>
                            ): null
                        }
                        <Grid>
                            <Label>
                                <b>Scenario: </b>
                            </Label>
                            {getOptions()}
                        </Grid>
                    </Col>
                </Row>
                <UnderwritingGrid
                    commitmentType={commitmentType}
                    scenario={scenario}
                    updateCurrentSliders={updateCurrentSliders}
                    updateSelectedRowData={updateSelectedRowData}
                    showLiquidatedFunds={showLiquidatedFunds}
                    isEditMultipleModalOpen={isEditMultipleModalOpen}
                />
            </div>
            <BottomNav
                backTarget='/'
                nextTarget='/pacing-analysis/quarterly-nav'
            />
            <ModalManager
                isTargetAllocationModalOpen={isTargetAllocationModalOpen}
                setTargeAllocationModal={setTargeAllocationModal}
                isCreateStrategyModalOpen={isCreateStrategyModalOpen}
                setCreateStrategyModal={setCreateStrategyModal}
                isDefaultAssumptionsModalOpen={isDefaultAssumptionsModalOpen}
                setDefaultAssumptionsModal={setDefaultAssumptionsModal}
                isEditMultipleModalOpen={isEditMultipleModalOpen}
                setEditMultipleModalOpen={setEditMultipleModalOpen}
            />
        </Container>
    );
};

export default ParameterLab;
