//@flow
import React from 'react';
import moment from 'moment';

type OptionType = {
    name: string,
    client_access: boolean,
    client_read_only: boolean,
    edited_by: string,
    updated_at: string,
};

type Props = {
    option: OptionType,
    userSpiClientId: number | null,
};

const SelectorOption = ({ option, userSpiClientId }: Props) => {
    if (typeof option === 'string') {
        return <>{option}</>;
    }

    const {
        name,
        client_access: clientAccess,
        client_read_only: clientReadOnly,
        edited_by: editedBy,
        updated_at: updatedAt,
    } = option;

    return (
        <div>
            <div>
                <h6>
                    <b>{name}</b>
                </h6>
            </div>
            <div>
                {!userSpiClientId && (
                    <>
                        <div>
                            Client: {clientAccess ? 'Accessible' : 'Hidden'}
                            &nbsp;-&nbsp;
                            {clientReadOnly ? 'Read Only' : 'Writable'}
                        </div>
                    </>
                )}
                <div>
                    Last Updated: {editedBy} on{' '}
                    {moment(updatedAt).format('MM/DD/YYYY')}
                </div>
            </div>
        </div>
    );
};

export default SelectorOption;
