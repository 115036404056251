// @flow
import React, { Component } from 'react';
import Slider, { Handle } from 'rc-slider';
import Badge from 'reactstrap/es/Badge';
import { HandleProps } from './types';

type Props = {
    availableAllocation: number,
    colors: {
        primary: string,
        secondary: string,
    },
    setGlobalValue: Function,
    value: number,
};

type State = {
    currentValue: number,
};

class AllocationSlider extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { value } = props;

        this.state = {
            currentValue: value,
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { value } = this.props;

        if (prevProps.value !== value) {
            this.setState({ currentValue: value });
        }
    }

    setCurrentValue = (newValue: number) => {
        const { availableAllocation } = this.props;

        const updatedValue =
            newValue > availableAllocation ? availableAllocation : newValue;

        this.setState({
            currentValue: updatedValue,
        });
    };

    render() {
        const { colors, setGlobalValue } = this.props;
        const { currentValue } = this.state;
        // dragging explicitly defined to keep it separate from
        // other passed props
        // eslint-disable-next-line no-unused-vars
        const handle = ({ value, dragging, ...props }: HandleProps) => {
            return (
                <>
                    <Badge
                        color='primary'
                        pill
                        className='rc-slider-hover'
                        style={{
                            [value <= 50 ? 'left' : 'right']: `${
                                value <= 50 ? value : 100 - value
                            }%`,
                            display: value !== 0 ? 'block' : 'none',
                            backgroundColor: colors.primary,
                            color: colors.secondary,
                        }}
                    >
                        {value}%
                    </Badge>
                    <Handle value={value} {...props} />
                </>
            );
        };

        return (
            <Slider
                allowCross={false}
                min={0}
                max={100}
                marks={{
                    0: <strong>0%</strong>,
                    100: <strong>100%</strong>,
                }}
                trackStyle={[{ backgroundColor: '#007899' }]}
                railStyle={{ backgroundColor: '#BDBFBF' }}
                tipFormatter={(value) => `${value}%`}
                handle={handle}
                value={currentValue}
                onChange={this.setCurrentValue}
                onAfterChange={() => {
                    setGlobalValue(currentValue);
                }}
            />
        );
    }
}
export default AllocationSlider;
