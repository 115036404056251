/**
 * Column defintions for Average Weighted Columns Schedule
 *
 * @param {*} startYear
 * @param {*} endYear
 * @param {*} fiscalYearPlusOne
 */

export function AverageWeightedColumnDefinitions(
    startYear,
    endYear,
    fiscalYearPlusOne
) {
    const tableDefinitions = [];
    tableDefinitions.push({
        headerName: 'Age of Portfolio in Years',
        field: 'ageInYears',
        pinned: 'left',
        editable: false,
        width: 300,
        resizable: false,
    });

    for (let i = startYear; i <= endYear; i++) {
        const year = fiscalYearPlusOne ? i + 1 : i;
        const stringYear = year.toString();
        if (year <= endYear) {
            const yearDefinition = {
                headerName: stringYear,
                field: stringYear,
                type: 'numericColumn',
                editable: false,
                width: 80,
            };

            tableDefinitions.push(yearDefinition);
        }
    }

    return tableDefinitions;
}

