import React from 'react';
import { Container, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { PageTitle } from 'utils';
import { ManagePacingTable } from './components';

const ManagePacing = () => (
    <Container fluid data-testid='manage-pacing'>
        <PageTitle
            handleSave={() => console.log('no save')}
            omitPacingName
            title='Pacing Management'
        />
        <Row>
            <Col md={12}>
                <div className='panel section-border'>
                    <ManagePacingTable />
                </div>
            </Col>
        </Row>
    </Container>
);

export default ManagePacing;
