export const Sector = [
    'Other',
    'Corporate',
    'Real Estate Credit',
    'Infrastructure & Real Assets',
    'None Specified',
    'Special Situations',
    'Buyout',
    'Venture Capital',
    'Growth Equity',
    'Natural Resources',
    'Energy',
    'Infrastructure',
    'RA Multi-Strategy',
    'Vehicles, Plant and Equipment',
    'Fund of Funds',
    'Core / Core+',
    'Opportunistic',
    'Multimanager',
    'Value-Add',
];

export const SubSector = [
    'IP/Royalty',
    'Direct Lending',
    'Life Settlements',
    'None Specified',
    'Distressed / NPL',
    'Private Performing',
    'Securitized',
    'Multi-strategy Debt',
    'CLO',
    'High Yield',
    'Long/Short Credit',
    'Secondary Corporate Securities',
    'SME Loan Portfolio Sales',
    'Syndicated Loans',
    'Core Infrastructure Debt',
    'Energy and Power',
    'Mining and Resources',
    'MLP',
    'Non-Core Asset Portfolio',
    'Trade Finance',
    'Regulatory Capital',
    'Marketplace Lending',
    'Leasing',
    'Royalties',
    'Enhanced Senior',
    'Mezzanine/High Yield',
    'Senior',
    'Other',
    'ABL (Asset Backed Lending)',
    'Distressed / Corporates',
    'Distressed / Multi-Strategy',
    'Mezzanine',
    'SME Lending',
    'Fund of Funds',
    'Large Buyout',
    'Global Buyout',
    'Middle Buyout',
    'Small Buyout',
    'Balanced',
    'Early Stage',
    'Late Stage',
    'Debt for Control',
    'Equity Turnaround',
    'Secondaries',
    'Venture Debt',
    'Co-Investments',
    'Small Growth Equity',
    'Medium Growth Equity',
    'Large Growth Equity',
    'Direct Secondaries',
    'Agriculture',
    'Timber',
    'NR Diversified',
    'Minerals',
    'ENR Services',
    'ENR Diversified',
    'Exploration and Production',
    'Power and Gas',
    'Commodities Logistics',
    'Social/Community Facilities & Services',
    'Transportation',
    'Water',
    'Renewables',
    'INFR Diversified',
    'Waste Management',
    'Communication',
    'RA Multi-Strategy',
    'Shipping',
    'Aircraft',
    'Rail',
    'VPE Other',
    'VPE Diversified',
    'Core',
    'Core+',
    'RE Distressed',
    'US / Global Large Market',
    'US Medium Market',
    'US Small Market',
    'Europe',
    'Rest of World',
];

export const Geography = [
    'Africa',
    'Arabian Markets',
    'Asia Australia',
    'BENELUX',
    'BRIC',
    'Caribbean',
    'CEE',
    'Central America',
    'DACH',
    'Developed Asia',
    'EAFE',
    'Emerging Markets',
    'EMEA',
    'EMU',
    'EMU UK',
    'Europe',
    'Far East',
    'GCC Countries',
    'Global',
    'Greater China',
    'Latin America',
    'MENA',
    'Middle East',
    'Nordic Countries',
    'North America',
    'North Asia',
    'OECD',
    'ROW',
    'South America',
    'Southeast Asia',
    'Western Europe',
];
