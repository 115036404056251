import React, { useContext } from 'react'
import { EditMultipleModal, TargetAllocationModal } from 'ParameterLab/modals'
import { CreateNewStrategyModal } from 'ParameterLab/modals/CreateNewStrategyModal'
import { DefaultAssumptionsModal } from '../DefaultAssumptionsModal'
import { UnderwritingContext } from 'context'

const ModalManager = ({
    isTargetAllocationModalOpen,
    setTargeAllocationModal,

    isCreateStrategyModalOpen,
    setCreateStrategyModal,

    isDefaultAssumptionsModalOpen,
    setDefaultAssumptionsModal,

    isEditMultipleModalOpen,
    setEditMultipleModalOpen,
}) => {
    const {
        commitmentType,
    } = useContext(UnderwritingContext);
    const onCloseTargetAllocationModal = () => setTargeAllocationModal(false);
    const onCloseCreateStrategyModal = () => {
        setCreateStrategyModal(false);
        setTargeAllocationModal(true);
    }
    const onCloseDefaultAssumptionsModal = () => {
        setDefaultAssumptionsModal(false);
        // only open target allocation modal when target allocation selected
        if (commitmentType == 'new') {
            setTargeAllocationModal(true);
        }
    }

    return (
        <>
            <TargetAllocationModal
                isOpen={isTargetAllocationModalOpen}
                onClose={onCloseTargetAllocationModal}
                openCreateStrategyModal={() => setCreateStrategyModal(true)}
                openDefaultAssumptionsModal={() => setDefaultAssumptionsModal(true)}
            />
            <CreateNewStrategyModal
                isOpen={isCreateStrategyModalOpen}
                onClose={onCloseCreateStrategyModal}
            />
            <DefaultAssumptionsModal
                isOpen={isDefaultAssumptionsModalOpen}
                onClose={onCloseDefaultAssumptionsModal}
            />
            <EditMultipleModal
                isOpen={isEditMultipleModalOpen}
                onClose={() => setEditMultipleModalOpen(false)}
            />
        </>
    )
}

export default ModalManager
