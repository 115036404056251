import { numberFormat, NumericEditor, numericValidator } from "utils";
import { UnderwritingPaidInRenderer } from "ParameterLab/components/UnderwritingGrid/components/UnderwritingTableColumnDefinitions/components";

const formatDisplayPercentage = (params) => {
    const { value, decimalPlace } = params;
    if (!value && value !== 0) {
        return '';
    }
    const formattedValue = numberFormat(value, decimalPlace);

    return `${formattedValue}%`;
}

function getCellStyling(params) {
    const field = params.colDef.field;

    const objectStyling = {};

    // Blue if column is editable (Year 1-Year 4 styling handled in UnderwritingPaidInRenderer)
    if (params.colDef.editable) {
        objectStyling.color = '#268fb3';
        // objectStyling.color = '#333333';
    }

    if (params.data?.userEdited?.length && params.data?.userEdited?.includes(field)) {
        objectStyling.fontWeight = 'bold';
    } else {
        objectStyling.fontWeight = 'normal';
    }
    return objectStyling;
}

const percentageRowDefinition = (field, updateValue) => ({
    field,
    width: '80px',
    editable: true,
    type: 'numericColumn',
    cellRenderer: formatDisplayPercentage,
    cellRendererParams: (params) => {
        const decimalPlace = 2;
        return {
            params,
            decimalPlace,
        };
    },
    cellEditor: 'numericEditor',
    cellEditorParams: () => ({
        field,
        updateValue,
    }),
    onCellValueChanged: numericValidator,
    cellStyle: getCellStyling,
    suppressMenu: true,
});

export default (updateValue) => [
    {
        headerName: 'Pacing Strategy',
        field: 'strategy',
        width: '240px',
        pinned: 'left',
        // cellStyle: { color: '#268fb3' }
    },
    {
        headerName: 'Paid In',
        ...percentageRowDefinition('paidIn', updateValue),
    },
    {
        headerName: 'Year 1',
        ...percentageRowDefinition('rc1', updateValue),
    },
    {
        headerName: 'Year 2',
        ...percentageRowDefinition('rc2', updateValue),
    },
    {
        headerName: 'Year 3',
        ...percentageRowDefinition('rc3', updateValue),
    },
    {
        headerName: 'Year 4',
        ...percentageRowDefinition('rc4', updateValue),
    },
    {
        headerName: 'Year 5+',
        ...percentageRowDefinition('rc5', updateValue),
    },
    {
        headerName: 'IRR',
        ...percentageRowDefinition('irr', updateValue),
    },
    {
        headerName: 'Yield',
        ...percentageRowDefinition('yld', updateValue),
    },
    {
        headerName: 'Fund Life',
        field: 'fundLife',
        width: '90px',
        editable: true,
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'fundLife',
            updateValue,
        }),
        cellStyle: getCellStyling,
    },
    {
        headerName: 'Distribution Delay',
        field: 'bow',
        width: '130px',
        editable: true,
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'bow',
            updateValue,
        }),
        cellStyle: getCellStyling,
    },
];

export const EditMultipleGridFrameworkComponents = {
    numericEditor: NumericEditor,
    UnderwritingPaidInRenderer
}
