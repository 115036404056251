import React, { Component } from 'react';
import Input from '@material-ui/core/Input';

export default class AgGridDatePicker extends Component {
    myRef: MyRefType;

    constructor(props: Props) {
        super(props);

        this.myRef = React.createRef();

        const { value } = this.props;
        this.state = { date: value };
    }

    // Set new date value to local state
    handleChange = (event) => {
        const { value } = event.target;

        this.setState({ date: value });
    };

    getValue = () => {
        const { date } = this.state;

        return date;
    };

    // any cleanup we need to be done here
    destroy = () => {};

    // if true, then this editor will appear in a popup
    isPopup = () => {
        return false;
    };

    render() {
        const dateField = {
            width: '100%',
        };
        const { date } = this.state;

        return (
            <Input
                ref={this.myRef}
                type='date'
                defaultValue={date}
                onChange={this.handleChange}
                style={dateField}
            />
        );
    }
}
