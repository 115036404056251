import {format, addMonths} from "date-fns";
import { getQuarterAndYearFromDate, getQuartersStartDate, generateQuarters } from './Helpers';

export const setQuartersDateOptions = (isEndDates = false, reportDate, years = 3) => {
    const startDate = isEndDates ? format(addMonths(new Date(reportDate), 1), 'yyyy-MM-dd') : reportDate;
    const quarters = getQuartersStartDate(startDate, years);
    const options = [];

    quarters.forEach( (q) => {
        options.push(
            {
                label: q,
                value: q
            }
        )
    })
    return options
}