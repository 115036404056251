//@flow
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap/es';
import {
    ReadOnlyTableDataColumnDefinitions,
    ReadOnlyFrameworkComponents,
} from '../components';

type Props = {
    isOpen: boolean,
    rawData: Array,
    toggleModal: Function,
};

/**
 * Create a simple non-editable table
 * containing raw version of uploaded/selected data
 */
const RawDataModal = ({ isOpen, toggleModal, rawData: data }: Props) => {
    const overlayLoadingTemplate = '<span>Loading Pacings...</span>';
    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        editable: false,
    };

    const onGridReady = (params) => {
        const gridColumnApi = params.columnApi;
        gridColumnApi.setColumnVisible('Id', false);
    };

    const columnDefs = ReadOnlyTableDataColumnDefinitions();
    const frameworkComponents = ReadOnlyFrameworkComponents();

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            style={{ minWidth: '1200px' }}
        >
            <ModalHeader toggle={toggleModal}>Raw Table Data</ModalHeader>
            <ModalBody>
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: '500px',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        animateRows
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        components={frameworkComponents}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={overlayLoadingTemplate}
                        pagination
                        paginationPageSize={50}
                        rowData={data}
                        rowHeight={35}
                        lockVisible
                        suppressExcelExport
                        suppressCsvExport
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className='secondary-button' onClick={toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default RawDataModal;
