//@flow
import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { FormGroup } from 'reactstrap/es';

type Props = {
    options: any,
    loading: boolean,
    onChange: Function,
};

const ClientSelector = ({ options, loading = false, onChange }: Props) => {
    const handleChange = useCallback((event, newValue) => {
        onChange(newValue);
    }, []);

    return (
        <Autocomplete
            autoComplete
            options={options}
            getOptionLabel={({ label }) => label}
            onChange={(e, newValue) =>
                handleChange(e, { value: newValue.value, name: 'Client' })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    className='db-load'
                    variant='outlined'
                    disabled={loading}
                    label='Select a Client'
                    fullWidth
                    size='small'
                />
            )}
        />
    );
};

export default ClientSelector;
