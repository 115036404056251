//@flow
import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

type Props = {
    options: any,
    loading: boolean,
    onChange: Function,
};

const PortfolioSelector = ({ options, loading = false, onChange }: Props) => {
    const handleChange = useCallback((event, newValue) => {
        onChange(newValue);
    }, []);

    return (
        <Autocomplete
            autoComplete
            options={options}
            getOptionLabel={({ label }) => label}
            onChange={handleChange}
            multiple
            renderInput={(params) => (
                <TextField
                    {...params}
                    className='db-load'
                    variant='outlined'
                    disabled={loading}
                    label='Select portfolio(s)'
                    fullWidth
                    name='Portfolios'
                    size='small'
                />
            )}
        />
    );
};

export default PortfolioSelector;
