// @flow
import React, { useContext, useState } from 'react';
import { Grid } from '@ssgglobal/techintnue';
import { InputLabel, RadioGroup } from '@ssgglobal/techintnue'
import { PacingContext } from 'context';

import { MonthDropdown } from './components';

type Props = {
    dateType: string,
    disableCheck: boolean,
    fiscalMonth: string,
    handleDateTypeChange: Function,
    handleMonthChange: Function,
    // showMonth: boolean,
    setUserEdited: Function,
};

export default function({
    dateType,
    handleDateTypeChange,
    disableCheck,
    fiscalMonth,
    // showMonth,
    handleMonthChange,
    setUserEdited,
}: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    const dateStyleOptions = [
        {
            label: 'Calendar Years',
            value: 'calendar'
        },
        {
            label: 'Fiscal Years',
            value: 'fiscal'
        },
    ]

    const handleChange = (e) => {
        const typeChange = {
            target: {
                name: 'dateType',
                value: e
            }
        }
        handleDateTypeChange(typeChange)
    }

    return (
        <>
            {pacingClientReadOnlyAndSpiClientId() ? (
                <Grid>
                    {dateType === 'fiscal' ? 'Calendar Years' : 'Fiscal Years'}
                </Grid>
            ) : (
                <Grid container>
                    <Grid
                        item
                        md={6}
                        sx={{
                            '& > .MuiBox-root > .MuiFormGroup-root > .MuiFormControlLabel-root': {
                                marginBottom: 0,
                            },
                        }}
                    >
                        <RadioGroup 
                            isThemed
                            label="Date Style"
                            value={dateType}
                            onChange={handleChange}
                            options={dateStyleOptions}
                        />
                    </Grid>
                    { dateType === 'fiscal' &&
                        <Grid item md={6}>
                            <InputLabel sm={12}>End Month</InputLabel>
                            <MonthDropdown
                                handleMonthChange={handleMonthChange}
                                month={fiscalMonth}
                                setUserEdited={setUserEdited}
                            />
                        </Grid>
                    }
                </Grid>
            )}
        </>
    );
}
