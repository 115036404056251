// @flow
import React, { Component } from 'react';
import { MdLockOutline } from 'react-icons/md';
import { Button } from 'reactstrap';
import FormatNumber from './FormatNumber';
import { LockStyling } from './types';

type Props = {
    optimizer: Array<any>,
    colDef: { field: string },
    data: { strategy: string },
    toggleLock: Function,
    rowIndex: number,
    handleValueClick: Function,
};

type State = {
    style: LockStyling,
};

export default class FormatNumberWithLock extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            style: {
                backgroundColor: 'transparent',
                border: '0px none',
                color: '#BDC3C7',
                padding: '0px 0px 5px 5px',
            },
        };
    }

    getStyle = () => {
        const { optimizer, colDef, data } = this.props;
        const { style } = this.state;
        optimizer.forEach((row) => {
            Object.keys(row).forEach((key) => {
                if (row.strategy === data.strategy && key === colDef.field) {
                    style.color = row[key] === 0 ? '#BDC3C7' : '#000';
                }
            });
        });

        return style;
    };

    handleToggle = () => {
        const { colDef, data, toggleLock } = this.props;

        toggleLock(colDef.field, data.strategy);
    };

    handleValueClick = () => {
        const { colDef, data, rowIndex, handleValueClick } = this.props;

        handleValueClick(colDef, rowIndex, data);
    };

    // a11y compliance
    handleKeyUp = (event: SyntheticKeyboardEvent<HTMLBodyElement>) => {
        if (event.key === 'Enter') {
            this.handleValueClick();
        }
    };

    renderLock = () => {
        const {
            data: { strategy = '' },
        } = this.props;

        const styl = this.getStyle()

        return [
            'Total Portfolio Net Cash Flows',
            'Total Portfolio Growth p.a. (%)',
            'Portfolio AuM',
            'Liquid Assets',
        ].includes(strategy) ? (
            <></>
        ) : (
            <Button onClick={this.handleToggle} style={{...styl}} className='btn-no-outline'>
                <MdLockOutline size={10} />
            </Button>
        );
    };

    render() {
        return (
            <>
                {/* Do not add a tab-index. Will break functionality */}
                {/* eslint-disable jsx-a11y/interactive-supports-focus */}
                <div
                    style={{ flexGrow: 2 }}
                    onClick={this.handleValueClick}
                    role='button'
                    onKeyUp={this.handleKeyUp}
                >
                    {FormatNumber(this.props)}
                </div>
                {/* eslint-enable jsx-a11y/interactive-supports-focus */}
                {this.renderLock()}
            </>
        );
    }
}
