import React from 'react'
import { TableVariant } from '@ssgglobal/techintnue'
import { UnderwritingFrameworkComponents } from 'ParameterLab/components/UnderwritingGrid/components'
import { ContributionColumnDefinition } from './ContributionColumnDefinition'


const ContributionPercentageTable = ({
    register,
    value,
}) => {
    const { name, onChange } = register('contribution')
    const frameworkComponents = UnderwritingFrameworkComponents()

    const updateValue = (incomingValue, field, row) => {
        const newValue = {
            ...row,
            [field]: incomingValue,
        }

        const event = {
            target: {
                name,
                value: newValue,
            }
        }
        onChange(event)
    }

    const columnDefs = ContributionColumnDefinition(updateValue);

    return (
        <TableVariant
            gridStyle={{ height: '61px', width: '100%' }}
            tableType="aggrid"
            minHeight="61px"
            agTheme="ag-theme-balham"
            className="ag-grid-custom"
            agGridOptions={{
                height: '61px',
                columnDefs: columnDefs,
                rowData: [value],
                components: frameworkComponents,
                singleClickEdit: true,
            }}
        />
    )
}

export default ContributionPercentageTable
