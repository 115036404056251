import React, { Component } from 'react';
import { numberFormat } from 'utils';
import { ExistingCommitmentsGraph, NewCommitmentsGraph } from './components';

type Props = {
    commitmentType: string,
    contribToDate: number,
    distribToDate: number,
    numYrsFromVinToCurrDate: number,
    plotGraph: any,
    graphValues: any,
    graphValuesHigh: any,
    graphValuesLow: any,
};

/**
 * Maps cumulative net cash flow
 */
class MiddleChart extends Component<Props> {
    state = {
        dataPoints: [],
        baseDataPoints: [],
        cashFlowUncertainty: [],
        years: [],
    };

    /**
     * To prevent multiple rerenderings of the graph
     * 
     * @param {*} nextProps 
     * @param {*} nextState 
     */
    shouldComponentUpdate(nextProps, nextState) {
        const { graphValues, graphValuesHigh, graphValuesLow, historical } = this.props;
        if (
            graphValues !== nextProps.graphValues ||
            graphValuesLow !== nextProps.graphValuesLow ||
            graphValuesHigh !== nextProps.graphValuesHigh ||
            historical !== nextProps.historical ||
            this.state !== nextState
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        this.updateGraph(prevProps);
    }

    /**
     * Format the incoming data into arrays
     * to plug into kendo charts
     */
    calculateValues = () => {
        const {
            graphValues,
            graphValuesHigh,
            graphValuesLow,
            commitmentType,
            numYrsFromVinToCurrDate,
            contribToDate,
            distribToDate,
            historical,
        } = this.props;

        if (Object.keys(graphValues).length === 0) {
            return {};
        }

        const shiftQtr = historical ? historical.shift_qtr / 4 : 0;
        const historicalContrib = historical ? historical.contrib_perc.map((value) => {
            return value * 100;
        }) : [];
        const historicalDistrib = historical ? historical.distrib_perc.map((value) => {
            return value * 100;
        }) : [];

        const historicalCashFlow = historicalDistrib.map((value, index) => {
            return value - historicalContrib[index];
        });

        let year = 0;
        const dataPoints = [];
        const baseDataPoints = [];

        let cumDistrib = 0;
        let cumContrib = 0;
        let cumBaseDistrib = 0;
        let cumBaseContrib = 0;
        let cumContribUncertaintyHigh = 0;
        let cumDistribUncertaintyHigh = 0;
        let cumContribUncertaintyLow = 0;
        let cumDistribUncertaintyLow = 0;
        const cashFlowUncertainty = [];
        const cashFlowToDateLine = [];
        const time = [];
        for (let i = 0; i < graphValues.contrib.length; i++) {
            time.push(year);
            cumDistrib += graphValues.distrib[i];
            cumContrib += graphValues.contrib[i];

            if (graphValues.base_contrib && graphValues.base_distrib) {
                cumBaseContrib += graphValues.base_contrib[i];
                cumBaseDistrib += graphValues.base_distrib[i];
            }

            const distributionContributionDiff =
                (cumDistrib - cumContrib) * 100;
            const baseDistributionContributionDiff =
                (cumBaseDistrib - cumBaseContrib) * 100;

            if (commitmentType === 'existing') {
                let holderObject;
                if (year === numYrsFromVinToCurrDate + shiftQtr) {
                    let NCF = 0;
                    if (contribToDate !== '' && distribToDate !== '') {
                        NCF = distribToDate - contribToDate;
                    }
                    cashFlowToDateLine.push(NCF);
                } else {
                    cashFlowToDateLine.push(holderObject);
                }
                // const currentValue = cumDistrib - cumContrib;
                let netCFValue;
                if (year > numYrsFromVinToCurrDate + shiftQtr) {
                    netCFValue = numberFormat(distributionContributionDiff);
                } else if (historical) {
                    netCFValue = numberFormat(historicalCashFlow[i]);
                }
                dataPoints.push(netCFValue);
                baseDataPoints.push(
                    numberFormat(baseDistributionContributionDiff)
                );
            } else {
                dataPoints.push(numberFormat(distributionContributionDiff));
                baseDataPoints.push(
                    numberFormat(baseDistributionContributionDiff)
                );
            }

            // Create the uncertainty shading
            if (
                typeof graphValuesHigh !== 'undefined' &&
                typeof graphValuesLow !== 'undefined'
            ) {
                // Create the uncertainty shading.
                cumContribUncertaintyHigh += graphValuesHigh.contrib[i];
                cumDistribUncertaintyHigh += graphValuesHigh.distrib[i];
                cumContribUncertaintyLow += graphValuesLow.contrib[i];
                cumDistribUncertaintyLow += graphValuesLow.distrib[i];

                if (cumContribUncertaintyLow !== 0) {
                    const contribDistribDiffHigh =
                        cumDistribUncertaintyHigh - cumContribUncertaintyHigh;
                    const contribDistribDiffLow =
                        cumDistribUncertaintyLow - cumContribUncertaintyLow;

                    cashFlowUncertainty.push({
                        min: numberFormat(contribDistribDiffLow * 100),
                        max: numberFormat(contribDistribDiffHigh * 100),
                    });
                } else {
                    cashFlowUncertainty.push({});
                }
            }

            year += 0.25;
        }

        return {
            dataPoints,
            baseDataPoints,
            cashFlowUncertainty,
            cashFlowToDateLine,
            time,
        };
    };

    /**
     * Sets state with returned value from 
     * calculateValues()
     * 
     * @param {*} prevProps 
     */
    updateGraph = async (prevProps) => {
        const { graphValues } = this.props;

        if (Object.keys(graphValues).length > 0 && this.props !== prevProps) {
            const {
                dataPoints,
                baseDataPoints,
                cashFlowUncertainty,
                cashFlowToDateLine,
                time,
            } = await this.calculateValues();
            this.setState({
                dataPoints,
                baseDataPoints,
                cashFlowUncertainty,
                cashFlowToDateLine,
                years: time,
            });
        }
    };

    render() {
        const {
            dataPoints,
            baseDataPoints,
            cashFlowUncertainty,
            cashFlowToDateLine,
            years,
        } = this.state;
        const { plotGraph, commitmentType } = this.props;
        let commitmentsGraph = '';

        if (commitmentType === 'new') {
            commitmentsGraph = (
                <NewCommitmentsGraph
                    plotGraph={plotGraph}
                    dataPoints={dataPoints}
                    baseDataPoints={baseDataPoints}
                    cashFlowUncertainty={cashFlowUncertainty}
                    years={years}
                />
            );
        } else {
            commitmentsGraph = (
                <ExistingCommitmentsGraph
                    plotGraph={plotGraph}
                    dataPoints={dataPoints}
                    baseDataPoints={baseDataPoints}
                    cashFlowUncertainty={cashFlowUncertainty}
                    cashFlowToDateLine={cashFlowToDateLine}
                    years={years}
                />
            );
        }

        return (
            <div className='panel underwriting-topper m-0 section-border'>
                {commitmentsGraph}
            </div>
        );
    }
}

export default MiddleChart;
