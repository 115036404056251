//@flow
import React from 'react';
import type { Node } from 'react';
import {
    StylesProvider,
    ThemeProvider as BaseThemeProvider,
} from '@material-ui/core/styles';
import theme from './theme';

type Props = {
    children: Node,
};

const ThemeProviders = ({ children }: Props) => (
    <BaseThemeProvider theme={theme}>
        <StylesProvider injectFirst>{children}</StylesProvider>
    </BaseThemeProvider>
);

export default ThemeProviders;
