// Blue as example

const blue = {
    50: '#e3f2fd',
    100: '#bbdefb',
    200: '#90caf9',
    300: '#64b5f6',
    400: '#42a5f5',
    500: '#2196f3',
    600: '#1e88e5',
    700: '#1976d2',
    800: '#1565c0',
    900: '#0d47a1',
    A100: '#82b1ff',
    A200: '#448aff',
    A400: '#2979ff',
    A700: '#2962ff',
};

// export default blue;

const primary = {
    50: '#d5edf6',
    100: '#c0e5f2',
    200: '#81cbe4',
    300: '#42b2d7',
    400: '#268fb3',
    500: '#007899',
    600: '#006f90',
    700: '#006b8c',
    800: '#00597a',
    900: '#004a67',
    A100: '#4ec9e3',
    A200: '#479fb8',
    A400: '#3d6f85',
    A700: '#315062',
};

export default primary;
