/* eslint-disable no-unused-expressions */
// @flow
import React, { useContext, useState } from 'react';
import {
    AuthContext,
    LandingContext,
    PacingAnalysisContext,
    PacingContext,
    StaticsContext,
    TargetAllocationContext,
    UnderwritingContext,
} from 'context';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap/es';
import swal2 from 'sweetalert2';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { postNewPacingData } from 'api';
import { trimSliderValues } from 'utils';

type Props = {
    open: boolean,
    toggleModal: Function,
};

const SavePacingData = ({ open, toggleModal }: Props) => {
    const { user } = useContext(AuthContext);
    const { landingContextDataToSave, updateLandingContext } = useContext(
        LandingContext
    );

    const {
        pacingAnalysisContextDataToSave,
        checkForRenamedStrategy,
        updatePacingAnalysisContext,
    } = useContext(PacingAnalysisContext);
    const {
        sliderValues,
        underwritingContextDataToSave,
        underwritingDataset,
        updateUnderwritingContext,
    } = useContext(UnderwritingContext);
    const {
        strategyAllocation,
        targetAllocationContextDataToSave,
        updateTargetAllocationContext,
    } = useContext(TargetAllocationContext);
    const {
        pacingClientAccess,
        pacingClientId,
        pacingClientReadOnly,
        pacingName: globalPacingName,
        updatePacingContext,
    } = useContext(PacingContext);
    const { clients } = useContext(StaticsContext);

    const [pacingName, setPacingName] = useState(globalPacingName || '');
    const [clientAccess, setClientAccess] = useState(pacingClientAccess || 0);
    const [clientReadOnly, setClientReadOnly] = useState(
        pacingClientReadOnly || 0
    );
    // const [pacingId, setPacingId] = useState(pacingId || 0);
    const [clientId, setClientId] = useState(pacingClientId || 0);

    const handleCheckboxChange = ({ target: { name = '' } }) => {
        name === 'clientAccess' ? setClientAccess(!clientAccess) : null;
        // Remove clientReadOnly checkbox for now
        // name === 'clientReadOnly' ? setClientReadOnly(!clientReadOnly) : null;
    };

    const handleInputChange = ({ target: { name = '', value = '' } }) => {
        name === 'pacingName' ? setPacingName(value) : null;
    };

    const handleClientChange = (event, newValue) => {
        const value = newValue || 0;

        setClientId(value);
    };

    const saveNewPacingData = async () => {
        // close modal first so loading pacing does not default to an open modal
        await toggleModal();

        swal2.showLoading();

        const { newSliderValues } = trimSliderValues(
            underwritingDataset,
            sliderValues,
            checkForRenamedStrategy,
            strategyAllocation
        );

        let clientReadOnlyCopy = clientReadOnly;
        let clientAccessCopy = clientAccess;

        // if spi_client_id
        if (user.spi_client_id || clientId) {
            // if new spi_client_id selected
            if (clientId) {
                user.spi_client_id = clientId;
            }
            clientReadOnlyCopy = 0;
            clientAccessCopy = 1;
        }

        const pacingDetailsToSave = {
            pacingName,
            pacingClientReadOnly: clientReadOnlyCopy,
            pacingClientAccess: clientAccessCopy,
        };

        const dataToSave = {
            user,
            sliderValues: newSliderValues,
            ...landingContextDataToSave,
            ...pacingAnalysisContextDataToSave,
            ...pacingDetailsToSave,
            ...targetAllocationContextDataToSave,
            ...underwritingContextDataToSave,
        };

        const name = user.name === '' ? user.email : user.name;

        const params = {
            name: pacingName,
            email: user.email,
            spi_client_id: user.spi_client_id || 0,
            client_access: clientAccessCopy ? 1 : 0,
            client_read_only: clientReadOnlyCopy ? 1 : 0,
            edited_by: name,
            saved_data: JSON.stringify(dataToSave),
        };

        try {
            const returnData = await postNewPacingData(params);
            const data = JSON.parse(returnData.saved_data);

            swal2.close();

            // Must run all of these functions together every time
            updateLandingContext(
                data,
                data.report_date ? data.report_date : data.reportDate
            );
            updatePacingContext(returnData);
            updatePacingAnalysisContext(data);
            updateTargetAllocationContext(data);
            updateUnderwritingContext(data);

            swal2.fire({
                type: 'success',
                html: 'Pacing saved!',
            });
        } catch (error) {
            const {
                responseJSON: {
                    // sets defaults to empty arrays to avoid error if keys do not exist in error.responseJSON
                    name: errorName = [],
                    non_field_errors: nonFieldErrors = [],
                },
            } = error;

            swal2.close();
            await toggleModal();

            let text =
                'Something went wrong trying to save the pacing model. ' +
                'Please contact an administrator if the problem persists';
            if (errorName[0] === 'This field may not be blank.') {
                text = 'Pacing Name is required.';
            } else if (
                errorName[0] === 'pacing data with this name already exists.' ||
                nonFieldErrors[0] ===
                    'The fields name, spi_client_id must make a unique set.'
            ) {
                text = 'Pacings must have a unique name.';
            }
            swal2.fire({
                type: 'error',
                html: text,
            });
        }
    };

    const checkboxLabelStyle = {
        marginLeft: '0px',
        marginBottom: '0px',
        marginTop: '5px',
    };
    const checkboxStyle = {
        marginLeft: '10px',
        marginTop: '0px',
    };

    return (
        <Modal isOpen={open} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
                Save Pacing to Database{' '}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                <b>Pacing Name:</b>
                            </Label>
                            <Input
                                type='string'
                                required
                                name='pacingName'
                                onChange={handleInputChange}
                                value={pacingName}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {!user.spi_client_id && (
                    <>
                        <Row>
                            <Col md={6}>
                                <Label style={checkboxLabelStyle}>
                                    <b>Client Accessible</b>
                                </Label>
                                <Input
                                    type='checkbox'
                                    name='clientAccess'
                                    style={checkboxStyle}
                                    onChange={handleCheckboxChange}
                                    checked={
                                        clientAccess === true ||
                                        clientAccess === 1
                                    }
                                />
                            </Col>
                            {/* {clientAccess ? (
                                <Col md={6}>
                                    <Label style={checkboxLabelStyle}>
                                        <b>Client Read-Only</b>
                                    </Label>
                                    <Input
                                        style={checkboxStyle}
                                        type='checkbox'
                                        name='clientReadOnly'
                                        onChange={handleCheckboxChange}
                                        checked={
                                            clientReadOnly === true ||
                                            clientReadOnly === 1
                                        }
                                    />
                                </Col>
                            ) : (
                                <></>
                            )} */}
                        </Row>
                        {clientAccess ? (
                            <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label
                                            title='Add client access by selecting from client name list.'
                                        >
                                            <b>Client Name:</b>
                                        </Label>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={clients}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                onChange={(e, newValue) => handleClientChange(e, newValue.id)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant='outlined'
                                                        label='Select a Client'
                                                        fullWidth
                                                        size='small'
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    className='primary-button btn btn-secondary'
                    onClick={saveNewPacingData}
                >
                    Save New Pacing
                </Button>
                <Button
                    className='secondary-button btn btn-secondary'
                    onClick={toggleModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SavePacingData;
