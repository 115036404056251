const getVintageDate = (commitQtr, vintageYear) => {
    let vintageDate = '';
    switch (commitQtr) {
        case 'Q1':
            vintageDate = '03/31/';
            break;
        case 'Q2':
            vintageDate = '06/30/';
            break;
        case 'Q3':
            vintageDate = '09/30/';
            break;
        case 'Q4':
            vintageDate = '12/31/';
            break;
        default:
            break;
    }

    vintageDate += vintageYear;

    return vintageDate;
};

export default getVintageDate;
