//@flow
import React from 'react';
import { Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from 'images/computing-cloud.svg';
import { parseExcel } from '../../helpers';

type Props = {
    docType: string,
    handleFileUploaded: Function,
    handlerFromParent: Function,
    incorrectTemplate: Function,
};

/**
 * Parse excel upload
 */
const DocUpload = ({
    docType,
    handleFileUploaded,
    handlerFromParent,
    incorrectTemplate,
    isTrialUser,
}: Props) => {
    const onDrop = (acceptedFiles) => {
        const fileType = acceptedFiles[0].type;

        const reader = new FileReader();

        reader.onabort = () => console.log('File reading was aborted');
        reader.onerror = () => console.log('File reading has failed');
        reader.onload = async (event) => {
            const data = event.target.result;

            let returnData;
            let correctTemplate;
            let jsonData;
            let message = '';
            switch (docType) {
                case 'excel':
                    if (fileType.includes('spreadsheet')) {
                        returnData = await parseExcel(data, isTrialUser);
                        ({ jsonData, correctTemplate } = returnData);
                        message =
                            'The excel upload was formatted incorrectly. Please ensure you are uploading an excel file, ' +
                            'the sheet is named "Existing Commitments", ' +
                            'and column headers are Number formatted as "Text" or "General".';
                    } else {
                        correctTemplate = false;
                        message =
                            'Incorrect File Type: Please upload an Excel file only';
                    }

                    if (correctTemplate) {
                        handlerFromParent(jsonData);
                    } else {
                        incorrectTemplate(message);
                    }

                    break;
                default:
                    break;
            }
        };

        acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
        handleFileUploaded();
    };

    const style = {
        textAlign: 'center',
    };
    return (
        <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => {
                return (
                    <div
                        {...getRootProps()}
                        className='form-control'
                        style={{ minHeight: '150px' }}
                    >
                        <input {...getInputProps()} />
                        <div style={style}>
                            <p>
                                <img
                                    src={CloudUploadIcon}
                                    height='25'
                                    width='25'
                                    alt='Cloud upload icon'
                                />
                            </p>
                            <p>
                                Drag and drop your <i>.XLS, .XLSX</i> file here
                            </p>
                            <p>or</p>
                            <div>
                                <Button className='primary-button'>
                                    Browse Files
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Dropzone>
    );
};

export default DocUpload;
