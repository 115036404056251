import { formatDisplayPercentage } from "utils";

const defaultEditableColumnDef = (field, updateValue) => ({
    field,
    editable: true,
    cellRendererParams: (params) => {
        const decimalPlace = 0;
        return {
            params,
            decimalPlace,
        };
    },
    cellEditor: 'numericEditor',
    cellEditorParams: {
        field,
        updateValue,
    },
    cellStyle: { color: '#007899' },
    flex: 1,
});

export const MetricColumnDefinition = (updateValue) => [
    {
        field: 'irr',
        headerName: 'Net IRR',
        ...defaultEditableColumnDef('irr', updateValue),
    },
    {
        field: 'yld',
        headerName: 'Yield',
        ...defaultEditableColumnDef('yld', updateValue),
        cellRenderer: formatDisplayPercentage,
    },
    {
        field: 'fundLife',
        headerName: 'Fund Life',
        ...defaultEditableColumnDef('fundLife', updateValue),
    },
    {
        field: 'bow',
        headerName: 'Distribution Delay',
        ...defaultEditableColumnDef('bow', updateValue),
    },
    {
        field: 'paidIn',
        headerName: 'Paid In',
        ...defaultEditableColumnDef('paidIn', updateValue),
        cellRenderer: formatDisplayPercentage,
    },
];
