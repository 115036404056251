// @flow
import React, { Component, useContext } from 'react';
import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { numberFormat, colorCombos } from 'utils';
import { PacingAnalysisContext } from 'context';

type Props = {
    graphData: any,
    globalTargetYear: number,
};

class SpecificYearAllocationComponent extends Component<Props> {
    state = {
        data: {
            pe: [],
            pd: [],
            re: [],
            in: [],
            ra: [],
        },
        targetYear: 0,
        title: '',
    };

    componentDidMount() {
        const { graphData } = this.props;
        if (Object.prototype.hasOwnProperty.call(graphData, 'assetclass_qtr')) {
            this.setData();
        }
    }

    shouldComponentUpdate(nextProps) {
        const { targetYear } = this.state;
        const {
            graphData: { assetclass_qtr: assetClassQtr },
        } = this.props;
        const {
            globalTargetYear,
            graphData: { assetclass_qtr: nextAssetClassQtr },
        } = nextProps;
        if (
            assetClassQtr !== nextAssetClassQtr ||
            targetYear !== globalTargetYear
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { targetYear } = this.state;
        const {
            graphData: { assetclass_qtr: assetClassQtr },
            globalTargetYear,
        } = this.props;
        const {
            graphData: { assetclass_qtr: prevAssetClassQtr },
        } = prevProps;
        if (
            assetClassQtr !== prevAssetClassQtr ||
            targetYear !== globalTargetYear
        ) {
            this.setData();
        }
    }

    setData = () => {
        const {
            graphData: {
                assetclass_qtr: assetClassQtrData,
                qtrdates: qtrDates,
            },
            globalTargetYear,
        } = this.props;

        if (!assetClassQtrData) {
            return;
        }

        const portAum = assetClassQtrData['0'].slice(2);
        const title = `Allocation in ${globalTargetYear}`;
        let indexVal = 0;

        const targetDate = `${globalTargetYear}-12-31`;
        qtrDates.forEach((date, index) => {
            if (date.includes(targetDate)) {
                indexVal = index;
            }
        });

        Object.keys(assetClassQtrData).forEach((key) => {
            if (assetClassQtrData[key][1] === 'NAV') {
                const values = assetClassQtrData[key].slice(2);

                const newValue =
                    values[indexVal] !== 0
                        ? numberFormat(
                              (values[indexVal] / portAum[indexVal]) * 100
                          )
                        : 0;

                this.setState((prevState) => ({
                    data: {
                        ...prevState.data,
                        [assetClassQtrData[key][0].toLowerCase()]: [newValue],
                    },
                }));
            }
        });

        this.setState({
            targetYear: globalTargetYear,
            title,
        });
    };

    render() {
        const {
            title,
            data: { pd, pe, ra, re },
        } = this.state;
        return (
            <div className='panel'>
                <Chart pannable={false} zoomable={false}>
                    <ChartLegend
                        visible
                        position='middle'
                        orientation='vertical'
                    />
                    <ChartTitle position='bottom' text={title} />
                    <ChartAxisDefaults majorGridLines={false} />
                    <ChartValueAxis>
                        <ChartValueAxisItem min={0} />
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            name='PE'
                            type='column'
                            color={colorCombos[0].primary}
                            stack
                            data={pe}
                        />
                        <ChartSeriesItem
                            name='PD'
                            type='column'
                            color={colorCombos[1].primary}
                            stack
                            data={pd}
                        />
                        <ChartSeriesItem
                            name='RE'
                            type='column'
                            color={colorCombos[2].primary}
                            stack
                            data={re}
                        />
                        {/* <ChartSeriesItem
                            name='IN'
                            type='column'
                            color={colorCombos[3].primary}
                            stack
                            data={in}
                        /> */}
                        <ChartSeriesItem
                            name='RA'
                            type='column'
                            color={colorCombos[3].primary}
                            stack
                            data={ra}
                        />
                    </ChartSeries>
                    <ChartTooltip />
                </Chart>
            </div>
        );
    }
}

const SpecificYearAllocation = (props) => {
    const {
        graphData,
        pacingParameters: { targetYear },
    } = useContext(PacingAnalysisContext);

    return (
        <SpecificYearAllocationComponent
            globalTargetYear={targetYear}
            graphData={graphData}
            {...props}
        />
    );
};

export default SpecificYearAllocation;
