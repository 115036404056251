import { dateSort, dateFilter } from 'utils';
import moment from 'moment';
import { ManagePacingActionButtons } from './components';

const insertEmptyValue = (listOfValues) => {
    return listOfValues ? ['', ...listOfValues] : [];
};

export default function ManagePacingColumnDefinitions(
    spiClientId,
    fetchPacings = null,
    clients = null
) {
    if (spiClientId) {
        return [
            {
                headerName: 'Client ID',
                field: 'spi_client_id',
                rowGroup: true,
                hide: true,
            },
            {
                headerName: 'Actions',
                field: 'client_read_only',
                editable: false,
                cellRenderer: ManagePacingActionButtons,
                cellRendererParams: (params) => {
                    return {
                        data: params.data,
                        refreshTable: fetchPacings,
                        spiClientId,
                    };
                },
            },
        ];
    }
    return [
        {
            headerName: 'Client ID',
            field: 'spi_client_id',
            rowGroup: true,
            hide: true,
            editable: false,
        },
        {
            headerName: 'Pacing ID',
            field: 'pacing_data_id',
            hide: true,
            editable: false,
        },
        {
            headerName: 'Client Accessible',
            field: 'client_access',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No'],
            },
            tooltipValueGetter: () => `Client Name field required before selecting Client Accessible.`,
            width: 175,
            editable: (params) => params.data.client_access == 'Yes' || params.data.client_name,
        },
        {
            headerName: 'Client Read-Only',
            field: 'client_read_only',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No'],
            },
            width: 165,
        },
        {
            headerName: 'Client Name',
            field: 'client_name',
            width: 165,
            cellEditorParams: () => {
                return {
                    values: insertEmptyValue(clients.map(item => item.name)),
                    field: 'client_name'
                };
            },
            cellRenderer: (params) => {
                let newVal = '';
                if (typeof params.value === 'string') {
                    newVal = clients.find(client => client.name == params.value)?.name || ''
                } else if (typeof params.value === 'number') {
                    newVal = clients.find(client => client.id == params.value)?.name || ''
                }
                return newVal;
            },
            cellEditor: 'agRichSelectCellEditor',
            cellEditorPopup: true,
        },
        {
            headerName: 'Last Updated By',
            field: 'last_updated_by',
            width: 165,
            editable: false,
        },
        {
            headerName: 'Last Updated On',
            field: 'last_updated_on',
            editable: false,
            cellRenderer: (params) => {
                if (params.value) {
                    return moment(params.value).format('MM-DD-YYYY hh:mma');
                }

                return null;
            },
            comparator: dateSort,
            filter: 'agDateColumnFilter',
            filterParams: () => {
                return {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                    comparator: dateFilter,
                };
            },
        },
        {
            headerName: 'Actions',
            field: 'client_read_only',
            editable: false,
            cellRenderer: ManagePacingActionButtons,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                    refreshTable: fetchPacings,
                };
            },
            flex: 1,
        },
    ];
}
