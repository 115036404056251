import { FormatNumber } from 'utils';
import TotalsRowRenderer from './TotalsRowRenderer';

export function ReadOnlyTableDataColumnDefinitions() {
    return [
        {
            headerName: 'Portfolio',
            field: 'Portfolio',
            hide: true,
        },
        {
            headerName: 'Investment',
            field: 'Investment',
            pinned: 'left',
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 200,
        },
        {
            headerName: 'Vintage',
            field: 'Vintage',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            width: 100,
        },
        {
            headerName: 'Asset Class',
            field: 'AssetClass',
            width: 150,
        },
        {
            headerName: 'Investment Type',
            field: 'InvestmentType',
            width: 100,
        },
        {
            headerName: 'Sector',
            field: 'Sector',
            width: 150,
        },
        {
            headerName: 'Sub-Sector',
            field: 'SubSector',
            width: 150,
        },
        {
            headerName: 'Geography',
            field: 'Geography',
            width: 150,
        },
        {
            headerName: 'Commitment',
            field: 'Commitment',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 100,
        },
        {
            headerName: 'Contributions',
            field: 'Contributions',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 100,
        },
        {
            headerName: 'Distributions',
            field: 'Distributions',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 100,
        },
        {
            headerName: 'AdjNAV',
            field: 'AdjNAV',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 100,
        },
        {
            headerName: 'Unfunded',
            field: 'Unfunded',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 100,
        },
        {
            headerName: 'IRR (%)',
            field: 'IRR',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                    decimal: 2,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 75,
        },
        {
            headerName: 'TVM (x)',
            field: 'TVM',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                    decimal: 2,
                };
            },
            pinnedRowCellRenderer: 'totalsRowRenderer',
            pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
            width: 75,
        },
    ];
}

export function ReadOnlyFrameworkComponents() {
    return {
        totalsRowRenderer: TotalsRowRenderer,
    };
}
