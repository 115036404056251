// @flow
import React, { useState, useEffect, useContext } from 'react';
import {
    AuthContext,
    LandingContext,
    StaticsContext,
    TargetAllocationContext,
    UnderwritingContext,
} from 'context';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    Container,
    Row,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { IoMdRemove } from 'react-icons/io';
import { PageTitle, BottomNav } from 'utils';
import { getOmniData, calcFwdNav } from 'api';
import { ReportDateModal } from './modals/index';
import {
    TableDataGrid,
    DocUploadModal,
    LoadFromDatabase,
    OmniImport
} from './components';

const uuidv4 = require('uuid/v4');

type Props = {
    history: any,
};

const Landing = ({ history }: Props) => {
    const { userSpiClientId, userOmniImportAccess, isTrialUser } = useContext(
        AuthContext
    );
    const {
        appendExistingCommitments,
        attributes,
        entityId,
        entityType,
        indexVersion,
        rawData,
        reportDate,
        setAttributes,
        setEntityId,
        setEntityType,
        setIsOmniImport,
        setRawData,
        setReportDate,
        setTableData,
        setUseForwardNav,
        tableData,
        updateReportDate,
        useForwardNav,
    } = useContext(LandingContext);
    const {
        fundSliderValues,
        resetGlobalData,
        setFundSliderValues,
        handleSave,
    } = useContext(UnderwritingContext);
    const {
        dveBetas,
        omniStrategyMapping,
        currency,
        currencyCode,
        setCurrency,
        setCurrencyCode,
        isFetchingLookups,
    } = useContext(StaticsContext);
    const { setCurrency: setTargetAllocCurrency } = useContext(
        TargetAllocationContext
    );

    const [docUploadIsOpen, setDocUploadIsOpen] = useState(false);
    const [selectInputIsOpen, setSelectInputsIsOpen] = useState(false);
    const [reportDateModalIsOpen, setReportDateModalIsOpen] = useState(false);
    const [loadFromDbIsOpen, setLoadFromDbIsOpen] = useState(false);
    const [selectedPortfolios, setSelectedPortfolios] = useState([]);
    const [invalidOmniEmail, setInvalidOmniEmail] = useState(false);
    const [uuidToResetRemove, setUuidToResetRemove] = useState('');
    const [keepCustomParams, toggleKeepCustomParams] = useState(false);
    const [firstCall, setFirstCall] = useState(true);
    const [loadedSavedPacing, setLoadedSavedPacing] = useState(false);

    useEffect(() => {
        if (isFetchingLookups) {
            Swal.showLoading();
            return;
        }
        Swal.close();
    }, [isFetchingLookups])

    const checkModalType = (type) => {
        switch (type) {
            case 'docUploadIsOpen':
                setDocUploadIsOpen(!docUploadIsOpen);
                break;
            case 'selectInputIsOpen':
                setSelectInputsIsOpen(!selectInputIsOpen);
                break;
            case 'reportDateModalIsOpen':
                setReportDateModalIsOpen(!reportDateModalIsOpen);
                break;
            case 'loadFromDbIsOpen':
                setLoadFromDbIsOpen(!loadFromDbIsOpen);
                break;
            default:
                break;
        }
    };

    const toggleModal = (type, redirect = null) => {
        if (invalidOmniEmail && type === 'selectInputIsOpen') {
            Swal.fire({
                type: 'error',
                html:
                    'You must have an Omni account to utilize this feature. Please contact an administrator',
            });
            return;
        }

        checkModalType(type);

        if (redirect) {
            history.push(redirect);
        }
    };

    const handleModalChange = (source) => {
        if (source.target) {
            const { name, value } = source.target;

            if (name === 'Client') {
                setEntityId(value);
                setEntityType(name);
            } else {
                if (name === 'Currency' && value !== 'Portfolio') {
                    setCurrencyCode(value);
                    setCurrency(value);
                    setTargetAllocCurrency(value);
                }

                if (name === 'reportDate') {
                    setReportDate(value);
                }
            }
            return;
        }

        setSelectedPortfolios(source);
        setEntityType('Portfolio');
        const entityIds = source.map((src) =>
            src.entity_id ? src.entity_id : 0
        );

        if (source.length) {
            setEntityId(entityIds);
        } else {
            setEntityId('portfolio');
        }
    };

    const getDefaultPacingStrategy = (
        investmentType,
        assetClass,
        sector,
        subSector
    ) => {
        let foundStrat = omniStrategyMapping.find(
            (row) =>
                investmentType === row.investment_type &&
                assetClass === row.asset_class &&
                sector === row.sector &&
                subSector === row.sub_sector
        );

        if (!foundStrat) {
            foundStrat = omniStrategyMapping.find(
                (row) =>
                    ((investmentType === 'Primary' ||
                        investmentType === 'Open-End') &&
                        investmentType === row.investment_type &&
                        assetClass === row.asset_class &&
                        sector === row.sector) ||
                    ((investmentType === 'Secondary' ||
                        investmentType === 'Co-Investment' ||
                        investmentType === 'Direct') &&
                        investmentType === row.investment_type &&
                        assetClass === row.asset_class)
            );
        }

        return foundStrat ? foundStrat.pacing_strategy : '';
    };

    // Sync IDs
    const syncIds = (newRawData, newTableData) => {
        return newTableData.map((row) => {
            const holder = { ...row };
            const matchingRecord = [...newRawData].find((matchingRow) => {
                if (row.investmentId) {
                    return row.investmentId === matchingRow.investmentId;
                } else {
                    return row.Investment === matchingRow.Investment;
                }
            });

            holder.Id = matchingRecord.Id;

            return holder;
        });
    };

    const handleData = async (data) => {
        if (!data) return;
        let newRawData;
        let newTableData;

        const rawDataMappedToStrat = data.map((datum) => {
            const datumCopy = { ...datum };

            if (!datumCopy.PacingStrategy) {
                datumCopy.PacingStrategy = getDefaultPacingStrategy(
                    datum.InvestmentType,
                    datumCopy.AssetClass,
                    datumCopy.Sector,
                    datum.SubSector
                );
            }

            return datumCopy;
        });

        const tableDataMappedToStrat = data.map((datum) => {
            const datumCopy = { ...datum };

            if (!datumCopy.PacingStrategy) {
                datumCopy.PacingStrategy = getDefaultPacingStrategy(
                    datum.InvestmentType,
                    datumCopy.AssetClass,
                    datumCopy.Sector,
                    datum.SubSector
                );
            }

            return datumCopy;
        });

        if (appendExistingCommitments) { 
            newRawData = rawData;
            newTableData = rawData;
        } else {
            newRawData = [];
            newTableData = [];
        }

        newRawData = [...newRawData, ...rawDataMappedToStrat];
        newTableData = [...newTableData, ...tableDataMappedToStrat];

        const formattedTableData = syncIds(
            newRawData,
            newTableData,
        );

        await resetGlobalData(newRawData, formattedTableData, keepCustomParams);
    };

    const checkOmniInputs = () => {
        return !(
            entityId === '' ||
            entityType === '' ||
            reportDate === '' ||
            currency === ''
        );
    };

    const toggleFwdNav = () => {
        const newForwardNav = useForwardNav || false;

        setUseForwardNav(!newForwardNav);
    };

    const retrieveOmniDataAssets = async (portEntityId, portEntityType) => {
        const params = {
            entity_id: portEntityId,
            entity_type: portEntityType,
        };

        try {
            const response = await getOmniData(
                'reporting/investment-data',
                params
            );
            setIsOmniImport(true);
            return response;
        } catch (error) {
            console.log(error);
            return undefined;
        }
    };

    const formatOmniData = async (data, portEntityId, portEntityType) => {
        const omniAssets = await retrieveOmniDataAssets(
            portEntityId,
            portEntityType
        );

        if (!omniAssets || !data) return '';

        // Get client attributes
        let response = [];
        if (entityType === 'Portfolio') {
            const clientList = await getOmniData('client-organizations/all');
            const foundClient = clientList.find(
                ({ name }) => name === omniAssets[0]['Client Full Name']
            );
            if (foundClient) {
                response = await getOmniData('client-attributes/all', {
                    client_organization_id: foundClient.id,
                });
            }
        } else {
            response = await getOmniData('client-attributes/all', {
                client_organization_id: entityId,
            });
        }
        // prevent rerender while rendering error with ag-grid
        if (firstCall) {
            await setAttributes(response);
            await setFirstCall(false);
        }

        if (currency === 'Portfolio') {
            const portfolioCurrency = omniAssets[0]['Portfolio Currency'];
            setCurrencyCode(portfolioCurrency);
            setTargetAllocCurrency(portfolioCurrency);
        }

        const fundData = [];
        const formattedData = data
            .filter(
                (datum) =>
                    datum['Investment ID'] != null &&
                    datum['Portfolio Name'] !== 'Total' &&
                    !datum['Fund Name'].includes('Fees')
            )
            .map((filteredDatum, index) => {
                // Match the portfolio data with its asset data
                const currentAsset = omniAssets.filter(
                    (asset) =>
                        asset['Investment ID'] ===
                        filteredDatum['Investment ID']
                )[0];

                const {
                    'Fund Vintage Year': vintage,
                    'SPI Fund Asset Class': assetClass,
                    'Investment Type': investmentType,
                    'SPI Fund Sector': sector,
                    'SPI Fund Sub-Sector': subSector,
                    'SPI Fund Region': geography,
                } = currentAsset || '';

                // Create the row of data for tableData
                const row = {
                    Portfolio: filteredDatum['Portfolio Name'],
                    PortfolioId: portEntityId,
                    Investment: filteredDatum['Fund Alias'],
                    PacingStrategy: getDefaultPacingStrategy(
                        investmentType,
                        assetClass,
                        sector,
                        subSector
                    ),
                    Vintage: vintage,
                    ClosingDate: filteredDatum['Closing Date'],
                    AssetClass: assetClass,
                    InvestmentType: investmentType,
                    Sector: sector,
                    SubSector: subSector,
                    Geography: geography,
                    Commitment: Math.round(filteredDatum['Current Commitment']),
                    Contributions: Math.round(filteredDatum.Contributions),
                    Distributions: Math.round(filteredDatum.Distributions),
                    AdjNAV: Math.round(filteredDatum.NAV),
                    Unfunded: Math.round(filteredDatum.Unfunded),
                    IRR: Math.round(filteredDatum.IRR * 100),
                    TVM: filteredDatum.TVPI,
                    Id: uuidv4(),
                    investmentId: filteredDatum['Investment ID'],
                    LastNavDate: filteredDatum['Last Reported NAV Date'],
                    FundStatus: filteredDatum['Active / Liquidated'],
                };

                response.forEach((attribute) => {
                    row[attribute.name] = filteredDatum[attribute.name];
                });

                // create fund data for forward nav
                if (useForwardNav && filteredDatum.NAV) {
                    const ppcfs =
                        filteredDatum.NAV - filteredDatum['Last Reported NAV'];
                    const holder = {
                        assetclass: assetClass,
                        fund_status: filteredDatum['Active / Liquidated'],
                        investmentid: filteredDatum['Investment ID'],
                        navdate: filteredDatum['Last Reported NAV Date'],
                        lastnav: filteredDatum['Last Reported NAV'],
                        ppcfs,
                        sector,
                        vintage,
                    };

                    fundData.push(holder);
                }

                return row;
            });

        try {
            // calculate forward nav
            if (useForwardNav) {
                const parameters = {
                    betas_tbl: dveBetas,
                    ccy: currencyCode,
                    entityId: portEntityId,
                    entityType,
                    funddata: fundData,
                    level: indexVersion === 'industry' ? 2 : 1,
                    rolldate: reportDate,
                };

                const results = await calcFwdNav(parameters);

                return formattedData.map((row) => {
                    const holder = row;
                    results.investmentid.forEach((value, index) => {
                        if (value === row.investmentId) {
                            holder.ForwardNAV = results.rollnav[index];
                        }
                    });

                    // default non-fund rows like 'SSG Fees' to 0
                    if (!holder.ForwardNAV) {
                        holder.ForwardNAV = 0;
                    }

                    return holder;
                });
            }
            return formattedData;
        } catch (e) {
            let text = e.responseJSON;
            if (e.status === 500) {
                text =
                    'Something went wrong. Unable to retrieve forward NAV. Please contact an administrator if the problem persists';
            }

            await Swal.fire({
                type: 'error',
                text,
                showCancelButton: false,
                confirmButtonText: 'Okay',
            });

            return formattedData.map((row) => {
                const holder = row;
                holder.ForwardNAV = 0;

                return holder;
            });
        }
    };

    const fetchInvestmentData = async (portEntityId, portEntityType) => {
        const params = {
            report_date: reportDate,
            entity_id: portEntityId,
            entity_type: portEntityType,
            currency,
        };

        const response = await getOmniData(
            'reporting/investments',
            params
        ).catch(() => {
            Swal.fire({
                type: 'error',
                html: 'Error fetching data, please try again later.',
            });
        });

        return formatOmniData(response, portEntityId, portEntityType);
    };

    const retrieveOmniData = async () => {
        const checkInputs = checkOmniInputs();

        if (checkInputs) {
            if (!appendExistingCommitments) {
                await setRawData([]);
            }

            if (entityType === 'Client') {
                Swal.showLoading();

                const newRawData = await fetchInvestmentData(
                    entityId,
                    entityType
                );
                const newTableData = await fetchInvestmentData(
                    entityId,
                    entityType
                );

                const formattedTableData = syncIds(newRawData, newTableData);

                await resetGlobalData(
                    newRawData,
                    formattedTableData,
                    keepCustomParams
                );
            } else if (selectedPortfolios.length) {
                let newRawData;
                let newTableData;
                if (appendExistingCommitments) { 
                    newRawData = rawData;
                    newTableData = rawData;
                } else {
                    newRawData = [];
                    newTableData = [];
                }

                selectedPortfolios.forEach(async (portfolio) => {
                    setTimeout(() => Swal.showLoading(), 1000);
                    const holder1 = await fetchInvestmentData(
                        portfolio.entity_id,
                        portfolio.entity_type
                    );
                    const holder2 = await fetchInvestmentData(
                        portfolio.entity_id,
                        portfolio.entity_type
                    );

                    newRawData = [...newRawData, ...holder1];
                    newTableData = [...newTableData, ...holder2];

                    const formattedTableData = syncIds(
                        newRawData,
                        newTableData
                    );

                    await resetGlobalData(
                        newRawData,
                        formattedTableData,
                        keepCustomParams
                    );
                    Swal.close();
                });
            }

            Swal.close();

            toggleModal('selectInputIsOpen');
            setFirstCall(true);
        } else {
            Swal.fire({
                type: 'error',
                html:
                    'All values are required: Report Date, Client or Portfolio, and Currency',
            });
        }
    };

    const setKeepCustomParams = () => {
        const newKeepCustomParams = keepCustomParams;

        toggleKeepCustomParams(!newKeepCustomParams);
    };

    const updateRawData = (data) => {
        const newUuid = uuidv4();

        const newRawData = rawData.map((row) => {
            const holder = row;
            if (row === data) {
                holder.Id = newUuid;

                return holder;
            }

            return holder;
        });

        setRawData(newRawData);
        setUuidToResetRemove(newUuid);
    };

    const incorrectTemplate = (message) => {
        Swal.fire({
            type: 'error',
            html: message,
        });
    };

    const setCurrencyVals = (value) => {
        setCurrency(value);
        setCurrencyCode(value);
        setTargetAllocCurrency(value)
    };

    const onSaveClick = async () => {
        await setTargetAllocCurrency(currencyCode);
        await handleSave();
    };

    /**
     * Update global currency on unmount
     */
    // TODO: may need to be added back in. currencyCode is not being set propertly for some reason
    // useEffect(() => {
    //     return () => {
    //         setTargetAllocCurrency(currencyCode);
    //     };
    // }, []);

    /**
     * Confirm valid email
     */
    useEffect(() => {
        const checkValid = async () => {
            const resp = await getOmniData('currencies/all');
            if (resp.message === 'Invalid email.') {
                setInvalidOmniEmail(true);
            }
        };
        checkValid();
    }, []);

    const excelButton = (
        <Button
            className='primary-button'
            onClick={() => toggleModal('docUploadIsOpen')}
        >
            Upload From Excel
        </Button>
    );

    const omniButton =
        userSpiClientId && !userOmniImportAccess ? (
            <></>
        ) : (
            <>
                <Button
                    className='primary-button'
                    onClick={() => toggleModal('selectInputIsOpen')}
                    disabled={isTrialUser ? true : false}
                >
                    SPI Reporting Import
                </Button>
                <IoMdRemove
                    size='30'
                    style={{
                        transform: 'rotate(90deg)',
                        marginLeft: '7px',
                        color: 'RGB(64,64,64)',
                    }}
                />
            </>
        );

    return (
        <Container fluid>
            <PageTitle title='Existing Commitments' handleSave={onSaveClick} />
            <div />
            <Row className='m-0 p-3 bg-white section-border'>
                <Col className='text-left' md={6}>
                    {excelButton}
                    {omniButton}
                    <Button
                        className='primary-button'
                        onClick={() => toggleModal('loadFromDbIsOpen')}
                    >
                        Load Saved Pacing
                    </Button>
                </Col>
                <DocUploadModal
                    isOpen={docUploadIsOpen}
                    handleData={handleData}
                    incorrectTemplate={incorrectTemplate}
                    setCurrency={setCurrencyVals}
                    toggleModal={() => toggleModal('docUploadIsOpen')}
                    isTrialUser={isTrialUser}
                    keepCustomParams={keepCustomParams}
                    setKeepCustomParams={setKeepCustomParams}
                />
                <LoadFromDatabase
                    open={loadFromDbIsOpen}
                    toggleModal={() => toggleModal('loadFromDbIsOpen')}
                    setLoadedSavedPacing={setLoadedSavedPacing}
                />
                <OmniImport
                    handleModalChange={handleModalChange}
                    indexVersion={indexVersion || 'public'}
                    open={selectInputIsOpen}
                    retrieveOmniData={retrieveOmniData}
                    toggleFwdNav={toggleFwdNav}
                    toggleModal={() => toggleModal('selectInputIsOpen')}
                    useForwardNav={useForwardNav || 0}
                    keepCustomParams={keepCustomParams}
                    setKeepCustomParams={setKeepCustomParams}
                />
                <ReportDateModal
                    isOpen={reportDateModalIsOpen}
                    toggleModal={() =>
                        toggleModal(
                            'reportDateModalIsOpen',
                            '/target-allocation/pe'
                        )
                    }
                />
            </Row>
            <Row>
                <Col md={12}>
                    <TableDataGrid
                        updateRawData={updateRawData}
                        //uuidToResetRemove={uuidToResetRemove}
                        getDefaultPacingStrategy={getDefaultPacingStrategy}
                        reportDateModalIsOpen={reportDateModalIsOpen}
                        setReportDateModalIsOpen={setReportDateModalIsOpen}
                        loadedSavedPacing={loadedSavedPacing}
                    />
                </Col>
            </Row>
            <BottomNav nextTarget='/parameter-lab' />
        </Container>
    );
};

export default Landing;
