import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

type Props = {
    options: Array,
    optionKey: String,
    strategies: Object,
    handlePortfolioChange: Function,
};

const PortfolioDropdown = ({ options, optionKey, strategies }: Props) => {
    const foundStrat = strategies.find(({ strategy: strat }) => {
        return strat === optionKey;
    })
    const portfolios = options.map((current) => {
        return (
            <option key={current} value={current}>
                {current}
            </option>
        );
    });

    return (
        <Row style={{marginTop: '10px'}}>
            <Col sm={4}>
                <Label>{optionKey}</Label>
            </Col>
            <Col sm={8}>
                <Input
                    name={optionKey}
                    id={optionKey}
                    type='select'
                    defaultValue ={foundStrat.portfolio}
                >
                    {portfolios}
                </Input>
            </Col>
        </Row>
    );
};

export default PortfolioDropdown;
