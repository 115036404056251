//@flow
import React from 'react';
import { IconButton, Slide, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const Transition = (props) => <Slide {...props} direction='left' />;

type Props = {
    autoHideDuration: number,
    clearSnackbar: Function,
    snackbarMessage: string,
};

// TODO: Move this to techint
const QuickSnackbar = ({
    snackbarMessage = null,
    clearSnackbar,
    autoHideDuration = 3000,
}: Props) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={!!snackbarMessage}
            autoHideDuration={autoHideDuration}
            onClose={clearSnackbar}
            message={snackbarMessage}
            TransitionComponent={Transition}
            action={
                <IconButton
                    onClick={clearSnackbar}
                    size='small'
                    style={{ color: 'white' }}
                >
                    <Close size='small' />
                </IconButton>
            }
        />
    );
};

export default QuickSnackbar;
