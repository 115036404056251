const uuidv4 = require('uuid/v4');

/**
 * Iterate through saved tableData and reassign Id
 * by creating a unique Id.
 *
 * @param {*} tableData
 * @param {*} fundSliderValues
 */
const normalizeTableData = (tableData, fundSliderValues) => {
    const newFundSliderValues = [];
    const newTableData = tableData.map((fund) => {
        const tableRowHolder = { ...fund };
        const newId = uuidv4();
        const foundSliders = fundSliderValues.find(
            ({ investmentId, investment }) => {
                if(investmentId) {
                return investmentId === fund.investmentId;
            } else {
                return investment === fund.Investment
            }}
        );

        if (foundSliders) {
            foundSliders.id = newId;
            newFundSliderValues.push(foundSliders);
        }
        tableRowHolder.Id = newId;

        return tableRowHolder;
    });

    return {
        tableData: newTableData,
        fundSliderValues: newFundSliderValues,
    };
};

export default normalizeTableData;
