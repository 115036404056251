import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import swal2 from 'sweetalert2';
import { getOmniData, getUploadTemplate } from 'api';
import { LandingContext } from 'context';
import XLSX from '@sheet/edit';
import ExcelIcon from 'images/Excel2019Icon.svg';
import { DocUpload } from './components';
import {  KeepCustomParams } from '../OmniImport/components';

type Props = {
    handleData: Function,
    incorrectTemplate: Function,
    isOpen: boolean,
    toggleModal: Function,
    setCurrency: Function,
};

export default function DocUploadModal({
    handleData,
    incorrectTemplate,
    isOpen,
    toggleModal,
    setCurrency,
    isTrialUser,
    keepCustomParams,
    setKeepCustomParams,
}: Props) {
    const [dateRequired, setDateRequired] = useState(false);
    const [currencies, setCurrencies] = useState([]);

    const {
        reportDate,
        setReportDate,
        appendExistingCommitments,
        setAppendExistingCommitments,
        tableData,
    } = useContext(LandingContext);

    const verifyDateSet = async (requiredOverride = false) => {
        if (
            (requiredOverride === true || dateRequired) &&
            (reportDate === '' || reportDate.length < 10)
        ) {
            return swal2.fire({
                type: 'warning',
                html: 'You must set a report date',
            });
        }
        toggleModal();
        return undefined;
    };

    const handleFileUploaded = async () => {
        await setDateRequired(true);
        return verifyDateSet(true);
    };

    const createCurrencyDropdown = async () => {
        const results = await getOmniData('currencies/codesAndSymbols');

        if (!results) {
            swal2.fire({
                type: 'error',
                html:
                    'Something went wrong trying to load currencies. ' +
                    'Please contact an administrator if the problem persists',
            });
            return;
        }

        if (results.message === 'Invalid email.') {
            return;
        }

        const options = [];
        await results.forEach((e) => {
            options.push(
                <option key={e.code} value={e.code}>
                    {e.code}
                </option>
            );
        });

        setCurrencies(options);
    };

    const downloadTemplate = async () => {
        const response = await getUploadTemplate();
        let binary = '';
        const responseArray = new Uint8Array(response);
        const length = responseArray.byteLength;
        for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(responseArray[i]);
        }

        const retrievedTemplate = XLSX.read(binary, {
            type: 'binary',
            template: true,
        });
        XLSX.writeFile(retrievedTemplate, 'ExcelUploadTemplate.xlsx', {
            template: true,
        });
    };

    const handleAppendChange = async (value) => {
        value === 'append'
            ? setAppendExistingCommitments(true)
            : setAppendExistingCommitments(false);
    };

    useEffect(() => {
        if (currencies.length === 0) {
            createCurrencyDropdown();
        }
    });

    const handleInputChange = (event) => {
        setCurrency(event.target.value);
    };

    const noStyle = {
        backgroundColor: 'transparent',
        border: '0px none',
        color: '#000',
        marginLeft: '0px',
        paddingLeft: '0px',
        boxShadow: 'none',
    };

    return (
        <Modal isOpen={isOpen} toggle={verifyDateSet}>
            <ModalHeader toggle={verifyDateSet}>Upload File</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <Label>
                            <strong>Report Details</strong>
                        </Label>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Report Date</Label>
                            <Input
                                value={reportDate}
                                onChange={(e) => setReportDate(e.target.value)}
                                type='date'
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Currency</Label>
                            <Input
                                type='select'
                                required
                                id='currencies'
                                name='currency'
                                onChange={handleInputChange}
                                className='selectInputStyles'
                                defaultValue='USD'
                            >
                                <option disabled value=''>
                                    -select-
                                </option>
                                {currencies}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {tableData.length > 0 && (
                    <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                        <Row>
                            <Col style={{ marginBottom: '10px' }} md={12}>
                                <div>
                                    <strong>Existing Data</strong>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className='square border rounded w-50'
                            style={{ marginLeft: '5px' }}
                        >
                            <Col md={12}>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            inline='true'
                                            id='override'
                                            type='radio'
                                            value='override'
                                            checked={
                                                appendExistingCommitments ===
                                                false
                                            }
                                            onChange={(e) =>
                                                handleAppendChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        Override (Default)
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col style={{ marginBottom: '10px' }} md={12}>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            inline='true'
                                            id='append'
                                            type='radio'
                                            value='append'
                                            checked={
                                                appendExistingCommitments ===
                                                true
                                            }
                                            onChange={(e) =>
                                                handleAppendChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        Append
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                )}
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={12}>
                        {tableData.length > 0 && !appendExistingCommitments && (
                            <KeepCustomParams
                                keepCustomParams={keepCustomParams}
                                setKeepCustomParams={setKeepCustomParams}
                            />
                        )} 
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={12}>
                        <Button
                            href='#'
                            onClick={downloadTemplate}
                            style={noStyle}
                        >
                            <img
                                height='25'
                                width='25'
                                src={ExcelIcon}
                                alt='Excel icon'
                            />
                            Download Template
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <DocUpload
                                handlerFromParent={handleData}
                                incorrectTemplate={incorrectTemplate}
                                handleFileUploaded={handleFileUploaded}
                                docType='excel'
                                isTrialUser={isTrialUser}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button className='secondary-button' onClick={toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
