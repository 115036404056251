// @flow
import $ from 'jquery';

/* eslint-disable prefer-destructuring */
// For some reason can't destructure process.env
// Has to be declared together.
const env = process.env;
/* eslint-disable prefer-destructuring */
export const quantUrl = env.REACT_APP_QUANT_URL;
export const appHeaderUrl = env.REACT_APP_APP_HEADER || 'https://static.stepstonegroup.com'; // temporary
export const redirectUrl = env.REACT_APP_REDIRECT_URL;
export const omniToken = env.REACT_APP_OMNI_TOKEN;

export const apiCall = (
    url: string | URL,
    method: string,
    data: any = null,
    withCredentials: boolean = true
) => {
    const ajax = {
        url,
        method,
        crossDomain: true,
        withCredentials,
        xhrFields: {
            withCredentials,
        },
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data: '',
        dataType: 'json',
    };

    if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
        const jsonData = JSON.stringify(data).replace(/\?\?/g, '?');

        if (data) {
            ajax.data = jsonData;
            ajax.dataType = 'json';
        }
    } else {
        ajax.data = data;
    }
    return $.ajax(ajax);
};

export const fileUpload = (url: string, name: string, file: any) => {
    const formData = new FormData();
    formData.append(name, file);

    return $.ajax({
        method: 'POST',
        url,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
    });
};
