import { trimSliderValues } from 'utils';

function setFundSliderWithScenario(underwritingPset, fund) {
    const { values, valuesHigh, valuesLow } = { ...fund };
    let holder = {};

    if (underwritingPset.scenario === 'Base') {
        holder = values || {};
    } else if (underwritingPset.scenario === 'High') {
        holder = valuesHigh || {};
    } else if (underwritingPset.scenario === 'Low') {
        holder = valuesLow || {};
    }

    holder.paidIn = underwritingPset.paidIn / 100;
    holder.irr = underwritingPset.irr / 100;
    holder.yld = underwritingPset.yld / 100;
    holder.fundLife = underwritingPset.fundLife;
    holder.bow = underwritingPset.bow;
    holder.coinvestFlag = underwritingPset.coinvestFlag;
    holder.rc1 = underwritingPset.rc1 / 100;
    holder.rc2 = underwritingPset.rc2 / 100;
    holder.rc3 = underwritingPset.rc3 / 100;
    holder.rc4 = underwritingPset.rc4 / 100;
    holder.rc5 = underwritingPset.rc5 / 100;
    holder.commitQtr = underwritingPset.commitQtr;
    holder.contribTiming = underwritingPset.contribTiming;
    holder.case = underwritingPset.scenario;
    holder.strategy = underwritingPset.strategy;
    holder.model = 'Yale';

    return holder;
}

function updateRelevantScenario(scenario, fund, newValues) {
    const fundHolder = { ...fund };

    if (scenario === 'Base') {
        fundHolder.values = newValues;
    } else if (scenario === 'High') {
        fundHolder.valuesHigh = newValues;
    } else if (scenario === 'Low') {
        fundHolder.valuesLow = newValues;
    }

    return fundHolder;
}

const formatDataToSetHelper = (
    commitmentType,
    underwritingDataset,
    fundSliderValues,
    sliderValues,
    checkForRenamedStrategy,
    strategyAllocation,
    getStrategyName,
) => {
    let name = '';
    let newSliders = [];
    if (commitmentType === 'new') {
        name = 'sliderValues';
        newSliders = sliderValues.map((slider) => {
            const foundSlider = underwritingDataset
                .find((row) => slider.strategy === getStrategyName(row.strategy) && slider.case === row.scenario)
            if (foundSlider) {
                return {
                    ...slider,
                    fundLife: foundSlider.fundLife,
                    bow: foundSlider.bow,
                    paidIn: foundSlider.paidIn / 100,
                    irr: foundSlider.irr / 100,
                    rc1: foundSlider.rc1 / 100,
                    rc2: foundSlider.rc2 / 100,
                    rc3: foundSlider.rc3 / 100,
                    rc4: foundSlider.rc4 / 100,
                    rc5: foundSlider.rc5 / 100,
                    yld: foundSlider.yld / 100,
                };
            }
            return slider;
        });
    } else {
        name = 'fundSliderValues';

        fundSliderValues.forEach((fund) => {
            let fundHolder = { ...fund };

            const foundFunds = underwritingDataset.filter(
                ({ id }) => id === fund.id
            );

            if (foundFunds) {
                foundFunds.forEach((row) => {
                    const newValues = setFundSliderWithScenario(
                        row,
                        fundHolder
                    );

                    fundHolder = updateRelevantScenario(
                        row.scenario,
                        fundHolder,
                        newValues
                    );
                });
            }

            newSliders.push(fundHolder);
        });
    }

    return {
        name,
        data: newSliders,
    };
};

export default formatDataToSetHelper;
