const resetSelectedRowHandler = (
    tableData,
    tableRowToResetRemove,
    rawData,
    uuidToResetRemove
) => {
    let resetRow = {};
    rawData.forEach((row) => {
        if (row.Id === uuidToResetRemove) {
            resetRow = row;
        }
    });

    const newTableData = tableData.map((row) => {
        if (row === tableRowToResetRemove) {
            return resetRow;
        }

        return row;
    });

    return newTableData;
};

export default resetSelectedRowHandler;
