import React from 'react';
import {
    Chart,
    ChartAxisDefaults,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartLegend,
    ChartLegendItem,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
} from '@progress/kendo-react-charts';
import { colorCombos } from 'utils';
import { tooltipRender } from '../sharedComponents';

type Props = {
    plotGraph: Object,
    contribDataPoints: Array<number>,
    distribDataPoints: Array<number>,
    baseContribDataPoints: Array<number>,
    baseDistribDataPoints: Array<number>,
    invYield: Array<number>,
    contribUncertainty: Array<number>,
    distribUncertainty: Array<number>,
    years: Array<number>,
};

const NewCommitmentsGraph = ({
    plotGraph,
    contribDataPoints,
    distribDataPoints,
    baseContribDataPoints,
    baseDistribDataPoints,
    invYield,
    contribUncertainty,
    distribUncertainty,
    years,
}: Props) => {
    return (
        <Chart pannable={false} zoomable={false}>
            <ChartLegend visible position='bottom' orientation='horizontal'>
                <ChartLegendItem visual={plotGraph} />
            </ChartLegend>
            <ChartAxisDefaults majorGridLines={false} />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    categories={years}
                    labels={{ step: 20 }}
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem
                    title={{ text: 'Cumulative Cash Flows' }}
                    labels={{ format: '{0}%' }}
                />
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem
                    name='Contributions'
                    type='line'
                    color={colorCombos[0].primary}
                    markers={{ visible: false }}
                    data={contribDataPoints}
                />
                <ChartSeriesItem
                    name='Distributions'
                    type='line'
                    color={colorCombos[1].primary}
                    markers={{ visible: false }}
                    data={distribDataPoints}
                />
                <ChartSeriesItem
                    name='Base Contributions'
                    type='line'
                    dashType='longDash'
                    color={colorCombos[0].primary}
                    markers={{ visible: false }}
                    data={baseContribDataPoints}
                />
                <ChartSeriesItem
                    name='Base Distributions'
                    type='line'
                    dashType='longDash'
                    color={colorCombos[1].primary}
                    markers={{ visible: false }}
                    data={baseDistribDataPoints}
                />
                <ChartSeriesItem
                    name='Yield'
                    type='line'
                    color={colorCombos[2].primary}
                    markers={{ visible: false }}
                    data={invYield}
                />
                <ChartSeriesItem
                    id='contribUncertainty'
                    name='contribUncertainty'
                    type='rangeArea'
                    data={contribUncertainty}
                    color={colorCombos[0].primary}
                    fromField='min'
                    toField='max'
                />
                <ChartSeriesItem
                    id='distribUncertainty'
                    name='distribUncertainty'
                    type='rangeArea'
                    data={distribUncertainty}
                    color={colorCombos[1].primary}
                    fromField='min'
                    toField='max'
                />
            </ChartSeries>
            <ChartTooltip shared render={tooltipRender} />
        </Chart>
    );
};

export default NewCommitmentsGraph;
