import React, { Component } from 'react';
import { formatIntWithCommas } from 'utils';

type Props = {
    value: string | number,
    decimal: number,
    style: any,
    useForwardNav: boolean,
};

export default class TotalsRowRenderer extends Component<Props> {
    render() {
        const { value, decimal, style, useForwardNav } = this.props;
        if (
            value === '' ||
            value === undefined ||
            value === 'Total' ||
            [
                'Total Portfolio Net Cash Flows',
                'Total Portfolio Growth p.a. (%)',
                'Portfolio AuM',
                'Liquid Assets',
            ].includes(value)
        ) {
            return <span style={style}>{value}</span>;
        }

        if (useForwardNav === false) {
            return <span style={style}>N/A</span>;
        }

        const unformattedNumber = value;
        const numberOfDecimals = typeof decimal !== 'undefined' ? decimal : 0;
        const formattedNumber = formatIntWithCommas(
            unformattedNumber,
            numberOfDecimals
        );

        return <span style={style}>{formattedNumber}</span>;
    }
}
