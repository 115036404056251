import React from 'react';
import { Container, Row } from 'reactstrap';

const env = process.env;
/* eslint-disable prefer-destructuring */
const quantUrl = env.REACT_APP_QUANT_URL || '';
const redirectUrl = env.REACT_APP_REDIRECT_URL || '';
const omniUrl = env.REACT_APP_OMNI_URL || '';
const bugsnagKey = env.BUGSNAG_API_KEY || env.REACT_APP_BUGSNAG || '';
const appEnv = env.REACT_APP_ENV || '';
const agGridKey = env.REACT_APP_AG_GRID_LICENSE_KEY || '';


const StaticEnvironment = () => {
    return (
        <Container>
            <Row>{`REACT_APP_QUANT_URL: ${quantUrl}`}</Row>
            <Row>{`REACT_APP_REDIRECT_URL: ${redirectUrl}`}</Row>
            <Row>{`REACT_APP_OMNI_URL: ${omniUrl}`}</Row>
            <Row>{`BUGSNAG_API_KEY: ${bugsnagKey}`}</Row>
            <Row>{`REACT_APP_ENV: ${appEnv}`}</Row>
            <Row>{`REACT_APP_AG_GRID_LICENSE_KEY: ${agGridKey}`}</Row>
        </Container>
    );
};

export default StaticEnvironment;
