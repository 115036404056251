import React from 'react';
import '../Switch.sass';
import { Label } from 'reactstrap/es';

type Props = {
    isOn: boolean,
    handleToggle: Function,
    leftStyle: {},
    rightStyle: {},
    leftLabel: string,
    rightLabel: string,
};

// export default class Switch extends Component<Props> {
const Switch = ({isOn, handleToggle, leftLabel, leftStyle={}, rightStyle={}, rightLabel}: Props) => {
    const handleKeyUp = (event: SyntheticKeyboardEvent<HTMLBodyElement>) => {
        const { handleToggle } = this.props;

        if (event.key === 'Enter') {
            handleToggle(event);
        }
    };

    const leftStyles = {
        marginRight: '45px',
        fontWeight: 'bold',
    };

    const rightStyles = {
        marginLeft: '15px',
        fontWeight: 'bold',
    };

    return (
        <div className='togglePlacement'>
            <Label style={{...leftStyles, ...leftStyle}}>{leftLabel}</Label>
            <div style={{width: '60px', display: 'inline-block'}}>
                <div
                    className={[
                        'switch',
                        isOn ? 'switch_is-on' : 'switch_is-off',
                    ].join(' ')}
                    onClick={handleToggle}
                    onKeyUp={handleKeyUp}
                    role='switch'
                    aria-checked={isOn}
                    tabIndex={0}
                    >
                    <div
                        className={[
                            'toggle-button',
                            isOn
                            ? 'toggle-button_position-right'
                            : 'toggle-button_position-left',
                        ].join(' ')}
                        />
                </div>
            </div>
            <Label style={{...rightStyles, ...rightStyle}}>{rightLabel}</Label>
        </div>
    );
}

export default Switch;