import styled from 'styled-components';
import { Error } from '@material-ui/icons';

const FlexCenterDiv = styled.div`
    color: #dc3545;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;

export const SmallErrorIcon = styled(Error)`
    font-size: 16px;
`;

export default FlexCenterDiv;
