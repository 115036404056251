import React from 'react';
import { CircularProgress } from '@material-ui/core';

/**
 * @param loading
 * @param endAdornment
 * @returns {*}
 */
const addLoadingEndAdornment = (loading, endAdornment) => (
    <>
        {loading ? <CircularProgress color='inherit' size={20} /> : null}
        {endAdornment}
    </>
);

export default addLoadingEndAdornment;
