const renderTotalsHandler = (tableData) => {
    let commitment = 0;
    let contributions = 0;
    let distributions = 0;
    let nav = 0;
    let unfunded = 0;
    let forwardNav = 0;
    tableData.forEach((row) => {
        commitment += parseFloat(row.Commitment);
        contributions += parseFloat(row.Contributions);
        distributions += parseFloat(row.Distributions);
        nav += parseFloat(row.AdjNAV);
        unfunded += parseFloat(row.Unfunded);
        forwardNav += parseFloat(row.ForwardNAV);
    });

    return [
        {
            Investment: 'Total',
            Vintage: '',
            AssetClass: '',
            InvestmentType: '',
            Sector: '',
            SubSector: '',
            Geography: '',
            Commitment: commitment,
            Contributions: contributions,
            Distributions: distributions,
            AdjNAV: nav,
            ForwardNAV: forwardNav,
            LastNavDate: '',
            Unfunded: unfunded,
            IRR: '',
            TVM: '',
        },
    ];
};

export default renderTotalsHandler;
