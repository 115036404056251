const colorCombos = [
    {
        // blue
        primary: '#007899',
        secondary: '#fff',
    },
    {
        // grey
        primary: '#BDBFBF',
        secondary: '#fff',
    },
    {
        // green
        primary: '#78A300',
        secondary: '#fff',
    },
    {
        // orange
        primary: '#F7AD21',
        secondary: '#fff',
    },
    {
        // yellow
        primary: '#F7E308',
        secondary: '#000',
    },
    {
        // blue #2
        primary: '#268fb3',
        secondary: '#fff',
    },
    {
        // grey #2
        primary: '#a4a5a8',
        secondary: '#fff',
    },
    {
        // green #2
        primary: '#95b832',
        secondary: '#fff',
    },
    {
        // orange #2
        primary: '#f29121',
        secondary: '#fff',
    },
    {
        // yellow #2
        primary: '#f9ea4a',
        secondary: '#000',
    },
    {
        // blue #3
        primary: '#42b2d7',
        secondary: '#fff',
    },
    {
        // grey #3
        primary: '#bebfc1',
        secondary: '#fff',
    },
    {
        // green #3
        primary: '#a8cc43',
        secondary: '#fff',
    },
    {
        // orange #3
        primary: '#ffae54',
        secondary: '#fff',
    },
    {
        // yellow #3
        primary: '#faee6b',
        secondary: '#000',
    },
    {
        // blue #4
        primary: '#81cbe4',
        secondary: '#fff',
    },
    {
        // grey #4
        primary: '#d8d9da',
        secondary: '#fff',
    },
    {
        // green #4
        primary: '#b5d460',
        secondary: '#fff',
    },
    {
        // orange #4
        primary: '#ffc688',
        secondary: '#fff',
    },
    {
        // yellow #4
        primary: '#fbf28c',
        secondary: '#000',
    },
    {
        // light grey
        primary: '#f8f8f8',
        secondary: '#fff',
    },
];

export default colorCombos;

export const getColorsByKey = (key) => {
    switch (key) {
        case 'blue':
            return colorCombos[0];
        case 'grey':
            return colorCombos[1];
        case 'green':
            return colorCombos[2];
        case 'orange':
            return colorCombos[3];
        case 'yellow':
            return colorCombos[4];
        default:
            return colorCombos[0];
    }
};
