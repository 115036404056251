import React, { Component } from 'react';
import Modal from 'reactstrap/es/Modal';
import ModalHeader from 'reactstrap/es/ModalHeader';
import ModalBody from 'reactstrap/es/ModalBody';
import ModalFooter from 'reactstrap/es/ModalFooter';
import Button from 'reactstrap/es/Button';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { TableDataColumnDefinitions, FrameworkComponents } from '../components';

type Props = {
    isOpen: boolean,
    rawData: Array<any>,
    toggleModal: Function,
    resetSelectedRow: Function,
};

/**
 * Create a simple non-editable table
 * containing raw version of uploaded/selected data
 */
class ResetRowModal extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            overlayLoadingTemplate: '<span>Loading Pacings...</span>',
            defaultColDef: {
                resizable: true,
                sortable: true,
                filter: true,
                editable: false,
            },
        };
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.setColumnVisible('Id', false);
    };

    onSelectionChanged = () => {
        const { resetSelectedRow } = this.props;
        const selectedRows = this.gridApi.getSelectedRows();

        resetSelectedRow(selectedRows[0]);
    };

    render() {
        const { defaultColDef, overlayLoadingTemplate } = this.state;
        const { isOpen, toggleModal, rawData: data } = this.props;
        const columnDefs = TableDataColumnDefinitions();
        const frameworkComponents = FrameworkComponents();

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                style={{ minWidth: '1200px' }}
            >
                <ModalHeader toggle={toggleModal}>Reset Row</ModalHeader>
                <ModalBody>
                    <div>
                        <strong>
                            We were unable to locate the correct fund you
                            selected. Please select the fund you would like to
                            reset.
                        </strong>
                    </div>
                    <div
                        className='ag-theme-balham'
                        style={{
                            position: 'relative',
                            height: '500px',
                            width: '100%',
                        }}
                    >
                        <AgGridReact
                            animateRows
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            components={frameworkComponents}
                            onGridReady={this.onGridReady}
                            overlayLoadingTemplate={overlayLoadingTemplate}
                            pagination
                            paginationPageSize={50}
                            rowData={data}
                            rowSelection='single'
                            onSelectionChanged={this.onSelectionChanged}
                            lockVisible
                            suppressExcelExport
                            suppressCsvExport
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='secondary'
                        className='mt-0'
                        onClick={toggleModal}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ResetRowModal;
