import React from 'react';
import { Col, Input, Label } from 'reactstrap';

type Props = {
    viewHighLow: Boolean,
    handleHighLowToggle: Function,
};

const HighLowCasesCheckbox = ({ viewHighLow, handleHighLowToggle }: Props) => {
    const styles = {
        marginLeft: '15px',
    };

    return (
        <Col md={12} style={styles}>
            <Input
                type='checkbox'
                value
                name='viewHistorical'
                checked={viewHighLow === true}
                onChange={handleHighLowToggle}
            />
            <Label sm={10}>High/Low Cases</Label>
        </Col>
    );
};

export default HighLowCasesCheckbox;
