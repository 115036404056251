import React from 'react';
import {
    Chart,
    ChartAxisDefaults,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartLegend,
    ChartLegendItem,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
} from '@progress/kendo-react-charts';
import { colorCombos } from 'utils';
import { SimpleTooltip } from 'ParameterLab/components/sharedComponents';

type Props = {
    plotGraph: Object,
    dataPoints: Array<number>,
    years: Array<number>,
    navUncertainty: Array<number>,
};

const NewCommitmentsGraph = ({
    plotGraph,
    dataPoints,
    navUncertainty,
    years,
}: Props) => (
    <Chart pannable={false} zoomable={false}>
        <ChartLegend visible position='bottom' orientation='horizontal'>
            <ChartLegendItem visual={plotGraph} />
        </ChartLegend>
        <ChartAxisDefaults majorGridLines={false} />
        <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={years} labels={{ step: 20 }} />
        </ChartCategoryAxis>
        <ChartValueAxis>
            <ChartValueAxisItem
                title={{ text: 'Net Asset Value' }}
                labels={{ format: '{0}%' }}
            />
        </ChartValueAxis>
        <ChartSeries>
            <ChartSeriesItem
                name='Net Asset Value'
                type='line'
                color={colorCombos[3].primary}
                markers={{ visible: false }}
                style={{
                    style: 'smooth',
                }}
                data={dataPoints}
            />
            <ChartSeriesItem
                id='navUncertainty'
                name='Uncertainty'
                type='rangeArea'
                data={navUncertainty}
                color={colorCombos[3].primary}
                fromField='min'
                toField='max'
            />
        </ChartSeries>
        <ChartTooltip render={SimpleTooltip} />
    </Chart>
);

export default NewCommitmentsGraph;
