// @flow
import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import FlexCenterDiv from './styles';

// TODO: Move this to techint
type FormikErrorHelperProps = {
  name: string,
  errors: Object,
  touched: Object,
};

const FormikErrorHelper = ({
 name,
 errors,
 touched,
}: FormikErrorHelperProps) => {
  if (
    !Object.hasOwnProperty.call(errors, name) ||
    !Object.hasOwnProperty.call(touched, name)
  ) {
    return <></>;
  }

  return (
    <FlexCenterDiv>
      <ErrorIcon />
      <div>{errors[name]}</div>
    </FlexCenterDiv>
  );
};

export default FormikErrorHelper;
