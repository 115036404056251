import React, {
    useCallback,
    useContext,
    useEffect,
    useRef, 
    useState,
} from 'react';
import { Label, Table, UncontrolledTooltip, Col, Row  } from 'reactstrap';
import { numberFormat } from 'utils';
import { IoMdInformationCircleOutline as InformationIcon } from 'react-icons/io';
import {
    PacingAnalysisContext,
} from 'context';
import { AgGridReact } from 'ag-grid-react';
//import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import { ChangeDetectionStrategyType } from 'ag-grid-react';
import {
    AverageWeightedColumnDefinitions,
} from './components';

type PortReturns = {
    'Net IRR': number,
    'Net IRR High': number,
    'Net IRR Low': number,
    'Net TVM': number,
    'Net TVM High': number,
    'Net TVM Low': number,
    'Overall TVM': number,
    'Overall TVM High': number,
    'Overall TVM Low': number,
};

type PortfolioAge = {
    portfolio_age_by_nav: Array<number>,
    portfolio_age_by_total_exposure: Array<number>,
};

type Props = {
    portReturns: PortReturns,
    portfolioAge: PortfolioAge
};


function roundAndLabel(portfolioArray, currYear, label){
    let formattedPortfolio = {};

    portfolioArray.forEach((weightedAveAge) => {
        formattedPortfolio[currYear] = weightedAveAge.toFixed(1);
        currYear++;
    });
    formattedPortfolio['ageInYears'] = label;

    return formattedPortfolio;
}

function formatPortfolioAge(
    portfolioAgeData,
    startYear,
    fiscalYearPlusOne
) {
    let formattedPortfolioAge = [];
    let formattedAgeByNav = {};
    let formattedAgeTotalExpo = {};
    let currentYear = fiscalYearPlusOne ? startYear + 1 : startYear;
    // round values to one decimal and label the values
    formattedAgeByNav = roundAndLabel(portfolioAgeData['portfolio_age_by_nav'], currentYear, 'NAV Weighted');
    formattedAgeTotalExpo = roundAndLabel(portfolioAgeData['portfolio_age_by_total_exposure'], currentYear, 'Total Exposure Weighted');

    formattedPortfolioAge.push(formattedAgeByNav);
    formattedPortfolioAge.push(formattedAgeTotalExpo);
    return formattedPortfolioAge;
}

const ProjectPerformance = ({ 
    portReturns,
    portfolioAge
}: Props) => {
    const aveWeightApi = useRef();
    const [apiReady, setApiReady] = useState({
        aveWeight: false
    });

    const {
        pacingParameters: {
            fiscalYearPlusOne,
            endYear,
            startYear,
        }
    } = useContext(PacingAnalysisContext);

    // set api handle to the average year aggrid
    const onAveWeightReady = (params) => {
        aveWeightApi.current = params.api;
        setApiReady((prevState) => ({
            ...prevState,
            aveWeight: true,
        }));
    };

    const [portfolioAgeGridData, setPortfolioAgeData] = useState([]);

    // reseed the column year names if the year settings change
    const updateColumnDefs = useCallback(() => {
        
        const aveWeightColumnDefs = AverageWeightedColumnDefinitions(
            startYear,
            endYear,
            fiscalYearPlusOne
        );
            
        if (
            aveWeightApi.current
        ) {
            aveWeightApi.current.setColumnDefs(aveWeightColumnDefs);
        }
    }, [
        startYear,
        endYear,
        fiscalYearPlusOne
    ]);
    
    // set up the header
    // we want the set up the columns.....
    // 1) when he grid loads initially AND....
    // 2) Anytime the user adjusts the start or end years along
    // with the fiscal Year - see updateColumnRefs useCallback.
    useEffect(() => {
        if (
            apiReady.aveWeight
        ) {
            updateColumnDefs();
        }
    }, [apiReady.aveWeight, updateColumnDefs]);


    // graphData is reset when... 
    // 1) the start/last year changes and...
    // 2) Click RunAnalysis 
    // format so the data displays correctly within the grid
    useEffect(() => {
        let formattedPortfolioAgeData;
        if(portfolioAge !== null){
            formattedPortfolioAgeData = formatPortfolioAge(portfolioAge,startYear,fiscalYearPlusOne);
        }
        setPortfolioAgeData(formattedPortfolioAgeData);
    }, [portfolioAge]);

    const defaultColDef = {
        resizable: true,
        sortable: false,
        filter: false,
        editable: false,
        suppressMenu: true,
        suppressDragLeaveHidesColumns: true,
        suppressMovable: true,
        flex: 1,
    };

    return (
        <div className='panel' style={{padding: '20px 12px 20px 5px', height: '540px'}}>
            <Label>
                <strong>
                    Projected Performance
                    <span href='#' id='UncontrolledTooltipExample'>
                        <InformationIcon />
                    </span>
                    <UncontrolledTooltip
                        style={{
                            backgroundColor: '#007899',
                            color: '#fff',
                            minWidth: '500px',
                            fontSize: '12px',
                        }}
                        placement='auto'
                        target='UncontrolledTooltipExample'
                    >
                        Net IRR and Net TVM are projected values for future
                        commitments. Overall TVM is the calculation for the overall
                        portfolio.
                    </UncontrolledTooltip>
                </strong>
            </Label>
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Net IRR (future commitments): </strong>
                            {numberFormat(portReturns['Net IRR'][0] * 100, 2)}
                            {'% | '}
                            {numberFormat(portReturns['Net IRR High'][0] * 100, 2)}
                            {'% (High) | '}
                            {numberFormat(portReturns['Net IRR Low'][0] * 100, 2)}
                            {'% '}
                            (Low)
                        </td>
                        <td>
                            <strong>Net TVM (future commitments): </strong>
                            {numberFormat(portReturns['Net TVM'][0], 2)}
                            {'x | '}
                            {numberFormat(portReturns['Net TVM High'][0], 2)}x
                            {'(High) | '}
                            {numberFormat(portReturns['Net TVM Low'][0], 2)}x (Low)
                        </td>
                        <td>
                            <strong>Overall TVM: </strong>
                            {numberFormat(portReturns['Overall TVM'][0], 2)}
                            {'x | '}
                            {numberFormat(portReturns['Overall TVM High'][0], 2)}
                            {'x (High) | '}
                            {numberFormat(portReturns['Overall TVM Low'][0], 2)}x
                            (Low)
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Col md={12} style={{padding: '0'}}>
                <Row>
                    <Col
                        md={12}
                    >
                        <div
                            className='ag-theme-balham'
                            style={{
                                position: 'relative',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <AgGridReact
                                animateRows
                                defaultColDef={defaultColDef}
                                domLayout='autoHeight'
                                headerHeight={25}
                                onGridReady={onAveWeightReady}
                                rowData={portfolioAgeGridData}
                                rowDataChangeDetectionStrategy={
                                    ChangeDetectionStrategyType.IdentityCheck
                                }
                                suppressContextMenu
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </div>
    );
};

export default ProjectPerformance;
