// @flow
import React, { Component, useContext, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import { ChangeDetectionStrategyType } from 'ag-grid-react';
import 'ag-grid-enterprise';

import {
    LandingContextType,
    TargetAllocationContextType,
    TargetAllocationStrategiesType,
    UnderwritingContextType,
    UserType,
} from 'types';
import { checkConversusAccess } from 'utils';
import {
    AuthContext,
    LandingContext,
    PacingAnalysisContext,
    StaticsContext,
    TargetAllocationContext,
    UnderwritingContext,
} from 'context';
import { resetScenario, setGridDataHelper } from './helpers';
import {
    UnderwritingTableColumnDefinitions,
    UnderwritingTableColumnDefinitionsNew,
    UnderwritingFrameworkComponents,
} from './components';
import { setFilterHandler } from 'Landing/helpers';

type Props = {
    assetClasses: Array<any>,
    checkForRenamedStrategy: Function,
    commitmentType: string,
    landingContext: LandingContextType,
    scenario: string,
    underwritingContext: UnderwritingContextType,
    targetAllocationContext: TargetAllocationContextType,
    targetAllocationStrategies: TargetAllocationStrategiesType,
    updateCurrentSliders: Function,
    updateSelectedRowData: Function,
    user: UserType,
    strategyParameters: Array<any>,
    userIsAdmin: number,
};

class UnderwritingGridComponent extends Component<Props> {
    constructor(props) {
        super(props);

        this.state = {
            dataset: [],
            overlayLoadingTemplate: '<span>Loading data...</span>',
            defaultColDef: {
                resizable: true,
                sortable: true,
                editable: true,
                filter: true,
                flex:1,
            },
            filterModel: {},
            // colDefState: null,
        };
    }

    // componentDidMount() {
    //     const {
    //         underwritingContext: { 
    //             existingUnderwritingColumnsDefs,
    //         },
    //     } = this.props;

    //     const {
    //         commitmentType,
    //         landingContext: { reportDate },
    //         targetAllocationStrategies,
    //         user,
    //         userIsAdmin,
    //         checkForRenamedStrategy,
    //     } = this.props;

    //     const conversusAccess = checkConversusAccess(user);

    //     const columnDefs = 
    //         commitmentType === 'new' ? 
    //             UnderwritingTableColumnDefinitionsNew(
    //                 commitmentType,
    //                 this.updateValue,
    //                 this.resetRow,
    //                 conversusAccess,
    //                 reportDate,
    //                 targetAllocationStrategies,
    //                 userIsAdmin,
    //                 checkForRenamedStrategy,
    //             )
    //             : UnderwritingTableColumnDefinitions(
    //                   commitmentType,
    //                   this.updateValue,
    //                   this.resetRow,
    //                   conversusAccess,
    //                   reportDate,
    //                   targetAllocationStrategies,
    //                   userIsAdmin,
    //                   checkForRenamedStrategy,
    //             );

    //     if(existingUnderwritingColumnsDefs) {
    //         this.setState((prev) => ({
    //             ...prev,
    //             colDefState: existingUnderwritingColumnsDefs
    //         }))
    //     } else {
    //         this.setState((prev) => ({
    //             ...prev,
    //             colDefState: columnDefs
    //         }))
    //     }
    // }

    async componentDidUpdate(prevProps) {
        const {
            commitmentType,
            scenario,
            showLiquidatedFunds,
            targetAllocationContext: {
                strategyAllocation
            },
            isEditMultipleModalOpen,
        } = this.props;

        if (
            (
                commitmentType !== prevProps.commitmentType
                || scenario !== prevProps.scenario
                || showLiquidatedFunds !== prevProps.showLiquidatedFunds
                || strategyAllocation !== prevProps.targetAllocationContext.strategyAllocation
                // check for changes on the modal to update
                || isEditMultipleModalOpen !== prevProps.isEditMultipleModalOpen
            ) &&
            this.gridApi
        ) {
            await this.setData();

            this.gridApi.forEachNode((node, index) => {
                if (index === 0) {
                    node.setSelected(true, true);
                }
            });

            this.gridApi.sizeColumnsToFit();
        }
    }

    onGridReady = (params) => {
        this.setData();

        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridApi.forEachNode((node, index) => {
            if (index === 0) {
                node.setSelected(true, true);
            }
        });

        this.gridApi.sizeColumnsToFit();
    };

    resizeTable = () => {
        this.gridApi.sizeColumnsToFit();
    };

    onRowSelected = async (e) => {
        // agGrid calls onRowSelected twice. Base it on row being selected only to ensure call once
        if (!e.node.selected) {
            return;
        }
        const { updateSelectedRowData } = this.props;
        const selectedRow = this.gridApi.getSelectedRows();

        await updateSelectedRowData(selectedRow[0]);
    };

    resetRow = async (data, stratToCheck = '', changedStrat = false) => {
        const { dataset } = this.state;
        const {
            commitmentType,
            updateCurrentSliders,
            underwritingContext: { setUnderwritingDataset },
            strategyParameters,
        } = this.props;

        const defaultParams = [...strategyParameters].find(
            (row) =>
                row.strategy ===
                    (changedStrat ? stratToCheck : data.strategy) &&
                row.case === data.scenario &&
                row.contribTiming === data.contribTiming &&
                row.numFunds ===
                    (data.scenario === 'Base' ? 1 : parseInt(data.numFunds, 10))
        );

        dataset.map((row) => {
            const holder = row;

            // New commitments data set does not have id.
            // Must compare with strategy instead
            if (
                (commitmentType === 'existing' &&
                    holder.id === data.id &&
                    holder.scenario === data.scenario) ||
                (commitmentType === 'new' &&
                    holder.strategy === data.strategy &&
                    holder.scenario === data.scenario)
            ) {
                holder.paidIn = parseFloat(defaultParams.paidIn) * 100;
                holder.irr = parseFloat(defaultParams.irr) * 100;
                holder.yld = parseFloat(defaultParams.yld) * 100;
                holder.fundLife = parseFloat(defaultParams.fundLife);
                holder.bow = parseFloat(defaultParams.bow);
                holder.rc1 = parseFloat(defaultParams.rc1) * 100;
                holder.coinvestFlag =
                    parseInt(defaultParams.coinvestFlag, 10) || 0;
                holder.rc2 = parseFloat(defaultParams.rc2) * 100;
                holder.rc3 = parseFloat(defaultParams.rc3) * 100;
                holder.rc4 = parseFloat(defaultParams.rc4) * 100;
                holder.rc5 = parseFloat(defaultParams.rc5) * 100;
                holder.strategy = defaultParams.strategy;
                holder.userEdited = defaultParams.userEdited || [];
            }

            return holder;
        });
        this.setState({ dataset });

        await setUnderwritingDataset(dataset);

        await this.gridApi.refreshCells();

        // Need to update current sliders
        updateCurrentSliders(defaultParams);
    };

    setData = () => {
        const {
            assetClasses,
            strategyParameters,
            checkForRenamedStrategy,
            commitmentType,
            landingContext: { reportDate, tableData, useForwardNav },
            scenario,
            showLiquidatedFunds,
            targetAllocationContext,
            underwritingContext: {
                fundSliderValues,
                setUnderwritingDataset,
                sliderValues,
            },
        } = this.props;

        const gridData = setGridDataHelper(
            assetClasses,
            strategyParameters,
            commitmentType,
            fundSliderValues,
            sliderValues,
            reportDate,
            tableData,
            checkForRenamedStrategy,
            targetAllocationContext,
            useForwardNav,
            scenario,
            showLiquidatedFunds
        );
        this.setState({ dataset: gridData });

        setUnderwritingDataset(gridData);
    };

    getStrategyName = (strategy) => {
        const {
            targetAllocationContext: {
                userDefinedRenamedTAS,
            },
        } = this.props;
        const renamed = Object.values(userDefinedRenamedTAS)
            .find((tas) => `${tas.prefix} ${tas.currentValue}` == strategy);
        if (renamed) {
            return `${renamed.prefix} ${renamed.originalValue}`;
        }
        return strategy;
    };

    updateValue = async (value, field, data) => {
        const {
            commitmentType,
            underwritingContext: {
                fundSliderValues,
                setFundSliderValues,
                setSliderValues,
                setUnderwritingDataset,
                sliderValues,
                underwritingDataset,
            },
            strategyParameters,
            updateSelectedRowData,
        } = this.props;

        // if (data[field] === value) {
        //     return;
        // }

        // get renamed strategy if user defined
        const renamedStrategy = this.getStrategyName(data.strategy);

        let sortedTable = []

        // Get current filters and set in state to grab after data has been replaced
        const filterModel = this.gridApi.getFilterModel();

        // remove filters to leave a sorted dataset
        this.gridApi.setFilterModel(null);
        this.gridApi.forEachNodeAfterFilterAndSort((node) => {
            sortedTable.push(node.data);
        });

        const sortModelFind = this.gridColumnApi.getColumnState().find(s => s.sort != null)

        if(sortModelFind) {

            const sortModelNewState = {
                state: [
                    {
                        colId: sortModelFind.colId,
                        sort: 'null'
                    }
                ]
            }
            this.gridColumnApi.applyColumnState(sortModelNewState)
        }

        if (
            data[field] === value &&
            field !== 'commitQtr' &&
            field !== 'contribTiming' &&
            field !== 'numFunds' &&
            field !== 'strategy'
        ) {
            return data;
        }

        let fieldHolder = field;
        if (field.includes('_1')) {
            fieldHolder = field.substring(0, field.length - 2);
        }

        if (
            field.includes('numFunds') ||
            field.includes('commitQtr') ||
            field.includes('contribTiming') ||
            field.includes('closingYear')
        ) {
            if (commitmentType === 'new') {
                const newSliderValues = [...sliderValues].map((row) => {
                    let holder = { ...row };

                    if (renamedStrategy === row.strategy) {
                        holder[fieldHolder] = value;
                        if (
                            Array.isArray(holder.userEdited) &&
                            !holder.userEdited?.includes(fieldHolder)
                        ) {
                            holder.userEdited.push(fieldHolder);
                        } else if (!Array.isArray(holder.userEdited)) {
                            holder.userEdited = [fieldHolder];
                        }
                    }
                    return holder;
                });
                setSliderValues(newSliderValues);
            } else {
                const newFundSliderValues = [...fundSliderValues].map((row) => {
                    const holder = { ...row };

                    if (data.id === row.id) {
                        if (
                            !field.includes('commitQtr') &&
                            !field.includes('closingYear')
                        ) {
                            holder.values = resetScenario(
                                data,
                                { ...row.values },
                                strategyParameters
                            );
                            holder.valuesHigh = resetScenario(
                                data,
                                { ...row.valuesHigh },
                                strategyParameters
                            );
                            holder.valuesLow = resetScenario(
                                data,
                                { ...row.valuesLow },
                                strategyParameters
                            );
                        }

                        if (
                            Array.isArray(holder.userEdited) &&
                            !holder.userEdited?.includes(fieldHolder)
                        ) {
                            holder.userEdited.push(fieldHolder);
                        } else if (!Array.isArray(holder.userEdited)) {
                            holder.userEdited = [fieldHolder];
                        }
                        if (field.includes('closingYear')) {
                            holder[fieldHolder] = value;
                        } else {
                            holder.values[fieldHolder] = value;
                            holder.valuesHigh[fieldHolder] = value;
                            holder.valuesLow[fieldHolder] = value;
                        }
                    }

                    return holder;
                });

                setFundSliderValues(newFundSliderValues);
            }
            return;
        }

        const newDataset = underwritingDataset.map((row) => {
            const rowHolder = row;

            if (
                (commitmentType === 'existing' &&
                    row.id === data.id &&
                    (row.scenario === data.scenario ||
                        field.includes('commitQtr') ||
                        field.includes('contribTiming'))) ||
                (commitmentType === 'new' &&
                    row.strategy === data.strategy &&
                    (row.scenario === data.scenario ||
                        field.includes('numFunds') ||
                        field.includes('commitQtr') ||
                        field.includes('contribTiming')))
            ) {
                let newValue = value;
                if (fieldHolder === 'fundLife') {
                    // approximate to nearest multiple of 0.25
                    newValue = Number((Math.round(newValue / 0.25) * 0.25).toFixed(2));
                }
                rowHolder[fieldHolder] = newValue;
                if (
                    Array.isArray(rowHolder.userEdited) &&
                    !rowHolder.userEdited?.includes(fieldHolder)
                ) {
                    rowHolder.userEdited.push(fieldHolder);
                } else if (!Array.isArray(rowHolder.userEdited)) {
                    rowHolder.userEdited = [fieldHolder];
                }
            }

            if (
                (commitmentType === 'new' &&
                    row.strategy === data.strategy &&
                    (field.includes('numFunds') ||
                        field.includes('contribTiming'))) ||
                (commitmentType === 'existing' &&
                    row.id === data.id &&
                    (field.includes('numFunds') ||
                        field.includes('contribTiming')))
            ) {
                this.resetRow(rowHolder);
            }

            return rowHolder;
        });

        // update Vintage in tableData with updated underwriting data
        if (field.includes('vintage')) {
            this.updateTableData(fieldHolder);
        }

        // Changes values to the right of 'Paid In' to Parameter Defaults when Pacing Strategy is changed
        if (field.includes('strategy')) {
            const stratsToReset = underwritingDataset.filter(
                ({ id }) => id === data.id
            );

            stratsToReset.forEach((fund) => {
                this.resetRow(fund, renamedStrategy, true);

                // Update the tableData
                this.updateTableData(fieldHolder);
            });

            // reset fundslidervalues base low and high
            const newFundSliderValues = [...fundSliderValues].map((row) => {
                let holderBase = row.values;
                let holderHigh = row.valuesHigh;
                let holderLow = row.valuesLow;

                if (row.id === data.id) {
                    strategyParameters.forEach((param) => {
                        if (
                            param.strategy === data.strategy &&
                            param.numFunds === data.numFunds &&
                            param.contribTiming === data.contribTiming
                        ) {
                            const holder = { ...param }; // MUST SPREAD
                            holder.strategy = data.strategy;
                            holder.model = 'Yale';
                            holder.case = param.case;
                            holder.paidIn = parseFloat(param.paidIn);
                            holder.irr = parseFloat(param.irr);
                            holder.yld = parseFloat(param.yld);
                            holder.fundLife = parseFloat(param.fundLife);
                            holder.bow = parseFloat(param.bow);
                            holder.rc1 = parseFloat(param.rc1);
                            holder.rc2 = parseFloat(param.rc2);
                            holder.rc3 = parseFloat(param.rc3);
                            holder.rc4 = parseFloat(param.rc4);
                            holder.rc5 = parseFloat(param.rc5);
                            holder.userEdited = param.userEdited;
                            holder.coinvestFlag =
                                parseInt(param.coinvestFlag, 10) || 0;

                            if (param.case === 'Base') {
                                holderBase = holder;
                            } else if (param.case === 'High') {
                                holderHigh = holder;
                            } else if (param.case === 'Low') {
                                holderLow = holder;
                            }
                        }
                    });
                }

                return {
                    ...row,
                    values: holderBase,
                    valuesHigh: holderHigh,
                    valuesLow: holderLow,
                };
            });

            setFundSliderValues(newFundSliderValues);
        }

        // update filterModel
        await this.setState({ filterModel });

        // Update the current sliders
        await updateSelectedRowData({
            ...data,
            strategy: renamedStrategy, // use original name
        });

        // Update global underwritingDataset
        await setUnderwritingDataset(newDataset);

        this.setState({dataset: sortedTable})

        // Refresh grid cells to display updated value
        this.gridApi.refreshCells();
    };

    /**
     * Grab filters from state and re-add to grid
     */
    setFilter = () => {
        const { filterModel } = this.state;
        const currentColumns = this.gridColumnApi.getAllGridColumns();
        const filters = setFilterHandler(filterModel, currentColumns);
        this.gridApi.setFilterModel(filters);
    };

    updateTableData = async (field) => {
        const {
            landingContext: { tableData, setTableData },
            underwritingContext: { underwritingDataset },
        } = this.props;

        const updatedTableData = tableData.map((datum) => {
            const alteredDatum = underwritingDataset.find(({ id }) => {
                return id === datum.Id.toString();
            });
            if (alteredDatum) {
                if (field === 'strategy') {
                    // eslint-disable-next-line no-param-reassign
                    datum.PacingStrategy = alteredDatum.strategy;
                }
            }
            return datum;
        });

        await setTableData(updatedTableData);
    };

    // columnVisibleHandler = (e) => {
    //     const {
    //         underwritingContext: { setExistingUnderwritingColumnsDefs },
    //     } = this.props;
    //     setExistingUnderwritingColumnsDefs(e.api.getColumnDefs())
    // }

    render() {
        const { overlayLoadingTemplate, defaultColDef, dataset } = {
            ...this.state,
        };
        const {
            commitmentType,
            landingContext: { reportDate },
            targetAllocationStrategies,
            user,
            userIsAdmin,
            checkForRenamedStrategy,
        } = this.props;

        const conversusAccess = checkConversusAccess(user);

        const columnDefs =
            commitmentType === 'new'
                ? UnderwritingTableColumnDefinitionsNew(
                      commitmentType,
                      this.updateValue,
                      this.resetRow,
                      conversusAccess,
                      reportDate,
                      targetAllocationStrategies,
                      userIsAdmin,
                      checkForRenamedStrategy,
                  )
                : UnderwritingTableColumnDefinitions(
                      commitmentType,
                      this.updateValue,
                      this.resetRow,
                      conversusAccess,
                      reportDate,
                      targetAllocationStrategies,
                      userIsAdmin,
                      checkForRenamedStrategy,
                  );

        const frameworkComponents = UnderwritingFrameworkComponents();
        return (
            <div className='panel'>
                <Row>
                    <Col md={12}>
                        <div
                            className='ag-theme-balham'
                            style={{
                                position: 'relative',
                                height: '600px',
                                width: '100%',
                            }}
                        >
                            <AgGridReact
                                animateRows
                                columnDefs={columnDefs}
                                // columnDefs={this.state.colDefState}
                                defaultColDef={defaultColDef}
                                components={frameworkComponents}
                                onGridReady={this.onGridReady}
                                onRowSelected={this.onRowSelected}
                                onGridSizeChanged={this.resizeTable}
                                // onColumnVisible={this.columnVisibleHandler}
                                //enterMovesDown
                                rowData={dataset}
                                rowSelection='single'
                                singleClickEdit
                                stopEditingWhenCellsLoseFocus={true}
                                suppressExcelExport
                                suppressCsvExport
                                suppressContextMenu
                                suppressMenuHide
                                suppressScrollOnNewData={true}
                                enterNavigatesVertically={true}
                                enterNavigatesVerticallyAfterEdit={true}
                                rowDataChangeDetectionStrategy={
                                    ChangeDetectionStrategyType.IdentityCheck
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const UnderwritingGrid = (props) => {
    const {
        assetClasses,
        strategyParameters,
        targetAllocationStrategies,
    } = useContext(StaticsContext);
    const { checkForRenamedStrategy } = useContext(PacingAnalysisContext);
    const { user, userIsAdmin } = useContext(AuthContext);
    const landingContext = useContext(LandingContext);
    const targetAllocationContext = useContext(TargetAllocationContext);
    const { strategyAllocation } = useContext(TargetAllocationContext);
    const underwritingContext = useContext(UnderwritingContext);

    return (
        <UnderwritingGridComponent
            assetClasses={assetClasses}
            strategyParameters={strategyParameters}
            checkForRenamedStrategy={checkForRenamedStrategy}
            landingContext={landingContext}
            targetAllocationContext={targetAllocationContext}
            targetAllocationStrategies={targetAllocationStrategies}
            underwritingContext={underwritingContext}
            user={user}
            userIsAdmin={userIsAdmin}
            {...props}
        />
    );
};

export default UnderwritingGrid;
