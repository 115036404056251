//@flow
import React from 'react';
import { Close } from '@material-ui/icons';
import {
    DialogTitleChildren,
    DialogTitleCloseAction,
    DialogTitleStyled,
    DialogTitleActions,
} from './styles';

type Props = {
    actions: Node[],
    children: Node,
    loading: boolean,
    onClose: Function,
};

const DialogTitleClose = ({
    actions = [],
    children = null,
    loading = false,
    onClose = null,
}: Props) => {
    return (
        <DialogTitleStyled>
            <DialogTitleChildren>{children}</DialogTitleChildren>
            <DialogTitleActions>{actions}</DialogTitleActions>
            {onClose && (
                <DialogTitleCloseAction
                    onClick={onClose}
                    size='small'
                    disabled={loading}
                >
                    <Close />
                </DialogTitleCloseAction>
            )}
        </DialogTitleStyled>
    );
};

export default DialogTitleClose;
