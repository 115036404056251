import React from "react"
import { Tabs } from '@ssgglobal/techintnue'

const AssetTab = ({
    value,
    onChange,
    options,
}) => {
    const parsedOptions = options.map((option) => ({ tabLabel: option }))
    return (
        <Tabs
            variant='isThemed'
            initialValue={value}
            handleInitialChange={onChange}
            tabObject={parsedOptions}
            tabOptions={{ style: { maxWidth: 'none' } }}
            size='xl'
        />
    )
}

export default AssetTab
