// @flow
import React, { useContext, useState } from 'react';
import { PacingContext, PacingAnalysisContext } from 'context';
import { Grid } from '@mui/material';
import {InputBase, InputLabel, RadioGroup, InputTextField} from '@ssgglobal/techintnue'

type Props = {
    setNumber: Function,
};

const inputStyle = {
    color: '#268fb3',
    border: '1px solid #BDBFBF',
    borderRadius: '5px',
    padding: '8px 8px 8px 16px',
    width: '100%',
    height: '40px',
}

export default function({ setNumber }: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);
    
    const {
        growthType,
        pacingParameters: { singleGrowthPercent },
        setGrowthType,
    } = useContext(PacingAnalysisContext);

    const growthPaOptions = [
        {
            label: 'Single',
            value: 'single'
        },
        {
            label: 'Multiple',
            value: 'multiple'
        }
    ]

    const handleChange = (e) => {
        setGrowthType(e);
    }

    return (
        <>
            {pacingClientReadOnlyAndSpiClientId() ? (
                <Grid item>{growthType === 'single' ? 'Single' : 'Multiple'}</Grid>
            ) : (
                <>
                    <Grid container>
                        <Grid item md={4}>
                            <RadioGroup 
                                isThemed
                                label="Growth p.a. (%)"
                                value={growthType}
                                onChange={handleChange}
                                options={growthPaOptions}
                            />
                        </Grid>
                        <Grid item md={8} style={{display: growthType === 'single' ? 'block' : 'none'}}>
                            <InputLabel>Growth p.a. (%)</InputLabel>
                            <InputTextField
                                name='singleGrowthPercent'
                                onChange={setNumber}
                                type='number'
                                defaultValue={singleGrowthPercent}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
