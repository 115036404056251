// @flow
import React, { useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LandingContext } from 'context';

const checkNextTarget = (nextTarget, validateData = null) => {
    if (typeof validateData === 'function' && typeof nextTarget === 'string') {
        validateData(nextTarget);
    } else if (typeof nextTarget === 'function') {
        nextTarget();
    }
};

type Props = {
    backTarget: string | Function,
    backText: string,
    nextTarget: string | Function,
};

export default function BottomNav({ backTarget, backText, nextTarget }: Props) {
    const { validateData } = useContext(LandingContext);

    const ctnBtnStyle = {
        marginLeft: '10px',
    };

    return (
        <div className='panel'>
            <Row>
                <Col className='text-right' sm={12}>
                    {backTarget ? (
                        <Button className='primary-button' color='info'>
                            <Link className='link-button' to={backTarget}>
                                {backText || 'Back'}
                            </Link>
                        </Button>
                    ) : (
                        ''
                    )}
                    {nextTarget ? (
                        <Button
                            className='primary-button'
                            style={ctnBtnStyle}
                            onClick={() => {
                                checkNextTarget(nextTarget, validateData);
                            }}
                        >
                            Continue
                        </Button>
                    ) : (
                        ''
                    )}
                </Col>
            </Row>
        </div>
    );
}
