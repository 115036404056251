const resetRowHandler = (data, tableData, rawData) => {
    if (typeof data.Id === 'undefined') {
        return {
            resetRemoveRowModalIsOpen: true,
            resetRemoveModalTitle: 'Reset Row',
            tableRowToResetRemove: data,
        };
    }

    const resetRow = rawData.find((row) => row.Id === data.Id);

    return tableData.map((row) => {
        if (row.Id === resetRow.Id) {
            return { ...resetRow };
        }

        return row;
    });
};

export default resetRowHandler;
