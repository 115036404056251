/**
 *  Each context accesses other contexts' data and the consuming
 *  context's provider must be below the providing context's provider.
 */
// @flow
import React from 'react';
import AuthContextProvider, { AuthContextConsumer } from './AuthContext';
import LandingContextProvider from './LandingContext';
import PacingAnalysisContextProvider from './PacingAnalysisContext';
import PacingContextProvider from './PacingContext';
import StaticsContextProvider from './StaticsContext';
import TargetAllocationContextProvider from './TargetAllocationContext';
import UnderwritingContextProvider from './UnderwritingContext';
import AuthComponent from '../AuthComponent';

type Props = {
    children: Node,
};

// THESE PROVIDERS MUST STAY IN THIS ORDER.
const ContextProviders = ({ children }: Props) => (
    <AuthContextProvider>
        <AuthContextConsumer>
            {({ user }) => {
                if (!user) {
                    return <AuthComponent />;
                }
                return (
                    <StaticsContextProvider>
                        <PacingContextProvider>
                            <TargetAllocationContextProvider>
                                <PacingAnalysisContextProvider>
                                    <LandingContextProvider>
                                        <UnderwritingContextProvider>
                                            {children}
                                        </UnderwritingContextProvider>
                                    </LandingContextProvider>
                                </PacingAnalysisContextProvider>
                            </TargetAllocationContextProvider>
                        </PacingContextProvider>
                    </StaticsContextProvider>
                );
            }}
        </AuthContextConsumer>
    </AuthContextProvider>
);

export default ContextProviders;
