/**
 * This context is for pacingParameter data used for Pacing Analysis
 */

// @flow
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import type { Node } from 'react';
import {
    getNextQuarterStartDate,
    getQuarterFromDate,
    formatCommitmentSchedule,
} from 'utils';
import { TargetAllocationContext } from './TargetAllocationContext';

type Props = {
    children: Node,
};

export const PacingAnalysisContext = createContext({});
export const PacingAnalysisContextConsumer = PacingAnalysisContext.Consumer;

const date = new Date();
const initialStartYear = date.getFullYear();
const initialStartDate = getNextQuarterStartDate();

const PacingAnalysisContextProvider = ({ children }: Props) => {
    const dtHistoricValues = useRef(true);
    const [graphData, setGraphData] = useState({});
    const [historicalData, setHistoricalData] = useState();
    const [growthOnLiquidAssets, setGrowthOnLiquidAssets] = useState(false);
    const [growthType, setGrowthType] = useState('single');
    const [increment, setIncrement] = useState(5);
    const [historicalSetBackValue, setHistoricalSetBackValue] = useState(
        {
            type: false,
            isHistoric: false,
            value: 1,
            drawChart: false
        }
    );
    const [pacingParameters, setPacingParameters] = useState({
        commitmentSchedule: [],
        commitmentScheduleOptimizer: [],
        dateType: 'calendar',
        endYear: initialStartYear + 12,
        feesOnNav: 0,
        fiscalMonth: 'September',
        fiscalYearPlusOne: false,
        singleGrowthPercent: 5,
        multipleGrowthPercents: {},
        portfolioAsOf: '',
        privateMarketExposure: 10,
        quarter: '',
        scenario: 'base',
        startYear: 2000,
        startDate: initialStartDate,
        targetYear: 2025,
        targetYearType: 'optimize',
        totalAum: 1000,
        commitmentScheduleStrategies: [],
        userEdited: [],
        dtStartQtr: '',
        dtStartYear: 2000,
        dtEndQtr: '',
        dtEndYear: 2000,
        dtPercent: .4,
        dtStartDate: '',
        dtEndDate: '',
        dtPercentValue: null,
        startDateNewInvestments: '',
    });
    const [
        portfolioAumAndLiquidAssets,
        setPortfolioAumAndLiquidAssets,
    ] = useState([]);
    const [portfolioContributions, setPortfolioContributions] = useState({});
    const [growthIncrement, setGrowthIncrement] = useState(0.5);

    const { userDefinedRenamedTAS, strategyAllocation } = useContext(TargetAllocationContext);

    /**
     * Object passed into handleSave function in UnderwritingContext
     */
    const pacingAnalysisContextDataToSave = {
        // previously reset graphData to initial blank slate for quicker loading
        // graphData: {},
        graphData,
        growthOnLiquidAssets,
        growthType,
        increment,
        pacingParameters,
        portfolioAumAndLiquidAssets,
        portfolioContributions,
    };

    const findStrategyByName = (stratName) => {
        let foundStrategy = strategyAllocation.find(({ serverNames }) => serverNames == stratName);
        if (foundStrategy) {
            return foundStrategy;
        }
        const tas = Object.values(userDefinedRenamedTAS);
        const foundTAS = tas
            .map(({ prefix, currentValue, originalValue }) => ({
                currentValue: `${prefix} ${currentValue}`,
                originalValue: `${prefix} ${originalValue}`
            }))
            .find(({ currentValue }) => currentValue == stratName)

        foundStrategy = strategyAllocation.find(({ serverNames }) => serverNames == foundTAS?.originalValue);
        if (foundStrategy) {
            return foundStrategy;
        }
    }

    /**
     * Check User Defined Strategies if their display name
     * has been changed in Target Allocations
     * and return the correct display value
     */
    const checkForRenamedStrategy = (stratName, checkWithPrefix = true) => {
        const uDRTASNames: Array<any> = Object.values(userDefinedRenamedTAS);

        type currentItemType = {
            prefix: string,
            originalValue: string,
            currentValue: string,
        };

        for (let index = 0; index < uDRTASNames.length; index++) {
            let originalTerm = '';
            let currentTerm = '';
            const currentItem: currentItemType = uDRTASNames[index];
            originalTerm = checkWithPrefix
                ? `${currentItem.prefix} ${currentItem.originalValue}`
                : currentItem.originalValue;
            currentTerm = checkWithPrefix
                ? `${currentItem.prefix} ${currentItem.currentValue}`
                : uDRTASNames[index].currentValue;

            if (stratName === originalTerm) {
                return currentTerm;
            }
        }
        return stratName;
    };

    /**
     * Updates any individual key/value pair on the pacingParameters object
     *
     * @param {string} key
     * @param {any} value
     */
    const setPacingParametersKeyValue = (key, value) => {
        setPacingParameters((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    useEffect(() => {
        const { quarter, startYear } = pacingParameters;
        const newDate = ['', '', ''];
        newDate[0] = startYear.toString();
        switch (quarter) {
            case 'Q1':
                newDate[1] = '01';
                newDate[2] = '01';
                break;
            case 'Q2':
                newDate[1] = '04';
                newDate[2] = '01';
                break;
            case 'Q3':
                newDate[1] = '07';
                newDate[2] = '01';
                break;
            case 'Q4':
                newDate[1] = '10';
                newDate[2] = '01';
                break;
            default:
                break;
        }

        setPacingParametersKeyValue('startDate', newDate.join('-'));
    }, [pacingParameters.quarter, pacingParameters.startYear]);

    useEffect(() => {
        const {
            commitmentSchedule,
            commitmentScheduleOptimizer,
        } = pacingParameters;
        if (
            commitmentSchedule.length !== 0 &&
            (!Array.isArray(commitmentScheduleOptimizer) ||
                commitmentScheduleOptimizer.length === 0)
        ) {
            const newOptimizer = [];
            commitmentSchedule.forEach((row) => {
                const holder = {};
                Object.keys(row).forEach((key: any) => {
                    if (key === 'strategy') {
                        holder[key] = row[key];
                    } else {
                        holder[key] = 0;
                    }
                });
                newOptimizer.push(holder);
            });
            setPacingParametersKeyValue(
                'commitmentScheduleOptimizer',
                newOptimizer
            );
        } else if (
            commitmentScheduleOptimizer &&
            commitmentSchedule &&
            commitmentScheduleOptimizer.length < commitmentSchedule.length
        ) {
            // If the user went back to Taget allocation and added another strat
            const newOptimizer = [...commitmentScheduleOptimizer];
            let newIndex = commitmentScheduleOptimizer.length;
            while (newIndex < commitmentSchedule.length) {
                newOptimizer.push(commitmentSchedule[newIndex]);
                newIndex++;
            }
            setPacingParametersKeyValue(
                'commitmentScheduleOptimizer',
                newOptimizer
            );
        }
    }, [
        pacingParameters.commitmentSchedule,
        pacingParameters.commitmentScheduleOptimizer,
    ]);

    /**
     * Currently objects are saved with a growthPercent key in pacingParameters.
     * Since we set the whole pacingParameters object at once, we have to manually
     * set the new singleGrowthPercent and multipleGrowthPercents values in the obj.
     * Eventually we can remove this updater function when all data is saved properly.
     *
     * @param {*} params
     */
    const updatePacingParameters = (params) => {
        const newParams = { ...params };
        // format commitment scheulde and optimizer
        const newCommitmentSchedule = formatCommitmentSchedule(
            newParams.commitmentSchedule
        );
        newParams.commitmentSchedule = newCommitmentSchedule;

        if (!newParams.singleGrowthPercent) {
            newParams.singleGrowthPercent = newParams.growthPercent || 5;
        }

        if (!newParams.multipleGrowthPercents) {
            newParams.multipleGrowthPercents = {};
        }

        delete newParams.growthPercent;

        setPacingParameters(newParams);
    };

    useEffect(() => {
        const {
            commitmentSchedule,
            commitmentScheduleStrategies,
        } = pacingParameters;

        if (
            (!Array.isArray(commitmentScheduleStrategies) ||
                commitmentScheduleStrategies.length === 0) &&
            commitmentSchedule.length !== 0
        ) {
            const strategies = [];
            commitmentSchedule.forEach((row) => {
                const holder = {};
                Object.keys(row).forEach((key: any) => {
                    if (key === 'strategy') {
                        holder[key] = row[key];
                    } else {
                        holder['portfolio'] = '';
                    }
                });
                strategies.push(holder);
            });
            setPacingParametersKeyValue(
                'commitmentScheduleStrategies',
                strategies
            );
        }
    }, [
        pacingParameters.commitmentSchedule,
        pacingParameters.commitmentScheduleStrategies,
    ]);

    /**
     * Must be run with all other context updater functions.
     * This is run when you load a pacing from the db, therefore
     * the initial useState declarations are overridden, and are
     * merely there for documentation purposes.
     *
     * @param {*} data
     */
    const updatePacingAnalysisContext = (data) => {
        setGraphData(data.graphData);
        setGrowthOnLiquidAssets(data.growthOnLiquidAssets);
        setGrowthType(data.growthType || 'single');
        setIncrement(data.increment);
        setPortfolioAumAndLiquidAssets(data.portfolioAumAndLiquidAssets);
        setPortfolioContributions(data.portfolioContributions || {});
        updatePacingParameters(data.pacingParameters);
    };

    useEffect(() => {
        if (!pacingParameters.targetYearType) {
            setPacingParametersKeyValue('targetYearType', 'optimize');
        }
    }, [pacingParameters.targetYearType]);

    return (
        <PacingAnalysisContext.Provider
            value={{
                checkForRenamedStrategy,
                findStrategyByName,
                graphData,
                growthIncrement,
                growthOnLiquidAssets,
                growthType,
                historicalData,
                increment,
                pacingAnalysisContextDataToSave,
                pacingParameters,
                portfolioAumAndLiquidAssets,
                portfolioContributions,
                setGraphData,
                setGrowthIncrement,
                setGrowthOnLiquidAssets,
                setGrowthType,
                setHistoricalData,
                historicalSetBackValue,
                setHistoricalSetBackValue,
                setIncrement,
                setPacingParametersKeyValue,
                setPacingParameters,
                setPortfolioAumAndLiquidAssets,
                setPortfolioContributions,
                updatePacingAnalysisContext,
                dtHistoricValues
            }}
        >
            {children}
        </PacingAnalysisContext.Provider>
    );
};

export default PacingAnalysisContextProvider;
