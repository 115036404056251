/**
 * This context is for all static lookup table data:
 * assetClasses, geographies, omniStrategyMapping, strategyParameters, dveBetas, targetAllocationStrategies
 * sector, subsector, investment-types, (when these tables are implemented in db restructure, put context here)
 * sp-index (currently no sp-index data held in context)
 */

// @flow
import React, { createContext, useEffect, useState } from 'react';
import type { Node } from 'react';
import { TargetAllocationStrategiesType } from 'types';
import {
    getAssetClasses,
    getClients,
    getDVEBetas,
    getGeographies,
    getOmniStrategyMapping,
    getStrategyParameters,
    getUniqueStrategies,
} from 'api';

type StaticsContextProps = {
    children: Node,
};

export const StaticsContext = createContext({});
export const StaticsContextConsumer = StaticsContext.Consumer;

const StaticsContextProvider = ({ children }: StaticsContextProps) => {
    const [assetClasses, setAssetClasses] = useState([]);
    const [dveBetas, setDveBetas] = useState([]);
    const [geographies, setGeographies] = useState([]);
    const [omniStrategyMapping, setOmniStrategyMapping] = useState([]);
    const [strategyParameters, setStrategyParameters] = useState([]);
    const [currency, setCurrency] = useState('Portfolio');
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [clients, setClients] = useState([]);
    const [isFetchingLookups, setFetchingLookups] = useState(false);
    /**
     * strategiesByAllocation will return an object with all the assets class
     * as keys and the list of strategies of the class
     */
    const [strategiesByAllocation, setStrategiesByAllocation] = useState({});
    /**
     * The below variable replaces the old TargetAllocationStrategies.js file data,
     * with unique (non-repeating) strategy list from Strategy table in db,
     * with only 'strategy', 'w_name', 'ui_name', 'p_name', 'r_name', 'server_name', 'panel_name' fields
     */
    const [
        targetAllocationStrategies,
        setTargetAllocationStrategies,
    ] = useState([]);

    /**
     * Renames /strategies table data from snake to camel case and sets them in state
     *
     * @param {Array} allStrats
     * @param {TargetAllocationStrategiesType} uniqueStrats
     */
    const renameAndSetStrategyData = (allStrats, uniqueStrats) => {
        const renamedStrategyParameters = allStrats.map(
            ({
                asset_class,
                coinvest_flag,
                contrib_timing,
                fund_life,
                num_funds,
                p_name,
                paid_in,
                panel_name,
                r_name,
                server_name,
                ui_name,
                w_name,
                ...strat
            }) => ({
                ...strat,
                assetClass: asset_class,
                coinvestFlag: coinvest_flag,
                contribTiming: contrib_timing,
                fundLife: fund_life,
                numFunds: num_funds,
                pName: p_name,
                paidIn: paid_in,
                panelName: panel_name,
                rName: r_name,
                serverName: server_name,
                uiName: ui_name,
                wName: w_name,
            })
        );
        setStrategyParameters(renamedStrategyParameters);

        const renamedUniqueStrategies = uniqueStrats.map(
            ({
                asset_class,
                p_name,
                panel_name,
                r_name,
                server_name,
                ui_name,
                w_name,
                ...strat
            }) => ({
                ...strat,
                assetClass: asset_class,
                pName: p_name,
                panelName: panel_name,
                rName: r_name,
                serverName: server_name,
                uiName: ui_name,
                wName: w_name,
            })
        );
        setTargetAllocationStrategies(renamedUniqueStrategies);
    };

    useEffect(() => {
        const strategies = {};
        assetClasses.forEach((asset) => {
            strategies[asset.short_name] = [];
            const strategy = targetAllocationStrategies
                .filter((strat) => strat.assetClass === asset.short_name)
                .map((strat) => strat.panelName)
            strategies[asset.short_name] = [...new Set(strategy)]
        })
        setStrategiesByAllocation(strategies);
    }, [assetClasses, targetAllocationStrategies])

    const initializeLookups = async () => {
        // init fetching
        setFetchingLookups(true);
        const initialAssetClasses = await getAssetClasses();
        const initialDveBetas = await getDVEBetas();
        const initialGeographies = await getGeographies();
        const initialOmniStrategyMapping = await getOmniStrategyMapping();
        const initialStrategyParameters = await getStrategyParameters();
        const uniqueStrategiesList = await getUniqueStrategies();
        const initialSpiClients = await getClients();

        setAssetClasses(initialAssetClasses);
        setClients(initialSpiClients);
        setDveBetas(initialDveBetas);
        setGeographies(initialGeographies);
        setOmniStrategyMapping(initialOmniStrategyMapping);
        renameAndSetStrategyData(
            initialStrategyParameters,
            uniqueStrategiesList
        );
        // finish fetching
        setFetchingLookups(false);
    };

    useEffect(() => {
        initializeLookups();
    }, []);

    const getAssetClassNames = (shortOrLong) =>
        assetClasses.map((assetClass) => assetClass[shortOrLong]);

    const getGeographyNames = () => geographies.map(({ name }) => name);
    const geographyNames = getGeographyNames();

    const assetClassShortNames = getAssetClassNames('short_name');
    const assetClassLongNames = getAssetClassNames('long_name');
    const assetClassShortNamesLower = getAssetClassNames('short_name').map(
        (value) => {
            return value.toLowerCase();
        }
    );

    return (
        <StaticsContext.Provider
            value={{
                assetClasses,
                assetClassLongNames,
                assetClassShortNames,
                assetClassShortNamesLower,
                clients,
                currency,
                currencyCode,
                dveBetas,
                geographyNames,
                omniStrategyMapping,
                setClients,
                setCurrency,
                setCurrencyCode,
                strategyParameters,
                targetAllocationStrategies,
                strategiesByAllocation,
                isFetchingLookups,
            }}
        >
            {children}
        </StaticsContext.Provider>
    );
};

export default StaticsContextProvider;