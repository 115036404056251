/* eslint-disable react/no-did-update-set-state */
// as it turns out, this is one of the few acceptable
// times to do this rule, so we letting it slide here
// @flow
import React, { Component } from 'react';
import {
    FormGroup,
    Input,
    Popover,
    PopoverHeader,
    PopoverBody,
} from 'reactstrap/es/';
import { StrategyAllocationType } from 'types';

type Props = {
    allocations: StrategyAllocationType,
    availablePercentAllocation: number,
    getSingleValueAndAllocation: Function,
    handleAssetClassStrategyAllocationUpdate: Function,
    name: string,
    strategy: string,
    update: Function,
    wName: string,
};

type State = {
    showAllocationWarning: boolean,
};

class AllocationKeyInputField extends Component<Props, State> {
    state = {
        showAllocationWarning: false,
    };

    componentDidUpdate = (prevProps: Props) => {
        const { allocations, name, getSingleValueAndAllocation } = this.props;
        const { showAllocationWarning } = this.state;

        if (allocations !== prevProps.allocations) {
            const incomingValue = allocations[name];
            const { availableAllocation } = getSingleValueAndAllocation(
                allocations,
                name,
                incomingValue
            );

            const newShowAllocationWarning = false;
            if (showAllocationWarning && availableAllocation <= 0) {
                this.setState({
                    showAllocationWarning: newShowAllocationWarning,
                });
            }
        }
    };

    render() {
        const {
            allocations,
            availablePercentAllocation,
            getSingleValueAndAllocation,
            handleAssetClassStrategyAllocationUpdate,
            name,
            strategy,
            update,
            wName,
        } = this.props;
        const { showAllocationWarning } = this.state;

        let stratIdentifier = null;
        stratIdentifier = wName;
        if (wName.indexOf('+') > 0) {
            stratIdentifier = wName.replace('+', 'plus');
        }

        return (
            <FormGroup>
                <Input
                    key={`${stratIdentifier}-${name}`}
                    id={`${stratIdentifier}-${name}`}
                    type='number'
                    value={
                        allocations[name] ? allocations[name].toString() : '0'
                    }
                    min={0}
                    max={100}
                    onChange={async (event) => {
                        const incomingValue = event.target.value;
                        const {
                            availableAllocation,
                        } = getSingleValueAndAllocation(
                            allocations,
                            name,
                            incomingValue
                        );

                        if (availableAllocation > 0) {
                            await this.setState({
                                showAllocationWarning: true,
                            });
                        } else if (showAllocationWarning === true) {
                            await this.setState({
                                showAllocationWarning: false,
                            });
                        }

                        update(name, incomingValue);
                    }}
                    onBlur={({ target: { value } }) => {
                        if (name === 'alloc') {
                            const unusedAllocation = availablePercentAllocation;
                            const updatedValue =
                                value > unusedAllocation
                                    ? unusedAllocation
                                    : value;
                            allocations.alloc = Number(updatedValue);
                            handleAssetClassStrategyAllocationUpdate(
                                allocations,
                                strategy
                            );
                        } else if (!showAllocationWarning) {
                            handleAssetClassStrategyAllocationUpdate(
                                allocations,
                                strategy
                            );
                        }
                    }}
                    invalid={name !== 'alloc' && allocations.underAllocation}
                />
                <Popover
                    isOpen={showAllocationWarning}
                    placement='bottom'
                    target={`${stratIdentifier}-${name}`}
                >
                    <PopoverHeader>Warning:</PopoverHeader>
                    <PopoverBody>
                        Geography allocation must add up to 100.
                    </PopoverBody>
                </Popover>
            </FormGroup>
        );
    }
}

export default AllocationKeyInputField;
