const setFilterHandler = (filterModel, currentColumns) => {
    const newFilterModel = {};

    Object.keys(filterModel).forEach((key) => {
        let columnName = key;

        // Strip '_1' from column name if it exists
        if (columnName.charAt(columnName.length - 2) === '_') {
            columnName = key.substring(0, key.length - 2);
        }

        // Find the correct column name
        currentColumns.forEach((column) => {
            // Strip '_1' from column name for exact comparison
            let nameToCompare = column.colId;
            if (column.colId.charAt(column.colId.length - 2) === '_') {
                nameToCompare = column.colId.substring(
                    0,
                    nameToCompare.length - 2
                );
            }

            if (nameToCompare === columnName) {
                newFilterModel[column.colId] = filterModel[key];
            }
        });
    });

    return newFilterModel;
};

export default setFilterHandler;
