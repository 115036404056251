const checkConversusAccess = (user) => {
    const { spi_client_id: spiClientId, email } = user;

    if (spiClientId) {
        return false;
    }

    const authorizedUsers = [
        'akash.chitrey@stepstonegroup.com',
        'laura.zhang@stepstonegroup.com',
        'lisa.larsson@stepstonegroup.com',
        'derek.roth@stepstonegroup.com',
        'fernando.zelada@stepstonegroup.com',
        'luke.bauer@stepstonegroup.com',
        'mary.zhang@stepstonegroup.com',
    ];

    if (authorizedUsers.includes(email)) {
        return true;
    }

    return false;
};

export default checkConversusAccess;
