import React, { useContext } from 'react';
import { Col } from 'reactstrap';
import { LandingContext } from 'context';

const ExistingCommitmentTableLegend = () => {
    const { useForwardNav } = useContext(LandingContext);
    const legendStyling = {
        marginLeft: '15px',
        display: 'inline-block',
    };

    return (
        <Col xs={6} data-testid='existing-commitment-table-legend'>
            <div className='legend'>
                <div className='positive-unfunded-old' style={legendStyling}>
                    Positive Unfunded for old VYs: Consider setting to zero
                </div>
                <div className='negative-values' style={legendStyling}>
                    Negative values: Please check
                </div>
                <div className='missing-values' style={legendStyling}>
                    Missing values
                </div>
                {useForwardNav && (
                    <div className='stale-nav-reporting' style={legendStyling}>
                        Old NAV: Value Cash Adjusted
                    </div>
                )}
            </div>
        </Col>
    );
};

export default ExistingCommitmentTableLegend;
