// @flow
import { apiCall, quantUrl } from './BaseApi';

const quant = quantUrl || '';

export const postLogisticFutureCashFlows = async (data: any) => {
    const url = `${quant}/pacing/cfslogisticdata/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postLogisticExistingCashFlows = async (data: any) => {
    const url = `${quant}/pacing/cfslogisticexistingdata/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postYaleFutureCashFlows = async (data: any) => {
    const url = `${quant}/pacing/cfsplusdata/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postYaleExistingCashFlows = async (data: any) => {
    const url = `${quant}/pacing/cfsplusexistingdata/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const getHistoricalCashFlows = async (data: any) => {
    const url = `${quant}/pacing/historical-fund/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const getAllHistoricalCashFlows = async (data: any) => {
    const url = `${quant}/pacing/historical-fund-all/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const getPortfolioHistoricalCashFlows = async (data: any) => {
    const url = `${quant}/pacing/historical-portfolio/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const getAllPortfolioHistoricalCashFlows = async (data: any) => {
    const url = `${quant}/pacing/historical-portfolio-all/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postEstimateCommitments = async (data: any) => {
    const url = `${quant}/pacing/estimatecommitments/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postEstimateCommitmentsCash = async (data: any) => {
    const url = `${quant}/pacing/estimatecommitments-cash/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const postAnalysis = async (data: any) => {
    const url = `${quant}/pacing/analysis/`;
    const method = 'POST';
    return apiCall(url, method, data);
};

export const calcFwdNav = async (data: any) => {
    const url = `${quant}/pacing/calc-forward-nav/`;
    const method = 'POST';
    return apiCall(url, method, data);
};
