// @flow
import React from 'react';
import {
    GrowthSensitivityGraph,
    GrowthSensitivityIncrement,
} from './components';

type Props = {
    viewYear: boolean,
};

const GrowthSensitivityGraphParent = ({ viewYear }: Props) => {
    return (
        <>
            <GrowthSensitivityIncrement />
            <GrowthSensitivityGraph viewYear={viewYear} />
        </>
    );
};

export default GrowthSensitivityGraphParent;
