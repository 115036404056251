const setFundSliderOnNameChange = (defaults, fundSlider) => {
    let holder = 'values';
    if (defaults.case === 'High') {
        holder = 'valuesHigh';
    } else if (defaults.case === 'Low') {
        holder = 'valuesLow';
    }

    return {
        ...fundSlider,
        [holder]: {
            strategy: defaults.strategy,
            model: defaults.model,
            paidIn: parseFloat(defaults.paidIn),
            irr: parseFloat(defaults.irr),
            yld: parseFloat(defaults.yld),
            fundLife: parseFloat(defaults.fundLife),
            bow: parseFloat(defaults.bow),
            rc1: parseFloat(defaults.rc1),
            rc2: parseFloat(defaults.rc2),
            rc3: parseFloat(defaults.rc3),
            rc4: parseFloat(defaults.rc4),
            rc5: parseFloat(defaults.rc5),
            coinvestFlag: defaults.coinvestFlag,
        },
    };
};

export default setFundSliderOnNameChange;
