import { calculateFundAge, getQuarterFromDate, numberFormat } from 'utils';
/**
 * Create the Underwriting grid data row for the existing funds view
 *
 * @param fundInfo
 * @param values
 * @param {string} scenario
 */
export function getDataRow(
    fundInfo,
    values,
    scenario = 'Base',
    strategyParameters,
    fundSliderClosingYear = false,
    measureDate
) {
    const {
        PacingStrategy,
        AssetClass,
        Id,
        Vintage,
        Investment,
        InvestmentType,
        Sector,
        IRR,
        TVM,
        AdjNAV,
        Contributions,
        Distributions,
        Commitment,
        Unfunded,
        ClosingDate,
        investmentId,
    } = fundInfo;

    const closingYear =
        fundSliderClosingYear ||
        (ClosingDate ? ClosingDate.substring(0, 4) : Vintage);
    let strategy;
    let { commitQtr } = values || false;
    let { contribTiming } = values || 'Base';
    let paidIn;
    let irr;
    let yld;
    let fundLife;
    let bow;
    let coinvestFlag;
    let rc1;
    let rc2;
    let rc3;
    let rc4;
    let rc5;
    let userEdited;

    // Assign values from matching fundSliderValue to dataset row
    if (values) {
        strategy = values.strategy;
        paidIn = values.paidIn * 100;
        irr = values.irr * 100;
        yld = values.yld * 100;
        fundLife = Number(parseFloat(values.fundLife).toFixed(2));
        bow =  Number(parseFloat(values.bow).toFixed(2));
        coinvestFlag = values.coinvestFlag || 0;
        rc1 = values.rc1 * 100;
        rc2 = values.rc2 * 100;
        rc3 = values.rc3 * 100;
        rc4 = values.rc4 * 100;
        rc5 = values.rc5 * 100;
        userEdited = values.userEdited || [];
    } else {
        strategy = '(Select a Strategy)';
        paidIn = '-';
        irr = '-';
        yld = '-';
        fundLife = '-';
        bow = '-';
        coinvestFlag = 0;
        rc1 = '-';
        rc2 = '-';
        rc3 = '-';
        rc4 = '-';
        rc5 = '-';
        userEdited = [];
    }

    // if there are no existing values from fundSliderValues,
    // pull from ParameterDefatuls
    if (!values) {
        const foundDefaultParams = [...strategyParameters].find(
            (row) =>
                PacingStrategy === row.strategy &&
                scenario === row.case &&
                row.contribTiming === 'Base' &&
                row.numFunds === 1
        );
        const calcClosingQuarter = ClosingDate
            ? getQuarterFromDate(ClosingDate)
            : 'Q2';

        strategy = foundDefaultParams
            ? foundDefaultParams.strategy
            : '(Select a Strategy)';
        commitQtr = calcClosingQuarter;
        contribTiming = 'Base';
        paidIn = foundDefaultParams
            ? parseFloat(foundDefaultParams.paidIn) * 100
            : '-';
        irr = foundDefaultParams
            ? parseFloat(foundDefaultParams.irr) * 100
            : '-';
        yld = foundDefaultParams
            ? parseFloat(foundDefaultParams.yld) * 100
            : '-';
        fundLife = foundDefaultParams
            ? Number(parseFloat(foundDefaultParams.fundLife).toFixed(2))
            : '-';
        bow = foundDefaultParams
            ?  Number(parseFloat(foundDefaultParams.bow).toFixed(2))
            : '-';
        rc1 = foundDefaultParams
            ? parseFloat(foundDefaultParams.rc1) * 100
            : '-';
        coinvestFlag = foundDefaultParams ? foundDefaultParams.coinvestFlag : 0;
        rc2 = foundDefaultParams
            ? parseFloat(foundDefaultParams.rc2) * 100
            : '-';
        rc3 = foundDefaultParams
            ? parseFloat(foundDefaultParams.rc3) * 100
            : '-';
        rc4 = foundDefaultParams
            ? parseFloat(foundDefaultParams.rc4) * 100
            : '-';
        rc5 = foundDefaultParams
            ? parseFloat(foundDefaultParams.rc5) * 100
            : '-';
        userEdited = foundDefaultParams ? foundDefaultParams.userEdited : [];
    }

    return {
        id: Id,
        investmentId: investmentId || null,
        fundAge: calculateFundAge(closingYear, measureDate),
        fundName: Investment,
        assetClass: AssetClass,
        investmentType: InvestmentType,
        sector: Sector,
        strategy,
        currentIrr: parseFloat(IRR) || 0,
        currentTvm: parseFloat(TVM) || 0,
        currentCommmitment: numberFormat(parseFloat(Commitment) / 1000000) || 0,
        currentContributions:
            numberFormat(parseFloat(Contributions) / 1000000) || 0,
        currentDistributions:
            numberFormat(parseFloat(Distributions) / 1000000) || 0,
        currentNAV: numberFormat(parseFloat(AdjNAV) / 1000000) || 0,
        currentUnfunded: numberFormat(parseFloat(Unfunded) / 1000000) || 0,
        contribToDate: (Contributions / Commitment) * 100,
        distribToDate: (Distributions / Commitment) * 100,
        projectedContrib:
            ((parseFloat(Contributions) + parseFloat(Unfunded)) /
                parseFloat(Commitment)) *
            100,
        commitQtr: commitQtr || 'Q2',
        closingYear,
        contribTiming: contribTiming || 'Base',
        paidIn,
        irr,
        yld,
        fundLife,
        bow,
        coinvestFlag,
        rc1,
        rc2,
        rc3,
        rc4,
        rc5,
        scenario,
        numFunds: 1,
        userEdited,
    };
}

/**
 * Create the Underwriting gri data row for the future commitments view
 *
 * @param {Object} assetClassInfo
 * @param {function} checkForRenamedStrategy
 * @param {Object} values
 */
export function getNewDataRow(
    assetClass,
    checkForRenamedStrategy,
    values,
    strategyParameters
) {
    const {
        strategy,
        contribTiming,
        paidIn,
        irr,
        yld,
        fundLife,
        bow,
        rc1,
        coinvestFlag,
        rc2,
        rc3,
        rc4,
        rc5,
        numFunds,
        commitQtr,
        case: scenario,
        userEdited,
    } = values;

    // Only need to the first one
    // All parameter sets for the strategy should have the same coinvestFlag
    // Will need to be more specific with this find()
    // if you want to use it for other default params

    const foundStrategy = strategyParameters.find((row) => {
        return row.strategy === strategy;
    });
    const defaultP7Value = foundStrategy.coinvestFlag;

    const displayName = checkForRenamedStrategy(strategy);

    let commitmentQuarter = commitQtr || 'All';
    commitmentQuarter =
        (displayName === 'PD Private Debt Platform' ||
            displayName === 'PD Private Debt Platform-Levered') &&
        typeof commitQtr === 'undefined'
            ? 'All'
            : commitmentQuarter;

    return {
        assetClass,
        strategy: displayName,
        paidIn: parseFloat(paidIn) * 100,
        irr: parseFloat(irr) * 100,
        yld: parseFloat(yld) * 100,
        fundLife: parseFloat(fundLife),
        bow: parseFloat(bow),
        coinvestFlag: Object.prototype.hasOwnProperty.call(
            values,
            'coinvestFlag'
        )
            ? coinvestFlag
            : defaultP7Value,
        rc1: parseFloat(rc1) * 100,
        rc2: parseFloat(rc2) * 100,
        rc3: parseFloat(rc3) * 100,
        rc4: parseFloat(rc4) * 100,
        rc5: parseFloat(rc5) * 100,
        commitQtr: commitmentQuarter,
        contribTiming: contribTiming || 'Base',
        scenario: scenario || 'Base',
        numFunds: numFunds ? numFunds.toString() : '5',
        userEdited: userEdited || [],
    };
}
