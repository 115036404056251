import React from 'react';
import { numberFormat } from 'utils';

type Props = {
    value: number,
    decimalPlace: number,
    data: { fundAge: number },
    year: number,
};

/**
 * Formats values for Paid In values (Year 1 - Year 4),
 * and styles cells blue if editable, grey if not
 */
const UnderwritingPaidInRenderer = ({
    value,
    decimalPlace,
    data: { fundAge, userEdited },
    year,
}: Props) => {
    const formattedValue =
        typeof value !== 'undefined' ? numberFormat(value, decimalPlace) : '';

    const style = {
        color: fundAge > year + 1 ? '#000000' : '#268fb3',
    };
    // create field value with year variable
    const field = 'rc' + year;

    if (userEdited?.includes(field)) {
        style.fontWeight = 'bold';
    } else {
        style.fontWeight = 'normal';
    }

    const displayedItem = fundAge > year + 1 ? '-' : formattedValue+'%';

    return <div style={style}>{displayedItem}</div>;
};

export default UnderwritingPaidInRenderer;
