// @flow
import { apiCall, quantUrl } from './BaseApi';

const quant = quantUrl || '';

export const isAuthenticatedRequest = async () => {
    const url = `${quant}/pacing/auth/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const attemptExchangeTokenRequest = async (token: string) => {
    const url = `${quant}/pacing/exchangetoken/?appName=pacing&token=${token}`;
    const method = 'GET';
    return apiCall(url, method);
};

export const attemptLogOut = async () => {
    const url = `${quant}/pacing/logout/`;
    const method = 'GET';
    return apiCall(url, method);
};
