import Swal from 'sweetalert2';
import { getCommitDate } from 'ParameterLab/helpers';
import { getDataRow, getNewDataRow } from './createDataRow';

const setData = (
    assetClasses,
    strategyParameters,
    commitmentType,
    fundSliderValues,
    sliderValues,
    reportDate,
    tableData,
    checkForRenamedStrategy,
    targetAllocationContext,
    useForwardNav,
    scenario = 'Base',
    showLiquidatedFunds
) => {
    const { exposure, strategyAllocation } = targetAllocationContext;
    const gridData = [];

    if (commitmentType === 'existing') {
        const reportDateParts = reportDate.split('-');
        const measureDate = new Date(
            reportDateParts[0],
            reportDateParts[1] - 1,
            reportDateParts[2]
        );

        tableData.forEach((row) => {
            const { AssetClass, Id } = row;
            const fundSliderInfo = fundSliderValues.find(({ id }) => {
                return id === Id.toString();
            });

            if (AssetClass && (!row.FundStatus || (!showLiquidatedFunds && row.FundStatus === 'Active') || showLiquidatedFunds)) {
                const { values, valuesHigh, valuesLow } =
                    fundSliderInfo || false;
                const nav = parseFloat(
                    useForwardNav ? row.ForwardNAV : row.AdjNAV,
                    10
                );

                const holder = getDataRow(
                    row,
                    values,
                    'Base',
                    strategyParameters,
                    fundSliderInfo.closingYear,
                    measureDate
                );
                const holderHigh = getDataRow(
                    row,
                    valuesHigh,
                    'High',
                    strategyParameters,
                    fundSliderInfo.closingYear,
                    measureDate
                );
                const holderLow = getDataRow(
                    row,
                    valuesLow,
                    'Low',
                    strategyParameters,
                    fundSliderInfo.closingYear,
                    measureDate
                );

                gridData.push(holder);
                gridData.push(holderHigh);
                gridData.push(holderLow);
            }
        });
    } else {
        // Iterate through all available saved parameter sets
        sliderValues.forEach((row) => {
            const { strategy } = row;
            const assetClassShort = strategy.substring(0, 2).toUpperCase();

            const assetClassInfo = assetClasses.find(
                ({ short_name: shortName }) => shortName === assetClassShort
            );

            const foundStrategy = strategyAllocation.find(
                ({ serverNames }) => serverNames === strategy
            );

            // Only display strategies with target allocations
            if (
                // this isn't a requirement anymore
                // exposure[assetClassShort.toLowerCase()] !== 0 &&
                // this isn't a requirement anymore
                // foundStrategy.alloc !== 0
                foundStrategy &&
                foundStrategy.selected
            ) {
                if (assetClassInfo) {
                    const holder = getNewDataRow(
                        assetClassInfo.long_name,
                        checkForRenamedStrategy,
                        row,
                        strategyParameters
                    );

                    gridData.push(holder);
                }
            }
        });
    }

    // Filter on scenario (Base/High/Low/All)
    return scenario === 'All'
        ? gridData
        : gridData.filter((row) => row.scenario === scenario);
};

export default setData;
