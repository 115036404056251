// @flow
import React, { Component, useContext } from 'react';
import { StrategyAllocationType } from 'types';
import { TargetAllocationContext } from 'context';
import { AllocationKeyInputsForSliderChild } from './components';
import {
    getAllocationData,
    getNewTotalsValueAndAllocation,
    getSingleValueAndAllocation,
} from './helpers';

type Props = {
    assetClass: 'PE' | 'PD' | 'RA' | 'RE',
    availablePercentAllocation: any,
    handleAssetClassStrategyAllocationUpdate: Function,
    strategy: string,
    strategyAllocation: Array<StrategyAllocationType>,
    stratByWName: any,
};

type State = {
    allocations: StrategyAllocationType,
    availableAllocation: number,
    strategy: string,
};

class GeoAllocationPanelComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { strategyAllocation, strategy } = props;

        this.state = getAllocationData(strategyAllocation, strategy);
    }

    componentDidUpdate = (prevProps: Props) => {
        const { strategyAllocation } = this.props;
        const { strategy } = this.state;

        const foundStrategy = strategyAllocation.find(({ serverNames }) => {
            return serverNames === strategy;
        });

        if (this.props !== prevProps) {
            this.setState({
                allocations: foundStrategy,
            });
        }
    };

    handleTotalUpdate = (name: string, value: number) => {
        const { allocations, availableAllocation } = this.state;

        const {
            availableAllocation: newAvailableAllocation,
            newValue,
        } = getNewTotalsValueAndAllocation(
            allocations,
            availableAllocation,
            value
        );

        this.setState({
            allocations: {
                ...allocations,
                alloc: newValue,
            },
            availableAllocation: newAvailableAllocation,
        });
    };

    handleSingleUpdate = async (name: string, value: number) => {
        const { allocations } = this.state;

        await this.setState((prevState) => {
            const { newValue } = getSingleValueAndAllocation(
                prevState.allocations,
                name,
                value
            );
            const newState = {
                allocations: {
                    ...allocations,
                    [name]: newValue,
                },
            };

            return newState;
        });
    };

    render() {
        const {
            assetClass,
            availablePercentAllocation,
            handleAssetClassStrategyAllocationUpdate,
            stratByWName,
        } = this.props;
        const { strategy, allocations } = this.state;

        return (
            <div>
                <AllocationKeyInputsForSliderChild
                    allocations={allocations}
                    assetClass={assetClass}
                    availablePercentAllocation={availablePercentAllocation}
                    getSingleValueAndAllocation={getSingleValueAndAllocation}
                    handleAssetClassStrategyAllocationUpdate={
                        handleAssetClassStrategyAllocationUpdate
                    }
                    handleSingleUpdate={this.handleSingleUpdate}
                    handleTotalUpdate={this.handleTotalUpdate}
                    strategy={strategy}
                    wName={stratByWName}
                />
            </div>
        );
    }
}

const GeoAllocationPanel = (props) => {
    const {
        handleAssetClassStrategyAllocationUpdate,
        strategyAllocation,
    } = useContext(TargetAllocationContext);

    return (
        <GeoAllocationPanelComponent
            handleAssetClassStrategyAllocationUpdate={
                handleAssetClassStrategyAllocationUpdate
            }
            strategyAllocation={strategyAllocation}
            {...props}
        />
    );
};

export default GeoAllocationPanel;
