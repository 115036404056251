import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { AgGridReact, ChangeDetectionStrategyType } from 'ag-grid-react';
import {
    AuthContext,
    PacingContext,
    PacingAnalysisContext,
    TargetAllocationContext,
    UnderwritingContext,
} from 'context';
import { IoIosCalculator, IoMdSettings } from 'react-icons/io';
import { Button, Grid, Tooltip } from '@ssgglobal/techintnue'
import { FiRefreshCcw } from 'react-icons/fi';
import {
    CommitmentScheduleColumnDefinitions,
    FrameworkComponents,
    ReadOnlyCommitmentScheduleColumnDefinitions,
    ReadOnlyFrameworkComponents,
    AdvancedOptimizationModal,
    TotalsColumnDefinitions,
} from './components';
import { Box } from '@mui/material';

const CommitmentTable = ({
    commitmentSchedule,
    conversusAccess,
    disableCheck,
    quarter,
    startYear,
    endYear,
    fiscalYearPlusOne,
    getCommitScheduleStyling,
    portfolioAumAndLiquidAssets,
    handleEstimate
}) => {
    const gridApi = useRef();
    const bottomApi = useRef();
    
    const totalsOptions = { alignedGrids: [] };
    const growthOptions = { alignedGrids: [] };
    const commitOptions = { alignedGrids: [] };
    const portAumOptions = { alignedGrids: [] };
    
    totalsOptions.alignedGrids.push(growthOptions);
    growthOptions.alignedGrids.push(totalsOptions);
    commitOptions.alignedGrids.push(portAumOptions);
    commitOptions.overlayLoadingTemplate =
        '<span>Loading Commitment Schedule...</span>';
    commitOptions.overlayNoRowsTemplate = '<span></span>';
    portAumOptions.alignedGrids.push(commitOptions);
    
    const [totalGrowthPercentOptions] = useState(growthOptions);
    const [totalPortfolioOptions] = useState(totalsOptions);
    const [commitmentScheduleOptions] = useState(commitOptions);
    const [apiReady, setApiReady] = useState({
        top: false,
        growth: false,
        grid: false,
        bottom: false,
    });
    const [enableResetCommitments, setEnableResetCommitments] = useState(false);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);
    const [advancedModalOpened, toggleAdvancedOptimizationModal] = useState(
        false
    );
    const [portfolioAumAndLiquidAssetOptions] = useState(portAumOptions);

    const {
        growthType,
        pacingParameters: {
            scenario = 'Base',
            commitmentScheduleOptimizer,
            multipleGrowthPercents,
            startDateNewInvestments,
        },
        portfolioContributions,
        setPortfolioContributions,
        setPacingParametersKeyValue,
    } = useContext(PacingAnalysisContext);

    const {
        pacingClientReadOnly,
        pacingClientReadOnlyAndSpiClientId,
    } = useContext(PacingContext);

    const {
        targetAllocation: { targetExposureType },
        handleStrategyAllocationUpdate,
    } = useContext(TargetAllocationContext);

    const {
        sliderValues,
        updateSliderValue,
    } = useContext(UnderwritingContext);

    const { userSpiClientId, userIsAdmin } = useContext(AuthContext);

    const defaultColDef = {
        resizable: true,
        sortable: false,
        cellStyle: (params) =>
            getCommitScheduleStyling(params, portfolioContributions),
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        cellEditorParams: (params) => {
            return {
                field: params.field,
                updateValue,
            };
        },
        filter: false,
        editable: !!(!pacingClientReadOnly && !userSpiClientId),
        suppressMenu: true,
        suppressDragLeaveHidesColumns: true,
        suppressMovable: true,
    };

    const frameworkComponents = pacingClientReadOnlyAndSpiClientId()
        ? ReadOnlyFrameworkComponents()
        : FrameworkComponents();

    const updateColumnDefs = useCallback(() => {
        const newColumnDefs = pacingClientReadOnlyAndSpiClientId()
            ? ReadOnlyCommitmentScheduleColumnDefinitions(
                    startYear,
                    endYear,
                    fiscalYearPlusOne
                )
            : CommitmentScheduleColumnDefinitions(
                    updateValue,
                    quarter,
                    startYear,
                    endYear,
                    fiscalYearPlusOne,
                    toggleLock,
                    commitmentScheduleOptimizer,
                    handleValueClick,
                    targetExposureType,
                    startDateNewInvestments
                );

        const newTotalsColumnDefs = TotalsColumnDefinitions(
            updateValue,
            startYear,
            endYear,
            fiscalYearPlusOne,
            toggleLock,
            commitmentScheduleOptimizer,
            handleValueClick,
            targetExposureType
        );

        if (
            gridApi.current &&
            bottomApi.current
        ) {
            gridApi.current.setColumnDefs(newColumnDefs);
            bottomApi.current.setColumnDefs(newColumnDefs);
        }
    }, [
        startYear,
        quarter,
        endYear,
        fiscalYearPlusOne,
        commitmentScheduleOptimizer,
        commitmentSchedule,
        growthType,
    ]);

    // Update portfolio contribution or commitment schedule
    // when a value has changed
    const updateValue = (value, field, data) => {
        let valueCopy = value;
        if (field == 'commitQtr') {
            const slider = sliderValues.find((value) => 
                value.strategy == data.serverNames && value.case.toLowerCase() == scenario.toLowerCase()
            );
            if (slider) {
                updateSliderValue({
                    ...slider,
                    scenario,
                    commitQtr: value,
                });
            }
        }

        if (field == 'alloc') {
            // update strategy allocation of the field
            handleStrategyAllocationUpdate(data.serverNames, value);
        }

        if(data[field] === value) {
            return data;
        }

        if (data.strategy === 'Total Portfolio Net Cash Flows') {
            const newPortContrib = portfolioContributions;
            newPortContrib[field] = valueCopy;
            if (
                Array.isArray(newPortContrib?.userEdited) &&
                !newPortContrib?.userEdited?.includes(field)
            ) {
                newPortContrib.userEdited.push(field);
            } else if (!Array.isArray(newPortContrib?.userEdited)) {
                newPortContrib.userEdited = [field];
            }

            setPortfolioContributions(newPortContrib);
            totalPortfolioOptions.api.refreshCells();
        } else if (data.strategy === 'Total Portfolio Growth p.a. (%)') {
            if (valueCopy > 100) {
                valueCopy = 100;
            }
            multipleGrowthPercents[field] = valueCopy;
            if (
                Array.isArray(multipleGrowthPercents?.userEdited) &&
                !multipleGrowthPercents?.userEdited?.includes(field)
            ) {
                multipleGrowthPercents.userEdited.push(field);
            } else if (!Array.isArray(multipleGrowthPercents?.userEdited)) {
                multipleGrowthPercents.userEdited = [field];
            }

            setPacingParametersKeyValue(
                'multipleGrowthPercents',
                multipleGrowthPercents
            );
            totalGrowthPercentOptions.api.refreshCells();
        } else {
            const newCommitmentSchedule = commitmentSchedule.map((row) => {
                const holder = row;
                if (holder.strategy === data.strategy) {
                    holder[field] = valueCopy;
                    if (
                        Array.isArray(holder?.userEdited) &&
                        !holder?.userEdited?.includes(field)
                    ) {
                        holder.userEdited.push(field);
                    } else if (!Array.isArray(holder?.userEdited)) {
                        holder.userEdited = [field];
                    }
                }

                return holder;
            });
            setPacingParametersKeyValue(
                'commitmentSchedule',
                newCommitmentSchedule
            );
            setEnableResetCommitments(true)
        }
    };

    const onCellValueChanged = (params) => {
        const { data, column, value } = params;
        const newCommitmentSchedule = commitmentSchedule.map((row) => {
            const returnRow = row;
            let columnId = column.colId;
            if (columnId.includes('_1')) {
                columnId = column.colId.slice(0, columnId.length - 2);
            }
            if (returnRow.strategy === data.strategy) {
                returnRow[columnId] = value;
            }
            return returnRow;
        });

        setPacingParametersKeyValue(
            'commitmentSchedule',
            newCommitmentSchedule
        );
    };

    const handleValueClick = (colDef, rowIndex, data) => {
        if (
            data.strategy !== 'Total Portfolio Net Cash Flows' &&
            data.strategy !== 'Total Portfolio Growth p.a. (%)' &&
            data.strategy !== 'Portfolio AuM'
        ) {
            const foundMatchingCol = commitmentScheduleOptions.columnApi
                .getAllGridColumns()
                .find((column) => column.colId.includes(colDef.field));
            gridApi.current.startEditingCell({
                rowIndex,
                colKey: foundMatchingCol.colId,
            });
        }
    };

    // Add up totals of all strategies for each year between start and end year
    const setTotals = () => {
        const totalRow = { strategy: 'Total' };
        for (let i = startYear; i <= endYear; i++) {
            const year = fiscalYearPlusOne ? i + 1 : i;
            if (year <= endYear) {
                totalRow[year] = 0;
            }
        }
        commitmentSchedule.forEach((row) => {
            for (let i = startYear; i <= endYear; i++) {
                const year = fiscalYearPlusOne ? i + 1 : i;
                if (year <= endYear && row[year]) {
                    totalRow[year] += parseFloat(row[year]);
                }
            }
        });

        totalRow.alloc = commitmentSchedule
            .map(({ alloc }) => alloc)
            .reduce((a, b) => a + b, 0);

        Object.values(totalRow).slice(0,-1).some(el => el > 0) ? setEnableResetCommitments(true) : setEnableResetCommitments(false)

        setPinnedBottomRowData([totalRow]);
    };

    const focusToNextCell = ({ nextCellPosition }) => {
        const {
            rowIndex,
            column: { colId },
        } = nextCellPosition;
        const { api } = commitmentScheduleOptions;
        api.setFocusedCell(rowIndex, colId);
    };
    
    const processCellFromClipboard = (params) => {
        const { value } = params;
        const formattedValue = parseFloat(value);
        return Number.isNaN(formattedValue) ? 0 : formattedValue;
    };

    const estimateAndUpdateDefs = () => {
        handleEstimate();
        updateColumnDefs();
    };

    const resetCommitments = () => {
        const ommitableFields = ['strategy', 'alloc', 'min', 'max', 'increment', 'serverNames', 'userEdited', 'commitQtr'];
        const resettedCommitments = commitmentSchedule.map((row) => {
            if(row.userEdited.length) {
                row.userEdited = []
            }

            Object.keys(row)
                .filter((column) => !ommitableFields.includes(column))
                .forEach((column) => row[column] = 0);

            return row;
        })

        setPacingParametersKeyValue(
            'commitmentSchedule',
            resettedCommitments
        );

        setEnableResetCommitments(!enableResetCommitments);
    }

    const toggleLock = (field, strategy) => {
        const newOptimizer = [...commitmentScheduleOptimizer].map((row) => {
            const updatedRow = { ...row };

            Object.keys(row).forEach((key) => {
                if (updatedRow.strategy === strategy && key === field) {
                    updatedRow[field] = row[field] === 0 ? 1 : 0;
                }
            });

            return updatedRow;
        });

        setPacingParametersKeyValue(
            'commitmentScheduleOptimizer',
            newOptimizer
        );
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
        setApiReady((prevState) => ({
            ...prevState,
            grid: true,
        }));
    };

    const onBottomReady = (params) => {
        bottomApi.current = params.api;
        setApiReady((prevState) => ({
            ...prevState,
            bottom: true,
        }));
    };

    /**
     * Helper function for creating portfolioContributions and
     * multipleGrowthPercents objects on initial component mounting.
     *
     * @param {*} objectToFormat
     */
    const formatDataObject = (objectToFormat) => {
        const newObject = {};
        const dataToFormat =
            objectToFormat === 'portfolioContributions'
                ? portfolioContributions
                : multipleGrowthPercents;

        // TODO: keep userEdited key
        if (Object.keys(dataToFormat).length === 0) {
            for (let i = startYear; i <= endYear; i++) {
                const year = fiscalYearPlusOne ? i + 1 : i;
                if (year <= endYear) {
                    newObject[year] = 0;
                }
            }
        } else {
            for (let i = startYear; i <= endYear; i++) {
                const year = fiscalYearPlusOne ? i + 1 : i;
                if (year <= endYear) {
                    if (
                        Object.prototype.hasOwnProperty.call(dataToFormat, year)
                    ) {
                        newObject[year] = dataToFormat[year];
                    } else {
                        newObject[year] = 0;
                    }
                }
            }
        }

        if (
            Object.keys(dataToFormat).length !== Object.keys(newObject).length
        ) {
            if (objectToFormat === 'portfolioContributions') {
                newObject.strategy = 'Total Portfolio Net Cash Flows';
                setPortfolioContributions(newObject);
            } else if (objectToFormat === 'multipleGrowthPercents') {
                newObject.strategy = 'Total Portfolio Growth p.a. (%)';
                setPacingParametersKeyValue(
                    'multipleGrowthPercents',
                    newObject
                );
            }
        }
    };
    
    useEffect(() => {
        if (
            startYear.toString().length === 4 &&
            endYear.toString().length === 4
        ) {
            formatDataObject('portfolioContributions');
            formatDataObject('multipleGrowthPercents');
        }
    }, []);

    /**
     * Reformats multipleGrowthPercents when endYear is altered
     */
    useEffect(() => {
        const lastYearInGrowthPercent = parseInt(
            Object.keys(multipleGrowthPercents).slice(-2)[0],
            10
        );

        if (endYear !== lastYearInGrowthPercent) {
            formatDataObject('multipleGrowthPercents');
        }
    }, [endYear]);

    // initial setup for the three grids once it loads
    useEffect(() => {
        if (
            apiReady.grid &&
            apiReady.bottom
        ) {
            updateColumnDefs();
        }
    }, [apiReady.grid, apiReady.bottom]);

    useEffect(() => {
        updateColumnDefs();
    }, [updateColumnDefs]);

    useEffect(() => {
        if (gridApi.current) {
            gridApi.current.setRowData(commitmentSchedule);
        }
        setTotals();
    }, [commitmentSchedule]);

    const { disableEstimate, disableMessage } = useMemo(() => {
        const response = {
            disableEstimate: false,
            disableMessage: '',
        }
        const pinnedValues = pinnedBottomRowData[0];
        if (!pinnedValues) return response;
        const allocTotal = pinnedValues.alloc || 0;
        if (targetExposureType === 'percent') {
            // total should sum to 100%
            response.disableEstimate = allocTotal !== 100;
            response.disableMessage = 'Target strategy % must sum up to 100%';
        } else if (targetExposureType === 'currency') {
            // all strategies should sum to a value > 0
            response.disableEstimate = allocTotal === 0;
            response.disableMessage = 'Target NAV must be greater than 0';
        }
        return response;
    }, [pinnedBottomRowData]);

    const noStyle = {
        border: '1px solid #6c757d',
        backgroundColor: '#6c757d',
        color: '#fff',
        borderRadius: '5px',
        margin: '5px',
        height: '30px',
        width: '30px',
    };

    const gearStyle = {
        color: '#333',
        backgroundColor: '#FFF',
        border: '1px solid #333',
        borderRadius: '5px',
        margin: '5px',
        height: '30px',
    };

    return (
        <>
            {pacingClientReadOnlyAndSpiClientId() ? (
                <></>
            ) : (
                <Grid container item md={6} direction="row" spacing="16px">
                    {advancedModalOpened && (
                        <AdvancedOptimizationModal
                            isOpen={advancedModalOpened}
                            toggleModal={() =>
                                toggleAdvancedOptimizationModal(!advancedModalOpened)
                            }
                            // smoothing={smoothing}
                            // setNumber={setNumber}
                        />
                    )}
                    <Grid item>
                        <Tooltip
                            title={disableEstimate && disableMessage}
                            placement="top"
                        >
                            <Box>
                                <Button
                                    theme="secondary"
                                    onClick={estimateAndUpdateDefs}
                                    disabled={disableEstimate}
                                    icon={<IoIosCalculator size={16} />}
                                >
                                    Estimate Values
                                </Button>
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            placement="top"
                            title="Reset Existing Commitments"
                        >
                            <Box>
                                <Button
                                    theme="secondary"
                                    disabled={disableCheck || !enableResetCommitments}
                                    onClick={resetCommitments}
                                    icon={<FiRefreshCcw />}
                                    >
                                        Reset
                                </Button>
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                    {(conversusAccess ||
                        (userIsAdmin ? true : false)) && (
                        <Button
                            theme="secondary"
                            disabled={disableCheck}
                            onClick={() => {
                                toggleAdvancedOptimizationModal(
                                    !advancedModalOpened
                                );
                            }}
                        >
                            <IoMdSettings />
                        </Button>
                    )}
                    </Grid>
                </Grid>
            )}
            <Grid container item md={12} style={{paddingBottom: '0px'}}>
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        animateRows
                        defaultColDef={defaultColDef}
                        enableRangeSelection
                        singleClickEdit
                        suppressClickEdit
                        components={frameworkComponents}
                        rowData={commitmentSchedule}
                        stopEditingWhenCellsLoseFocus={true}
                        pinnedBottomRowData={pinnedBottomRowData}
                        gridOptions={commitmentScheduleOptions}
                        suppressContextMenu
                        domLayout='autoHeight'
                        enterMovesDown
                        // Using tabToPreviousCell to move to the next cell
                        // b/c idk why tabToNextCell doesn't work as expected
                        tabToPreviousCell={focusToNextCell}
                        processCellFromClipboard={processCellFromClipboard}
                        onCellValueChanged={onCellValueChanged}
                        onPasteEnd={setTotals}
                        onGridReady={onGridReady}
                        suppressHorizontalScroll
                        rowDataChangeDetectionStrategy={
                            ChangeDetectionStrategyType.IdentityCheck
                        }
                    />
                </div>
            </Grid>
            <Grid container item md={12} style={{paddingTop: '0px'}}>
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: portfolioAumAndLiquidAssetOptions?.rowData?.length > 1 ? '5rem' : '2.5rem',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        animateRows
                        headerHeight={0}
                        suppressClickEdit
                        suppressContextMenu
                        defaultColDef={defaultColDef}
                        rowData={portfolioAumAndLiquidAssets}
                        gridOptions={portfolioAumAndLiquidAssetOptions}
                        components={frameworkComponents}
                        onGridReady={onBottomReady}
                        rowDataChangeDetectionStrategy={
                            ChangeDetectionStrategyType.IdentityCheck
                        }
                    />
                </div>
            </Grid>
        </>
    )
}

export default CommitmentTable