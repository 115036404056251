import React, { useEffect, useState, useContext } from 'react';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import { numberFormat, colorCombos } from 'utils';
import { PacingAnalysisContext } from 'context';

const RealEstateExposureGraph = () => {
    const {
        graphData,
        pacingParameters: { dateType },
    } = useContext(PacingAnalysisContext);

    const [data, updateData] = useState({});
    const [xValues, setXValues] = useState([]);

    const title =
        dateType === 'fiscal' ? { text: 'Fiscal Years' } : { text: 'Years' };

    const setData = () => {
        const reNavYrData = graphData.re_nav_yr;
        const holder = [];

        if (!reNavYrData) {
            return;
        }

        Object.keys(reNavYrData).forEach((key) => {
            const values = reNavYrData[key].slice(1);
            values.forEach((value) => {
                if (!(reNavYrData[key][0] in holder)) {
                    holder[reNavYrData[key][0]] = [];
                    holder[reNavYrData[key][0]].push(
                        value !== 0 ? numberFormat(value * 100) : 0
                    );
                } else if (value !== 0) {
                    holder[reNavYrData[key][0]].push(numberFormat(value * 100));
                } else {
                    holder[reNavYrData[key][0]].push(0);
                }
            });
        });

        const newData = { ...data, holder };
        updateData(holder);

        let year = 0;
        let years = [];
        if (Object.prototype.hasOwnProperty.call(graphData, 'year_labels')) {
            years = graphData.year_labels;
        } else {
            for (let j = 0; j < graphData.qtrdates.length; j++) {
                const newYear = parseInt(graphData.qtrdates[j], 10);
                if (year !== newYear) {
                    years.push(parseInt(graphData.qtrdates[j], 10));
                    year = newYear;
                }
            }
        }

        setXValues(years);
    };

    const generateColumns = (data) => {
        let columnGraphContent = null;
        let keyholder = null;

        if (data) {
            const numColorsSSPalate = colorCombos.length;
            let colorCounter = 0;
            columnGraphContent = Object.keys(data).map((key) => {
                keyholder = key;
                const columnPortion = (
                    <ChartSeriesItem
                        key={key}
                        name={key}
                        type='column'
                        stack
                        data={data[key]}
                        color={colorCombos[colorCounter].primary}
                    >
                        <ChartSeriesItemTooltip
                            render={({ point }) => `${key}: ${point.value}%`}
                        />
                    </ChartSeriesItem>
                );
                colorCounter++;

                colorCounter =
                    colorCounter >= numColorsSSPalate ? 0 : colorCounter;

                return columnPortion;
            });

            columnGraphContent.push(
                <ChartSeriesItem
                    key={keyholder + 1}
                    name='Yield'
                    type='line'
                    dashType='longDash'
                    color={colorCombos[1].primary}
                    markers={{ visible: false }}
                />
            );
            return columnGraphContent;
        }

        return <div>Loading...</div>;
    };

    useEffect(() => {
        setData();
    }, [graphData]);

    return (
        <Chart pannable={false} zoomable={false}>
            <ChartLegend visible={false} />
            <ChartTooltip />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    title={title}
                    categories={xValues}
                    labels={{ step: 1, rotation: 'auto' }}
                    majorGridLines={false}
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem
                    title={{ text: '% of Real Estate Portfolio Value' }}
                />
            </ChartValueAxis>
            <ChartSeries>{generateColumns(data)}</ChartSeries>
        </Chart>
    );
};

export default RealEstateExposureGraph;
