import {
    NumericEditor,
    numberFormat,
    numericValidator,
} from 'utils';
import { calculateNumQuarter, getVintageDate } from 'ParameterLab/helpers';
import { UnderwritingTableActionButtons } from '../UnderwritingTableColumnDefinitions/components';

function formatDisplayPercentage(params) {
    const { value, decimalPlace } = params;
    if (typeof value === 'undefined') {
        return '';
    }
    const formattedValue = numberFormat(value, decimalPlace);

    return params.colDef.field === 'currentTvm'
        ? `${formattedValue}x`
        : `${formattedValue}%`;
}

function getCellStyling(params, reportDate = null) {
    if (params.node.rowPinned) {
        return {};
    }

    const objectStyling = {};

    // Blue if column is editable (Year 1-Year 4 styling handled in UnderwritingPaidInRenderer)
    if (params.colDef.editable) {
        objectStyling.color = '#268fb3';
    }

    if (params.data?.userEdited?.includes(params.colDef.field)) {
        objectStyling.fontWeight = 'bold';
    } else {
        objectStyling.fontWeight = 'normal';
    }

    if (reportDate) {
        const {
            data: { closingYear, commitQtr },
            value,
        } = params;

        const vintageDate = getVintageDate(commitQtr, closingYear);
        const numYrsFromVinToCurrDate = closingYear
            ? calculateNumQuarter(vintageDate, reportDate) / 4
            : null;

        objectStyling.backgroundColor =
            parseFloat(value) < parseFloat(numYrsFromVinToCurrDate)
                ? 'RGB(0, 120, 153, 0.25)'
                : 'transparent';
    }

    return objectStyling;
}

const UnderwritingTableColumnDefinitionsNew = (
    commitmentType,
    updateValue = null,
    resetRow = null,
    conversusAccess = false,
    reportDate = null,
    targetAllocationStrategies,
    userIsAdmin = null
) => {
    const colDefs = [
        {
            headerName: 'Asset Class',
            field: 'assetClass',
            pinned: 'left',
            editable: false,
            width: 120,
        },
        {
            headerName: 'Pacing Strategy',
            field: 'strategy',
            pinned: 'left',
            editable: false,
            cellStyle: (params) => getCellStyling(params),
        },
        {
            headerName: 'Scenario',
            field: 'scenario',
            editable: false,
            filter: true,
            width: 100,
            suppressMenu: false,
            pinned: 'left',
            hide: true,
        },
        {
            headerName: 'Diversification',
            field: 'numFunds',
            headerTooltip:
                'The Diversification input is used to determine the range of the high/low parameters. For instance, selecting one commitment indicates that the possibility of a very low or very high outcome is possible. As the number of commitments increases, the range of outcomes converges towards the median due to diversification',
            editable: ({ data: { scenario } }) => scenario === 'Base',
            filter: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: () => ({
                values: ['1', '5', '10'],
                field: 'numFunds',
                updateValue,
            }),
            onCellValueChanged: ({ newValue, column: { colId }, data }) => {
                updateValue(newValue, colId, data);
            },
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellStyle: getCellStyling,
            width: 100,
            suppressMenu: false,
        },
        {
            headerName: 'Paid In',
            field: 'paidIn',
            headerTooltip: 'Values must be between 0 - 100',
            type: 'numericColumn',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: (params) => {
                const decimalPlace = 0;
                return {
                    params,
                    decimalPlace,
                };
            },
            cellEditor: 'numericEditor',
            cellEditorParams: () => ({
                field: 'paidIn',
                updateValue,
            }),
            onCellValueChanged: numericValidator,
            editable: true,
            cellStyle: (params) => getCellStyling(params),
            filter: false,
            width: 100,
            suppressMenu: true,
        },
        {
            headerName: 'Paid In',
            children: [
                {
                    headerName: 'Year 1',
                    field: 'rc1',
                    headerTooltip: 'Values must be between 0 - 100',
                    type: 'numericColumn',
                    cellRenderer: 'UnderwritingPaidInRenderer',
                    cellRendererParams: (params) => {
                        const decimalPlace = 0;
                        return {
                            params,
                            decimalPlace,
                            year: 1,
                        };
                    },
                    cellEditor: 'numericEditor',
                    cellEditorParams: () => ({ field: 'rc1', updateValue }),
                    editable: ({ data: { fundAge } }) => !(fundAge > 1),
                    onCellValueChanged: numericValidator,
                    filter: false,
                    width: 100,
                    suppressMenu: true,
                },
                {
                    headerName: 'Year 2',
                    field: 'rc2',
                    headerTooltip: 'Values must be between 0 - 100',
                    type: 'numericColumn',
                    cellRenderer: 'UnderwritingPaidInRenderer',
                    cellRendererParams: (params) => {
                        const decimalPlace = 0;
                        return {
                            params,
                            decimalPlace,
                            year: 2,
                        };
                    },
                    cellEditor: 'numericEditor',
                    cellEditorParams: () => ({ field: 'rc2', updateValue }),
                    editable: ({ data: { fundAge } }) => !(fundAge > 2),
                    onCellValueChanged: numericValidator,
                    filter: false,
                    width: 100,
                    suppressMenu: true,
                },
                {
                    headerName: 'Year 3',
                    field: 'rc3',
                    headerTooltip: 'Values must be between 0 - 100',
                    type: 'numericColumn',
                    cellRenderer: 'UnderwritingPaidInRenderer',
                    cellRendererParams: (params) => {
                        const decimalPlace = 0;
                        return {
                            params,
                            decimalPlace,
                            year: 3,
                        };
                    },
                    cellEditor: 'numericEditor',
                    cellEditorParams: () => ({ field: 'rc3', updateValue }),
                    editable: ({ data: { fundAge } }) => !(fundAge > 3),
                    onCellValueChanged: numericValidator,
                    filter: false,
                    width: 100,
                    suppressMenu: true,
                },
                {
                    headerName: 'Year 4',
                    field: 'rc4',
                    headerTooltip: 'Values must be between 0 - 100',
                    type: 'numericColumn',
                    cellRenderer: 'UnderwritingPaidInRenderer',
                    cellRendererParams: (params) => {
                        const decimalPlace = 0;
                        return {
                            params,
                            decimalPlace,
                            year: 4,
                        };
                    },
                    cellEditor: 'numericEditor',
                    cellEditorParams: () => ({ field: 'rc4', updateValue }),
                    editable: ({ data: { fundAge } }) => !(fundAge > 4),
                    onCellValueChanged: numericValidator,
                    filter: false,
                    width: 100,
                    suppressMenu: true,
                },
                {
                    headerName: 'Year 5+',
                    field: 'rc5',
                    headerTooltip: 'Values must be between 0 - 100',
                    type: 'numericColumn',
                    cellRenderer: formatDisplayPercentage,
                    cellRendererParams: (params) => {
                        const decimalPlace = 0;
                        return {
                            params,
                            decimalPlace,
                        };
                    },
                    cellEditor: 'numericEditor',
                    cellEditorParams: () => ({ field: 'rc5', updateValue }),
                    onCellValueChanged: numericValidator,
                    editable: true,
                    cellStyle: (params) => getCellStyling(params),
                    filter: false,
                    width: 100,
                    suppressMenu: true,
                },
            ],
        },
        {
            headerName: 'IRR',
            field: 'irr',
            headerTooltip: 'Values must be between -99 - 50',
            type: 'numericColumn',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: (params) => {
                const decimalPlace = 1;
                return {
                    params,
                    decimalPlace,
                };
            },
            cellEditor: 'numericEditor',
            cellEditorParams: () => ({
                field: 'irr',
                updateValue,
            }),
            onCellValueChanged: numericValidator,
            editable: true,
            cellStyle: (params) => getCellStyling(params),
            filter: false,
            width: 100,
            suppressMenu: true,
        },
        {
            headerName: 'Yield',
            field: 'yld',
            headerTooltip: 'Values must be between 0 - 20',
            type: 'numericColumn',
            cellRenderer: formatDisplayPercentage,
            cellRendererParams: (params) => {
                const decimalPlace = 1;
                return {
                    params,
                    decimalPlace,
                };
            },
            cellEditor: 'numericEditor',
            cellEditorParams: () => ({
                field: 'yld',
                updateValue,
            }),
            editable: true,
            cellStyle: (params) => getCellStyling(params),
            onCellValueChanged: numericValidator,
            filter: false,
            width: 100,
            suppressMenu: true,
        },
        {
            headerName: 'Fund Life',
            field: 'fundLife',
            headerTooltip: 'Values must be between 1 - 100',
            cellStyle: (params) => getCellStyling(params, reportDate),
            type: 'numericColumn',
            cellEditor: 'numericEditor',
            cellEditorParams: () => ({
                field: 'fundLife',
                updateValue,
            }),
            editable: true,
            onCellValueChanged: (event) => {
                const { newValue, column, data } = event;
                updateValue(newValue, column.colId, data);
                numericValidator(event);
            },
            filter: false,
            width: 100,
            suppressMenu: true,
        },
        {
            headerName: 'Distribution Delay',
            field: 'bow',
            headerTooltip: 'Values must be between 0.1 - 10',
            type: 'numericColumn',
            cellEditor: 'numericEditor',
            cellEditorParams: () => ({
                field: 'bow',
                updateValue,
            }),
            editable: true,
            cellStyle: (params) => getCellStyling(params),
            onCellValueChanged: numericValidator,
            filter: false,
            width: 100,
            suppressMenu: true,
        },
        {
            headerName: 'Contributions Timing', // defaults to base, hide when not conversus permissioned
            field: 'contribTiming',
            hide: !conversusAccess && !userIsAdmin,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Base', 'Fast', 'Slow'],
            },
            onCellValueChanged: ({ newValue, column, data }) => {
                updateValue(newValue, column.colId, data);
            },
            editable: ({ data: { scenario } }) => scenario === 'Base',
            cellStyle: ({ data: { scenario } }) => ({
                color: scenario === 'Base' ? '#268fb3' : '#000000',
            }),
            filter: false,
            width: 100,
            suppressMenu: true,
        },
        {
            headerName: 'Actions',
            cellRenderer: UnderwritingTableActionButtons,
            cellRendererParams: () => ({
                handleReset: resetRow,
                value: 'default',
            }),
            editable: false,
            width: 100,
        },
    ];

    return colDefs;
};

export default UnderwritingTableColumnDefinitionsNew;
