// @flow
import React, { useContext, useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Input,
    Button,
    Label,
} from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { StaticsContext } from 'context';
import { DefaultAssumptionsColumnDefinitions } from './components';

type Props = {
    isOpen: boolean,
    onClose: Function,
};

/**
 * Create a simple non-editable table
 * containing raw version of uploaded/selected data
 */
const DefaultAssumptionsModal = ({ isOpen, onClose }: Props) => {
    const { assetClasses, strategyParameters } = useContext(StaticsContext);

    const [rowData, setRowData] = useState([]);
    const [filteredRowData, setFilteredRowData] = useState([]);
    const [scenario, setScenario] = useState('Base');

    const overlayLoadingTemplate = '<span>Loading Pacings...</span>';
    const autoGroupColumnDef = {
        headerName: 'Sub-Sector',
        field: 'strategy',
        width: 200,
    };
    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        menuTabs: ['filterMenuTab'],
    };

    const filter = () => {
        const filterByScenario = rowData.filter((row) => row.case === scenario);

        setFilteredRowData(filterByScenario);
    };

    const constructRowData = async () => {
        const newRowData = [];

        strategyParameters.forEach((row) => {
            if (!row.strategy.includes('User Defined')) {
                const assetClassShort = row.strategy.slice(0, 2);
                const strategy = row.strategy.slice(3);

                const assetClassInfo = assetClasses.find(
                    ({ short_name: shortName }) => shortName === assetClassShort
                );

                const holder = {
                    assetClass: assetClassInfo
                        ? assetClassInfo.long_name
                        : null,
                    strategy,
                    paidIn: row.paidIn * 100,
                    rc1: row.rc1 * 100,
                    rc2: row.rc2 * 100,
                    rc3: row.rc3 * 100,
                    rc4: row.rc4 * 100,
                    rc5: row.rc5 * 100,
                    irr: row.irr * 100,
                    yld: row.yld * 100,
                    fundLife: row.fundLife,
                    delayInDistributions: row.bow,
                    case: row.case,
                    numFunds: row.numFunds,
                    contribTiming: row.contribTiming,
                };

                newRowData.push(holder);
            }
        });
        await setRowData(newRowData);
    };

    useEffect(() => {
        constructRowData();
    }, []);

    useEffect(() => {
        if (rowData.length) {
            filter();
        }
    }, [rowData]);

    useEffect(() => {
        if (filteredRowData.length && filteredRowData[0].case !== scenario) {
            filter();
        }
    }, [scenario]);

    const handleChange = async (event) => {
        const { value } = event.target;

        await setScenario(value);
    };

    const onGridReady = async (params) => {
        params.api.sizeColumnsToFit();
    };

    const getOptions = () => (
        <Input type='select' defaultValue='Base' onChange={handleChange}>
            <option key='Base' value='Base'>
                Base
            </option>
            <option key='High' value='High'>
                High
            </option>
            <option key='Low' value='Low'>
                Low
            </option>
        </Input>
    );

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            style={{ minWidth: '1200px' }}
        >
            <ModalHeader toggle={onClose}>Default Assumptions</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label>
                                <b>Scenario: </b>
                            </Label>
                            {getOptions()}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div
                            className='ag-theme-balham'
                            style={{
                                position: 'relative',
                                height: '500px',
                                width: '100%',
                            }}
                        >
                            <AgGridReact
                                animateRows
                                autoGroupColumnDef={autoGroupColumnDef}
                                columnDefs={DefaultAssumptionsColumnDefinitions()}
                                defaultColDef={defaultColDef}
                                groupDefaultExpanded={-1}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={overlayLoadingTemplate}
                                rowData={filteredRowData}
                                lockVisible
                                suppressExcelExport
                                suppressCsvExport
                                suppressMenuHide
                            />
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color='secondary'
                    className='mt-0'
                    onClick={onClose}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DefaultAssumptionsModal;
