import {
    formatTableData,
    formatCommitSchedule,
    formatPsetList,
    formatInputs,
} from './PacingAnalysisHelpers';

/**
 * Create a neat object with formatted paramters for
 * estimate commitments and run analysis
 */
const GatherParameterHelper = (
    pacingAnalysisContext,
    targetAllocationContext,
    targetAllocationStrategies,
    underwritingContext,
    tableData,
    smoothing,
    assetClassShortNames,
    strategyParameters,
    reportDate,
    useForwardNav
) => {
    const { sliderValues, fundSliderValues } = underwritingContext;
    const {
        userDefinedRenamedTAS,
        targetExposureType,
        strategyAllocation,
    } = targetAllocationContext;
    const formattedTableData = formatTableData(tableData, fundSliderValues);

    const noStrategies = strategyAllocation.filter(({ selected }) => selected).length === 0

    const formattedInputs = formatInputs(
        pacingAnalysisContext,
        targetAllocationContext,
        targetAllocationStrategies,
        smoothing,
        assetClassShortNames,
        reportDate,
        useForwardNav
    );

    const {
        formattedCommitmentSchedule,
        formattedOptimizer,
        commitConstraints,
    } = formatCommitSchedule(
        pacingAnalysisContext,
        userDefinedRenamedTAS,
        targetExposureType,
        noStrategies,
        reportDate
    );
    const formattedPsetList = formatPsetList(
        sliderValues,
        fundSliderValues,
        tableData,
        strategyParameters
    );

    formattedInputs.commitScheduleOptimizer = formattedOptimizer;
    formattedInputs.commitConstraints = commitConstraints;

    return {
        existingLabel: formattedTableData,
        psetlist: formattedPsetList,
        inputs: formattedInputs,
        commitSchedule: formattedCommitmentSchedule,
    };
};

export default GatherParameterHelper;
