// @flow
import React from 'react';
import {
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
} from 'reactstrap';
import { IoMdInformationCircleOutline as InformationIcon } from 'react-icons/io';

type Props = {
    setIndexVersion: Function,
    indexVersion: string,
    toggleFwdNav: Function,
    useForwardNav: boolean,
    conversusAccess: boolean,
    userIsAdmin: boolean,
    userSpiClientId: number,
};

const ForwardNavInputs = ({
    setIndexVersion,
    indexVersion,
    toggleFwdNav,
    useForwardNav,
    conversusAccess,
    userIsAdmin,
    userSpiClientId,
}: Props) => {
    const style = {
        display: 'inline-flex',
        top: '-10px',
        left: '30px',
    };
    const precentReturnDisplay = {
        display:
            useForwardNav && (conversusAccess || userIsAdmin) ? 'flex' : 'none',
    };

    return (
        <>
            <Row>
                <Col md={12}>
                    {!userSpiClientId && (
                        <FormGroup>
                            <Input
                                onChange={() => toggleFwdNav()}
                                checked={useForwardNav}
                                type='checkbox'
                                style={style}
                            />
                            <Label
                                style={{
                                    marginLeft: '20px',
                                    display: 'inline-flex',
                                }}
                            >
                                DVE Estimated NAV
                            </Label>
                            <span href='#' id='UncontrolledTooltipExample' style={{marginLeft: '10px'}}>
                                <InformationIcon />
                            </span>
                            <UncontrolledTooltip
                                style={{
                                    backgroundColor: '#007899',
                                    color: '#fff',
                                    minWidth: '500px',
                                    fontSize: '12px',
                                }}
                                placement='auto'
                                target='UncontrolledTooltipExample'
                            >
                                The DVE Estimated NAV leverages the Daily
                                Valuation Engine to estimate unrealized
                                gain/loss across each fund in the portfolio.
                            </UncontrolledTooltip>
                        </FormGroup>
                    )}
                </Col>
            </Row>
            <Row style={precentReturnDisplay}>
                <Col md={12}>
                    <div>
                        <strong>Select an Index:</strong>
                    </div>
                </Col>
                <Col xs={6}>
                    <FormGroup check className='mb-3' inline>
                        <Label check>
                            <Input
                                id='public'
                                type='radio'
                                value='public'
                                checked={indexVersion === 'public'}
                                onChange={(e) => setIndexVersion(e)}
                                inline='true'
                            />
                            Public Index
                        </Label>
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup check className='mb-3' inline>
                        <Label check>
                            <Input
                                id='industry'
                                type='radio'
                                value='industry'
                                checked={indexVersion === 'industry'}
                                onChange={(e) => setIndexVersion(e)}
                                inline='true'
                            />
                            Industry Specific Index
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};

export default ForwardNavInputs;
