//@flow
import React, { useEffect, useCallback, useState } from 'react';
import {
    Button,
    Breadcrumbs,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from '@material-ui/core';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
    object as yupObject,
    string as yupString,
    bool as yupBool,
    number as yupNumber,
} from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { createPacingUser, updatePacingUser, getClients } from 'api';
import FormikErrorHelper from './FormikErrorHelper';
import DialogTitleClose from './DialogTitleClose';

type CreateUserModalProps = {
    clients: Array,
    pushUpUser: Function,
    closeUserModal: Function,
    userModalOpen: any,
};

const validationSchema = yupObject().shape({
    name: yupString()
        .required('This field is required')
        .min(6, 'Name must be more than 8 characters')
        .max(100, 'Name cannot be more than 100 characters'),
    email: yupString()
        .required('Email Required')
        .email(),
    spiClientId: yupNumber().nullable(),
    parameterLabAccess: yupBool(),
    omniImportAccess: yupBool(),
    isActive: yupBool(),
    trialUser: yupBool(),
    accessUntil: yupString().nullable(),
    isAdmin: yupBool(),
    isSSGEmployee: yupBool(),
});

const UserModal = ({
    userModalOpen,
    closeUserModal,
    pushUpUser,
}: CreateUserModalProps) => {
    const [clients, setClients] = useState([]);

    const onSubmit = useCallback(async (user, { setSubmitting }) => {
        setSubmitting(true);

        const {
            userId,
            name,
            email,
            spiClientId,
            omniImportAccess,
            isActive,
            trialUser,
            accessUntil,
            isAdmin,
        } = user;
        let response;

        if (!spiClientId && !email.includes('stepstonegroup') && !trialUser) {
            Swal.fire(
                'Error',
                'Please select a client for this user.',
                'error'
            );
            setSubmitting(false);
            return;
        }

        try {
            if (userId !== null) {
                response = await updatePacingUser(userId, {
                    name,
                    email,
                    disable_welcome: 1,
                    is_admin: isAdmin ? 1 : 0,
                    omni_import_access: omniImportAccess || isSSGEmployee ? 1 : 0,
                    parameter_lab_access: 1,
                    active: isActive ? 1 : 0,
                    trial_user: trialUser ? 1 : 0,
                    access_until: accessUntil !== '' ? accessUntil : null,
                    spi_client_id: spiClientId === '' ? null : spiClientId,
                });
            } else {
                response = await createPacingUser({
                    name,
                    email,
                    disable_welcome: 1,
                    is_admin: isAdmin ? 1 : 0,
                    omni_import_access: omniImportAccess || isSSGEmployee ? 1 : 0,
                    parameter_lab_access: 1,
                    active: isActive ? 1 : 0,
                    trial_user: trialUser ? 1 : 0,
                    access_until: accessUntil !== '' ? accessUntil : null,
                    spi_client_id: spiClientId === '' ? null : spiClientId,
                });
            }
        } catch (error) {
            console.log('error', error);
            if (error.status === 401) {
                Swal.fire(
                    'Error',
                    'Not authorized. Please ensure you are logged in.',
                    'error'
                );
            } else {
                Swal.fire(
                    'Error',
                    'Something went wrong while trying to create the user. Please try again or contact an administrator.',
                    'error'
                );
            }
            setSubmitting(false);
            return;
        }
        setSubmitting(false);
        Swal.close();
        pushUpUser(response);
    }, []);

    const {
        errors,
        handleSubmit,
        handleChange,
        isSubmitting,
        isValidating,
        touched,
        setFieldValue,
        values: {
            name,
            email,
            spiClientId,
            parameterLabAccess,
            omniImportAccess,
            isActive,
            trialUser,
            accessUntil,
            isAdmin,
            isSSGEmployee,
        },
    } = useFormik({
        initialValues:
            userModalOpen === true
                ? {
                      name: '',
                      email: '',
                      isClient: false,
                      spiClientId: null,
                      parameterLabAccess: false,
                      omniImportAccess: false,
                      isActive: true,
                      trialUser: false,
                      accessUntil: '',
                      isAdmin: false,
                      userId: null,
                      isSSGEmployee: false,
                  }
                : userModalOpen,
        onSubmit,
        validationSchema,
    });

    const updateClients = async () => {
        const results = await getClients();
        setClients(results);
    };

    useEffect(() => {
        if (email.includes('stepstonegroup')) {
            setFieldValue('isSSGEmployee', true);
        } else {
            setFieldValue('isSSGEmployee', false);
        }
    }, [email]);

    useEffect(() => {
        if (spiClientId && isAdmin) {
            setFieldValue('isAdmin', false);
        }
    }, [spiClientId]);

    useEffect(() => {
        updateClients();
    }, []);

    const errorProps = {
        touched,
        errors,
    };

    const loading = isSubmitting || isValidating;
    const isOpen = Boolean(userModalOpen);

    return (
        <Dialog open={isOpen} fullWidth maxWidth='sm' onClose={closeUserModal}>
            <DialogTitleClose onClose={closeUserModal} loading={loading}>
                <Breadcrumbs>
                    <div>Users</div>
                    <div>{userModalOpen === true ? 'Add' : 'Modify'}</div>
                </Breadcrumbs>
            </DialogTitleClose>
            <DialogContent>
                <Grid
                    container
                    direction='column'
                    spacing={1}
                    style={{ paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <Grid item xs={12}>
                        <TextField
                            name='name'
                            id='name'
                            label='Full Name'
                            onChange={handleChange}
                            required
                            value={name}
                            error={Boolean(touched?.name && errors?.name)}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            type='text'
                        />
                        <FormikErrorHelper name='name' {...errorProps} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name='email'
                            id='email'
                            label='E-mail'
                            onChange={handleChange}
                            required
                            value={email}
                            error={Boolean(touched?.email && errors?.email)}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            type='text'
                        />
                        <FormikErrorHelper name='email' {...errorProps} />
                    </Grid>
                    {trialUser || isSSGEmployee ? (
                        <></>
                    ) : (
                        <Grid item xs={12}>
                            <Autocomplete
                                id='spiClientId'
                                options={clients}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option) =>
                                    option.id === spiClientId
                                }
                                onChange={(e, newVal) => {
                                    if (newVal) {
                                        setFieldValue('spiClientId', newVal.id);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Client'
                                        variant='outlined'
                                        name='spiClientId'
                                    />
                                )}
                                error={Boolean(touched?.email && errors?.email)}
                            />
                            <FormikErrorHelper
                                name='spiClientId'
                                {...errorProps}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isActive}
                                    onChange={handleChange}
                                    name='isActive'
                                    color='primary'
                                />
                            }
                            label='Active'
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={parameterLabAccess}
                                    onChange={handleChange}
                                    name='parameterLabAccess'
                                    color='primary'
                                />
                            }
                            label='Parameter Lab Access'
                        />
                    </Grid> */}
                    {isSSGEmployee ? (
                        <></>
                    ) : (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={omniImportAccess}
                                        onChange={handleChange}
                                        name='omniImportAccess'
                                        color='primary'
                                    />
                                }
                                label='SPI Reporting Import Access'
                            />
                        </Grid>
                    )}
                    {isSSGEmployee ? (
                        <></>
                    ) : (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={trialUser}
                                        onChange={handleChange}
                                        name='trialUser'
                                        color='primary'
                                    />
                                }
                                label='Trial User'
                            />
                        </Grid>
                    )}

                    {trialUser ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12}>
                                <TextField
                                    id='accessUntil'
                                    label='Access Until'
                                    type='date'
                                    value={accessUntil}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                />
                                <FormikErrorHelper
                                    name='accessUntil'
                                    {...errorProps}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    ) : (
                        <></>
                    )}
                    {!spiClientId && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isAdmin}
                                        onChange={handleChange}
                                        name='isAdmin'
                                        color='primary'
                                    />
                                }
                                label='Admin Access'
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color='primary'
                    onClick={handleSubmit}
                    disabled={loading}
                    variant='outlined'
                >
                    {userModalOpen === true ? 'Create' : 'Save'} User
                </Button>
                <Button
                    onClick={closeUserModal}
                    disabled={loading}
                    variant='outlined'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserModal;
