//@flow
import React, { useCallback, useEffect, useState } from 'react';
import swal2 from 'sweetalert2';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import addLoadingEndAdornment from './addLoadingEndAdornment';
import { getPacingData } from '../../../api';
import SelectorOption from './SelectorOption';

const optionToLabel = (option) => {
    if (typeof option === 'string') {
        return option;
    }

    return option?.name || '';
};

type Props = {
    selectedPacing: any,
    setSelectedPacing: Function,
    userSpiClientId: number | null,
};

const PacingSelector = ({
    selectedPacing,
    setSelectedPacing,
    userSpiClientId,
}: Props) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    /** On selecting a pacing */
    const onChange = useCallback((event, newValue) => {
        setSelectedPacing(newValue);
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let newOptions = [];
            try {
                newOptions = await getPacingData();
                // If the user is a client, display only Pacings with matching Client ID
                if (userSpiClientId !== null) {
                    newOptions = newOptions.filter(
                        ({ spi_client_id: pacingSpiClientId }) =>
                            pacingSpiClientId === userSpiClientId
                    );
                }
            } catch (error) {
                console.log(error);
                swal2.fire({
                    type: 'error',
                    html:
                        'No pacings found. If you think this is an error, please contact an admin.',
                });
            }
            setOptions(newOptions);
            setLoading(false);
        })();
    }, []);

    return (
        <Grid item>
            <Autocomplete
                autoComplete
                options={options.sort((a, b) =>
                    b.created_at.localeCompare(a.created_at)
                )}
                getOptionLabel={optionToLabel}
                renderOption={(option) => (
                    <SelectorOption
                        option={option}
                        userSpiClientId={userSpiClientId}
                    />
                )}
                getOptionSelected={(thisOption) =>
                    thisOption?.pacing_data_id ===
                    selectedPacing?.pacing_data_id
                }
                loading={loading}
                onChange={onChange}
                value={selectedPacing}
                renderInput={(params) => (
                    <TextField
                        className='db-load'
                        {...params}
                        variant='outlined'
                        disabled={loading}
                        label='Select a pacing'
                        fullWidth
                        size='small'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: addLoadingEndAdornment(
                                loading,
                                params?.InputProps?.endAdornment
                            ),
                        }}
                    />
                )}
            />
        </Grid>
    );
};

export default PacingSelector;
