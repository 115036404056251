import { Path, Circle, geometry, drawing } from '@progress/kendo-drawing';

import { colorCombos } from 'utils';

const plotGraphItem = (
    graphSeriesName,
    dashType = null,
    id = null,
    drawBubble = false,
    color = null
) => {
    const rect = new geometry.Rect([0, 0], [200, 150]);
    const layout = new drawing.Layout(rect, {
        spacing: 2,
        lineSpacing: 10,
        alignItems: 'center',
        alignContent: 'center',
        wrap: false,
    });

    let label = '';
    let path = '';

    const labelConfig = {
        fill: {
            color: '#343a40',
        },
    };

    if (drawBubble) {
        const { Circle: GeomCircle } = geometry;
        const ge = new GeomCircle([1, 3], 3);
        path = new Circle(ge, {
            stroke: {
                color,
                width: 2,
            },
        });
    } else {
        path = new Path({
            stroke: {
                color,
                dashType,
            },
        });

        if (
            id === 'contribUncertainty' ||
            id === 'distribUncertainty' ||
            id === 'cashFlowUncertainty' ||
            id === 'navUncertainty'
        ) {
            path.moveTo(0, 0)
                .lineTo(20, 0)
                .lineTo(20, 4)
                .lineTo(0, 4)
                .close()
                .fill(color);
        } else {
            path.moveTo(0, 0).lineTo(20, 0);
        }
    }

    label = new drawing.Text(graphSeriesName, [0, 0], labelConfig);
    layout.append(path, label);
    layout.reflow();
    return layout;
};

const plotGraphHelper = (commitmentType) => (event) => {
    let layout = null;
    let color = null;
    const seriesName = event.series.name;
    const { id } = event.series;

    switch (seriesName) {
        case 'Contributions':
            layout = plotGraphItem(
                seriesName,
                null,
                null,
                false,
                colorCombos[0].primary
            );
            break;
        case 'Net Cash Flows':
            layout = plotGraphItem(
                seriesName,
                null,
                null,
                false,
                colorCombos[2].primary
            );
            break;
        case 'Net Asset Value':
            layout = plotGraphItem(
                seriesName,
                null,
                null,
                false,
                colorCombos[3].primary
            );
            break;
        case 'Distributions':
            layout = plotGraphItem(
                seriesName,
                null,
                null,
                false,
                colorCombos[1].primary
            );
            break;
        case 'Base Contributions':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    'longDash',
                    null,
                    false,
                    colorCombos[0].primary
                );
            }
            break;
        case 'Base Distributions':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    'longDash',
                    null,
                    false,
                    colorCombos[1].primary
                );
            }
            break;
        case 'Base NCFs':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    'longDash',
                    null,
                    false,
                    colorCombos[2].primary
                );
            }
            break;
        case 'Base NAV':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    'longDash',
                    null,
                    false,
                    colorCombos[3].primary
                );
            }
            break;
        case 'Yield':
            layout = plotGraphItem(
                seriesName,
                null,
                null,
                false,
                colorCombos[2].primary
            );
            break;
        case 'Uncertainty':
        case 'contribUncertainty':
        case 'distribUncertainty':
            if (event.series.id === 'contribUncertainty') {
                color = colorCombos[0].primary;
            } else if (id === 'distribUncertainty') {
                color = colorCombos[1].primary;
            } else if (id === 'cashFlowUncertainty') {
                color = colorCombos[2].primary;
            } else if (id === 'navUncertainty') {
                color = colorCombos[3].primary;
            }
            layout = plotGraphItem(seriesName, null, id, false, color);
            break;
        case 'Contrib To Date':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    null,
                    null,
                    true,
                    colorCombos[0].primary
                );
            }
            break;
        case 'Distrib To Date':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    null,
                    null,
                    true,
                    colorCombos[1].primary
                );
            }
            break;
        case 'NCF To Date':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    null,
                    null,
                    true,
                    colorCombos[2].primary
                );
            }
            break;
        case 'Current NAV':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    null,
                    null,
                    true,
                    colorCombos[3].primary
                );
            }
            break;
        case 'Projected Contributions':
            if (commitmentType === 'existing') {
                layout = plotGraphItem(
                    seriesName,
                    'dot',
                    null,
                    false,
                    colorCombos[0].primary
                );
            }
            break;
        default:
            break;
    }
    return layout;
};

export default plotGraphHelper;
