import React, { useContext, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Modal, Grid, Button } from '@ssgglobal/techintnue'
import { StaticsContext, TargetAllocationContext } from 'context'

import AssetTab from './AssetTab'
import StrategyTab from './StrategyTab'
import { StrategiesTables } from './StrategiesTables'
import { useMemo } from 'react';


const TargetAllocationModal = ({
    isOpen,
    onClose,
    openCreateStrategyModal,
    openDefaultAssumptionsModal,
}) => {
    const { assetClasses, strategiesByAllocation } = useContext(StaticsContext)
    const { hasUserDefinedTASForAssetClass, userDefinedRenamedTAS, strategyAllocation } = useContext(TargetAllocationContext)
    
    const [strategy, setStrategy] = useState(0)
    const handleStrategyChange = (_event, value) => setStrategy(value);
    const [asset, setAsset] = useState(0)
    const handleAssetChange = (_event, value) => {
        setAsset(value);
        setStrategy(0);
    }
    
    const assetList = assetClasses.map((asset) => asset.long_name);
    const strategiesList = useMemo(() => {
        return (strategiesByAllocation[assetClasses[asset]?.short_name] || [])
            .filter((strat) => {
                if (strat === 'User Defined Strategies') {
                    // filter out user define tab if there are not user defined strategies
                    return hasUserDefinedTASForAssetClass(assetClasses[asset]?.short_name);
                }
                return true;
            });
    }, [assetClasses, asset, strategiesByAllocation, userDefinedRenamedTAS])
    

    return (
        <Modal
            title="Add Investment Strategies"
            isThemed
            size="xl"
            sx={{
                '> .MuiBox-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '650px',
                    '> .MuiBox-root:nth-child(2)': {
                        height: '-webkit-fill-available',
                        '> .MuiBox-root': {
                            height: '100%',
                            maxHeight: 'none'
                        }
                    }
                }
            }}
            open={isOpen}
            onClose={onClose}
        >
            <Grid
                container
                flex
                gap="24px"
                sx={{
                    '> .MuiBox-root > div[role=tabpanel]': {
                        height: 0 
                    }
                }}
            >
                <AssetTab
                    value={asset}
                    onChange={handleAssetChange}
                    options={assetList}
                />
                <StrategyTab
                    value={strategy}
                    onChange={handleStrategyChange}
                    options={strategiesList || []}
                />
                <StrategiesTables
                    asset={assetClasses[asset]?.short_name}
                    strategy={strategiesList[strategy]}
                />
                <Grid
                    container
                    item
                    flex
                    spacing="16px"
                    justifyContent="start"
                >
                    <Grid item>
                        <Button
                            onClick={() => {
                                openCreateStrategyModal();
                                onClose();
                            }}
                            theme="secondary"
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                                width: '115px'
                            }}
                        >
                            <AddIcon />
                            <span>Create New</span>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                openDefaultAssumptionsModal();
                                onClose();
                            }}
                            theme="secondary"
                            sx={{ width: '88px' }}
                        >
                            View Default Assumptions
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    flex
                    spacing="16px"
                    justifyContent="end"
                >
                    <Grid item>
                        <Button
                            onClick={onClose}
                            theme="secondary"
                            sx={{ width: '88px' }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={onClose}
                            theme="primary"
                            sx={{ width: '88px' }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default TargetAllocationModal;
