// @flow
import React, { useContext } from 'react';
import { PacingContext } from 'context';
import {InputBase, InputLabel, InputTextField} from '@ssgglobal/techintnue'

type Props = {
    setNumber: Function,
    totalAum: number,
    setUserEdited: Function,
};

const inputStyle = {
    color: '#268fb3',
    border: '1px solid #BDBFBF',
    borderRadius: '5px',
    padding: '8px 8px 8px 16px',
    width: '100%',
    height: '40px',
}

export default function({ setNumber, totalAum, setUserEdited }: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    return (
        <>
            <InputLabel md={12} title='Amount is in millions'>
                Total Portfolio AuM (M)
            </InputLabel>
            <>
                {pacingClientReadOnlyAndSpiClientId() ? (
                    <div>{totalAum}</div>
                ) : (
                    <InputTextField
                        name='totalAum'
                        step='0.001'
                        type='number'
                        defaultValue={totalAum}
                        onChange={setNumber}
                    />
                )}
            </>
        </>
    );
}
