//@flow
import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { FormGroup } from 'reactstrap/es';

type Props = {
    options: any,
    loading: boolean,
    onChange: Function,
};

const CurrencySelector = ({ options, loading = false, onChange }: Props) => {
    const handleChange = useCallback((event, newValue) => {
        onChange(newValue);
    }, []);

    return (
        <FormGroup>
            <Autocomplete
                autoComplete
                options={options}
                getOptionLabel={({ label }) => label}
                defaultValue={options.find(item => item.value === 'Portfolio') || ''}
                onChange={(e, newValue) => {
                    handleChange(e, {
                        value: newValue.value,
                        name: 'Currency',
                    });
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className='db-load'
                        variant='outlined'
                        disabled={loading}
                        margin='normal'
                        label='Currency'
                        fullWidth
                        size='small'
                    />
                )}
            />
        </FormGroup>
    );
};

export default CurrencySelector;
