export const getCommitScheduleStylingForPercentage = (params) => {
    const row = params.data;
    const column = params.column;

    if (params.node.rowPinned || params.data?.strategy == 'Portfolio AuM' || params.data?.strategy == 'Liquid Assets') {
        if (column.colId == 'alloc') {
            const color = row.alloc < 100 ? 'red' : 'green';
            return {
                color,
                fontWeight: 'bold'
            };
        }
        return {};
    }
    const inputCellStyle = {};
    const id = params.colDef.field;

    if (params.colDef.editable) {
        inputCellStyle.color = '#268fb3';
    }

    if (row.userEdited?.includes(id) && params.colDef.pinned != 'left') {
        inputCellStyle.fontWeight = 'bold';
    } else {
        inputCellStyle.fontWeight = 'normal';
    }

    inputCellStyle.display = 'flex';

    return inputCellStyle;
};

export const getCommitScheduleStyling = (params, portCont = null) => {
    const row = params.data;
    const column = params.column;

    if (params.node.rowPinned || params.data?.strategy == 'Portfolio AuM' || params.data?.strategy == 'Liquid Assets') {
        return {};
    }

    const inputCellStyle = {};

    if (
        row.strategy === 'Total Portfolio Net Cash Flows' ||
        row.strategy === 'Total Portfolio Growth p.a. (%)'
    ) {
        let userEdited = [];

        if (portCont?.userEdited?.length) {
            userEdited = portCont.userEdited;
        }
        if (!Array.isArray(row.userEdited)) {
            row.userEdited = [];
        }
        let combined = userEdited.concat(row.userEdited);
        const set = new Set(combined);

        combined = Array.from(set);
        row.userEdited = combined;
    }

    const id = params.colDef.field;

    if (params.colDef.editable) {
        inputCellStyle.color = '#268fb3';
    }

    if (row.userEdited?.includes(id) && params.colDef.pinned != 'left') {
        inputCellStyle.fontWeight = 'bold';
    } else {
        inputCellStyle.fontWeight = 'normal';
    }

    inputCellStyle.display = 'flex';

    return inputCellStyle;
};
