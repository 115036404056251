import { FormatNumber } from 'utils';
import { TotalsRowRenderer } from 'Landing/components';

export function ReadOnlyCommitmentScheduleColumnDefinitions(
    startYear,
    endYear,
    fiscalYearPlusOne
) {
    const tableDefinitions = [];
    tableDefinitions.push({
        headerName: 'Pacing Strategy',
        field: 'strategy',
        pinned: 'left',
        editable: false,
        width: 300,
        // resizable: false,
    });

    for (let i = startYear; i <= endYear; i++) {
        const year = fiscalYearPlusOne ? i + 1 : i;
        const stringYear = year.toString();
        if (year <= endYear) {
            const yearDefinition = {
                headerName: stringYear,
                field: stringYear,
                type: 'numericColumn',
                cellRenderer: FormatNumber,
                cellRendererParams: (params) => {
                    return {
                        data: params.data,
                    };
                },
                // cellEditor: 'numericEditor',
                // cellEditorParams: () => {
                //     return {
                //         field: stringYear,
                //         updateValue,
                //     };
                // },
                pinnedRowCellRenderer: 'totalsRowRenderer',
                pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
                editable: false,
                width: 125,
            };

            tableDefinitions.push(yearDefinition);
        }
    }

    return tableDefinitions;
}

export function ReadOnlyFrameworkComponents() {
    return {
        // numericEditor: NumericEditor,
        totalsRowRenderer: TotalsRowRenderer,
    };
}
