// @flow
import React, { useContext } from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { PacingContext, PacingAnalysisContext } from 'context';
import { numberFormat } from 'utils';

export default function() {
    const { growthIncrement, setGrowthIncrement } = useContext(
        PacingAnalysisContext
    );
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    const style = {
        marginTop: '8px',
        marginLeft: '18px',
    };

    const handleIncrementChangeOnBlur = ({ target: { value } }) => {
        let formattedValue = numberFormat(value);

        if (formattedValue < 0) {
            formattedValue = 0;
        } else if (formattedValue > 100) {
            formattedValue = 100;
        }

        setGrowthIncrement(formattedValue);
    };

    const updateValue = ({ key, target: { value } }) => {
        if (key === 'Enter') {
            const parsedValue = parseFloat(value);

            setGrowthIncrement(parsedValue);
        }
    };

    return (
        <Row>
            <Label
                style={style}
                title='Enter a value between 1 and 100 (up to 2 decimal places)'
            >
                Increment (%)
            </Label>
            <Col md={1}>
                {pacingClientReadOnlyAndSpiClientId() ? (
                    <div>{growthIncrement}</div>
                ) : (
                    <Input
                        name='growthIncrement'
                        type='number'
                        step='0.01'
                        defaultValue={growthIncrement}
                        onBlur={handleIncrementChangeOnBlur}
                        onKeyPress={updateValue}
                    />
                )}
            </Col>
        </Row>
    );
}
