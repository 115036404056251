/**
 * This context is for Pacing specific related data:
 * pacingId, pacingName, pacingClientId, pacingClientAccess, pacingClientReadOnly
 */

// @flow
import React, { createContext, useContext, useState } from 'react';
import type { Node } from 'react';
import { AuthContext } from './AuthContext';

type Props = {
    children: Node,
};

export const PacingContext = createContext({});
export const PacingContextConsumer = PacingContext.Consumer;

const PacingContextProvider = ({ children }: Props) => {
    const [pacingId, setPacingId] = useState(0);
    const [pacingName, setPacingName] = useState('');
    const [pacingClientId, setPacingClientId] = useState(null);
    const [pacingClientAccess, setPacingClientAccess] = useState(null);
    const [pacingClientReadOnly, setPacingClientReadOnly] = useState(null);

    const { userSpiClientId } = useContext(AuthContext);

    /**
     * Object passed to handleSave function in UnderwritingContext
     */
    const pacingContextDataToSave = {
        pacingId,
        pacingName,
        pacingClientId,
        pacingClientReadOnly,
        pacingClientAccess,
    };

    /**
     * Updates pacing related context data, must be run with all other context updater functions
     *
     * @param {number} updatedPacingId
     * @param {string} updatedPacingName
     * @param {number | null} updatedPacingClientId
     * @param {boolean} updatedPacingClientAccess
     * @param {boolean} updatedPacingClientReadOnly
     */
    const updatePacingContext = ({
        pacing_data_id: updatedPacingId,
        name: updatedPacingName,
        spi_client_id: updatedPacingClientId,
        client_access: updatedPacingClientAccess,
        client_read_only: updatedPacingClientReadOnly,
    }) => {
        setPacingId(updatedPacingId);
        setPacingName(updatedPacingName);
        setPacingClientId(updatedPacingClientId);
        setPacingClientAccess(updatedPacingClientAccess);
        setPacingClientReadOnly(updatedPacingClientReadOnly);
    };

    /**
     * Determines whether the user has a spi client id & if the pacing is client read only
     */
    const pacingClientReadOnlyAndSpiClientId = () =>
        !!(pacingClientReadOnly && userSpiClientId);

    return (
        <PacingContext.Provider
            value={{
                pacingClientAccess,
                pacingClientId,
                pacingClientReadOnly,
                pacingClientReadOnlyAndSpiClientId,
                pacingContextDataToSave,
                pacingId,
                pacingName,
                setPacingClientAccess,
                setPacingClientId,
                setPacingClientReadOnly,
                setPacingId,
                setPacingName,
                updatePacingContext,
            }}
        >
            {children}
        </PacingContext.Provider>
    );
};

export default PacingContextProvider;
