// @flow
import { quantUrl } from './BaseApi';

const quant = quantUrl || '';

export const getExcelBase = async () => {
    const url = new URL(`${quant}/pacing/excelbase/`);

    const response = await fetch(url, {
        method: 'GET',
    });

    const parsedResponse = await response.arrayBuffer();
    return parsedResponse;
};

export const getUploadTemplate = async () => {
    const url = new URL(`${quant}/pacing/uploadtemplate/`);

    const response = await fetch(url, {
        method: 'GET',
    });

    const parsedResponse = await response.arrayBuffer();
    return parsedResponse;
};
