import styled from 'styled-components';
import { DialogTitle, IconButton } from '@material-ui/core';

export const DialogTitleCloseAction = styled(IconButton)`
    margin-left: auto !important;
    margin-right: -10px !important;
    margin-top: -5px !important;
`;

export const DialogTitleActions = styled.div`
    flex-grow: 1;
    justify-self: flex-end;
`;

export const DialogTitleChildren = styled.div``;

export const DialogTitleStyled = styled(DialogTitle)`
    h2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: flex-start;
    }
`;
