// @flow
import { apiCall, quantUrl } from './BaseApi';

const quant = quantUrl || '';

export const getAssetClasses = async () => {
    const url = `${quant}/pacing/asset-classes/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const getGeographies = async () => {
    const url = `${quant}/pacing/geographies/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const getOmniStrategyMapping = async () => {
    const url = `${quant}/pacing/omni-strategy-mapping/`;
    const method = 'GET';
    return apiCall(url, method);
};

export const getSPIndex = async () => {
    const url = `${quant}/pacing/sp-index/`;
    const method = 'GET';
    return apiCall(url, method);
};

/**
 * Retrieves all strategies with all fields
 */
export const getStrategyParameters = async () => {
    const url = `${quant}/pacing/strategies/`;
    const method = 'GET';
    return apiCall(url, method);
};

/**
 *  Retrieves a filtered list of Strategies with unique strategy field with only
 * 'strategy', 'w_name', 'ui_name', 'p_name', 'r_name', 'server_name', 'panel_name' fields
 */
export const getUniqueStrategies = async () => {
    const url = `${quant}/pacing/unique-strategies/`;
    const method = 'GET';
    return apiCall(url, method);
};

/**
 * Daily Valuation Engine Betas static data.
 * Used for omni import /calc-forward-nav parameters.
 */
export const getDVEBetas = async () => {
    const url = `${quant}/pacing/dve-betas/`;
    const method = 'GET';
    return apiCall(url, method);
};

/**
 * Get SPI client list.
 */
export const getClients = async () => {
    const url = `${quant}/pacing/clients/`;
    const method = 'GET';
    const results = await apiCall(url, method);
    const newResults = results.map((row) => {
        return {
            id: row[0],
            name: row[1],
        };
    });
    return newResults;
};
