// @flow
import React, { useContext } from 'react';
import { InputLabel, Grid } from '@ssgglobal/techintnue'
import { InputDateNewInvestments } from 'utils';
import { PacingContext, LandingContext } from 'context';

function renderStartDateVersion(
    disableCheck,
    quarter,
    reportDate,
    setStartDate,
    year, 
    setUserEdited
) {
    return (
        <InputDateNewInvestments
            quarter={quarter}
            year={year}
            updateDate={setStartDate}
            reportDate={reportDate}
            disableCheck={disableCheck}
            setUserEdited={setUserEdited}
        />
    );
}

type Props = {
    disableCheck: boolean,
    quarter: string,
    setStartDate: Function,
    startDate: string,
    useForwardNav: boolean,
    year: string,
    setUserEdited: Function,
};

export default function({
    disableCheck,
    quarter,
    setStartDate,
    startDate,
    useForwardNav,
    year,
    setUserEdited,
}: Props) {
    const { reportDate } = useContext(LandingContext);
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);

    return (
        <>
            <Grid container item sx={{ height: 'fit-content' }}>
                <InputLabel sx={{ height: '16px' }}>Start Date for New Investments</InputLabel>
                <Grid item md={12} style={{padding: '0'}}>
                    {pacingClientReadOnlyAndSpiClientId() ? (
                        <div>{startDate}</div>
                    ) : (
                        renderStartDateVersion(
                            disableCheck,
                            quarter,
                            reportDate,
                            setStartDate,
                            year,
                            setUserEdited
                        )
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <InputLabel md={12} style={{ padding: '0', margin: 0, fontSize: '8px' }}>
                    NAV Used: {useForwardNav ? 'FwdNAV' : 'AdjNAV'}
                </InputLabel>
            </Grid>
        </>
    );
}
