// @flow
import React, { useContext } from 'react';
import { PacingAnalysisContext } from 'context';
import {
    CommitmentSensitivityGraph,
    CommitmentSensitivityIncrement,
} from './components';

type Props = {
    viewYear: boolean,
};

const CommitmentSensitivityGraphParent = ({ viewYear }: Props) => {
    const { setIncrement } = useContext(PacingAnalysisContext);

    const handleIncrementChange = ({ target: { value } }) => {
        let parsedNum = parseInt(value, 10);
        if (parsedNum < 0) {
            parsedNum = 0;
        } else if (parsedNum > 250) {
            parsedNum = 250;
        }

        setIncrement(parsedNum);
    };

    return (
        <>
            <CommitmentSensitivityIncrement
                updateIncrement={handleIncrementChange}
            />
            <CommitmentSensitivityGraph viewYear={viewYear} />
        </>
    );
};

export default CommitmentSensitivityGraphParent;
